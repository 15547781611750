import cx from "classnames";
import React, { ReactElement, ReactNode } from "react";

import styles from "./EngagementPanelSection.module.scss";

interface Props {
    children: ReactNode;
    className?: string;
}

export function EngagementPanelSection(props: Props): ReactElement {
    return <div className={cx(styles.sectionContainer, props.className)}>{props.children}</div>;
}

export function EngagementPanelSectionLabel(props: Props): ReactElement {
    return <div className={cx(styles.sectionLabel, props.className)}>{props.children}</div>;
}

export function EngagementPanelSectionValue(props: Props): ReactElement {
    return <div className={cx(styles.sectionValue, props.className)}>{props.children}</div>;
}
