import { useQuery, UseQueryResult } from "@tanstack/react-query";

import { useChromeExtensionId } from "../hooks/chromeExtensionId";
import { sendDataToExtension } from "../util/ChromeExtensionUtil";

import { QueryKey } from "./queryKeys";

export function useChromeExtensionInstalledQuery(): UseQueryResult<boolean> {
    const extensionId = useChromeExtensionId();
    return useQuery<boolean>(
        [QueryKey.ChromeExtensionInstalled, extensionId],
        // Attempt to send a message to the Chrome extension.
        // If any response is returned, the extension is installed.
        () =>
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            sendDataToExtension(extensionId!, "readVersion")
                .then(() => true)
                .catch(() => false),
        { enabled: extensionId !== undefined, networkMode: "always" },
    );
}
