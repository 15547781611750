import { useSnackbar } from "notistack";
import React, { ReactElement } from "react";
import { useIntercom } from "react-use-intercom";

import useAmplitudeTracker from "../../hooks/useAmplitudeTracker";
import { useGetGuidePdfMutation } from "../../mutations/getGuidePdf";
import { Guide } from "../../network/responseTypes";
import { downloadFile } from "../../util/DownloadUtil";
import ExportAsFormatButton from "../ExportAsFormatButton/ExportAsFormatButton";

interface Props {
    guide: Guide;
}

function GuideDownloadAsPdfButton({ guide }: Props): ReactElement {
    const { track } = useAmplitudeTracker();
    const intercom = useIntercom();
    const { mutate: getGuidePdf, isLoading } = useGetGuidePdfMutation(guide.id);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const getPdf = () => {
        track("Export Guide as PDF Button Clicked");
        intercom.trackEvent("clicked_export_guide_as_pdf", { guideId: guide.id });
        enqueueSnackbar("Preparing PDF…", {
            variant: "info",
            autoHideDuration: null,
        });

        getGuidePdf(
            {},
            {
                onSuccess: (data) => {
                    downloadFile(data, {
                        filename: `${guide.title}.pdf`,
                        mimeType: "application/pdf",
                    });
                    closeSnackbar();
                },
                onError: () => {
                    closeSnackbar();
                    enqueueSnackbar("Failed to process pdf. Please try again.", {
                        variant: "error",
                    });
                },
            },
        );
    };
    return (
        <ExportAsFormatButton
            title="PDF"
            description="Best for adding as an attachment"
            type="download"
            ariaLabel="Download as PDF"
            ariaDescription="Best for adding as an attachment"
            disabled={isLoading}
            onClick={getPdf}
            data-dd-action-name="Download Guide PDF"
        />
    );
}

export default GuideDownloadAsPdfButton;
