import { useMutation } from "@tanstack/react-query";
import Axios from "axios";

import { Guide } from "../network/responseTypes";
import { useInferGuideInstructionsMutation } from "../pages/onboarding/upload-guide/operations.generated";
import { ChromeExtensionCaptureData } from "../queries/chromeExtensionCaptureData";

export function useCreateGuideFromChromeExtensionMutation() {
    const [mutate] = useInferGuideInstructionsMutation();
    return useMutation<Guide, Error, ChromeExtensionCaptureData>(async (chromeExtensionData) => {
        const screenshots = chromeExtensionData.steps.map((step) => step.screenshot_data_url);
        const recordedSessionWithoutScreenshot = {
            ...chromeExtensionData,
            steps: chromeExtensionData.steps.map((step) => ({
                ...step,
                screenshot_data_url: undefined,
            })),
        };
        const { data: guide } = await Axios.post<Guide>(
            "/api/guides/",
            recordedSessionWithoutScreenshot,
        );

        if (screenshots.length !== guide.steps.length) {
            throw new Error("Screenshots and guide steps are not the same length");
        }
        await Promise.all([
            mutate({ variables: { guideId: guide.id } }),
            ...screenshots.map((screenshot, index) =>
                Axios.post("/api/screenshot/upload", {
                    screenshot_data_url: screenshot,
                    step_id: guide.steps[index].id,
                }),
            ),
        ]);

        return guide;
    });
}
