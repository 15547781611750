import { Button } from "@mui/material";
import cx from "classnames";
import React, { ReactElement, useContext, useState } from "react";

import { UserContext } from "../../contexts/user";
import CrownYellow from "../../icons/crown-yellow.svg";
import InviteUserDialog from "../InviteUserDialog/InviteUserDialog";
import SecondaryButton from "../SecondaryButton/SecondaryButton";

import styles from "./InviteUserButton.module.scss";

interface Props {
    buttonType: "primary" | "secondary";
    className?: string;
}

function InviteUserButton(props: Props): ReactElement {
    const { buttonType, className } = props;
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const {
        organization: { subscription },
    } = useContext(UserContext);

    const prefix =
        subscription?.tier === "FREE" ? (
            <img src={CrownYellow} alt="" className={styles.crownIcon} />
        ) : (
            <span aria-hidden>+&nbsp;</span>
        );
    return (
        <>
            {buttonType === "primary" ? (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setIsDialogOpen(true)}
                    className={cx(styles.button, className)}
                >
                    {prefix}Invite people
                </Button>
            ) : (
                <SecondaryButton
                    onClick={() => setIsDialogOpen(true)}
                    className={cx(styles.button, className)}
                >
                    {prefix}Invite people
                </SecondaryButton>
            )}
            <InviteUserDialog isOpen={isDialogOpen} onClose={() => setIsDialogOpen(false)} />
        </>
    );
}

export default InviteUserButton;
