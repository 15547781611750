import { useState } from "react";

type TimerHookResult = {
    time: number;
    start: () => void;
    stop: () => void;
    reset: () => void;
};

const useTimer = (initialTime = 0, interval = 1000): TimerHookResult => {
    const [time, setTime] = useState<number>(initialTime);
    const [timerId, setTimerId] = useState<number | undefined>(undefined);

    const start = (): void => {
        if (!timerId) {
            const id = window.setInterval(() => setTime((t) => t + interval), interval);
            setTimerId(id);
        }
    };

    const stop = (): void => {
        if (timerId) {
            window.clearInterval(timerId);
            setTimerId(undefined);
        }
    };

    const reset = (): void => {
        stop();
        setTime(initialTime);
    };

    return { time, start, stop, reset };
};

export default useTimer;
