import { MicNone, RadioButtonChecked } from "@mui/icons-material";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { Button, Icon, IconButton, Popover } from "@mui/material";
import React, { useCallback, useState } from "react";

import useAmplitudeTracker from "../../hooks/useAmplitudeTracker";

import DeviceSelection from "./DeviceSelection";
import styles from "./RecordButton.module.scss";
import ToolbarButton from "./ToolbarButton";

function RecordPopover(props: {
    anchorEl: HTMLButtonElement | null;
    onClose: () => void;
    onRecord: () => void;
    onSynthesize: () => void;
    onStop: () => void;
    onSelectDevice: (deviceId: string) => void;
    selectedDeviceId?: string;
}) {
    const { anchorEl, onClose } = props;

    return (
        <Popover
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            classes={{ paper: styles.paper }}
        >
            <div className={styles.topBar}>
                <div className={styles.title}>Voiceover</div>
                <IconButton onClick={onClose} size="small" aria-label="Close" edge="end">
                    <Icon>close</Icon>
                </IconButton>
            </div>
            <div className={styles.body}>
                Record your voice or auto-generate an audio clip to narrate this step.
            </div>
            <DeviceSelection
                shouldPrompt={!!anchorEl}
                onSelect={props.onSelectDevice}
                selectedDeviceId={props.selectedDeviceId}
            />
            <Button
                variant="outlined"
                color="primary"
                className={styles.synthesizeButton}
                onClick={props.onSynthesize}
            >
                <AutoAwesomeIcon fontSize="small" className={styles.recordIcon} />
                Auto-generate
            </Button>
            <Button
                variant="contained"
                color="primary"
                className={styles.recordButton}
                onClick={props.onRecord}
            >
                <RadioButtonChecked fontSize="small" className={styles.recordIcon} />
                Start recording
            </Button>
        </Popover>
    );
}

function RecordButton(props: {
    onSelectDevice: (deviceId: string) => void;
    selectedDeviceId?: string;
    onRecord: () => void;
    disabled: boolean;
    onSynthesize: () => void;
    onStop: () => void;
}) {
    const { onStop, onRecord, onSynthesize } = props;
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const { track } = useAmplitudeTracker();
    const openVoiceoverPopover = useCallback(
        (evt: React.MouseEvent<HTMLButtonElement>) => {
            track("Record Voiceover Button Clicked");
            setAnchorEl(evt.currentTarget);
        },
        [setAnchorEl, track],
    );

    const handleClose = useCallback(() => {
        onStop();
        setAnchorEl(null);
    }, [setAnchorEl, onStop]);
    return (
        <>
            <ToolbarButton
                title="Record voiceover"
                onClick={(e) => openVoiceoverPopover(e)}
                disabled={props.disabled}
            >
                <MicNone />
            </ToolbarButton>
            <RecordPopover
                anchorEl={anchorEl}
                onClose={handleClose}
                onRecord={onRecord}
                onSynthesize={onSynthesize}
                onStop={handleClose}
                onSelectDevice={props.onSelectDevice}
                selectedDeviceId={props.selectedDeviceId}
            />
        </>
    );
}

export default RecordButton;
