import { EditOutlined } from "@mui/icons-material";
import React, { ReactElement, useCallback, useState, useContext } from "react";

import { useSubscriptionTier } from "../../hooks/subscriptionTier";
import { UserContext } from "../../contexts/user";
import CrownYellow from "../../icons/crown-yellow.svg";
import EditScreenshotDialog from "../EditScreenshotDialog/EditScreenshotDialog";
import ProFeaturePopover from "../ProFeaturePopover/ProFeaturePopover";

import ToolbarButton from "./ToolbarButton";
import styles from "./ToolbarButton.module.scss";

interface Props {
    onEditScreenshot: (file: File, imageState: string) => void;
    guideId: string;
    step: {
        id: string;
    };
}

function EditScreenshotButton(props: Props): ReactElement {
    const { guideId, step, onEditScreenshot } = props;
    const subscriptionTier = useSubscriptionTier();
    const {
        organization: { subscription },
    } = useContext(UserContext);
    const is_trial = subscription ? subscription.is_trial : false;
    const [isEditScreenshotDialogOpen, setIsEditScreenshotDialogOpen] = useState(false);
    const [upgradeToEditPopoverAnchorEl, setUpgradeToEditPopoverAnchorEl] =
        useState<HTMLElement | null>(null);

    const isEditScreenshotEnabled = subscriptionTier !== "FREE";
    const shouldShowCrownIcon = subscriptionTier === "FREE" || is_trial;

    const onClick = useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            if (isEditScreenshotEnabled) {
                setIsEditScreenshotDialogOpen(true);
            } else {
                setUpgradeToEditPopoverAnchorEl(event.currentTarget);
            }
        },
        [isEditScreenshotEnabled],
    );

    return (
        <>
            <ToolbarButton title="Edit screenshot" onClick={onClick}>
                <EditOutlined />
                {shouldShowCrownIcon && (
                    <img src={CrownYellow} alt="" className={styles.crownIcon} />
                )}
            </ToolbarButton>
            <EditScreenshotDialog
                onEditScreenshot={onEditScreenshot}
                isOpen={isEditScreenshotDialogOpen}
                guideId={guideId}
                stepId={step.id}
                onClose={() => setIsEditScreenshotDialogOpen(false)}
            />
            <ProFeaturePopover
                open={Boolean(upgradeToEditPopoverAnchorEl)}
                anchorEl={upgradeToEditPopoverAnchorEl}
                onClose={() => setUpgradeToEditPopoverAnchorEl(null)}
                title="Markup your screenshots"
                description="Get a full suite of image editing tools to highlight, crop, or draw on your screenshots."
            />
        </>
    );
}

export default EditScreenshotButton;
