import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { Button } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { SnackbarKey, useSnackbar } from "notistack";
import React, { ReactElement, useCallback, useId, useRef, useState } from "react";

import { useAddVideoVideoStep } from "../../hooks/addVideoStep";
import { QueryKey } from "../../queries/queryKeys";

import styles from "./AddStepsButtons.module.scss";

interface Props {
    stepsInsertionOrder: number;
    guideId: string;
}

function UploadVideoButton({ guideId, stepsInsertionOrder }: Props): ReactElement {
    const inputRef = useRef<HTMLInputElement>(null);

    const [uploadingToastKey, setUploadingToastKey] = useState<SnackbarKey | null>(null);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const reactQueryClient = useQueryClient();
    const { mutate: addVideoStep, loading } = useAddVideoVideoStep({
        onError: () => {
            if (uploadingToastKey) {
                closeSnackbar(uploadingToastKey);
                setUploadingToastKey(null);
            }
            enqueueSnackbar(
                "Something went wrong when trying to upload the video. Please try again.",
                { variant: "error" },
            );
        },
        onSuccess: () => {
            // Remove when GuideDetail is migrated to graphql
            void reactQueryClient.invalidateQueries([QueryKey.GuideDetail, guideId]);
            if (uploadingToastKey) {
                closeSnackbar(uploadingToastKey);
                setUploadingToastKey(null);
            }
            enqueueSnackbar("The video has been successfully uploaded.", {
                variant: "success",
            });
        },
    });
    const showWaitForUploadCompletionToast = useCallback(() => {
        enqueueSnackbar("Please wait for the video upload to finish", {
            variant: "info",
        });
    }, [enqueueSnackbar]);
    const onFileSelected = useCallback(
        (file: File | undefined) => {
            if (!file) {
                console.error("No file selected");
                return;
            }
            if (uploadingToastKey) {
                showWaitForUploadCompletionToast();
                return;
            }
            addVideoStep({
                guideId,
                insertionIndex: stepsInsertionOrder,
                file,
            });
            const snackbarKey = enqueueSnackbar("Uploading video…", {
                persist: true,
                variant: "info",
            });
            setUploadingToastKey(snackbarKey);
        },
        [
            addVideoStep,
            enqueueSnackbar,
            guideId,
            showWaitForUploadCompletionToast,
            stepsInsertionOrder,
            uploadingToastKey,
        ],
    );

    const addStepsButtonId = useId();

    return (
        <>
            <Button
                variant="outlined"
                startIcon={<FileUploadOutlinedIcon />}
                onClick={() => {
                    if (loading) {
                        showWaitForUploadCompletionToast();
                        return;
                    }
                    inputRef.current?.click();
                }}
                aria-label="Upload video"
                id={addStepsButtonId}
                data-dd-action-name="Upload Video Button"
            >
                Upload video
            </Button>
            <input
                type="file"
                ref={inputRef}
                className={styles.hiddenUploadInput}
                accept="video/*"
                onChange={(event) => {
                    if (loading) {
                        showWaitForUploadCompletionToast();
                        return;
                    }
                    onFileSelected(event.target.files?.[0]);
                }}
            />
        </>
    );
}

export default UploadVideoButton;
