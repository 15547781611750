import React, { ReactElement, useCallback, useEffect } from "react";

import { useViewEventRecorder } from "../../hooks/viewEventRecorder";
import { ViewEventSource } from "../../mutations/recordGuideView";
import GuideSlideshow, {
    Props as GuideSlideshowProps,
    SlideshowStatus,
} from "../GuideSlideshow/GuideSlideshow";

interface Props extends GuideSlideshowProps {
    eventSource: ViewEventSource;
}

function EventTrackingGuideSlideshow(props: Props): ReactElement {
    const {
        status,
        guide,
        stepIndex,
        eventSource,
        onStart: onStartInternal,
        ...otherProps
    } = props;
    const { id: guideId } = guide;

    const recordViewEvent = useViewEventRecorder({
        source: eventSource,
    });

    const onStart = useCallback(() => {
        recordViewEvent({ type: "guide", guideId, force: true });
        onStartInternal?.();
    }, [recordViewEvent, guideId, onStartInternal]);

    useEffect(() => {
        // When slideshow begins in the Inactive state, both onStart and this useEffect hook will
        // invoke recordViewEvent. We rely upon useViewEventRecorder's ability to dedupe guide views
        // for the same guide ID, assuring that we don't record two guide view events.
        if (status === SlideshowStatus.Active) {
            recordViewEvent({
                type: "guide",
                guideId,
            });
        }
    }, [status, guideId, recordViewEvent]);

    const currentStepId = guide.steps[stepIndex]?.id;
    const stepCount = guide.steps.length;
    useEffect(() => {
        if (currentStepId && status === SlideshowStatus.Active) {
            recordViewEvent({
                type: "step",
                stepId: currentStepId,
                stepIndex,
                totalSteps: stepCount,
            });
        }
    }, [recordViewEvent, currentStepId, stepIndex, stepCount, status]);

    return (
        <GuideSlideshow
            status={status}
            guide={guide}
            stepIndex={stepIndex}
            onStart={onStart}
            {...otherProps}
        />
    );
}

export default EventTrackingGuideSlideshow;
