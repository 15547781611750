import cx from "classnames";
import React, { ReactElement, useCallback, useRef } from "react";

import { FullscreenContextProvider } from "../../contexts/fullscreen";
import { useHideIntercom } from "../../hooks/hideIntercom";
import { Guide } from "../../network/responseTypes";
import { isInterestPointScalingFixEnabled } from "../../util/VersionUtil";
import EmbedInactiveView from "../GuideEmbed/InactiveView";
import EmbedStepView from "../GuideEmbed/StepView";
import PlaceholderMessage from "../PlaceholderMessage/PlaceholderMessage";

import styles from "./GuideSlideshow.module.scss";

export enum SlideshowStatus {
    Inactive,
    Active,
}

export interface Props {
    status: SlideshowStatus;
    guide: Guide;
    onStart?: () => void;
    onComplete?: () => void;
    stepIndex: number;
    onPreviousStep: () => void;
    onNextStep: () => void;
    onRestart: () => void;
    onSetStep: (stepIndex: number) => void;
    autoZoomScreenshots?: boolean;
    className?: string;
}

function GuideSlideshow(props: Props): ReactElement {
    const {
        status,
        guide,
        onStart,
        onComplete,
        stepIndex,
        onPreviousStep,
        onNextStep,
        onRestart,
        onSetStep,
        autoZoomScreenshots = false,
        className,
    } = props;

    const stepCount = guide?.steps.length || 0;
    const isFirstStep = stepIndex === 0;
    const isLastStep = stepIndex === stepCount - 1;

    useHideIntercom();

    const handleNextStep = useCallback(() => {
        if (!isLastStep) {
            onNextStep();
            return;
        }
        onComplete?.();
    }, [isLastStep, onNextStep, onComplete]);

    const hasSteps = guide.steps.length > 0;

    const getStepScreenshotUrl = useCallback(
        (stepIndex: number) => {
            const step = guide.steps[stepIndex];
            return step && step.type === "action" ? step.screenshot.url : undefined;
        },
        [guide],
    );

    const getStepColor = useCallback(
        (stepIndex: number) => {
            const step = guide.steps[stepIndex];
            return step?.type === "action" ? step.color ?? undefined : undefined;
        },
        [guide],
    );

    const showClickableInterestPointHighlighter =
        !!guide.extension_version && isInterestPointScalingFixEnabled(guide.extension_version);

    const containerElRef = useRef<HTMLDivElement>(null);

    return (
        <FullscreenContextProvider fullscreenableElRef={containerElRef}>
            <div className={cx(styles.container, className)} ref={containerElRef}>
                {status === SlideshowStatus.Inactive && (
                    <EmbedInactiveView
                        guide={guide}
                        onStartGuide={onStart}
                        className={styles.inactiveView}
                    />
                )}
                {status === SlideshowStatus.Active && hasSteps && (
                    <EmbedStepView
                        guideId={guide.id}
                        author={guide.author}
                        organization={guide.organization}
                        step={guide?.steps[stepIndex]}
                        activeStepIndex={stepIndex}
                        onNextStep={handleNextStep}
                        onPreviousStep={onPreviousStep}
                        onRestart={onRestart}
                        onSetStep={onSetStep}
                        getStepScreenshotUrl={getStepScreenshotUrl}
                        getStepColor={getStepColor}
                        isFirstStep={isFirstStep}
                        isLastStep={isLastStep}
                        stepCount={stepCount}
                        autoZoomScreenshots={autoZoomScreenshots}
                        showClickableInterestPointHighlighter={
                            showClickableInterestPointHighlighter
                        }
                        className={styles.stepView}
                    />
                )}
                {status === SlideshowStatus.Active && !hasSteps && (
                    <PlaceholderMessage
                        title="There are no steps in this guide."
                        className={styles.placeholder}
                    />
                )}
            </div>
        </FullscreenContextProvider>
    );
}

export default GuideSlideshow;
