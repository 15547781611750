/* eslint-disable */
// THIS IS A GENERATED FILE. DO NOT EDIT DIRECTLY
// To generate new type definitions run `npm run generate-schema-types`
import * as Types from '../../../@types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteStepMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteStepMutation = { readonly __typename?: 'Mutation', readonly deleteStep?: { readonly __typename?: 'DeleteStepMutation', readonly ok?: boolean | null } | null };

export type AddNarrationMutationVariables = Types.Exact<{
  input: Types.AddNarrationMutationInput;
}>;


export type AddNarrationMutation = { readonly __typename?: 'Mutation', readonly addNarration?: { readonly __typename?: 'AddNarrationMutation', readonly ok: boolean } | null };

export type SynthesizeNarrationMutationVariables = Types.Exact<{
  input: Types.SynthesizeNarrationMutationInput;
}>;


export type SynthesizeNarrationMutation = { readonly __typename?: 'Mutation', readonly synthesizeNarration?: { readonly __typename?: 'SynthesizeNarrationMutation', readonly ok: boolean } | null };

export type DeleteNarrationMutationVariables = Types.Exact<{
  narrationId: Types.Scalars['ID']['input'];
}>;


export type DeleteNarrationMutation = { readonly __typename?: 'Mutation', readonly deleteNarration?: { readonly __typename?: 'DeleteNarrationMutation', readonly ok?: boolean | null } | null };


export const DeleteStepDocument = gql`
    mutation DeleteStep($id: ID!) {
  deleteStep(id: $id) {
    ok
  }
}
    `;
export type DeleteStepMutationFn = Apollo.MutationFunction<DeleteStepMutation, DeleteStepMutationVariables>;

/**
 * __useDeleteStepMutation__
 *
 * To run a mutation, you first call `useDeleteStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStepMutation, { data, loading, error }] = useDeleteStepMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteStepMutation(baseOptions?: Apollo.MutationHookOptions<DeleteStepMutation, DeleteStepMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteStepMutation, DeleteStepMutationVariables>(DeleteStepDocument, options);
      }
export type DeleteStepMutationHookResult = ReturnType<typeof useDeleteStepMutation>;
export type DeleteStepMutationResult = Apollo.MutationResult<DeleteStepMutation>;
export type DeleteStepMutationOptions = Apollo.BaseMutationOptions<DeleteStepMutation, DeleteStepMutationVariables>;
export const AddNarrationDocument = gql`
    mutation AddNarration($input: AddNarrationMutationInput!) {
  addNarration(input: $input) {
    ok
  }
}
    `;
export type AddNarrationMutationFn = Apollo.MutationFunction<AddNarrationMutation, AddNarrationMutationVariables>;

/**
 * __useAddNarrationMutation__
 *
 * To run a mutation, you first call `useAddNarrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNarrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNarrationMutation, { data, loading, error }] = useAddNarrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddNarrationMutation(baseOptions?: Apollo.MutationHookOptions<AddNarrationMutation, AddNarrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddNarrationMutation, AddNarrationMutationVariables>(AddNarrationDocument, options);
      }
export type AddNarrationMutationHookResult = ReturnType<typeof useAddNarrationMutation>;
export type AddNarrationMutationResult = Apollo.MutationResult<AddNarrationMutation>;
export type AddNarrationMutationOptions = Apollo.BaseMutationOptions<AddNarrationMutation, AddNarrationMutationVariables>;
export const SynthesizeNarrationDocument = gql`
    mutation SynthesizeNarration($input: SynthesizeNarrationMutationInput!) {
  synthesizeNarration(input: $input) {
    ok
  }
}
    `;
export type SynthesizeNarrationMutationFn = Apollo.MutationFunction<SynthesizeNarrationMutation, SynthesizeNarrationMutationVariables>;

/**
 * __useSynthesizeNarrationMutation__
 *
 * To run a mutation, you first call `useSynthesizeNarrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSynthesizeNarrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [synthesizeNarrationMutation, { data, loading, error }] = useSynthesizeNarrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSynthesizeNarrationMutation(baseOptions?: Apollo.MutationHookOptions<SynthesizeNarrationMutation, SynthesizeNarrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SynthesizeNarrationMutation, SynthesizeNarrationMutationVariables>(SynthesizeNarrationDocument, options);
      }
export type SynthesizeNarrationMutationHookResult = ReturnType<typeof useSynthesizeNarrationMutation>;
export type SynthesizeNarrationMutationResult = Apollo.MutationResult<SynthesizeNarrationMutation>;
export type SynthesizeNarrationMutationOptions = Apollo.BaseMutationOptions<SynthesizeNarrationMutation, SynthesizeNarrationMutationVariables>;
export const DeleteNarrationDocument = gql`
    mutation DeleteNarration($narrationId: ID!) {
  deleteNarration(narrationId: $narrationId) {
    ok
  }
}
    `;
export type DeleteNarrationMutationFn = Apollo.MutationFunction<DeleteNarrationMutation, DeleteNarrationMutationVariables>;

/**
 * __useDeleteNarrationMutation__
 *
 * To run a mutation, you first call `useDeleteNarrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNarrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNarrationMutation, { data, loading, error }] = useDeleteNarrationMutation({
 *   variables: {
 *      narrationId: // value for 'narrationId'
 *   },
 * });
 */
export function useDeleteNarrationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteNarrationMutation, DeleteNarrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteNarrationMutation, DeleteNarrationMutationVariables>(DeleteNarrationDocument, options);
      }
export type DeleteNarrationMutationHookResult = ReturnType<typeof useDeleteNarrationMutation>;
export type DeleteNarrationMutationResult = Apollo.MutationResult<DeleteNarrationMutation>;
export type DeleteNarrationMutationOptions = Apollo.BaseMutationOptions<DeleteNarrationMutation, DeleteNarrationMutationVariables>;