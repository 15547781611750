import { useEffect } from "react";

type Callback = () => void;

export function useOnWindowResize(callback: Callback): void {
    useEffect(() => {
        window.addEventListener("resize", callback);
        return () => window.removeEventListener("resize", callback);
    }, [callback]);
}
