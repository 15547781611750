import { useCallback, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { SnackbarKey, useSnackbar } from "notistack";

import { QueryKey } from "../../queries/queryKeys";

import { useAddNarrationMutation } from "./operations.generated";

function useUploadNarration(guideId: string, stepId: string) {
    const [uploadingToastKey, setUploadingToastKey] = useState<SnackbarKey | null>(null);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const reactQueryClient = useQueryClient();
    const [addNarration, { loading }] = useAddNarrationMutation({
        onError: () => {
            if (uploadingToastKey) {
                closeSnackbar(uploadingToastKey);
                setUploadingToastKey(null);
            }
            enqueueSnackbar(
                "Something went wrong when trying to upload your voiceover. Please try again.",
                { variant: "error" },
            );
        },
        onCompleted: () => {
            // Remove when GuideDetail is migrated to graphql
            void reactQueryClient.invalidateQueries([QueryKey.GuideDetail, guideId]);
            if (uploadingToastKey) {
                closeSnackbar(uploadingToastKey);
                setUploadingToastKey(null);
            }
            enqueueSnackbar("The voiceover has been successfully uploaded.", {
                variant: "success",
            });
        },
    });
    const showWaitForUploadCompletionToast = useCallback(() => {
        enqueueSnackbar("Please wait for the voiceover upload to finish", {
            variant: "info",
        });
    }, [enqueueSnackbar]);
    const uploadNarration = useCallback(
        (file: File | undefined) => {
            if (!file) {
                console.error("No file selected");
                return;
            }
            if (uploadingToastKey) {
                showWaitForUploadCompletionToast();
                return;
            }
            addNarration({
                variables: {
                    input: {
                        stepId,
                        file,
                    },
                },
            }).catch(console.error);
            const snackbarKey = enqueueSnackbar("Uploading voiceover…", {
                persist: true,
                variant: "info",
            });
            setUploadingToastKey(snackbarKey);
        },
        [
            addNarration,
            enqueueSnackbar,
            stepId,
            uploadingToastKey,
            showWaitForUploadCompletionToast,
        ],
    );
    return {
        loading,
        uploadNarration,
    };
}

export default useUploadNarration;
