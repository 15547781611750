import React, { ReactElement, useRef } from "react";

import { Guide } from "../../network/responseTypes";
import CopyGuideLinkButton from "../CopyGuideLinkButton/CopyGuideLinkButton";

import styles from "./ShareGuideLinkTab.module.scss";

interface Props {
    guide: Guide;
}

function ShareGuideLinkTab(props: Props): ReactElement {
    const { guide } = props;
    const inputEl = useRef<HTMLInputElement | null>(null);
    const guideUrl = `${window.location.origin}/guides/${guide.id}`;

    return (
        <div className={styles.container}>
            <input
                type="text"
                aria-label="Link"
                ref={inputEl}
                onClick={() => inputEl.current?.select()}
                className={styles.linkInput}
                readOnly
                value={guideUrl}
            />
            <CopyGuideLinkButton
                guideId={guide.id}
                variant="button"
                className={styles.copyLinkButton}
            />
        </div>
    );
}

export default ShareGuideLinkTab;
