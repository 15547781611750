import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

import { useChromeExtensionId } from "../hooks/chromeExtensionId";
import { sendDataToExtension } from "../util/ChromeExtensionUtil";
import { isBigListCaptureDataEnabled } from "../util/VersionUtil";

import { useChromeExtensionVersionQuery } from "./chromeExtensionVersion";
import { QueryKey } from "./queryKeys";

interface CapturedStep {
    screenshot_data_url: string | undefined;
}

export interface ChromeExtensionCaptureData {
    steps: ReadonlyArray<CapturedStep>;
    steps_insertion_order: null | number;
    guide_id: null | number;
    extension_version: string;
    duration: number;
}

export function useChromeExtensionCaptureDataQuery(
    options?: UseQueryOptions<ChromeExtensionCaptureData>,
): UseQueryResult<ChromeExtensionCaptureData> {
    const extensionId = useChromeExtensionId();
    const { data: version } = useChromeExtensionVersionQuery();
    return useQuery<ChromeExtensionCaptureData>(
        [QueryKey.ChromeExtensionCaptureData, extensionId],
        () => {
            return sendDataToExtension(
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                extensionId!,
                version && isBigListCaptureDataEnabled(version)
                    ? "readCapturedData"
                    : "readSession",
            );
        },
        {
            enabled: extensionId !== undefined && !!version,
            networkMode: "always",
            staleTime: Infinity,
            ...options,
        },
    );
}
