import React, { ReactElement, ReactNode, useContext } from "react";

import { UserContext } from "../../contexts/user";
import { useIsMatchingRoute } from "../../hooks/isMatchingRoute";
import NavigationSidebar from "../NavigationSidebar/NavigationSidebar";

import styles from "./SidebarLayout.module.scss";

interface Props {
    children: ReactNode;
}

const hiddenSidebarRoutes = [
    { path: "/guides", matchChildRoutes: true },
    { path: "/videos", matchChildRoutes: true },
];

function SidebarLayout(props: Props): ReactElement {
    const { isAuthenticated } = useContext(UserContext);
    const { children } = props;
    const sidebarVisible = !useIsMatchingRoute(hiddenSidebarRoutes) && isAuthenticated;
    return (
        <div className={styles.container}>
            {sidebarVisible && <NavigationSidebar className={styles.sidebar} />}
            <main className={sidebarVisible ? styles.mainContentWithSidebar : styles.mainContent}>
                {children}
            </main>
        </div>
    );
}

export default SidebarLayout;
