/* eslint-disable */
// THIS IS A GENERATED FILE. DO NOT EDIT DIRECTLY
// To generate new type definitions run `npm run generate-schema-types`
import * as Types from '../../../../@types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InferGuideInstructionsMutationVariables = Types.Exact<{
  guideId: Types.Scalars['ID']['input'];
}>;


export type InferGuideInstructionsMutation = { readonly __typename?: 'Mutation', readonly inferGuideInstructions?: { readonly __typename?: 'InferGuideInstructionsMutation', readonly guide?: { readonly __typename?: 'GuideType', readonly id: string } | null } | null };


export const InferGuideInstructionsDocument = gql`
    mutation InferGuideInstructions($guideId: ID!) {
  inferGuideInstructions(guideId: $guideId) {
    guide {
      id
    }
  }
}
    `;
export type InferGuideInstructionsMutationFn = Apollo.MutationFunction<InferGuideInstructionsMutation, InferGuideInstructionsMutationVariables>;

/**
 * __useInferGuideInstructionsMutation__
 *
 * To run a mutation, you first call `useInferGuideInstructionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInferGuideInstructionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inferGuideInstructionsMutation, { data, loading, error }] = useInferGuideInstructionsMutation({
 *   variables: {
 *      guideId: // value for 'guideId'
 *   },
 * });
 */
export function useInferGuideInstructionsMutation(baseOptions?: Apollo.MutationHookOptions<InferGuideInstructionsMutation, InferGuideInstructionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InferGuideInstructionsMutation, InferGuideInstructionsMutationVariables>(InferGuideInstructionsDocument, options);
      }
export type InferGuideInstructionsMutationHookResult = ReturnType<typeof useInferGuideInstructionsMutation>;
export type InferGuideInstructionsMutationResult = Apollo.MutationResult<InferGuideInstructionsMutation>;
export type InferGuideInstructionsMutationOptions = Apollo.BaseMutationOptions<InferGuideInstructionsMutation, InferGuideInstructionsMutationVariables>;