import { useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";

import { useEditScreenshotMutation } from "../components/VideoStepEditor/operations.generated";
import { Guide } from "../network/responseTypes";
import { QueryKey } from "../queries/queryKeys";
import { optimisticUpdate } from "../util/ReactQueryUtil";

export function useEditScreenshot(guideId: string) {
    const { enqueueSnackbar } = useSnackbar();
    const reactQueryClient = useQueryClient();

    const [editScreenshot] = useEditScreenshotMutation({
        onError: () => {
            enqueueSnackbar(
                "Something went wrong when trying to edit your screenshot. Please try again.",
                { variant: "error" },
            );
        },
        onCompleted: () => {
            // Remove when GuideDetail is migrated to graphql
            void reactQueryClient.invalidateQueries([QueryKey.GuideDetail, guideId]);
        },
        refetchQueries: ["Screenshot"],
    });

    const mutate: typeof editScreenshot = (options) => {
        const input = options?.variables?.input;

        if (input) {
            const { file, stepId } = input;
            const objUrl = URL.createObjectURL(file);

            optimisticUpdate<Guide>(reactQueryClient, [QueryKey.GuideDetail, guideId], (guide) => ({
                ...guide,
                steps: guide.steps.map((step) =>
                    step.id === stepId && step.type === "action"
                        ? {
                              ...step,
                              screenshot_url: objUrl,
                              screenshot: {
                                  ...step.screenshot,
                                  url: objUrl,
                              },
                          }
                        : step,
                ),
            })).catch(console.error);
        }

        return editScreenshot(options);
    };

    return [mutate];
}
