import { Button, ButtonProps } from "@mui/material";
import React, { ReactElement, useCallback, useState } from "react";

import useAmplitudeTracker from "../../hooks/useAmplitudeTracker";
import SubscriptionUpgradeDialog from "../SubscriptionUpgradeDialog/SubscriptionUpgradeDialog";

type OnClick = NonNullable<ButtonProps["onClick"]>;

function SubscriptionUpgradeButton(props: ButtonProps): ReactElement {
    const { onClick, ...otherProps } = props;
    const [isDialogOpen, setDialogOpen] = useState(false);
    const { track } = useAmplitudeTracker();

    const onClickInternal = useCallback<OnClick>(
        (e) => {
            setDialogOpen(true);
            track("Upgrade Button Clicked");
            onClick?.(e);
        },
        [setDialogOpen, track, onClick],
    );

    return (
        <>
            <Button
                onClick={onClickInternal}
                data-dd-action-name="Upgrade Button"
                {...otherProps}
            />
            <SubscriptionUpgradeDialog isOpen={isDialogOpen} onClose={() => setDialogOpen(false)} />
        </>
    );
}

export default SubscriptionUpgradeButton;
