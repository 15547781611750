import React, { ReactElement, useRef } from "react";
import classnames from "classnames";

import { Guide, Step } from "../../network/responseTypes";
import GuidePreviewInfo from "../GuidePreviewInfo/GuidePreviewInfo";

import GuideScrollItem from "./GuideScrollItem";
import GuideScrollOutline from "./GuideScrollOutline";
import styles from "./GuideScrollView.module.scss";

export interface Props {
    guide: Guide;
    className?: string;
    onStepChange?: (step: Step, stepIndex: number, totalSteps: number) => void;
}

function GuideScrollView({ className, ...props }: Props): ReactElement {
    const { guide } = props;
    const { steps } = guide;
    const refs = useRef<Record<string, HTMLDivElement>>({});

    const scrollToRef = (stepId: string) => {
        refs.current[stepId].scrollIntoView({
            behavior: "smooth",
            block: "center",
        });
    };

    const handleViewStep = (step: Step, stepIndex: number) => {
        if (props.onStepChange) {
            props.onStepChange(step, stepIndex, steps.length);
        }
    };

    return (
        <div className={classnames(styles.container, className)}>
            <div className={styles.leftColumn}>
                <GuideScrollOutline guide={guide} scrollToStep={scrollToRef} />
            </div>
            <div className={styles.middleColumn}>
                <GuidePreviewInfo guide={guide} />
                {steps.map((step, idx) => {
                    return (
                        <GuideScrollItem
                            ref={(el) => {
                                if (el) {
                                    refs.current[step.id] = el;
                                }
                            }}
                            onViewStep={handleViewStep}
                            key={step.id}
                            step={step}
                            index={idx}
                            numSteps={steps.length}
                        />
                    );
                })}
            </div>
            <div className={styles.rightColumn} />
        </div>
    );
}

export default GuideScrollView;
