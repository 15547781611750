import { Button as MuiButton, ButtonProps, Dialog, DialogContent } from "@mui/material";
import React, { ReactElement } from "react";

import styles from "./ConfirmDeletionDialog.module.scss";

interface Props {
    entity: "guide" | "invitation" | "tag";
    isOpen?: boolean;
    isDisabled?: boolean;
    additionalMessage?: string;
    onConfirm: () => void;
    onClose: () => void;
}

function ConfirmDeletionDialog(props: Props): ReactElement {
    const {
        entity,
        isOpen = false,
        isDisabled = false,
        additionalMessage,
        onConfirm,
        onClose,
    } = props;
    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            maxWidth="xs"
            classes={{ paper: styles.dialogPaper }}
        >
            <DialogContent className={styles.dialogContent}>
                <div className={styles.dialogTitle}>Delete {entity}</div>
                <div className={styles.dialogMessage}>
                    Are you sure you want to delete this {entity}? Once this {entity} is deleted,
                    this action cannot be reversed. {additionalMessage}
                </div>
                <Button
                    color="inherit"
                    variant="contained"
                    onClick={onConfirm}
                    disabled={isDisabled}
                    className={styles.deleteButton}
                    data-dd-action-name="`Confirm Delete ${entity}`"
                    fullWidth
                >
                    Delete
                </Button>
                <Button
                    color="primary"
                    variant="outlined"
                    onClick={onClose}
                    className={styles.cancelButton}
                    data-dd-action-name="`Dismiss Delete ${entity}`"
                    fullWidth
                >
                    Cancel
                </Button>
            </DialogContent>
        </Dialog>
    );
}

function Button(props: ButtonProps): ReactElement {
    const { classes, ...otherProps } = props;
    return (
        <MuiButton
            classes={{
                root: styles.buttonRoot,
                contained: styles.buttonContained,
                outlinedPrimary: styles.buttonOutlinedPrimary,
                ...classes,
            }}
            {...otherProps}
        />
    );
}

export default ConfirmDeletionDialog;
