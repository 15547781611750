import { NavigateNext } from "@mui/icons-material";
import { ListItemAvatar, ListItemButton, ListItemText } from "@mui/material";
import cx from "classnames";
import React, { ReactElement } from "react";

import AnonymousAvatar from "../AnonymousAvatar/AnonymousAvatar";
import PersonAvatar from "../PersonAvatar/PersonAvatar";

import { AnonymousEngagementInfo, PersonEngagementInfo } from "./EngagementInsightPopover";
import styles from "./ViewerListItem.module.scss";

type Props = BaseProps & (PersonEngagementInfo | AnonymousEngagementInfo);

interface BaseProps {
    onClick: () => void;
}

function ViewerListItem(props: Props): ReactElement {
    const viewerName = props.type === "anonymous" ? "Anonymous" : props.viewer.fullName;

    const guideViewCount = props.type === "anonymous" ? 1 : props.viewer.viewsCount || 0;
    const viewCountDescription = `${guideViewCount} view${guideViewCount !== 1 ? "s" : ""}`;

    return (
        <ListItemButton
            onClick={() => props.onClick()}
            className={styles.listItem}
            aria-label={`${viewerName}, ${viewCountDescription}`}
        >
            <ListItemAvatar className={styles.listItemAvatar}>
                {props.type === "person" ? (
                    <PersonAvatar
                        person={{
                            first_name: props.viewer.firstName || "",
                            last_name: props.viewer.lastName || "",
                            avatar_url: props.viewer.avatarUrl || "",
                        }}
                        className={styles.avatar}
                    />
                ) : (
                    <AnonymousAvatar className={cx(styles.avatar, styles.anonAvatar)} />
                )}
            </ListItemAvatar>
            <ListItemText
                primary={viewerName}
                secondary={viewCountDescription}
                classes={{
                    primary: styles.name,
                    secondary: styles.numViews,
                }}
            />
            <NavigateNext />
        </ListItemButton>
    );
}

export default ViewerListItem;
