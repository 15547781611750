import React from "react";

// This max-width value was taken from Bootstrap, but this is pretty arbitrary.
// See https://getbootstrap.com/docs/5.0/layout/breakpoints/#available-breakpoints
const MOBILE_MEDIA_QUERY = "screen and (max-width:576px)";

// Use window.matchMedia to avoid changing experiences when the viewport resizes
const { matches: isMobile } = window.matchMedia(MOBILE_MEDIA_QUERY);

export function withMobileExperience(
    NonMobileComponent: React.ComponentType,
    MobileComponent: React.ComponentType,
): React.FunctionComponent {
    const RenderedComponent = () => {
        return isMobile ? <MobileComponent /> : <NonMobileComponent />;
    };

    return RenderedComponent;
}
