import React, { ReactElement } from "react";

import NetworkingWrapper from "../../components/NetworkingWrapper/NetworkingWrapper";
import { useHideIntercom } from "../../hooks/hideIntercom";
import { useRequiredParams } from "../../util/ReactRouterUtil";

import styles from "./VideoPage.module.scss";
import { useVideoDetailQuery } from "./operations.generated";

function VideoPage(): ReactElement {
    useHideIntercom();
    const { videoId } = useRequiredParams(["videoId"]);
    const { data, loading, error } = useVideoDetailQuery({
        variables: { videoId: videoId },
    });
    const videoUrl = data?.videoDetail?.url;
    return (
        <NetworkingWrapper loading={loading} error={error}>
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            {videoUrl && <video controls src={videoUrl} className={styles.video} />}
        </NetworkingWrapper>
    );
}

export default VideoPage;
