import { useQueryClient } from "@tanstack/react-query";

import { Guide, TooltipConfig } from "../network/responseTypes";
import { QueryKey } from "../queries/queryKeys";
import { UndoUpdateFunc, optimisticUpdate } from "../util/ReactQueryUtil";

type UpdateFunc = (tooltipConfig: TooltipConfig) => Promise<UndoUpdateFunc | unknown>;

export function useTooltipConfigOptimisticUpdate(guideId: string, stepId: string): UpdateFunc {
    const queryClient = useQueryClient();
    return (tooltipConfig) =>
        optimisticUpdate<Guide>(queryClient, [QueryKey.GuideDetail, guideId], (guide) => ({
            ...guide,
            steps: guide.steps.map((step) =>
                step.id === stepId && step.type === "action"
                    ? {
                          ...step,
                          tooltip_config: tooltipConfig,
                      }
                    : step,
            ),
        }));
}
