/* eslint-disable */
// THIS IS A GENERATED FILE. DO NOT EDIT DIRECTLY
// To generate new type definitions run `npm run generate-schema-types`
import * as Types from '../../../@types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TriggerQueryVariables = Types.Exact<{
  guideId: Types.Scalars['ID']['input'];
}>;


export type TriggerQuery = { readonly __typename?: 'Query', readonly trigger?: { readonly __typename?: 'TriggerType', readonly audienceType: Types.TriggerAudienceType, readonly urlRegexes: ReadonlyArray<string>, readonly isEnabled: boolean, readonly frequency: Types.TriggerFrequency, readonly startTime?: string | null, readonly endTime?: string | null, readonly persons: ReadonlyArray<{ readonly __typename?: 'PersonType', readonly id: any }>, readonly widgetUsers: ReadonlyArray<{ readonly __typename?: 'WidgetUserType', readonly userId: string }> } | null, readonly organizationMembers: ReadonlyArray<{ readonly __typename?: 'PersonType', readonly id: any, readonly fullName: string }> };

export type SetTriggerMutationVariables = Types.Exact<{
  input: Types.SetTriggerMutationInput;
}>;


export type SetTriggerMutation = { readonly __typename?: 'Mutation', readonly setTrigger?: { readonly __typename?: 'SetTriggerMutation', readonly ok: boolean } | null };


export const TriggerDocument = gql`
    query Trigger($guideId: ID!) {
  trigger(guideId: $guideId) {
    audienceType
    persons {
      id
    }
    widgetUsers {
      userId
    }
    urlRegexes
    isEnabled
    frequency
    startTime
    endTime
  }
  organizationMembers {
    id
    fullName
  }
}
    `;

/**
 * __useTriggerQuery__
 *
 * To run a query within a React component, call `useTriggerQuery` and pass it any options that fit your needs.
 * When your component renders, `useTriggerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTriggerQuery({
 *   variables: {
 *      guideId: // value for 'guideId'
 *   },
 * });
 */
export function useTriggerQuery(baseOptions: Apollo.QueryHookOptions<TriggerQuery, TriggerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TriggerQuery, TriggerQueryVariables>(TriggerDocument, options);
      }
export function useTriggerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TriggerQuery, TriggerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TriggerQuery, TriggerQueryVariables>(TriggerDocument, options);
        }
export type TriggerQueryHookResult = ReturnType<typeof useTriggerQuery>;
export type TriggerLazyQueryHookResult = ReturnType<typeof useTriggerLazyQuery>;
export type TriggerQueryResult = Apollo.QueryResult<TriggerQuery, TriggerQueryVariables>;
export const SetTriggerDocument = gql`
    mutation SetTrigger($input: SetTriggerMutationInput!) {
  setTrigger(input: $input) {
    ok
  }
}
    `;
export type SetTriggerMutationFn = Apollo.MutationFunction<SetTriggerMutation, SetTriggerMutationVariables>;

/**
 * __useSetTriggerMutation__
 *
 * To run a mutation, you first call `useSetTriggerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTriggerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTriggerMutation, { data, loading, error }] = useSetTriggerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetTriggerMutation(baseOptions?: Apollo.MutationHookOptions<SetTriggerMutation, SetTriggerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetTriggerMutation, SetTriggerMutationVariables>(SetTriggerDocument, options);
      }
export type SetTriggerMutationHookResult = ReturnType<typeof useSetTriggerMutation>;
export type SetTriggerMutationResult = Apollo.MutationResult<SetTriggerMutation>;
export type SetTriggerMutationOptions = Apollo.BaseMutationOptions<SetTriggerMutation, SetTriggerMutationVariables>;