import cx from "classnames";
import { Button } from "@mui/material";
import React, { ReactElement } from "react";

import styles from "./ErrorMessage.module.scss";

interface Props {
    retry?: () => void;
    className?: string;
}

function ErrorMessage(props: Props): ReactElement {
    const { retry } = props;

    return (
        <div className={cx(styles.container, props.className)}>
            <h2>Uh oh! Something went wrong.</h2>
            {retry && (
                <Button size="medium" variant="contained" color="secondary" onClick={retry}>
                    Retry
                </Button>
            )}
        </div>
    );
}

export default ErrorMessage;
