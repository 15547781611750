import { StepInterestPoint } from "../network/responseTypes";

export function getInterestPointBoundingClientRect(
    interestPoint: Omit<StepInterestPoint, "type"> | undefined,
    imageElRect: DOMRect,
    originalWidth: number | undefined,
    originalHeight: number | undefined,
): DOMRect {
    return interestPoint && originalHeight && originalWidth
        ? DOMRect.fromRect({
              x: (interestPoint.position_x / originalWidth) * imageElRect.width + imageElRect.x,
              y: (interestPoint.position_y / originalHeight) * imageElRect.height + imageElRect.y,
              width: (interestPoint.width / originalWidth) * imageElRect.width,
              height: (interestPoint.height / originalHeight) * imageElRect.height,
          })
        : // Position in bottom-left corner of image when interest point unavailable
          DOMRect.fromRect({
              x: imageElRect.x,
              y: imageElRect.y + imageElRect.height,
              width: 0,
              height: 0,
          });
}
