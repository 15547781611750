import { useEffect, useState } from "react";

type PermissionStatus = "granted" | "denied" | "prompt" | "unknown" | "unsupported";

function useBrowserPermissionStatus(permissionName: PermissionName): PermissionStatus {
    const [status, setStatus] = useState<PermissionStatus>("unknown");

    useEffect(() => {
        if (!navigator.permissions || !navigator.permissions.query) {
            setStatus("unsupported");
            return;
        }

        const handlePermissionChange = (event: Event) => {
            setStatus((event.target as unknown as { state: PermissionStatus }).state);
        };

        navigator.permissions
            .query({ name: permissionName })
            .then((permissionStatus) => {
                setStatus(permissionStatus.state);
                permissionStatus.addEventListener("change", handlePermissionChange);
            })
            .catch(console.error);

        return () => {
            if (navigator.permissions && navigator.permissions.query) {
                navigator.permissions
                    .query({ name: permissionName })
                    .then((permissionStatus) => {
                        permissionStatus.removeEventListener("change", handlePermissionChange);
                    })
                    .catch(console.error);
            }
        };
    }, [permissionName]);

    return status;
}

export default useBrowserPermissionStatus;
