import React, { ReactElement, useRef } from "react";
import { PinturaEditorModal } from "@pqina/react-pintura";
import "@pqina/pintura/pintura.css";
import {
    createDefaultImageReader,
    createDefaultImageScrambler,
    createDefaultImageWriter,
    createDefaultShapePreprocessor,
    locale_en_gb,
    markup_editor_defaults,
    markup_editor_locale_en_gb,
    plugin_annotate_locale_en_gb,
    plugin_annotate,
    plugin_crop_locale_en_gb,
    plugin_crop,
    plugin_filter_defaults,
    plugin_finetune_defaults,
    plugin_redact_locale_en_gb,
    plugin_redact,
    plugin_sticker_locale_en_gb,
    plugin_sticker,
    setPlugins,
    PinturaDefaultImageWriterResult,
    createMarkupEditorShapeStyleControls,
    createMarkupEditorBackgroundColorControl,
    createDefaultColorOptions,
    createMarkupEditorColorOptions,
    createMarkupEditorToolStyle,
    createMarkupEditorToolStyles,
    colorStringToColorArray,
    createMarkupEditorStrokeColorControl,
} from "@pqina/pintura";

import NetworkingWrapper from "../NetworkingWrapper/NetworkingWrapper";

import { useGuideColorCustomizationsQuery, useScreenshotQuery } from "./operations.generated";

setPlugins(plugin_annotate, plugin_crop, plugin_redact, plugin_sticker);

const DRIVEWAY_PURPLE = "#525CEE";

const editorConfig = {
    imageReader: createDefaultImageReader(),
    imageWriter: createDefaultImageWriter(),
    imageScrambler: createDefaultImageScrambler(),

    ...markup_editor_defaults,

    ...plugin_finetune_defaults,
    ...plugin_filter_defaults,

    shapePreprocessor: createDefaultShapePreprocessor(),

    locale: {
        ...locale_en_gb,
        ...markup_editor_locale_en_gb,
        ...plugin_annotate_locale_en_gb,
        ...plugin_crop_locale_en_gb,
        ...plugin_redact_locale_en_gb,
        ...plugin_sticker_locale_en_gb,
    },
};

interface Props {
    isOpen: boolean;
    guideId: string;
    stepId: string;
    onClose: () => void;
    onEditScreenshot: (file: File, imageState: string) => void;
}

function EditScreenshotDialog(props: Props): ReactElement | null {
    const editorRef = useRef<PinturaEditorModal>(null);
    const { data, loading, error } = useScreenshotQuery({
        variables: {
            stepId: props.stepId,
        },
    });

    // not using error bc we don't want the page to depend on customizations
    const { data: guideCustomizationsData, loading: isGuideCustomizationsLoading } =
        useGuideColorCustomizationsQuery({
            variables: {
                guideId: props.guideId,
            },
        });

    const defaultColorArray = colorStringToColorArray(
        guideCustomizationsData?.guideCustomizations.color || DRIVEWAY_PURPLE,
    );

    const handleEditorLoad = () => {
        if (data && !data.screenshot.hasDeprecatedEdits) {
            editorRef?.current?.editor.history.set(JSON.parse(data.screenshot.imageStateHistory));
        }
    };

    const handleEditorProcess = (res: PinturaDefaultImageWriterResult) => {
        props.onEditScreenshot(res.dest, JSON.stringify(res.imageState));
    };

    const screenshotUrl = data
        ? data.screenshot.hasDeprecatedEdits
            ? `${data.screenshot.editedUrl}?response-cache-control=no-cache`
            : `${data.screenshot.originalUrl}?response-cache-control=no-cache`
        : undefined;

    if (!props.isOpen) {
        return null;
    }

    return (
        <NetworkingWrapper loading={loading || isGuideCustomizationsLoading} error={error}>
            <PinturaEditorModal
                ref={editorRef}
                {...editorConfig}
                markupEditorShapeStyleControls={createMarkupEditorShapeStyleControls({
                    backgroundColor: createMarkupEditorBackgroundColorControl(
                        createMarkupEditorColorOptions({
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore because the type definitions for pintura are incomplete
                            custom: defaultColorArray,
                            ...createDefaultColorOptions(),
                        }),
                        {
                            enableInput: true,
                        },
                    ),
                    strokeColor: createMarkupEditorStrokeColorControl(
                        createMarkupEditorColorOptions({
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore because the type definitions for pintura are incomplete
                            custom: defaultColorArray,
                            ...createDefaultColorOptions(),
                        }),
                        {
                            enableInput: true,
                            defaultStrokeWidth: "0.5%",
                        },
                    ),
                })}
                markupEditorToolStyles={createMarkupEditorToolStyles({
                    sharpie: createMarkupEditorToolStyle("path", {
                        strokeColor: defaultColorArray,
                        strokeWidth: "0.5%",
                    }),
                    line: createMarkupEditorToolStyle("line", {
                        strokeColor: defaultColorArray,
                        strokeWidth: "0.5%",
                    }),
                    arrow: createMarkupEditorToolStyle("line", {
                        strokeColor: defaultColorArray,
                        strokeWidth: "0.5%",
                        lineEnd: "arrow-solid",
                    }),
                    ellipse: createMarkupEditorToolStyle("ellipse", {
                        strokeColor: defaultColorArray,
                        backgroundColor: [0, 0, 0, 0], // this is transparent black
                        strokeWidth: "0.5%",
                    }),
                    text: createMarkupEditorToolStyle("text", {
                        strokeColor: defaultColorArray,
                        strokeWidth: "0.5%",
                    }),
                    rectangle: createMarkupEditorToolStyle("rectangle", {
                        strokeColor: defaultColorArray,
                        backgroundColor: [0, 0, 0, 0], // this is transparent black
                        strokeWidth: "0.5%",
                    }),
                })}
                // TODO: the query param is a hack to get around a CORS issue
                // where the browser caches the image when it's first loaded
                // and the cached version doesn't include the original request
                // headers, causing chrome to throw a CORS error.
                // see https://stackoverflow.com/questions/44865121/canvas-tainted-by-cors-data-and-s3/44866772#44866772
                src={screenshotUrl}
                onLoad={handleEditorLoad}
                onClose={props.onClose}
                onHide={props.onClose}
                onProcess={(imgWriterRes) => {
                    handleEditorProcess(imgWriterRes);
                }}
                markupEditorTextInputMode="modal"
            />
        </NetworkingWrapper>
    );
}

export default EditScreenshotDialog;
