import { CircularProgress, List } from "@mui/material";
import React, { ReactElement } from "react";

import ErrorMessage from "../ErrorMessage/ErrorMessage";

import { AnonymousEngagementInfo, PersonEngagementInfo } from "./EngagementInsightPopover";
import styles from "./EngagementOverviewPanel.module.scss";
import {
    EngagementPanelSection,
    EngagementPanelSectionLabel,
    EngagementPanelSectionValue,
} from "./EngagementPanelSection";
import { useAllGuideViewsQuery } from "./operations.generated";
import ViewerListItem from "./ViewerListItem";

interface Props {
    guideId: string;
    numViews: number;
    onSelectViewer: (info: PersonEngagementInfo | AnonymousEngagementInfo) => void;
}

function EngagementOverviewPanel(props: Props): ReactElement {
    const { guideId, numViews, onSelectViewer } = props;
    const { data, error, loading } = useAllGuideViewsQuery({ variables: { guideId } });

    const numViewer =
        (data?.guideViewsByPerson?.length ?? 0) + (data?.anonymousGuideViews?.length ?? 0);
    return (
        <div className={styles.container}>
            <h2 className={styles.title}>Engagement insights</h2>
            <EngagementPanelSection className={styles.insightStatsRow}>
                <EngagementPanelSectionValue>{numViews}</EngagementPanelSectionValue>
                <EngagementPanelSectionLabel>Total guide views</EngagementPanelSectionLabel>
            </EngagementPanelSection>
            <div className={styles.viewerContainer}>
                {loading && <CircularProgress className={styles.loader} />}
                {error && <ErrorMessage />}
                {data && (
                    <>
                        <div className={styles.viewerCount}>
                            {numViewer} viewer{numViewer !== 1 && "s"}
                        </div>
                        <List component="div" disablePadding dense>
                            {data?.guideViewsByPerson?.map((viewer) => (
                                <ViewerListItem
                                    key={viewer.id}
                                    type="person"
                                    viewer={viewer}
                                    onClick={() => onSelectViewer({ type: "person", viewer })}
                                />
                            ))}
                            {data?.anonymousGuideViews?.map((guideViewEvent) => (
                                <ViewerListItem
                                    key={guideViewEvent.id}
                                    type="anonymous"
                                    guideViewEvent={guideViewEvent}
                                    onClick={() =>
                                        onSelectViewer({
                                            type: "anonymous",
                                            guideViewEvent,
                                        })
                                    }
                                />
                            ))}
                        </List>
                    </>
                )}
            </div>
        </div>
    );
}

export default EngagementOverviewPanel;
