import { useSnackbar } from "notistack";
import React, { ReactElement, useCallback } from "react";
import { useDropzone } from "react-dropzone";

import CurrentUserAvatar from "../PersonAvatar/CurrentUserAvatar";

import styles from "./AvatarUploader.module.scss";
import { useUploadProfileAvatarMutation } from "./operations.generated";

function AvatarUploader(): ReactElement {
    const { enqueueSnackbar } = useSnackbar();
    const [uploadAvatar] = useUploadProfileAvatarMutation({
        refetchQueries: ["Me"],
        onError: () => {
            enqueueSnackbar(
                "Something went wrong when trying to upload your avatar. Please try again.",
                { variant: "error" },
            );
        },
        onCompleted: () => {
            enqueueSnackbar("Your avatar has been successfully uploaded.", {
                variant: "success",
            });
        },
    });

    const onDrop = useCallback(
        (acceptedFiles: ReadonlyArray<File>) => {
            const file = acceptedFiles[0];
            void uploadAvatar({ variables: { file } });
        },
        [uploadAvatar],
    );

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        maxFiles: 1,
        accept: {
            "image/png": [".png"],
            "image/jpeg": [".jpg", ".jpeg"],
        },
    });

    return (
        <div {...getRootProps({ className: styles.dropzone })}>
            <input {...getInputProps()} />
            <CurrentUserAvatar className={styles.avatar} />
            Click to change avatar
        </div>
    );
}

export default AvatarUploader;
