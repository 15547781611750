import { AddBox as AddIcon } from "@mui/icons-material";
import { Button } from "@mui/material";
import cx from "classnames";
import React, { ReactElement, useCallback } from "react";

import useAmplitudeTracker from "../../hooks/useAmplitudeTracker";
import { useShowTabListForNewCaptureInExtensionMutation } from "../../mutations/showTabListForNewCaptureInExtension";
import { useChromeExtensionVersionQuery } from "../../queries/chromeExtensionVersion";
import { isCreateGuideDialogFeatureEnabled } from "../../util/VersionUtil";

import styles from "./CreateGuideButton.module.scss";

interface Props {
    className?: string;
}

function CreateGuideButton(props: Props): ReactElement | null {
    const { className } = props;
    const { mutate: showTabListForNewCapture, isLoading } =
        useShowTabListForNewCaptureInExtensionMutation();
    const { data: extensionVersion } = useChromeExtensionVersionQuery();
    const { track } = useAmplitudeTracker();

    const onClick = useCallback(() => {
        showTabListForNewCapture();
        track("New Guide Button Clicked");
    }, [showTabListForNewCapture, track]);

    const isGuideCreationEnabled =
        !!extensionVersion && isCreateGuideDialogFeatureEnabled(extensionVersion);

    return isGuideCreationEnabled ? (
        <>
            <Button
                color="primary"
                variant="contained"
                className={cx(styles.createGuideButton, className)}
                onClick={onClick}
                data-dd-action-name="New Guide Button"
                disabled={isLoading}
                startIcon={<AddIcon />}
                data-driveway="new-guide-button"
            >
                Create guide
            </Button>
        </>
    ) : null;
}

export default CreateGuideButton;
