export function formatInteger(value: number): string {
    return value.toLocaleString();
}

export function formatPercentage(value: number): string {
    return value.toLocaleString(undefined, { style: "percent" });
}

export function formatCurrency(value: number): string {
    return value.toLocaleString(undefined, {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
    });
}

const escapedRegExpChar = /\\[\\^$.*+?()[\]{}|]/g;
const hasEscapedRegExpChar = RegExp(escapedRegExpChar.source);

/**
 * Unescapes the `RegExp` special characters "^", "$", "\", ".", "*", "+",
 * "?", "(", ")", "[", "]", "{", "}", and "|" in an already escaped `string`.
 *
 * @see {@link https://docs-lodash.com/v4/escape-reg-exp/ escapeRegExp}
 *
 * @example
 *
 * unescapeRegExp('\[lodash\]\(https://lodash\.com/\)')
 * // => '[lodash](https://lodash.com/)'
 */
export function unescapeRegExp(value: string): string {
    return hasEscapedRegExpChar.test(value)
        ? value.replace(
              escapedRegExpChar,
              // Removes leading \ character used to escape
              (entity) => entity.substring(1),
          )
        : value;
}

const escapedForwardSlashChar = /\\\//g;
const hasEscapedForwardSlashChar = RegExp(escapedForwardSlashChar.source);

/**
 * Unescapes the forward slash "/" character in an already escaped `string`.
 *
 * The `source` property of `RegExp` escapes forward slash characters; this function can undo that escaping.
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/source#description}
 */
export function unescapeForwardSlash(value: string): string {
    return hasEscapedForwardSlashChar.test(value)
        ? value.replace(
              escapedForwardSlashChar,
              // Removes leading \ character used to escape
              (entity) => entity.substring(1),
          )
        : value;
}
