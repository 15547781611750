/* eslint-disable */
// THIS IS A GENERATED FILE. DO NOT EDIT DIRECTLY
// To generate new type definitions run `npm run generate-schema-types`
import * as Types from '../../../@types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateOrganizationMutationVariables = Types.Exact<{
  input: Types.UpdateOrganizationMutationInput;
}>;


export type UpdateOrganizationMutation = { readonly __typename?: 'Mutation', readonly updateOrganization?: { readonly __typename?: 'UpdateOrganizationMutationPayload', readonly clientMutationId?: string | null } | null };

export type OrgGuideCustomizationsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type OrgGuideCustomizationsQuery = { readonly __typename?: 'Query', readonly orgGuideCustomizations: { readonly __typename?: 'OrgGuideCustomizationsType', readonly hidePlayButton?: boolean | null, readonly color?: string | null } };

export type UpdateOrgGuideCustomizationsMutationVariables = Types.Exact<{
  input: Types.UpdateOrgGuideCustomizationsMutationInput;
}>;


export type UpdateOrgGuideCustomizationsMutation = { readonly __typename?: 'Mutation', readonly updateOrgGuideCustomizations?: { readonly __typename?: 'UpdateOrgGuideCustomizationsMutation', readonly orgGuideCustomizations?: { readonly __typename?: 'OrgGuideCustomizationsType', readonly hidePlayButton?: boolean | null, readonly color?: string | null } | null } | null };


export const UpdateOrganizationDocument = gql`
    mutation UpdateOrganization($input: UpdateOrganizationMutationInput!) {
  updateOrganization(input: $input) {
    clientMutationId
  }
}
    `;
export type UpdateOrganizationMutationFn = Apollo.MutationFunction<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>;

/**
 * __useUpdateOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationMutation, { data, loading, error }] = useUpdateOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>(UpdateOrganizationDocument, options);
      }
export type UpdateOrganizationMutationHookResult = ReturnType<typeof useUpdateOrganizationMutation>;
export type UpdateOrganizationMutationResult = Apollo.MutationResult<UpdateOrganizationMutation>;
export type UpdateOrganizationMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>;
export const OrgGuideCustomizationsDocument = gql`
    query OrgGuideCustomizations {
  orgGuideCustomizations {
    hidePlayButton
    color
  }
}
    `;

/**
 * __useOrgGuideCustomizationsQuery__
 *
 * To run a query within a React component, call `useOrgGuideCustomizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgGuideCustomizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgGuideCustomizationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrgGuideCustomizationsQuery(baseOptions?: Apollo.QueryHookOptions<OrgGuideCustomizationsQuery, OrgGuideCustomizationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrgGuideCustomizationsQuery, OrgGuideCustomizationsQueryVariables>(OrgGuideCustomizationsDocument, options);
      }
export function useOrgGuideCustomizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrgGuideCustomizationsQuery, OrgGuideCustomizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrgGuideCustomizationsQuery, OrgGuideCustomizationsQueryVariables>(OrgGuideCustomizationsDocument, options);
        }
export type OrgGuideCustomizationsQueryHookResult = ReturnType<typeof useOrgGuideCustomizationsQuery>;
export type OrgGuideCustomizationsLazyQueryHookResult = ReturnType<typeof useOrgGuideCustomizationsLazyQuery>;
export type OrgGuideCustomizationsQueryResult = Apollo.QueryResult<OrgGuideCustomizationsQuery, OrgGuideCustomizationsQueryVariables>;
export const UpdateOrgGuideCustomizationsDocument = gql`
    mutation UpdateOrgGuideCustomizations($input: UpdateOrgGuideCustomizationsMutationInput!) {
  updateOrgGuideCustomizations(input: $input) {
    orgGuideCustomizations {
      hidePlayButton
      color
    }
  }
}
    `;
export type UpdateOrgGuideCustomizationsMutationFn = Apollo.MutationFunction<UpdateOrgGuideCustomizationsMutation, UpdateOrgGuideCustomizationsMutationVariables>;

/**
 * __useUpdateOrgGuideCustomizationsMutation__
 *
 * To run a mutation, you first call `useUpdateOrgGuideCustomizationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrgGuideCustomizationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrgGuideCustomizationsMutation, { data, loading, error }] = useUpdateOrgGuideCustomizationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrgGuideCustomizationsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrgGuideCustomizationsMutation, UpdateOrgGuideCustomizationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrgGuideCustomizationsMutation, UpdateOrgGuideCustomizationsMutationVariables>(UpdateOrgGuideCustomizationsDocument, options);
      }
export type UpdateOrgGuideCustomizationsMutationHookResult = ReturnType<typeof useUpdateOrgGuideCustomizationsMutation>;
export type UpdateOrgGuideCustomizationsMutationResult = Apollo.MutationResult<UpdateOrgGuideCustomizationsMutation>;
export type UpdateOrgGuideCustomizationsMutationOptions = Apollo.BaseMutationOptions<UpdateOrgGuideCustomizationsMutation, UpdateOrgGuideCustomizationsMutationVariables>;