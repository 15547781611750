import React, { ReactElement } from "react";
import { CircularProgress } from "@mui/material";

import styles from "./LoaderFooter.module.scss";

function LoaderFooter(): ReactElement {
    return (
        <div className={styles.loader}>
            <CircularProgress />
        </div>
    );
}

export default LoaderFooter;
