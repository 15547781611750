import { StepIcon } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { ReactElement, useCallback } from "react";

import { useEditScreenshot } from "../../mutations/editScreenshot";
import { useEditStepMutation } from "../../mutations/editStep";
import { Guide, VideoStep } from "../../network/responseTypes";
import { slateNodesToString } from "../../util/SlateUtil";
import EditableField from "../EditableField/EditableField";
import StepDetailToolbar from "../StepDetailToolbar/StepDetailToolbar";

import styles from "./VideoStepEditor.module.scss";

interface Props {
    guide: Guide;
    step: VideoStep;
    isUserAuthor: boolean;
    stepNumber: number;
}

// The max length matches with the BE Step model
const STEP_TITLE_MAX_LENGTH = 300;

function VideoStepEditor(props: Props): ReactElement {
    const { step, guide, isUserAuthor, stepNumber } = props;
    const { mutate: editStep, isLoading: isEditStepSubmitting } = useEditStepMutation(
        step.id,
        guide.id,
    );

    const { enqueueSnackbar } = useSnackbar();
    const [editScreenshot] = useEditScreenshot(guide.id);
    const showEditErrorSnackbar = useCallback(
        (entityName: string) =>
            enqueueSnackbar(
                `Something went wrong while editing the ${entityName} of this step. Please try again later.`,
                {
                    variant: "error",
                },
            ),
        [enqueueSnackbar],
    );

    return (
        <div className={styles.stepContainer}>
            <div className={styles.detailsGrid}>
                <StepIcon
                    icon={stepNumber + 1}
                    active={true}
                    classes={{ root: styles.stepNumberIcon }}
                />
                <EditableField
                    value={step.title}
                    mode="multiline"
                    placeholder="Enter a title…"
                    isSubmitting={isEditStepSubmitting}
                    onSubmit={(value) =>
                        editStep(
                            { title: value.trim() },
                            {
                                onError: () => showEditErrorSnackbar("title"),
                            },
                        )
                    }
                    entityId={step.id}
                    readOnly={!isUserAuthor}
                    validate={(value) => {
                        const trimmedLength = value.trim().length;
                        if (trimmedLength > STEP_TITLE_MAX_LENGTH) {
                            enqueueSnackbar(
                                "The title of this step is too long. Consider use the step description.",
                                { variant: "warning" },
                            );
                            return false;
                        }
                        return trimmedLength > 0;
                    }}
                    classes={{ root: styles.title, field: styles.titleInput }}
                />
                <div className={styles.actions}>
                    {isUserAuthor && (
                        <StepDetailToolbar
                            onEditScreenshot={(f, imageState) => {
                                editScreenshot({
                                    variables: { input: { stepId: step.id, file: f, imageState } },
                                }).catch(console.error);
                            }}
                            guideId={guide.id}
                            step={step}
                        />
                    )}
                </div>
                <EditableField
                    value={slateNodesToString(step.description)}
                    mode="multiline"
                    placeholder="Enter a description…"
                    isSubmitting={isEditStepSubmitting}
                    onSubmit={(value) =>
                        editStep(
                            { description: value },
                            {
                                onError: () => showEditErrorSnackbar("description"),
                            },
                        )
                    }
                    entityId={step.id}
                    readOnly={!isUserAuthor}
                    classes={{ root: styles.description, field: styles.descriptionInput }}
                />
            </div>
            <div className={styles.mediaContentWrapper}>
                {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                <video src={step.video.url} controls className={styles.video} />
            </div>
        </div>
    );
}

export default VideoStepEditor;
