/* eslint-disable */
// THIS IS A GENERATED FILE. DO NOT EDIT DIRECTLY
// To generate new type definitions run `npm run generate-schema-types`
import * as Types from '../../../@types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MoveStepMutationVariables = Types.Exact<{
  input: Types.MoveStepMutationInput;
}>;


export type MoveStepMutation = { readonly __typename?: 'Mutation', readonly moveStep?: { readonly __typename?: 'MoveStepMutation', readonly guide?: { readonly __typename?: 'GuideType', readonly id: string } | null } | null };


export const MoveStepDocument = gql`
    mutation MoveStep($input: MoveStepMutationInput!) {
  moveStep(input: $input) {
    guide {
      id
    }
  }
}
    `;
export type MoveStepMutationFn = Apollo.MutationFunction<MoveStepMutation, MoveStepMutationVariables>;

/**
 * __useMoveStepMutation__
 *
 * To run a mutation, you first call `useMoveStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveStepMutation, { data, loading, error }] = useMoveStepMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoveStepMutation(baseOptions?: Apollo.MutationHookOptions<MoveStepMutation, MoveStepMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MoveStepMutation, MoveStepMutationVariables>(MoveStepDocument, options);
      }
export type MoveStepMutationHookResult = ReturnType<typeof useMoveStepMutation>;
export type MoveStepMutationResult = Apollo.MutationResult<MoveStepMutation>;
export type MoveStepMutationOptions = Apollo.BaseMutationOptions<MoveStepMutation, MoveStepMutationVariables>;