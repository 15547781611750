import { Dialog, Icon, IconButton } from "@mui/material";
import React, { ReactElement } from "react";

import styles from "./DownloadExtensionDialog.module.scss";
import DownloadExtensionMessage from "./DownloadExtensionMessage";

interface Props {
    open: boolean;
    onClose: () => void;
}

function DownloadExtensionDialog(props: Props): ReactElement {
    const { open, onClose } = props;

    return (
        <Dialog onClose={onClose} open={open}>
            <div className={styles.dialogContainer}>
                <IconButton
                    aria-label="Close"
                    onClick={onClose}
                    className={styles.closeButton}
                    size="large"
                >
                    <Icon>close</Icon>
                </IconButton>
                <DownloadExtensionMessage />
            </div>
        </Dialog>
    );
}
export default DownloadExtensionDialog;
