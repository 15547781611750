import { useSnackbar } from "notistack";
import React, { ReactElement } from "react";
import { useIntercom } from "react-use-intercom";

import useAmplitudeTracker from "../../hooks/useAmplitudeTracker";
import { useGetGuidePngMutation } from "../../mutations/getGuidePng";
import { Guide } from "../../network/responseTypes";
import { downloadFile } from "../../util/DownloadUtil";
import ExportAsFormatButton from "../ExportAsFormatButton/ExportAsFormatButton";

interface Props {
    guide: Guide;
}

function GuideDownloadAsPngButton({ guide }: Props): ReactElement {
    const { track } = useAmplitudeTracker();
    const intercom = useIntercom();
    const { mutate: getGuidePng, isLoading } = useGetGuidePngMutation(guide.id);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const getPng = () => {
        track("Export Guide as PNG Button Clicked");
        intercom.trackEvent("clicked_export_guide_as_png", { guideId: guide.id });
        enqueueSnackbar("Preparing PNG…", {
            variant: "info",
            autoHideDuration: null,
        });

        getGuidePng(
            {},
            {
                onSuccess: (data) => {
                    downloadFile(data, {
                        filename: `${guide.title}.zip`,
                        mimeType: "application/zip",
                    });
                    closeSnackbar();
                },
                onError: () => {
                    closeSnackbar();
                    enqueueSnackbar("Failed to process png. Please try again.", {
                        variant: "error",
                    });
                },
            },
        );
    };
    return (
        <ExportAsFormatButton
            title="PNG"
            description="Best for downloading screenshots"
            type="download"
            ariaLabel="Download as PNG"
            ariaDescription="Best for downloading screenshots"
            disabled={isLoading}
            onClick={getPng}
            data-dd-action-name="Download Screenshot PNGs"
        />
    );
}

export default GuideDownloadAsPngButton;
