import { ArrowForward as ArrowForwardIcon } from "@mui/icons-material";
import cx from "classnames";
import React, { ReactElement, useContext } from "react";

import { UserContext } from "../../contexts/user";
import CrownYellow from "../../icons/crown-yellow.svg";
import SubscriptionUpgradeButton from "../SubscriptionUpgradeButton/SubscriptionUpgradeButton";

import SharedGuideLimitProgressBar from "./SharedGuideLimitProgressBar";
import styles from "./SubscriptionBanner.module.scss";

interface Props {
    className?: string;
}

function SubscriptionBanner(props: Props): ReactElement | null {
    const {
        organization: { subscription },
    } = useContext(UserContext);
    if (!subscription || !subscription.tier || subscription.tier === "ENTERPRISE") {
        return null;
    }
    // TODO: remove this after view based pro plans are converted.
    if (
        subscription.tier === "PRO1" ||
        subscription.tier === "PRO2" ||
        subscription.tier === "PRO3"
    ) {
        console.warn("SubscriptionBanner: PRO1, PRO2, and PRO3 tiers are deprecated.");
        return null;
    }

    return (
        <div className={cx(styles.container, props.className)}>
            <PlanTitle tier={subscription.tier} isTrial={subscription.is_trial} />
            {subscription.tier === "PRO" && subscription.is_trial && (
                <div className={styles.message}>
                    There {subscription.days_remaining === 1 ? "is" : "are"}{" "}
                    {subscription.days_remaining} day
                    {subscription.days_remaining !== 1 && "s"} left in your Pro plan free trial.
                    Upgrade to share unlimited guides and collaborate with your team members.
                </div>
            )}
            {subscription.tier === "FREE" && (
                <>
                    <SharedGuideLimitProgressBar />
                    <div className={styles.message}>
                        Need more than 3 public guides? Upgrade to share unlimited public guides and
                        collaborate with your team.
                    </div>
                </>
            )}
            {(subscription.tier === "FREE" || subscription.is_trial) && (
                <SubscriptionUpgradeButton
                    endIcon={<ArrowForwardIcon />}
                    className={styles.upgradeButton}
                    classes={{
                        iconSizeMedium: styles.arrowIcon,
                    }}
                >
                    Upgrade today
                </SubscriptionUpgradeButton>
            )}
        </div>
    );
}

function PlanTitle(props: { tier: "FREE" | "PRO"; isTrial: boolean }): ReactElement {
    const { tier, isTrial } = props;
    return (
        <div className={styles.titleContainer}>
            {tier === "PRO" && <img src={CrownYellow} alt="" className={styles.crownIcon} />}
            <div className={styles.planTitle}>
                {tier === "FREE" ? "Free plan" : "Pro plan"}
                {isTrial && tier === "PRO" && " (Trial)"}
            </div>
        </div>
    );
}

export default SubscriptionBanner;
