import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { datadogRum } from "@datadog/browser-rum";
import * as amplitude from "@amplitude/analytics-browser";

import App from "./App";
import "./index.scss";

if (process.env.REACT_APP_AMPLITUDE_API_KEY && window.env !== "staging") {
    amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY);
}

datadogRum.init({
    applicationId: "3a09e0c2-3b5c-47a7-b37d-403cd169336e",
    clientToken: "pub564745d2501c01084de2a0512d5bf458",
    site: "datadoghq.com",
    service: "driveway-web-app",
    env: window.env,
    // do not track for non-production environments
    sampleRate: window.env == "production" ? 100 : 0,
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
});

if (window.env == "production") {
    datadogRum.startSessionReplayRecording();
}

const container = document.getElementById("root");
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
    <React.StrictMode>
        <Router>
            <App />
        </Router>
    </React.StrictMode>,
);
