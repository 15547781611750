import { Padding, arrow, autoUpdate, flip, offset, shift, useFloating } from "@floating-ui/react";
import { Button } from "@mui/material";
import cx from "classnames";
import React, { ReactElement, useCallback, useEffect, useRef, useState } from "react";
import { useTransformEffect } from "react-zoom-pan-pinch";

import MessageBubble from "../../components/MessageBubble/MessageBubble";
import { useAnimationFrameCountdown } from "../../hooks/animationFrameCountdown";
import { ActionStep, StepInterestPoint } from "../../network/responseTypes";
import { getInterestPointBoundingClientRect } from "../../util/InterestPointUtil";
import { slateNodesToString } from "../../util/SlateUtil";
import {
    TOOLTIP_ARROW_PADDING,
    TOOLTIP_FLIP_FALLBACK_AXIS_SIDE_DIR,
    TOOLTIP_SHIFT_CROSS_AXIS,
    getTooltipOffset,
    getTooltipPlacement,
} from "../../util/TooltipUtil";
import MessageBubbleButton from "../MessageBubbleButton/MessageBubbleButton";
import MessageBubbleFloatingArrow from "../MessageBubbleFloatingArrow/MessageBubbleFloatingArrow";
import MessageBubbleFooter from "../MessageBubbleFooter/MessageBubbleFooter";
import PlayPauseNarrationButton from "../PlayPauseNarrationButton/PlayPauseNarrationButton";

import styles from "./ActionStepMessageBubble.module.scss";

const ANIMATION_START_OFFSET = 16;

interface Props {
    step: ActionStep;
    onNextStep: () => void;
    onPreviousStep: () => void;
    onRestart: () => void;
    stepIndex: number;
    stepCount: number;
    interestPoint?: StepInterestPoint;
    shiftPadding: Padding;
    getImageElBoundingClientRect: () => DOMRect;
    className?: string;
}

function ActionStepMessageBubble(props: Props): ReactElement {
    const {
        step,
        onNextStep,
        onPreviousStep,
        onRestart,
        stepIndex,
        stepCount,
        interestPoint,
        shiftPadding,
        getImageElBoundingClientRect,
        className,
    } = props;

    const { height: imageHeight, width: imageWidth } = step.screenshot;

    const getHighlightBoundingClientRect = useCallback(() => {
        const imageElRect = getImageElBoundingClientRect();
        return getInterestPointBoundingClientRect(
            interestPoint,
            imageElRect,
            imageWidth,
            imageHeight,
        );
    }, [interestPoint, getImageElBoundingClientRect, imageWidth, imageHeight]);

    const arrowRef = useRef(null);

    const tooltipConfigOffset = getTooltipOffset(step.tooltip_config);
    const [bubbleOffset, resetBubbleOffset] = useAnimationFrameCountdown({
        from: tooltipConfigOffset + ANIMATION_START_OFFSET,
        to: tooltipConfigOffset,
    });

    // Re-trigger bubble offset animation every time step changes
    useEffect(resetBubbleOffset, [resetBubbleOffset, step.id]);

    const { refs, floatingStyles, context } = useFloating({
        middleware: [
            offset(bubbleOffset),
            flip({ fallbackAxisSideDirection: TOOLTIP_FLIP_FALLBACK_AXIS_SIDE_DIR }),
            shift({ padding: shiftPadding, crossAxis: TOOLTIP_SHIFT_CROSS_AXIS }),
            arrow({ element: arrowRef, padding: TOOLTIP_ARROW_PADDING }),
        ],
        placement: getTooltipPlacement(step.tooltip_config),
        whileElementsMounted: autoUpdate,
    });

    useTransformEffect(
        // Update position of message bubble whenever user zooms, pinches, or pans
        () => context.update(),
    );

    useEffect(
        () =>
            refs.setPositionReference({
                getBoundingClientRect: getHighlightBoundingClientRect,
            }),
        [refs, getHighlightBoundingClientRect],
    );

    // Determine if the user has started navigating
    const [hasStartedNavigating, setHasStartedNavigating] = useState(false);

    useEffect(() => {
        if (stepIndex > 0) {
            setHasStartedNavigating(true);
        }
    }, [stepIndex]);

    const isFirstStep = stepIndex === 0;
    const isLastStep = stepIndex === stepCount - 1;
    const plainTextDescription = slateNodesToString(step.description ?? []);

    const debugRect = getHighlightBoundingClientRect();

    return (
        <>
            <MessageBubble
                key={
                    // key is used to reset state so prop changes trigger fade in animation
                    // see https://react.dev/reference/react/useState#resetting-state-with-a-key
                    `messageBubble-${step.id}`
                }
                title={step.title}
                description={plainTextDescription}
                ref={refs.setFloating}
                className={cx(styles.messageBubble, className)}
                style={floatingStyles}
            >
                <Button
                    aria-label="Next step"
                    onClick={onNextStep}
                    className={styles.clickableBackground}
                />
                <MessageBubbleFooter>
                    {step.narration !== null && (
                        <PlayPauseNarrationButton
                            narrationUrl={step.narration.url}
                            className={styles.playPauseNarrationButton}
                            isFirstStep={isFirstStep}
                            hasStartedNavigating={hasStartedNavigating}
                            setHasStartedNavigating={setHasStartedNavigating}
                        />
                    )}
                    <div className={styles.stepCount}>
                        {stepIndex + 1}/{stepCount}
                    </div>
                    {!isFirstStep && (
                        <MessageBubbleButton onClick={onPreviousStep}>Back</MessageBubbleButton>
                    )}
                    {!isLastStep && (
                        <MessageBubbleButton onClick={onNextStep}>Next</MessageBubbleButton>
                    )}
                    {isLastStep && (
                        <MessageBubbleButton onClick={onRestart}>Restart</MessageBubbleButton>
                    )}
                </MessageBubbleFooter>
                {interestPoint && <MessageBubbleFloatingArrow ref={arrowRef} context={context} />}
            </MessageBubble>
            <div ref={refs.setReference} />
            {process.env.REACT_APP_DEBUG_INTEREST_POINT === "true" && (
                <div
                    className={styles.debugRect}
                    style={{
                        top: debugRect.top,
                        left: debugRect.left,
                        width: debugRect.width,
                        height: debugRect.height,
                    }}
                />
            )}
        </>
    );
}

export default ActionStepMessageBubble;
