import cx from "classnames";
import React, { ReactElement } from "react";

import { useEditScreenshot } from "../../mutations/editScreenshot";
import { Guide, Step } from "../../network/responseTypes";
import EditableStepScreenshot from "../EditableStepScreenshot/EditableStepScreenshot";
import StepDetailToolbar from "../StepDetailToolbar/StepDetailToolbar";
import VideoStepEditor from "../VideoStepEditor/VideoStepEditor";

import styles from "./StepEditor.module.scss";

interface Props {
    guide: Guide;
    step: Step;
    isUserAuthor: boolean;
    stepNumber: number;
    goToNextStep: () => void;
    goToPreviousStep: () => void;
    onRestart: () => void;
    className?: string;
}

function StepEditor(props: Props): ReactElement {
    const {
        guide,
        step,
        stepNumber,
        isUserAuthor,
        goToNextStep,
        goToPreviousStep,
        onRestart,
        className,
    } = props;

    const [editScreenshot] = useEditScreenshot(guide.id);

    if (step.type === "video") {
        return (
            <VideoStepEditor
                guide={guide}
                step={step}
                stepNumber={stepNumber}
                isUserAuthor={isUserAuthor}
            />
        );
    }

    return (
        <div className={cx(styles.container, className)}>
            <StepDetailToolbar
                onEditScreenshot={(f, imageState) => {
                    editScreenshot({
                        variables: { input: { stepId: step.id, file: f, imageState } },
                    }).catch(console.error);
                }}
                guideId={guide.id}
                step={step}
            />
            <EditableStepScreenshot
                guideId={guide.id}
                step={step}
                onNextStep={goToNextStep}
                onPreviousStep={goToPreviousStep}
                onRestart={onRestart}
                stepIndex={stepNumber}
                stepCount={guide.steps.length}
                className={styles.editableStepScreenshot}
            />
        </div>
    );
}

export default StepEditor;
