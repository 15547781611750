import { useSnackbar } from "notistack";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useIntercom } from "react-use-intercom";

import useAmplitudeTracker from "../../hooks/useAmplitudeTracker";
import { Guide } from "../../network/responseTypes";
import ExportAsFormatButton from "../ExportAsFormatButton/ExportAsFormatButton";
import InformationalTooltip from "../InformationalTooltip/InformationalTooltip";

import { useExportGuideAsVideoMutation, useVideoExportRequestsQuery } from "./operations.generated";
import styles from "./GuideExportAsVideoButton.module.scss";

interface Props {
    guide: Guide;
}

function GuideExportAsVideoButton({ guide }: Props): ReactElement {
    const [isProcessing, setIsProcessing] = useState(false);
    const { track } = useAmplitudeTracker();
    const intercom = useIntercom();
    const { enqueueSnackbar } = useSnackbar();
    const [exportGuideAsVideo, { loading: exportMutationLoading }] = useExportGuideAsVideoMutation({
        refetchQueries: ["VideoExportRequests"],
        awaitRefetchQueries: true,
        variables: { input: { guideId: guide.id } },
        onError: () => {
            enqueueSnackbar("Failed to export guide as video. Please try again.", {
                variant: "error",
            });
        },
        onCompleted: (data) => {
            if (data.exportGuideAsVideo?.status === "OK") {
                enqueueSnackbar("Generating video. You’ll receive an email when it’s ready.", {
                    variant: "info",
                });
                // setIsProcessing(true);
            } else if (data.exportGuideAsVideo?.status === "TOO_LONG") {
                enqueueSnackbar(
                    "Videos are limited to 30 steps. Only the first 30 of this guide will be exported.",
                    {
                        variant: "info",
                    },
                );
            } else {
                enqueueSnackbar("Failed to export guide as video. Please try again.", {
                    variant: "error",
                });
            }
        },
    });
    const {
        data,
        loading: videoExportRequestsLoading,
        startPolling,
        stopPolling,
    } = useVideoExportRequestsQuery({
        variables: { guideId: guide.id },
    });

    const videoExportRequests = data?.videoExportRequests ?? [];
    const processingVideoExportRequests = videoExportRequests.filter(
        (request) => !request.videoExport?.url,
    );

    useEffect(() => {
        setIsProcessing(processingVideoExportRequests.length > 0);
    }, [processingVideoExportRequests]);

    useEffect(() => {
        if (isProcessing) {
            startPolling(5000);
        } else {
            stopPolling();
        }
        return (): void => stopPolling();
    }, [isProcessing, startPolling, stopPolling]);

    useEffect(() => {
        if (isProcessing) {
            startPolling(5000);
        } else {
            stopPolling();
        }
        return (): void => stopPolling();
    }, [isProcessing, startPolling, stopPolling]);

    const onClick = useCallback(() => {
        track("Export Guide as Video Button Clicked");
        intercom.trackEvent("clicked_export_guide_as_video", { guideId: guide.id });
        exportGuideAsVideo().catch(console.error);
    }, [exportGuideAsVideo, track, intercom, guide.id]);

    const percentComplete = processingVideoExportRequests[0]?.percentComplete || 0;
    const percentCompleteString = percentComplete > 0 ? ` (${percentComplete}%)` : "";

    return (
        <ExportAsFormatButton
            title={
                <div className={styles.title}>
                    Video
                    {!isProcessing && !exportMutationLoading ? (
                        <InformationalTooltip>
                            Videos are limited to 30 steps. Only the first 30 of this guide will be
                            exported.
                        </InformationalTooltip>
                    ) : null}
                </div>
            }
            description={
                isProcessing || exportMutationLoading
                    ? `Processing${percentCompleteString}... you’ll receive your video in an email when complete`
                    : "Best for sharing on social media"
            }
            type="email"
            ariaLabel="Export as Video"
            ariaDescription="Best for sharing video"
            disabled={exportMutationLoading || videoExportRequestsLoading || isProcessing}
            onClick={onClick}
            data-dd-action-name="Export Guide Video"
        />
    );
}

export default GuideExportAsVideoButton;
