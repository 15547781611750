import { useCallback } from "react";
import * as amplitude from "@amplitude/analytics-browser";

export default function useAmplitudeTracker() {
    const track = useCallback((eventName: string, eventProperties = {}) => {
        amplitude.track(eventName, { ...eventProperties, source: "app" });
    }, []);

    return { track };
}
