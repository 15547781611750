import { Player } from "@lottiefiles/react-lottie-player";
import { Button } from "@mui/material";
import React, { ReactElement, useEffect } from "react";

import mapAnimation from "../../images/map.json";

import styles from "./GuideUploadErrorMessage.module.scss";

interface Props {
    retry: () => void;
    debugMessage: string;
}

function GuideUploadErrorMessage(props: Props): ReactElement {
    useEffect(() => {
        const orgId = localStorage.getItem("organizationId");
        // based on docs, this should trigger a datadogRum.addError
        console.error(`${props.debugMessage} for org ${orgId}`);
    }, [props.debugMessage]);

    return (
        <div className={styles.container}>
            <div aria-hidden>
                <Player src={mapAnimation} autoplay loop className={styles.animation} />
            </div>
            <h1 className={styles.heading}>An error occurred while creating your guide.</h1>
            <p className={styles.description}>
                We&rsquo;ve saved your guide data in your browser so it can still be recovered.
                Please try uploading your guide again or chat with the Driveway team from this page
                for help.
            </p>
            <Button
                size="medium"
                variant="contained"
                color="primary"
                onClick={props.retry}
                className={styles.retryButton}
            >
                Retry upload
            </Button>
            <p className={styles.debugMessage}>{props.debugMessage}</p>
        </div>
    );
}

export default GuideUploadErrorMessage;
