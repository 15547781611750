import { useCallback } from "react";

import { sendDataToExtension } from "../util/ChromeExtensionUtil";

import { useChromeExtensionId } from "./chromeExtensionId";

type MessageSendFunc = <TValue, TResult>(name: string, data?: TValue) => Promise<TResult>;

export function useChromeExtensionMessage(): MessageSendFunc {
    const extensionId = useChromeExtensionId();
    return useCallback<MessageSendFunc>(
        (name, data) =>
            extensionId
                ? sendDataToExtension(extensionId, name, data)
                : Promise.reject(new Error("Chrome extension ID is undefined")),
        [extensionId],
    );
}
