import React, { useState } from "react";

import { Guide, Step } from "../../network/responseTypes";
import GuideDemoView from "../GuideDemoView/GuideDemoView";
import GuideDetailViewToggle, {
    GuideDetailView,
} from "../GuideDetailViewToggle/GuideDetailViewToggle";
import GuideScrollView from "../GuideScrollView/GuideScrollView";

import styles from "./GuidePreview.module.scss";

export interface Props {
    guide: Guide;
    stepIndex: number;
    onNextStep: () => void;
    onPreviousStep: () => void;
    onRestart: () => void;
    onSetStep: React.Dispatch<React.SetStateAction<number>>;
    canComplete: boolean;
    onStepChange?: (step: Step, stepIndex: number, totalSteps: number) => void;
}

export default function GuidePreview(props: Props) {
    const [view, setView] = useState(GuideDetailView.Demo);

    return (
        <div className={styles.container}>
            <GuideDetailViewToggle
                className={styles.toggleContainer}
                view={view}
                setGuideDetailView={setView}
            />
            {view === GuideDetailView.Demo ? (
                <GuideDemoView {...props} />
            ) : (
                <GuideScrollView guide={props.guide} onStepChange={props.onStepChange} />
            )}
        </div>
    );
}
