import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { ReactElement, useCallback, useState } from "react";

import TextInput from "../../components/TextInput/TextInput";

import styles from "./CreateTagButton.module.scss";
import { TagMutation, useTagMutation } from "./operations.generated";

function CreateTagButton(): ReactElement {
    const [tagToAdd, setTagToAdd] = useState<string>("");
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const { enqueueSnackbar } = useSnackbar();

    const [createTag, { loading }] = useTagMutation({
        refetchQueries: ["Tags"],
        onCompleted: (data: TagMutation) => {
            if (data.tag?.errors) {
                let errorMessage = "Something went wrong when adding a new tag.";
                if (
                    data.tag.errors.some(
                        (error) =>
                            error?.field === "name" &&
                            error?.messages.includes("Unique name validation failed."),
                    )
                ) {
                    errorMessage = "Unable to add new tag. Tag with that name already exists.";
                }
                enqueueSnackbar(errorMessage, {
                    variant: "error",
                });
            } else {
                enqueueSnackbar(`Tag "${tagToAdd}" was added to the workspace.`, {
                    variant: "success",
                });
                setTagToAdd("");
                setIsDialogOpen(false);
            }
        },
        onError: () => {
            enqueueSnackbar("Something went wrong when adding a new tag.", {
                variant: "error",
            });
        },
    });

    const handleClick = useCallback(
        (name: string) => {
            void createTag({ variables: { input: { name } } });
        },
        [createTag],
    );

    const onDialogClose = useCallback(() => setIsDialogOpen(false), []);

    return (
        <>
            <Button
                variant="contained"
                color="primary"
                onClick={() => setIsDialogOpen(true)}
                className={styles.addButton}
            >
                New Tag
            </Button>
            <Dialog open={isDialogOpen} onClose={onDialogClose} classes={{ paper: styles.paper }}>
                <DialogTitle className={styles.title}>Create a new tag</DialogTitle>
                <DialogContent>
                    <TextInput
                        value={tagToAdd}
                        placeholder="Onboarding"
                        onChange={(e) => setTagToAdd(e.target.value)}
                        label="Tag name"
                        disabled={loading}
                        // eslint-disable-next-line jsx-a11y/no-autofocus
                        autoFocus
                    />
                    <div className={styles.buttonRow}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleClick(tagToAdd)}
                            disabled={loading || !tagToAdd.trim()}
                        >
                            Create tag
                        </Button>
                        <Button
                            variant="outlined"
                            className={styles.cancelButton}
                            onClick={onDialogClose}
                            disabled={loading}
                        >
                            Cancel
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default CreateTagButton;
