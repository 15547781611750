import { useMutation, UseMutationResult } from "@tanstack/react-query";
import Axios from "axios";

import { ViewEventSource } from "./recordGuideView";

export interface RecordStepViewVariables {
    stepId: string;
    guideViewEventId: string;
    source: ViewEventSource;
    stepIndex: number;
    totalSteps: number;
}

export function useRecordStepViewMutation(): UseMutationResult<
    void,
    Error,
    RecordStepViewVariables
> {
    return useMutation(async ({ stepId, ...otherVariables }) => {
        const { data } = await Axios.post<void>(`/api/steps/${stepId}/record-view/`, {
            guide_view_event: otherVariables.guideViewEventId,
            source: otherVariables.source,
            step_index: otherVariables.stepIndex,
            total_steps: otherVariables.totalSteps,
        });
        return data;
    });
}
