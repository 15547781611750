/* eslint-disable */
// THIS IS A GENERATED FILE. DO NOT EDIT DIRECTLY
// To generate new type definitions run `npm run generate-schema-types`
import * as Types from '../../../../@types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GuideCustomizationsQueryVariables = Types.Exact<{
  guideId: Types.Scalars['ID']['input'];
}>;


export type GuideCustomizationsQuery = { readonly __typename?: 'Query', readonly guideCustomizations: { readonly __typename?: 'OrgGuideCustomizationsType', readonly hidePlayButton?: boolean | null } };


export const GuideCustomizationsDocument = gql`
    query GuideCustomizations($guideId: ID!) {
  guideCustomizations(guideId: $guideId) {
    hidePlayButton
  }
}
    `;

/**
 * __useGuideCustomizationsQuery__
 *
 * To run a query within a React component, call `useGuideCustomizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuideCustomizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuideCustomizationsQuery({
 *   variables: {
 *      guideId: // value for 'guideId'
 *   },
 * });
 */
export function useGuideCustomizationsQuery(baseOptions: Apollo.QueryHookOptions<GuideCustomizationsQuery, GuideCustomizationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GuideCustomizationsQuery, GuideCustomizationsQueryVariables>(GuideCustomizationsDocument, options);
      }
export function useGuideCustomizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GuideCustomizationsQuery, GuideCustomizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GuideCustomizationsQuery, GuideCustomizationsQueryVariables>(GuideCustomizationsDocument, options);
        }
export type GuideCustomizationsQueryHookResult = ReturnType<typeof useGuideCustomizationsQuery>;
export type GuideCustomizationsLazyQueryHookResult = ReturnType<typeof useGuideCustomizationsLazyQuery>;
export type GuideCustomizationsQueryResult = Apollo.QueryResult<GuideCustomizationsQuery, GuideCustomizationsQueryVariables>;