type DownloadFileConfig = {
    filename: string;
    mimeType: string;
};

export function downloadFile(data: string, config: DownloadFileConfig) {
    const { filename, mimeType } = config;
    const blob = new Blob([data], { type: mimeType });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
    window.URL.revokeObjectURL(link.href);
}
