import React, { ReactElement } from "react";
import { Helmet } from "react-helmet-async";

import OrganizationSettings from "../../../components/OrganizationSettings/OrganizationSettings";
import ProfileSettings from "../../../components/ProfileSettings/ProfileSettings";
import SubscriptionSettings from "../../../components/SubscriptionSettings/SubscriptionSettings";
import TagsSettings from "../../../components/TagsSettings/TagsSettings";
import { useShowSubscriptionSettings } from "../../../hooks/featureFlags";

import styles from "./SettingsPage.module.scss";

function SettingsPage(): ReactElement {
    const showSubscriptionSettings = useShowSubscriptionSettings();
    return (
        <div className={styles.container}>
            <div className={styles.body}>
                <Helmet title="Settings" />
                <h1 className={styles.pageTitle}>Settings</h1>
                <h2 className={styles.sectionTitle}>Profile</h2>
                <ProfileSettings />
                <h2 className={styles.sectionTitle}>Workspace</h2>
                <OrganizationSettings className={styles.organizationSettings} />
                {showSubscriptionSettings && (
                    <>
                        <h2 className={styles.sectionTitle}>Subscription</h2>
                        <SubscriptionSettings className={styles.subscriptionSettings} />
                    </>
                )}
                <TagsSettings />
            </div>
        </div>
    );
}

export default SettingsPage;
