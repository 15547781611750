import React, { ReactElement, ReactNode } from "react";

import styles from "./MessageBubbleFooter.module.scss";

interface Props {
    children: ReactNode;
}

function MessageBubbleFooter(props: Props): ReactElement {
    const { children } = props;
    return <div className={styles.container}>{children}</div>;
}

export default MessageBubbleFooter;
