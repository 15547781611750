import { UseMutationResult, useMutation } from "@tanstack/react-query";

import { useChromeExtensionMessage } from "../hooks/chromeExtensionMessage";
import { Guide } from "../network/responseTypes";

interface SendGuideToExtensionInput {
    guide: Guide;
    openInCurrentTab?: boolean;
}

interface SendGuideToExtensionResponse {
    success: boolean;
    errorMessage?: string;
}

export function useSendGuideToExtensionMutation(): UseMutationResult<
    void,
    Error,
    SendGuideToExtensionInput
> {
    const sendMessageToExtension = useChromeExtensionMessage();
    return useMutation(async (variables) => {
        const response = await sendMessageToExtension<
            { guide: Guide; openInSenderTab?: boolean },
            SendGuideToExtensionResponse
        >("startInteractiveGuide", {
            guide: variables.guide,
            openInSenderTab: !!variables.openInCurrentTab,
        });
        if (!response.success) {
            throw new Error(response.errorMessage);
        }
    });
}
