import { useSnackbar } from "notistack";
import React, { ReactElement, useCallback } from "react";
import { useIntercom } from "react-use-intercom";

import { useCopyToClipboard } from "../../hooks/copyToClipboard";
import useAmplitudeTracker from "../../hooks/useAmplitudeTracker";
import confluenceLogo from "../../images/confluence-logo.svg";
import notionLogo from "../../images/notion-logo.svg";
import ExportAsFormatButton from "../ExportAsFormatButton/ExportAsFormatButton";

import styles from "./GuideCopyAsMarkdownButton.module.scss";
import { useExportGuideAsMarkdownMutation } from "./operations.generated";

interface Props {
    guideId: string;
}

function GuideCopyAsMarkdownButton({ guideId }: Props): ReactElement {
    const { enqueueSnackbar } = useSnackbar();
    const { track } = useAmplitudeTracker();
    const intercom = useIntercom();
    const copyToClipboard = useCopyToClipboard();
    const [getGuideMarkdown] = useExportGuideAsMarkdownMutation({
        variables: { id: guideId },
        onError: () => {
            enqueueSnackbar("Failed to copy guide as Markdown. Please try again.", {
                variant: "error",
            });
        },
        onCompleted: (data) => {
            if (data.exportGuideAsMarkdown?.markdown) {
                copyToClipboard(data.exportGuideAsMarkdown.markdown, "Markdown");
            }
        },
    });

    const onClick = useCallback(() => {
        track("Export Guide as Markdown Button Clicked");
        intercom.trackEvent("clicked_export_guide_as_markdown", { guideId });
        getGuideMarkdown().catch(console.error);
    }, [getGuideMarkdown, track, intercom, guideId]);

    return (
        <ExportAsFormatButton
            onClick={onClick}
            data-dd-action-name="Copy Guide Markdown Button"
            title="Markdown"
            description={
                <div className={styles.description}>
                    Best for pasting into
                    <img src={notionLogo} alt="Notion" className={styles.logo} />
                    <img src={confluenceLogo} alt="Confluence" className={styles.logo} /> + more
                </div>
            }
            type="copy"
            ariaLabel="Copy as Markdown"
            ariaDescription="Best for pasting into Notion, Confluence, and more"
        />
    );
}

export default GuideCopyAsMarkdownButton;
