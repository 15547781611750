import { DateTimeValidationError } from "@mui/x-date-pickers";

export function convertMinutesStringToSeconds(minutesStr?: string | null): number | null {
    return minutesStr ? parseInt(minutesStr) * 60 : null;
}

export function isToday(date: Date): boolean {
    const today = new Date();
    return (
        date.getFullYear() === today.getFullYear() &&
        date.getMonth() === today.getMonth() &&
        date.getDate() === today.getDate()
    );
}

export function getDateTimeValidationErrorMessage(error: DateTimeValidationError): string {
    switch (error) {
        case "minDate":
        case "maxDate":
        case "invalidDate":
        default:
            return "Please select a valid date and time.";
    }
}

export const humanizeDuration = (timeInMillis: number): string => {
    const totalSeconds = Math.floor(timeInMillis / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60)
        .toString()
        .padStart(2, "0");
    const seconds = Math.floor(totalSeconds % 60)
        .toString()
        .padStart(2, "0");

    if (hours > 0) {
        return `${hours.toString().padStart(2, "0")}:${minutes}:${seconds}`;
    } else {
        return `${minutes}:${seconds}`;
    }
};
