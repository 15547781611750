import { useMutation } from "@tanstack/react-query";
import Axios from "axios";

import { Guide } from "../network/responseTypes";
import { ChromeExtensionCaptureData } from "../queries/chromeExtensionCaptureData";

export function useAddStepsFromChromeExtensionMutation() {
    return useMutation<Guide, Error, ChromeExtensionCaptureData>(async (chromeExtensionData) => {
        const screenshots = chromeExtensionData.steps.map((step) => step.screenshot_data_url);

        const {
            steps_insertion_order: insertionOrder,
            guide_id: guideId,
            duration,
            steps,
        } = chromeExtensionData;

        const data = {
            steps: steps.map((step) => ({
                ...step,
                screenshot_data_url: undefined,
            })),
            insertion_order: insertionOrder,
            duration,
        };

        const { data: guide } = await Axios.post<Guide>(`/api/guides/${guideId}/add-steps/`, data);
        const stepOffset = insertionOrder ?? 0;

        await Promise.all(
            screenshots.map((screenshot, i) =>
                Axios.post("/api/screenshot/upload", {
                    screenshot_data_url: screenshot,
                    step_id: guide.steps[stepOffset + i].id,
                }),
            ),
        );

        return guide;
    });
}
