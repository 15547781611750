import { useEffect, useRef } from "react";

export function useOnValueChange<T>(value: T, onChange: () => void) {
    const ref = useRef<T>(value);
    useEffect(() => {
        if (value !== ref.current) {
            onChange();
            ref.current = value;
        }
    }, [value, onChange]);
}
