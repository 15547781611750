import { useContext } from "react";

import { SubscriptionTier } from "../../@types/graphql";
import { UserContext } from "../contexts/user";

export function useSubscriptionTier(): SubscriptionTier | null {
    const {
        organization: { subscription },
    } = useContext(UserContext);
    return subscription?.tier ?? null;
}
