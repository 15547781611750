import cx from "classnames";
import React, { ReactElement } from "react";

import TopBarButton from "../TopBarButton/TopBarButton";

import styles from "./MadeWithDrivewayBadge.module.scss";

interface Props {
    className?: string;
}

function MadeWithDrivewayBadge(props: Props): ReactElement {
    return (
        <TopBarButton
            href="https://www.driveway.app/"
            target="_blank"
            rel="noreferrer"
            className={cx(styles.badge, props.className)}
        >
            Made with Driveway
        </TopBarButton>
    );
}

export default MadeWithDrivewayBadge;
