import cx from "classnames";
import React, { ReactElement } from "react";

import CrownYellow from "../../icons/crown-yellow.svg";

import styles from "./SubscriptionPill.module.scss";

interface Props {
    tier: "Pro" | "Enterprise";
    className?: string;
}

function SubscriptionPill(props: Props): ReactElement {
    const { tier, className } = props;

    return (
        <div className={cx(styles.pill, className)}>
            <img src={CrownYellow} alt="" className={styles.crownIcon} />
            {tier}
        </div>
    );
}

export default SubscriptionPill;
