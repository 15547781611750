/* eslint-disable */
// THIS IS A GENERATED FILE. DO NOT EDIT DIRECTLY
// To generate new type definitions run `npm run generate-schema-types`
import * as Types from '../../../@types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ResetScreenshotMutationVariables = Types.Exact<{
  stepId: Types.Scalars['ID']['input'];
}>;


export type ResetScreenshotMutation = { readonly __typename?: 'Mutation', readonly resetScreenshot?: { readonly __typename?: 'ResetScreenshotMutation', readonly ok: boolean } | null };


export const ResetScreenshotDocument = gql`
    mutation ResetScreenshot($stepId: ID!) {
  resetScreenshot(stepId: $stepId) {
    ok
  }
}
    `;
export type ResetScreenshotMutationFn = Apollo.MutationFunction<ResetScreenshotMutation, ResetScreenshotMutationVariables>;

/**
 * __useResetScreenshotMutation__
 *
 * To run a mutation, you first call `useResetScreenshotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetScreenshotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetScreenshotMutation, { data, loading, error }] = useResetScreenshotMutation({
 *   variables: {
 *      stepId: // value for 'stepId'
 *   },
 * });
 */
export function useResetScreenshotMutation(baseOptions?: Apollo.MutationHookOptions<ResetScreenshotMutation, ResetScreenshotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetScreenshotMutation, ResetScreenshotMutationVariables>(ResetScreenshotDocument, options);
      }
export type ResetScreenshotMutationHookResult = ReturnType<typeof useResetScreenshotMutation>;
export type ResetScreenshotMutationResult = Apollo.MutationResult<ResetScreenshotMutation>;
export type ResetScreenshotMutationOptions = Apollo.BaseMutationOptions<ResetScreenshotMutation, ResetScreenshotMutationVariables>;