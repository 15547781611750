import { useEffect, useState } from "react";

import { ActionStep, Step } from "../network/responseTypes";

export function useStepManager(totalStepCount: number) {
    const [stepNumber, setStepNumber] = useState(0);
    const isFirstStep = stepNumber === 0;
    const isLastStep = stepNumber === totalStepCount - 1;

    const goToPreviousStep = () => {
        if (!isFirstStep) {
            setStepNumber((stepNumber) => stepNumber - 1);
        }
    };

    const goToNextStep = () => {
        if (!isLastStep) {
            setStepNumber((stepNumber) => stepNumber + 1);
        }
    };

    const reset = () => {
        setStepNumber(0);
    };

    return {
        goToNextStep,
        goToPreviousStep,
        stepNumber,
        setStepNumber,
        isLastStep,
        isFirstStep,
        reset,
    };
}

interface UseImageMetaArgs {
    imgUrl: string;
    anonymousCrossOrigin?: boolean;
    bustCache?: boolean;
}

export function useImageMeta({
    imgUrl,
    anonymousCrossOrigin = false,
    bustCache = false,
}: UseImageMetaArgs) {
    const [image, setImage] = useState<HTMLImageElement>(new Image());
    const [loaded, setLoaded] = useState(false);
    const [width, setWidth] = useState<number>();
    const [height, setHeight] = useState<number>();
    const aspectRatio = width && height ? width / height : null;

    useEffect(() => {
        const img = new Image();

        if (anonymousCrossOrigin) {
            img.crossOrigin = "anonymous";
        }

        img.onload = function () {
            setLoaded(true);
            setWidth(img.width);
            setHeight(img.height);
        };

        if (bustCache) {
            img.src = imgUrl + `&bust=${new Date().getTime()}`;
        } else {
            img.src = imgUrl;
        }

        setImage(img);
    }, [imgUrl, anonymousCrossOrigin, bustCache]);

    return { loaded, aspectRatio, width, height, image };
}

export function isActionStep(step: Step): step is ActionStep {
    return step.type === "action";
}
