import React, { ReactElement, useCallback, useEffect } from "react";

import { useViewEventRecorder } from "../../hooks/viewEventRecorder";
import { ViewEventSource } from "../../mutations/recordGuideView";
import { Step } from "../../network/responseTypes";
import GuideEditor, { Props as GuideEditorProps } from "../GuideEditor/GuideEditor";

interface Props extends GuideEditorProps {
    eventSource: ViewEventSource;
}

function EventTrackingGuideEditor(props: Props): ReactElement {
    const { guide, eventSource, ...otherProps } = props;

    const recordViewEvent = useViewEventRecorder({
        source: eventSource,
    });

    const { id: guideId } = guide;
    useEffect(() => recordViewEvent({ type: "guide", guideId }), [recordViewEvent, guideId]);

    const onStepChange = useCallback(
        (step: Step, stepIndex: number, totalSteps: number) => {
            recordViewEvent({
                type: "step",
                stepId: step.id,
                stepIndex,
                totalSteps,
            });
        },
        [recordViewEvent],
    );

    return <GuideEditor guide={guide} onStepChange={onStepChange} {...otherProps} />;
}

export default EventTrackingGuideEditor;
