import { useSnackbar } from "notistack";
import React, { ReactElement } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { DeleteOutline } from "@mui/icons-material";

import { QueryKey } from "../../queries/queryKeys";
import UndoDeleteStepButton from "../UndoDeleteStepButton/UndoDeleteStepButton";

import { useDeleteStepMutation } from "./operations.generated";
import ToolbarButton from "./ToolbarButton";

interface Props {
    stepId: string;
    guideId: string;
}

function DeleteStepButton(props: Props): ReactElement {
    const { stepId, guideId } = props;
    const { enqueueSnackbar } = useSnackbar();
    const reactQueryClient = useQueryClient();
    const [deleteStep, { loading }] = useDeleteStepMutation({
        variables: { id: stepId },
        onCompleted: (data) => {
            if (data?.deleteStep?.ok) {
                enqueueSnackbar("Step deleted", {
                    autoHideDuration: 5000,
                    variant: "success",
                    // you can only undo the most recently deleted step
                    // https://linear.app/lotus/issue/LOT-873#comment-5af7ed62
                    preventDuplicate: true,
                    action: (snackbarId) => (
                        <UndoDeleteStepButton
                            snackbarId={snackbarId}
                            guideId={guideId}
                            deletedStepId={stepId}
                        />
                    ),
                });
                void reactQueryClient.invalidateQueries([QueryKey.GuideDetail, guideId]);
            }
        },
        onError: () => {
            enqueueSnackbar("Failed to delete step. Please try again.", {
                variant: "error",
            });
        },
    });

    return (
        <ToolbarButton disabled={loading} title="Delete step" onClick={() => deleteStep()}>
            <DeleteOutline />
        </ToolbarButton>
    );
}

export default DeleteStepButton;
