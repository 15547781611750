import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { MenuItem } from "@mui/material";
import { SnackbarKey, useSnackbar } from "notistack";
import React, { ReactElement, useCallback, useState } from "react";

import { DuplicateGuideMutation, useDuplicateGuideMutation } from "./operations.generated";

interface Props {
    guideId: string;
    className?: string;
    onSuccess?: (guideId: string) => void;
    iconClassName?: string;
}

function DuplicateGuideMenuItem(props: Props): ReactElement {
    const { guideId, className, iconClassName, onSuccess } = props;
    const [uploadingToastKey, setUploadingToastKey] = useState<SnackbarKey | undefined>(undefined);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [duplicateGuide, { loading }] = useDuplicateGuideMutation({
        variables: { id: guideId },
        refetchQueries: ["Guides"],
        onCompleted: (data: DuplicateGuideMutation) => {
            closeSnackbar();
            if (data?.duplicateGuide?.guide) {
                onSuccess?.(data.duplicateGuide.guide.id);
                enqueueSnackbar("Guide duplicated", {
                    variant: "success",
                });
            }
            if (uploadingToastKey) {
                closeSnackbar(uploadingToastKey);
                setUploadingToastKey(undefined);
            }
        },
        onError: () => {
            closeSnackbar(uploadingToastKey);
            setUploadingToastKey(undefined);
            enqueueSnackbar("Failed to duplicate guide. Please try again.", {
                variant: "error",
            });
        },
    });

    const onClick = useCallback(() => {
        const toastKey = enqueueSnackbar("Duplicating guide…", {
            variant: "info",
            autoHideDuration: null,
        });
        setUploadingToastKey(toastKey);
        void duplicateGuide();
    }, [duplicateGuide, enqueueSnackbar]);

    return (
        <MenuItem className={className} disabled={loading} onClick={onClick}>
            <ContentCopyIcon fontSize="small" className={iconClassName} />
            Duplicate
        </MenuItem>
    );
}

export default DuplicateGuideMenuItem;
