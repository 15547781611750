import noExtensionIcon from "../../../icons/no-extension.svg";
import noGuidesWithFilterIcon from "../../../icons/no-guides-with-filter.svg";
import noGuidesIcon from "../../../icons/no-guides.svg";

enum EmptyStateVariant {
    NoExtension = "no-extension",
    NoGuides = "no-guides",
    NoGuidesWithFilter = "no-guides-with-filter",
}

const getEmptyStateVariant = (hasExtension: boolean, isFiltering: boolean) => {
    if (!hasExtension) {
        return EmptyStateVariant.NoExtension;
    }

    if (!isFiltering) {
        return EmptyStateVariant.NoGuides;
    }

    return EmptyStateVariant.NoGuidesWithFilter;
};

const VARIANT_TO_CONTENT = {
    [EmptyStateVariant.NoExtension]: {
        title: "Install the Driveway Extension to get started",
        description:
            "Click the button in the top right to download our Chrome extension and record your first guide.",
        imgSrc: noExtensionIcon,
    },
    [EmptyStateVariant.NoGuides]: {
        title: "No guides created yet",
        description:
            "To create your first guide, click the Create guide button in the top right of your screen.",
        imgSrc: noGuidesIcon,
    },
    [EmptyStateVariant.NoGuidesWithFilter]: {
        title: "There are no guides matching this filter",
        description: "Update your filters to see more of your guides.",
        imgSrc: noGuidesWithFilterIcon,
    },
};

export const getEmptyStateContent = (hasExtension: boolean, isFiltering: boolean) => {
    const variant = getEmptyStateVariant(hasExtension, isFiltering);
    return VARIANT_TO_CONTENT[variant];
};
