import React, { ReactElement, useRef } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import { ActionStep, VideoStep } from "../../network/responseTypes";
import { Step } from "../../network/responseTypes";
import { slateNodesToString } from "../../util/SlateUtil";
import { useResponsivePanZoom } from "../../hooks/responsivePanZoom";

import styles from "./GuideScrollItem.module.scss";
import { useFullViewObserver } from "./hooks";

interface Props {
    index: number;
    step: Step;
    numSteps: number;
    onViewStep: (step: Step, stepIndex: number) => void;
}

function StepNumber(props: { index: number }): ReactElement {
    return <div className={styles.stepNumber}>{props.index + 1}</div>;
}

const Screenshot = (props: { step: ActionStep }) => {
    const { step } = props;
    const src = step.screenshot_url;
    const { containerRef, imageScale } = useResponsivePanZoom<HTMLDivElement>(step.screenshot_url);

    return (
        <div className={styles.screenshotContainer} ref={containerRef}>
            {imageScale > 0 && (
                <TransformWrapper
                    initialScale={imageScale}
                    minScale={imageScale}
                    centerOnInit
                    wheel={{
                        disabled: true,
                    }}
                >
                    {({ zoomIn, zoomOut }) => (
                        <>
                            <TransformComponent wrapperClass={styles.screenshot}>
                                <img src={src} alt={step.title + " screenshot"} />
                            </TransformComponent>
                            <div className={styles.zoomControls}>
                                <button onClick={() => zoomIn()} aria-label="Zoom in">
                                    <svg
                                        width="16"
                                        height="16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        aria-hidden
                                    >
                                        <path
                                            d="M7.5 12.667V8.5H3.333v-1H7.5V3.333h1V7.5h4.167v1H8.5v4.167h-1Z"
                                            fill="#fff"
                                        />
                                    </svg>
                                </button>
                                <button onClick={() => zoomOut()} aria-label="Zoom out">
                                    <svg
                                        width="16"
                                        height="16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        aria-hidden
                                    >
                                        <path d="M3.333 8.5v-1h9.334v1H3.333Z" fill="#fff" />
                                    </svg>
                                </button>
                            </div>
                        </>
                    )}
                </TransformWrapper>
            )}
        </div>
    );
};

const Video = (props: { step: VideoStep }) => {
    const { step } = props;
    const videoUrl = step.video.url;

    // eslint-disable-next-line jsx-a11y/media-has-caption
    return <video controls src={videoUrl} className={styles.video} title={step.title + " video"} />;
};

const StepMedia = (props: {
    step: Step;
    index: number;
    onViewStep: (step: Step, stepIndex: number) => void;
}) => {
    const { step } = props;
    const ref = useRef<HTMLDivElement>(null);

    useFullViewObserver(ref, () => {
        props.onViewStep(step, props.index);
    });
    return (
        <div ref={ref}>
            {step.type === "action" ? <Screenshot step={step} /> : <Video step={step} />}
        </div>
    );
};

export default React.forwardRef<HTMLDivElement, Props>(
    function GuideScrollItem(props, ref): ReactElement {
        return (
            <div className={styles.container} ref={ref}>
                <div className={styles.header}>
                    <StepNumber index={props.index} />
                    <div className={styles.description}>
                        <h2 className={styles.title}>{props.step.title}</h2>
                        {props.step.description ? (
                            <p className={styles.subtitle}>
                                {slateNodesToString(props.step.description)}
                            </p>
                        ) : null}
                        {props.step.type === "action" && props.step.narration ? (
                            <div className={styles.audioContainer}>
                                {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                                <audio
                                    src={props.step.narration.url}
                                    controls
                                    controlsList="noplaybackrate nodownload"
                                />
                            </div>
                        ) : null}
                    </div>
                </div>
                <StepMedia onViewStep={props.onViewStep} step={props.step} index={props.index} />
            </div>
        );
    },
);
