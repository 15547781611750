import { UseMutationResult, useMutation } from "@tanstack/react-query";

import { useChromeExtensionMessage } from "../hooks/chromeExtensionMessage";

interface ShowTabListForNewCaptureInExtensionResponse {
    success: boolean;
    errorMessage?: string;
}

export function useShowTabListForNewCaptureInExtensionMutation(): UseMutationResult<
    void,
    Error,
    void
> {
    const sendMessageToExtension = useChromeExtensionMessage();
    return useMutation(async () => {
        const response = await sendMessageToExtension<
            void,
            ShowTabListForNewCaptureInExtensionResponse
        >("showTabListForNewCapture");
        if (!response.success) {
            throw new Error(response.errorMessage);
        }
    });
}
