import cx from "classnames";
import React, { ReactElement } from "react";

import SubscriptionPill from "../ProPill/SubscriptionPill";
import SubscriptionUpgradeButton from "../SubscriptionUpgradeButton/SubscriptionUpgradeButton";

import styles from "./UpgradeBannerButton.module.scss";

interface Props {
    tier: "Pro" | "Enterprise";
    text: string;
    className?: string;
}

function UpgradeBannerButton(props: Props): ReactElement {
    const { tier, text, className } = props;
    return (
        <SubscriptionUpgradeButton
            color="primary"
            variant="text"
            className={cx(styles.container, className)}
        >
            <SubscriptionPill tier={tier} className={styles.subscriptionPill} />
            {text}
            <span aria-hidden>&nbsp;→</span>
        </SubscriptionUpgradeButton>
    );
}

export default UpgradeBannerButton;
