import cx from "classnames";
import React, { ReactElement, useContext, useEffect, useState } from "react";

import { UserContext } from "../../contexts/user";
import { useKeyboardNavigation } from "../../hooks/useKeyboardNavigation";
import GuideSidebar from "../../layouts/GuideSidebar/GuideSidebar";
import { Guide, Step } from "../../network/responseTypes";
import AddScreenshotsButton from "../AddStepsButtons/AddScreenshotsButton";
import GuidePreview from "../GuidePreview/GuidePreview";
import PlaceholderMessage from "../PlaceholderMessage/PlaceholderMessage";
import StepEditor from "../StepEditor/StepEditor";

import styles from "./GuideEditor.module.scss";

export interface Props {
    guide: Guide;
    isExtensionInstalled?: boolean;
    onStepChange?: (step: Step, stepIndex: number, totalSteps: number) => void;
    isPreviewing?: boolean;
    className?: string;
}

function GuideEditor(props: Props): ReactElement {
    const {
        guide,
        isExtensionInstalled = false,
        onStepChange,
        isPreviewing = false,
        className,
    } = props;

    const { id: userId } = useContext(UserContext);
    const [stepIndex, setStepIndex] = useState(0);

    const isUserAuthor = guide?.author.id === userId;
    const step = guide?.steps[stepIndex];
    const stepCount = guide?.steps.length || 0;

    useEffect(() => {
        if (step && onStepChange) {
            onStepChange(step, stepIndex, stepCount);
        }
    }, [onStepChange, step, stepIndex, stepCount]);

    useEffect(() => {
        if (!step && stepCount > 0) {
            setStepIndex(stepIndex - 1);
        }
    }, [step, stepCount, stepIndex]);

    const goToPreviousStep = () => {
        const isFirstStep = guide?.steps && stepIndex === 0;
        if (!isFirstStep) {
            setStepIndex(stepIndex - 1);
        }
    };

    const goToNextStep = () => {
        const isLastStep = guide?.steps && stepIndex === guide?.steps.length - 1;
        if (!isLastStep) {
            setStepIndex(stepIndex + 1);
        }
    };

    useKeyboardNavigation(goToPreviousStep, goToNextStep);

    return (
        <div className={cx(styles.container, className)}>
            <GuideSidebar
                guide={guide}
                stepNumber={stepIndex}
                setStepNumber={setStepIndex}
                goToPreviousStep={goToPreviousStep}
                goToNextStep={goToNextStep}
                className={
                    // Use styles.hidden (display: none) rather than conditional rendering so that scroll
                    // position of steps side panel doesn't reset whenever user switches from preview back to edit
                    isPreviewing ? styles.hidden : undefined
                }
            />
            {!isPreviewing && (
                <div className={styles.step}>
                    {step ? (
                        <StepEditor
                            guide={guide}
                            step={step}
                            stepNumber={stepIndex}
                            isUserAuthor={isUserAuthor}
                            goToNextStep={goToNextStep}
                            goToPreviousStep={goToPreviousStep}
                            onRestart={() => setStepIndex(0)}
                            className={styles.stepEditor}
                        />
                    ) : (
                        <PlaceholderMessage
                            className={styles.placeholder}
                            title="There are no steps in this guide."
                        >
                            {isUserAuthor && isExtensionInstalled && (
                                <AddScreenshotsButton
                                    className={styles.addStepsButton}
                                    guideId={guide.id}
                                    stepsInsertionOrder={0}
                                    color="primary"
                                    variant="contained"
                                />
                            )}
                        </PlaceholderMessage>
                    )}
                </div>
            )}
            {isPreviewing && (
                <GuidePreview
                    guide={guide}
                    stepIndex={stepIndex}
                    onNextStep={goToNextStep}
                    onPreviousStep={goToPreviousStep}
                    onRestart={() => setStepIndex(0)}
                    onSetStep={setStepIndex}
                    canComplete={false}
                    onStepChange={onStepChange}
                />
            )}
        </div>
    );
}

export default GuideEditor;
