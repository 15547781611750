import { Button } from "@mui/material";
import React, { ReactElement, useContext, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { UserContext } from "../../contexts/user";
import { Card } from "../Card/Card";
import SubscriptionUpgradeDialog from "../SubscriptionUpgradeDialog/SubscriptionUpgradeDialog";

import styles from "./SubscriptionSettings.module.scss";

const UPGRADE_DIALOG_QUERY_PARAM = "showUpgradeDialog";

interface Props {
    className?: string;
}

function SubscriptionSettings(props: Props): ReactElement | null {
    const {
        organization: { subscription },
    } = useContext(UserContext);
    const [searchParams] = useSearchParams();
    const [isDialogOpen, setDialogOpen] = useState(
        searchParams.get(UPGRADE_DIALOG_QUERY_PARAM) === "true",
    );

    if (!subscription || !subscription.tier) {
        return null;
    }
    const { tier, is_trial } = subscription;
    const showCheckoutButton = tier === "FREE" || (tier === "PRO" && is_trial);

    return (
        <Card variant="content" className={props.className}>
            <h3 className={styles.cardTitle}>Current plan</h3>
            <div className={styles.planName}>
                {tier === "FREE" && "Free plan"}
                {tier === "PRO" && `Pro plan${is_trial ? " (Trial)" : ""}`}
                {tier === "ENTERPRISE" && "Enterprise plan"}
            </div>
            <div className={styles.planDescription}>
                {tier === "FREE" && "A private workspace and share up to 3 public guides"}
                {tier === "PRO" && "Unlimited guides and up to 10 team members"}
                {tier === "ENTERPRISE" && "Advanced features, security, and support"}
            </div>
            {showCheckoutButton ? (
                <>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => setDialogOpen(true)}
                        className={styles.button}
                    >
                        Upgrade
                    </Button>
                    <SubscriptionUpgradeDialog
                        isOpen={isDialogOpen}
                        onClose={() => setDialogOpen(false)}
                    />
                </>
            ) : (
                <Button
                    color="primary"
                    variant="contained"
                    className={styles.button}
                    href="/subscriptions/create-customer-portal-session"
                >
                    Manage
                </Button>
            )}
        </Card>
    );
}

export default SubscriptionSettings;
