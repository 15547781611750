import { Pause, PlayArrow } from "@mui/icons-material";
import { ButtonBase, ClickAwayListener, Tooltip } from "@mui/material";
import cx from "classnames";
import React, { ReactElement, useCallback, useEffect, useRef, useState } from "react";

import { useIsWithinGuideEmbed } from "../../hooks/isWithinGuideEmbed";

import styles from "./PlayPauseNarrationButton.module.scss";

const BUTTON_WIDTH = 24; // not including the progress bar border
const STROKE_WIDTH = 3;
const WIDTH_WITH_BORDER = BUTTON_WIDTH + STROKE_WIDTH * 2;
const RADIUS = BUTTON_WIDTH / 2 + STROKE_WIDTH / 2;
const CIRCUMFERENCE = 2 * Math.PI * RADIUS;

interface Props {
    narrationUrl: string;
    className?: string;
    isFirstStep: boolean;
    hasStartedNavigating: boolean;
    setHasStartedNavigating: React.Dispatch<React.SetStateAction<boolean>>;
}

function PlayPauseNarrationButton(props: Props): ReactElement {
    const { narrationUrl, className, isFirstStep, hasStartedNavigating, setHasStartedNavigating } =
        props;
    const [isPlaying, setIsPlaying] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);

    const isWithinGuideEmbed = useIsWithinGuideEmbed();

    const audioRef = useRef<HTMLAudioElement>(null);
    const { percentComplete, resetProgress, startProgress } = usePercentComplete(audioRef);

    const strokeDashoffset = CIRCUMFERENCE - (percentComplete / 100) * CIRCUMFERENCE;

    const onClick = useCallback(() => {
        if (isPlaying) {
            audioRef.current?.pause();
            setIsPlaying(false);
        } else {
            audioRef.current
                ?.play()
                .then(startProgress)
                .then(() => {
                    setIsPlaying(true);
                    setHasStartedNavigating(true);
                })
                .catch(console.error);
            setShowTooltip(false);
        }
    }, [isPlaying, startProgress, setHasStartedNavigating]);

    const onEnded = useCallback(() => {
        setIsPlaying(false);
        resetProgress();
    }, [resetProgress]);

    useEffect(
        () => {
            if (audioRef.current) {
                if (isWithinGuideEmbed && isFirstStep && !hasStartedNavigating) {
                    setShowTooltip(true);
                } else {
                    audioRef.current
                        .play()
                        .then(startProgress)
                        .then(() => setIsPlaying(true))
                        .catch((e) => {
                            if (e.name === "NotAllowedError") {
                                setShowTooltip(true);
                            } else {
                                console.error(e);
                            }
                        });
                }
            }
        },
        // Autoplay narration whenever narrationUrl changes except in embed
        [startProgress, narrationUrl, isWithinGuideEmbed, isFirstStep, hasStartedNavigating],
    );

    return (
        <>
            <div
                className={cx(styles.progressCircleButton, className)}
                style={{
                    right: -BUTTON_WIDTH / 1.5,
                    bottom: -BUTTON_WIDTH / 1.5,
                }}
            >
                <svg
                    width={WIDTH_WITH_BORDER}
                    height={WIDTH_WITH_BORDER}
                    viewBox={`0 0 ${WIDTH_WITH_BORDER} ${WIDTH_WITH_BORDER}`}
                    fill="none"
                >
                    <circle
                        stroke="white"
                        fill="white"
                        strokeWidth={STROKE_WIDTH}
                        r={RADIUS}
                        cx={WIDTH_WITH_BORDER / 2}
                        cy={WIDTH_WITH_BORDER / 2}
                    />
                    <circle
                        stroke="#5055F1"
                        strokeLinecap="round"
                        fill="transparent"
                        strokeWidth={STROKE_WIDTH}
                        strokeDasharray={CIRCUMFERENCE}
                        strokeDashoffset={strokeDashoffset}
                        r={RADIUS}
                        cx={WIDTH_WITH_BORDER / 2}
                        cy={WIDTH_WITH_BORDER / 2}
                    />
                </svg>

                <ClickAwayListener onClickAway={() => setShowTooltip(false)}>
                    <Tooltip
                        open={showTooltip}
                        title="Click to play audio"
                        placement="right"
                        arrow
                        disableHoverListener
                        disableTouchListener
                        disableFocusListener
                        componentsProps={{ tooltip: { "aria-hidden": true } }}
                        classes={{
                            tooltip: styles.tooltip,
                            arrow: styles.tooltipArrow,
                        }}
                    >
                        <ButtonBase
                            className={styles.playPauseButton}
                            style={{
                                width: BUTTON_WIDTH,
                                height: BUTTON_WIDTH,
                                top: STROKE_WIDTH,
                                left: STROKE_WIDTH,
                            }}
                            onClick={onClick}
                            aria-label={isPlaying ? "Pause audio" : "Play audio"}
                        >
                            {isPlaying ? (
                                <Pause fontSize="small" />
                            ) : (
                                <PlayArrow fontSize="small" />
                            )}
                        </ButtonBase>
                    </Tooltip>
                </ClickAwayListener>
            </div>
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <audio src={narrationUrl} ref={audioRef} onEnded={onEnded} />
        </>
    );
}

const usePercentComplete = (audioRef: React.RefObject<HTMLAudioElement>) => {
    const [percentComplete, setPercentComplete] = useState(0);
    const animationFrameId = useRef<number>();

    const calculatePercentComplete = useCallback(() => {
        if (audioRef.current?.currentTime && audioRef.current?.duration) {
            setPercentComplete((audioRef.current?.currentTime / audioRef.current?.duration) * 100);
        } else {
            setPercentComplete(0);
        }
        animationFrameId.current = requestAnimationFrame(calculatePercentComplete);
    }, [audioRef]);

    useEffect(() => {
        return () => {
            if (animationFrameId.current) {
                cancelAnimationFrame(animationFrameId.current);
            }
        };
    }, []);

    return {
        percentComplete,
        startProgress: calculatePercentComplete,
        resetProgress: () => {
            if (animationFrameId.current) {
                cancelAnimationFrame(animationFrameId.current);
            }
            setPercentComplete(0);
        },
    };
};

export default PlayPauseNarrationButton;
