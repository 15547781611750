import { Close as CloseIcon } from "@mui/icons-material";
import { Dialog, IconButton } from "@mui/material";
import React, { ReactElement } from "react";

import triggerConfigAnimation from "../../images/trigger-configuration.gif";
import SubscriptionUpgradeButton from "../SubscriptionUpgradeButton/SubscriptionUpgradeButton";
import UpgradePill from "../UpgradePill/UpgradePill";

import styles from "./UpgradeToTriggerDialog.module.scss";

interface Props {
    isOpen: boolean;
    onClose: () => void;
}

function UpgradeToTriggerDialog(props: Props): ReactElement {
    const { isOpen, onClose } = props;
    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            scroll="body"
            fullWidth
            keepMounted
            classes={{ paper: styles.paper }}
        >
            <UpgradePill />
            <IconButton
                aria-label="Close"
                onClick={onClose}
                className={styles.closeButton}
                size="large"
            >
                <CloseIcon />
            </IconButton>
            <h2 className={styles.title}>Drive adoption with nudges</h2>
            <p className={styles.description}>
                Automatically show this guide to members of your team when and where they need it
                most. Use nudges to train reps on newly released features, roll out new CRM
                processes, or guide new hires right in the flow of their work.
            </p>
            <img src={triggerConfigAnimation} className={styles.animation} alt="" />
            <SubscriptionUpgradeButton
                variant="contained"
                color="primary"
                onClick={onClose}
                className={styles.upgradeButton}
                fullWidth
            >
                Upgrade now
            </SubscriptionUpgradeButton>
        </Dialog>
    );
}

export default UpgradeToTriggerDialog;
