import { useSnackbar } from "notistack";
import React, { ReactElement, useCallback } from "react";
import { useIntercom } from "react-use-intercom";

import useAmplitudeTracker from "../../hooks/useAmplitudeTracker";
import gmailLogo from "../../images/gmail-logo.svg";
import googleDocsLogo from "../../images/google-docs-logo.svg";
import { copyHtmlToClipboard } from "../../util/ClipboardUtil";
import ExportAsFormatButton from "../ExportAsFormatButton/ExportAsFormatButton";

import styles from "./GuideCopyAsHTMLButton.module.scss";
import { ExportGuideAsHtmlMutation, useExportGuideAsHtmlMutation } from "./operations.generated";

interface Props {
    guideId: string;
}

function GuideCopyAsHTMLButton({ guideId }: Props): ReactElement {
    const { enqueueSnackbar } = useSnackbar();
    const { track } = useAmplitudeTracker();
    const intercom = useIntercom();
    const [getGuideHtml] = useExportGuideAsHtmlMutation({
        variables: { id: guideId },
        onError: () => {
            enqueueSnackbar("Failed to copy guide as HTML. Please try again.", {
                variant: "error",
            });
        },
        onCompleted: (data: ExportGuideAsHtmlMutation) => {
            if (data?.exportGuideAsHtml?.html) {
                copyHtmlToClipboard(data.exportGuideAsHtml.html)
                    .then(() =>
                        enqueueSnackbar("HTML copied to clipboard!", {
                            variant: "success",
                        }),
                    )
                    .catch(console.error);
            }
        },
    });

    const handleClick = useCallback(() => {
        track("Export Guide as HTML Button Clicked");
        intercom.trackEvent("clicked_export_guide_as_html", { guideId });
        void getGuideHtml();
    }, [getGuideHtml, track, intercom, guideId]);

    return (
        <ExportAsFormatButton
            title="HTML"
            description={
                <div className={styles.description}>
                    Best for pasting into
                    <img src={googleDocsLogo} alt="Google Docs" className={styles.logo} />
                    <img src={gmailLogo} alt="Gmail" className={styles.logo} /> + more
                </div>
            }
            type="copy"
            ariaLabel="Copy as HTML"
            ariaDescription="Best for pasting into Google Docs, Gmail, and more"
            data-dd-action-name="Copy Guide HTML Button"
            onClick={handleClick}
        />
    );
}

export default GuideCopyAsHTMLButton;
