import { ExpandMore as ExpandIcon } from "@mui/icons-material";
import { MenuItem, MenuItemProps, Select, TextField } from "@mui/material";
import cx from "classnames";
import React, { ReactElement } from "react";

import { PatternType, StringPattern } from "../../util/StringPatternUtil";

import styles from "./StringPatternInput.module.scss";

interface Props {
    pattern: StringPattern;
    onChange: (pattern: StringPattern) => void;
    disabled?: boolean;
    className?: string;
}

function StringPatternInput(props: Props): ReactElement {
    const { pattern, onChange, disabled = false, className } = props;

    return (
        <div className={cx(styles.container, className)}>
            <Select
                value={pattern.type}
                onChange={(ev) => {
                    const newType = ev.target.value as PatternType;
                    onChange(
                        newType === "any"
                            ? { type: newType }
                            : {
                                  type: newType,
                                  value: pattern.type === "any" ? "" : pattern.value,
                              },
                    );
                }}
                inputProps={{ "aria-label": "Pattern type" }}
                disabled={disabled}
                classes={{ select: styles.select }}
                IconComponent={ExpandIcon}
            >
                <PatternTypeMenuItem value="any">Is anything</PatternTypeMenuItem>
                <PatternTypeMenuItem value="is">Is</PatternTypeMenuItem>
                <PatternTypeMenuItem value="is not">Is not</PatternTypeMenuItem>
                <PatternTypeMenuItem value="starts with">Starts with</PatternTypeMenuItem>
                <PatternTypeMenuItem value="ends with">Ends with</PatternTypeMenuItem>
                <PatternTypeMenuItem value="contains">Contains</PatternTypeMenuItem>
                <PatternTypeMenuItem value="does not contain">Does not contain</PatternTypeMenuItem>
            </Select>
            {pattern.type !== "any" && (
                <TextField
                    value={pattern.value}
                    onChange={(ev) => onChange({ ...pattern, value: ev.target.value })}
                    inputProps={{ "aria-label": "Pattern text" }}
                    disabled={disabled}
                    className={styles.textField}
                    InputProps={{ classes: { input: styles.input } }}
                />
            )}
        </div>
    );
}

const PatternTypeMenuItem = (props: Omit<MenuItemProps, "value"> & { value: PatternType }) => (
    <MenuItem {...props} />
);

export default StringPatternInput;
