import { FlipOptions, Placement } from "@floating-ui/react";

import { TooltipConfig } from "../network/responseTypes";

export const TOOLTIP_FLIP_FALLBACK_AXIS_SIDE_DIR: FlipOptions["fallbackAxisSideDirection"] =
    "start";
export const TOOLTIP_SHIFT_CROSS_AXIS = true;
export const TOOLTIP_ARROW_PADDING = 24;

const DEFAULT_TOOLTIP_PLACEMENT: Placement = "top";
const DEFAULT_TOOLTIP_OFFSET = 24;

export function getTooltipPlacement(tooltipConfig: TooltipConfig | null): Placement {
    const configPlacement = tooltipConfig?.placement;
    switch (configPlacement) {
        case "TOP":
            return "top";
        case "BOTTOM":
            return "bottom";
        case "LEFT":
            return "left";
        case "RIGHT":
            return "right";
        default:
            return DEFAULT_TOOLTIP_PLACEMENT;
    }
}

export function getTooltipOffset(tooltipConfig: TooltipConfig | null): number {
    return tooltipConfig?.offset ?? DEFAULT_TOOLTIP_OFFSET;
}
