import { Icon, IconButton, Menu } from "@mui/material";
import cx from "classnames";
import React, { ReactElement, useContext, useId, useLayoutEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import { GuideType } from "../../../../@types/graphql";
import DeleteGuideMenuItem from "../../../components/DeleteGuideMenuItem/DeleteGuideMenuItem";
import DuplicateGuideMenuItem from "../../../components/DuplicateGuideMenuItem/DuplicateGuideMenuItem";
import PersonAvatar from "../../../components/PersonAvatar/PersonAvatar";
import TagChip from "../../../components/TagsChip/TagChip";
import { UserContext } from "../../../contexts/user";
import { useCopyGuideLink } from "../../../hooks/copyGuideLink";
import eyeIcon from "../../../icons/eye.svg";
import starBlankIcon from "../../../icons/star-blank.svg";
import starFilledIcon from "../../../icons/star-filled.svg";
import tagsIcon from "../../../icons/tags.svg";
import { stringToColor } from "../../../util/ColorUtil";
import { isToday } from "../../../util/TimeUtil";

import styles from "./GuideListCard.module.scss";
import StarGuide from "./StarGuide";

interface Props {
    guide: GuideType;
    className?: string;
}

function GuideListCard(props: Props): ReactElement {
    const { guide, className } = props;
    const { id: userId } = useContext(UserContext);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [keepHoverState, setKeepHoverState] = useState<boolean>(false);
    const copyGuideLink = useCopyGuideLink(guide.id);
    const { id: guideId, title, author, numViews } = guide;
    const isUserAuthor = author.id === userId;

    const shadowTagsRef = useRef<HTMLDivElement>(null);
    const [numTagsToShow, setNumTagsToShow] = useState<number>(-1);

    useLayoutEffect(() => {
        if (shadowTagsRef.current) {
            const tagEls = Array.from(shadowTagsRef.current.children);
            const top = tagEls[0].getBoundingClientRect().top;
            const tagsToShow = tagEls.filter((tagEl) => tagEl.getBoundingClientRect().top === top);
            setNumTagsToShow(tagsToShow.length);
        }
    }, [guide.tags]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setKeepHoverState(true);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setKeepHoverState(false);
    };
    const updatedAt = new Date(guide.updatedAt);
    // Remove "Guide for" when applying color so they don't look similar.
    const topColor = stringToColor(
        guide.title.startsWith("Guide for") ? guide.title.substring(7) : guide.title,
    );

    const moreGuideOptionsButtonId = useId();

    return (
        <div
            className={cx(styles.container, className)}
            style={{ borderTop: `4px solid ${topColor}` }}
        >
            <Link
                to={`/guides/${guideId}`}
                className={styles.clickableBackgroundLink}
                data-driveway="guide-card-link"
                aria-label={`View guide “${guide.title}”`}
            />
            <div className={styles.details}>
                <div>
                    <div className={styles.title}>{title}</div>
                </div>
                <div className={styles.starContainer}>
                    <img
                        className={cx({ [styles.blankStar]: !guide.isStarred })}
                        src={guide.isStarred ? starFilledIcon : starBlankIcon}
                        alt={guide.isStarred ? "Starred" : "Not starred"}
                    />
                </div>
            </div>
            {guide.tags.length > 0 && (
                <div className={styles.tagsContainer}>
                    <div className={styles.realTags}>
                        <Icon>
                            <img className={styles.tagsIcon} src={tagsIcon} alt="" />
                        </Icon>
                        <div className={styles.tagRow}>
                            {guide.tags.map((tag, index) =>
                                index >= numTagsToShow ? null : (
                                    <TagChip key={index} tag={tag} className={styles.tagChip} />
                                ),
                            )}
                        </div>
                        {guide.tags.length - numTagsToShow > 0 && (
                            <TagChip
                                tag={{
                                    id: "overflow",
                                    name: `+${guide.tags.length - numTagsToShow}`,
                                }}
                                className={styles.tagChip}
                            />
                        )}
                    </div>
                    <div ref={shadowTagsRef} className={styles.shadowTags}>
                        {guide.tags.map((tag) => (
                            <TagChip key={tag.id} tag={tag} className={styles.tagChip} />
                        ))}
                    </div>
                </div>
            )}
            <div className={styles.metadataContainer}>
                <PersonAvatar
                    person={{
                        first_name: author.firstName,
                        last_name: author.lastName,
                        avatar_url: author.avatarUrl || "",
                    }}
                    className={styles.avatar}
                />
                <div className={styles.authorTextContainer}>
                    <div className={styles.authorName}>{author.fullName}</div>
                    <div>
                        {isToday(updatedAt)
                            ? updatedAt.toLocaleTimeString()
                            : updatedAt.toLocaleDateString()}
                    </div>
                </div>
                <div className={styles.viewCount}>
                    <img src={eyeIcon} alt="View count:" className={styles.eyeIcon} />
                    {numViews}
                </div>
            </div>
            <div className={cx(styles.overlay, keepHoverState ? styles.keepHoverState : undefined)}>
                <div className={styles.overlayStarContainer}>
                    <StarGuide guideId={guide.id} isStarred={guide.isStarred} />
                </div>
                <div className={styles.actions}>
                    <IconButton
                        aria-label="Copy guide link"
                        size="small"
                        onClick={() => {
                            copyGuideLink();
                            handleClose();
                        }}
                    >
                        <Icon className={styles.white}>link</Icon>
                    </IconButton>
                    <IconButton
                        aria-label="More guide options"
                        size="small"
                        onClick={handleClick}
                        id={moreGuideOptionsButtonId}
                    >
                        <Icon className={styles.white}>more_horiz</Icon>
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        transformOrigin={{
                            vertical: -30,
                            horizontal: 85,
                        }}
                        classes={{
                            paper: styles.menuPaper,
                        }}
                        MenuListProps={{ "aria-labelledby": moreGuideOptionsButtonId }}
                    >
                        <DuplicateGuideMenuItem
                            guideId={guideId}
                            className={styles.grey800}
                            iconClassName={styles.menuItemIcon}
                            onSuccess={() => {
                                setAnchorEl(null);
                                setKeepHoverState(false);
                            }}
                        />
                        {isUserAuthor && (
                            <DeleteGuideMenuItem
                                guideId={guideId}
                                onClose={() => {
                                    setAnchorEl(null);
                                    setKeepHoverState(false);
                                }}
                                className={styles.grey800}
                                iconClassName={styles.menuItemIcon}
                            />
                        )}
                    </Menu>
                </div>
            </div>
        </div>
    );
}

export default GuideListCard;
