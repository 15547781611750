import React, { ReactNode } from "react";

import styles from "./EmptyState.module.scss";

interface Props {
    imgSrc: string;
    title: string;
    description: string;
    button: ReactNode;
}

const EmptyState = (props: Props) => {
    const { imgSrc, title, description, button } = props;
    return (
        <div className={styles.emptyContainer}>
            <img src={imgSrc} className={styles.emptyIcon} alt="" />
            <>
                <div className={styles.emptyTitle}>{title}</div>
                <div className={styles.emptyDescription}>{description}</div>
            </>
            {button}
        </div>
    );
};

export default EmptyState;
