import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { Button, ButtonProps } from "@mui/material";
import React, { ReactElement, useCallback } from "react";

import { useChromeExtensionMessage } from "../../hooks/chromeExtensionMessage";

interface Props extends ButtonProps {
    stepsInsertionOrder: number;
    guideId: string;
}

function AddScreenshotsButton({
    guideId,
    stepsInsertionOrder,
    className,
    variant = "outlined",
}: Props): ReactElement {
    const sendMessageToExtension = useChromeExtensionMessage();
    const startCapture = useCallback(() => {
        sendMessageToExtension("insertSteps", { guideId, stepsInsertionOrder }).catch(
            console.error,
        );
    }, [sendMessageToExtension, guideId, stepsInsertionOrder]);

    return (
        <>
            <Button
                variant={variant}
                startIcon={<RadioButtonCheckedIcon />}
                onClick={startCapture}
                aria-label="Add screenshots"
                data-dd-action-name="Add Screenshots Button"
                className={className}
            >
                Add&nbsp;screenshots
            </Button>
        </>
    );
}

export default AddScreenshotsButton;
