import React, { ReactElement } from "react";

import { useUserPerson } from "../../hooks/userPerson";

import PersonAvatar from "./PersonAvatar";

interface Props {
    className?: string;
}

function CurrentUserAvatar(props: Props): ReactElement {
    const person = useUserPerson();
    return <PersonAvatar person={person} className={props.className} />;
}

export default CurrentUserAvatar;
