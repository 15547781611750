import { createTheme, adaptV4Theme } from "@mui/material/styles";

const primaryColor = "#5056f1"; // Royal blue
const white = "#FFFFFF";
const grey1 = "#696d75";
const grey4 = "#E9E9EC";
const black1 = "#111111";
const purple50 = "#EBECFE";
const purple100 = "#CDCEFC";
const purple500 = "#5055f1";

export const appTheme = createTheme(
    adaptV4Theme({
        props: {
            MuiButton: {
                disableElevation: true,
            },
            MuiButtonBase: {
                disableTouchRipple: true,
            },
        },
        palette: {
            primary: {
                main: primaryColor,
            },
        },
        typography: {
            fontFamily: "Inter",
            button: {
                fontSize: "13px",
                lineHeight: "17px",
                fontWeight: 500,
                textTransform: "none",
            },
        },
        overrides: {
            MuiButton: {
                root: {
                    borderRadius: "4px",
                    padding: "8px 12px",
                    "&:hover": {
                        backgroundColor: purple50,
                    },
                },
                sizeSmall: {
                    padding: "4px 12px",
                    minWidth: "51px",
                    fontSize: "12px",
                    lineHeight: "16px",
                    fontWeight: 600,
                },
                text: {
                    color: grey1,
                },
                outlined: {
                    borderColor: purple500,
                },
                contained: {
                    backgroundColor: grey4,
                    color: black1,
                    "&:hover": {
                        backgroundColor: grey4,
                        backgroundImage:
                            "linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1))",
                    },
                    "&:disabled": {
                        backgroundColor: grey4,
                        color: "#32393D80",
                    },
                },
                containedSizeLarge: {
                    fontSize: "14px",
                    minHeight: "52px",
                },
                containedPrimary: {
                    color: white,
                    backgroundColor: primaryColor,
                    "&:hover": {
                        backgroundColor: primaryColor,
                    },
                    "&:disabled": {
                        backgroundColor: purple100,
                        color: "#FFFFFF80",
                    },
                },
            },
            MuiMenu: {
                paper: {
                    background: "rgba(0, 0, 0, 0.03)",
                    borderRadius: "8px",
                    backdropFilter: "blur(20px)",
                    boxShadow:
                        "0px 38px 80px rgba(0, 0, 0, 0.04), 0px 14.637px 25.4815px rgba(0, 0, 0, 0.0242963), 0px 3.0963px 6.51852px rgba(0, 0, 0, 0.0157037)",
                },
                list: {
                    padding: "4px",
                },
            },
            MuiMenuItem: {
                root: {
                    "&:hover": {
                        backgroundColor: white,
                        boxShadow:
                            "0px 0px 20px rgba(0, 0, 0, 0.04), 0px 0px 3.25px rgba(0, 0, 0, 0.03)",
                    },
                    borderRadius: "4px",
                    fontSize: "13px",
                    lineHeight: "17px",
                    color: black1,
                },
                gutters: {
                    paddingLeft: "12px",
                    paddingRight: "12px",
                },
            },
            MuiListItemText: {
                root: {
                    marginTop: 0,
                    marginBottom: 0,
                },
            },
            MuiListItemIcon: {
                root: {
                    minWidth: "26px",
                },
            },
            MuiOutlinedInput: {
                multiline: {
                    padding: 0,
                    color: "inherit",
                },
            },
            MuiChip: {
                deleteIcon: {
                    fontSize: "12px",
                    lineHeight: "16px",
                    width: "12px",
                    height: "16px",
                    margin: "0 -2px 0 2px",
                },
            },
        },
    }),
);
