import React, { ReactElement, useState } from "react";
import { Button, Popover, Icon } from "@mui/material";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

import CheckboxGroupPanel from "../CheckboxGroupPanel/CheckboxGroupPanel";
import { useTagsQuery } from "../TagsSettings/operations.generated";
import tagsIcon from "../../icons/tags.svg";

import { useGuideAuthorsQuery } from "./operations.generated";
import styles from "./GuideListFilterButton.module.scss";

interface Props {
    filteredAuthorIds: Array<string>;
    filteredTagIds: Array<string>;
    setFilteredAuthorIds: (ids: Array<string> | null) => void;
    setFilteredTagIds: (ids: Array<string> | null) => void;
}

type FilterType = "people" | "tags";

function GuideListFilterButton(props: Props): ReactElement {
    const { filteredAuthorIds, filteredTagIds, setFilteredAuthorIds, setFilteredTagIds } = props;

    const [filterType, setFilterType] = useState<FilterType>("people");

    const { data: guideAuthorResults } = useGuideAuthorsQuery();
    const { data: guideTagResults } = useTagsQuery();

    const authorOptions = (guideAuthorResults?.guideAuthors || []).map((result) => {
        return {
            id: result.id,
            label: `${result.fullName}`,
            checked: filteredAuthorIds.includes(result.id),
        };
    });

    const tagOptions = (guideTagResults?.tags || []).map((result) => {
        return {
            id: result.id,
            label: `${result.name} (${result.guideCount})`,
            checked: filteredTagIds.includes(result.id),
        };
    });

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const openPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleFilterClose = () => {
        setAnchorEl(null);
    };

    const applyAuthorFilter = (selectedAuthorIds: Array<string>) => {
        handleFilterClose();
        setFilteredAuthorIds(
            selectedAuthorIds.length !== authorOptions.length ? selectedAuthorIds : null,
        );
    };

    const applyTagFilter = (selectedTagIds: Array<string>) => {
        handleFilterClose();
        setFilteredTagIds(selectedTagIds);
    };

    return (
        <>
            <Button
                className={styles.menuButton}
                variant="outlined"
                aria-controls={open ? "basic-popover" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={openPopover}
                endIcon={<Icon className={styles.menuButtonIcon}>keyboard_arrow_down</Icon>}
            >
                Filter by people or tags
            </Button>
            <Popover
                classes={{ paper: styles.popover }}
                transformOrigin={{
                    vertical: -36,
                    horizontal: 0,
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleFilterClose}
            >
                <div className={styles.panelContainer}>
                    <div className={styles.panelLeft}>
                        <div className={styles.panelTitle}>Filters</div>
                        <ToggleButtonGroup
                            orientation="vertical"
                            value={filterType}
                            exclusive
                            classes={{
                                root: styles.toggleButtonGroup,
                                groupedVertical: styles.toggleButtonGroupVertical,
                            }}
                            onChange={(e, value) => {
                                if (value !== null) {
                                    setFilterType(value);
                                }
                            }}
                        >
                            <ToggleButton
                                classes={{
                                    root: styles.toggleButtonRoot,
                                    selected: styles.toggleButtonSelected,
                                }}
                                value="people"
                                aria-label="people"
                            >
                                People
                            </ToggleButton>
                            <ToggleButton
                                classes={{
                                    root: styles.toggleButtonRoot,
                                    selected: styles.toggleButtonSelected,
                                }}
                                value="tags"
                                aria-label="tags"
                            >
                                Tags
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                    {filterType === "people" && (
                        <CheckboxGroupPanel
                            title="People"
                            options={authorOptions}
                            applyUpdate={applyAuthorFilter}
                        />
                    )}
                    {filterType === "tags" && (
                        <CheckboxGroupPanel
                            title="Tags"
                            helpText="Add tags to your guide pages to keep your workspace organized."
                            helpIcon={tagsIcon}
                            options={tagOptions}
                            applyUpdate={applyTagFilter}
                        />
                    )}
                </div>
            </Popover>
        </>
    );
}

export default GuideListFilterButton;
