import {
    PeopleOutlineOutlined as AudienceIcon,
    ExpandMore as ExpandIcon,
} from "@mui/icons-material";
import {
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormControlLabelProps,
    ListItemText,
    MenuItem,
    MenuItemProps,
    Radio,
    RadioGroup,
    Select,
    Switch,
} from "@mui/material";
import { DateTimePicker, DateTimeValidationError } from "@mui/x-date-pickers";
import classNames from "classnames";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import React, { ReactElement, useEffect, useId, useState } from "react";

import { TriggerAudienceType, TriggerFrequency } from "../../../@types/graphql";
import NetworkingWrapper from "../../components/NetworkingWrapper/NetworkingWrapper";
import { Guide } from "../../network/responseTypes";
import { isActionStep } from "../../util/GuideUtil";
import {
    StringPattern,
    regExpToStringPattern,
    stringPatternToRegExp,
} from "../../util/StringPatternUtil";
import { getDateTimeValidationErrorMessage } from "../../util/TimeUtil";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import StringPatternInput from "../StringPatternInput/StringPatternInput";

import styles from "./ConfigureTriggerDialog.module.scss";
import { useSetTriggerMutation, useTriggerQuery } from "./operations.generated";

interface Props {
    guide: Guide;
    isOpen: boolean;
    onClose: () => void;
}

interface FormState {
    isEnabled: boolean;
    startTime: DateTime | null;
    endTime: DateTime | null;
    urlPattern: StringPattern;
    audienceType: TriggerAudienceType;
    personIds: readonly string[];
    widgetUserIds: readonly string[];
    frequency: TriggerFrequency;
    errorInfo: {
        field: "startTime" | "endTime";
        error: DateTimeValidationError;
    } | null;
}

const defaultFormState: FormState = {
    isEnabled: false,
    startTime: null,
    endTime: null,
    urlPattern: { type: "any" },
    audienceType: "PERSONS",
    personIds: [],
    widgetUserIds: [],
    frequency: "ONCE_PER_PERSON",
    errorInfo: null,
};

type TimeMode = "any" | "before" | "after" | "between";

function ConfigureTriggerDialog(props: Props): ReactElement {
    const { guide, isOpen = false, onClose } = props;

    const { enqueueSnackbar } = useSnackbar();

    const [formState, setFormState] = useState<FormState>(defaultFormState);

    const { data, refetch, loading, error } = useTriggerQuery({
        fetchPolicy: "cache-and-network",
        variables: { guideId: guide.id },
        onCompleted: ({ trigger }) => {
            if (trigger) {
                const urlRegex = trigger.urlRegexes[0];
                setFormState({
                    isEnabled: trigger.isEnabled,
                    startTime: trigger.startTime ? DateTime.fromISO(trigger.startTime) : null,
                    endTime: trigger.endTime ? DateTime.fromISO(trigger.endTime) : null,
                    urlPattern: urlRegex
                        ? regExpToStringPattern(new RegExp(urlRegex))
                        : { type: "any" },
                    audienceType: trigger.audienceType,
                    personIds: trigger.persons.map(({ id }) => id),
                    widgetUserIds: trigger.widgetUsers.map(({ userId }) => userId),
                    frequency: trigger.frequency,
                    errorInfo: null,
                });
            } else {
                // If no trigger exists yet for this guide, use the URL of the first action step as the default URL pattern
                const firstActionStepUrl = guide.steps.find(isActionStep)?.page_url;
                const urlPattern: StringPattern = firstActionStepUrl
                    ? {
                          type: "is",
                          value: firstActionStepUrl,
                      }
                    : defaultFormState.urlPattern;
                setFormState({ ...defaultFormState, urlPattern });
            }
        },
    });

    const [setTrigger, { loading: isSaving }] = useSetTriggerMutation({
        onCompleted: (result) => {
            const success = result.setTrigger?.ok ?? false;
            if (success) {
                enqueueSnackbar("Nudge saved!", { variant: "success" });
                onClose();
            } else {
                enqueueSnackbar("Failed to save nudge. Please try again.", { variant: "error" });
            }
        },
        onError: () => {
            enqueueSnackbar("Failed to save nudge. Please try again.", { variant: "error" });
        },
    });

    const isSectionInactive = !formState.isEnabled || loading || isSaving;

    // Refetch whenever dialog opens
    useEffect(() => {
        if (isOpen) {
            refetch().catch(console.error);
        }
    }, [isOpen, refetch]);

    const idPrefix = useId();

    return (
        <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle className={styles.dialogTitle}>
                <span aria-hidden>⚡️&nbsp;</span>Nudges
            </DialogTitle>
            <DialogContent className={styles.dialogContent}>
                <NetworkingWrapper
                    loading={loading}
                    error={error}
                    loadingComponent={<CircularProgress className={styles.loader} />}
                    errorComponent={
                        <ErrorMessage
                            retry={() => refetch().catch(console.error)}
                            className={styles.errorMessage}
                        />
                    }
                >
                    <p className={styles.description}>
                        Automatically show this guide to other members of your team in-context.
                    </p>
                    <div className={styles.activateSection}>
                        <div className={styles.activeDetails}>
                            <h3
                                className={styles.sectionHeading}
                                id={`${idPrefix}-activate-section-heading`}
                            >
                                Activate nudges for this guide
                            </h3>
                            <p
                                className={styles.description}
                                id={`${idPrefix}-active-section-description`}
                            >
                                Show nudges based on the rules in the sections below
                            </p>
                        </div>
                        <Switch
                            checked={formState.isEnabled}
                            onChange={(ev) =>
                                setFormState((formState) => ({
                                    ...formState,
                                    isEnabled: ev.target.checked,
                                }))
                            }
                            disabled={loading || isSaving}
                            inputProps={{
                                "aria-labelledby": `${idPrefix}-activate-section-heading`,
                                "aria-describedby": `${idPrefix}-active-section-description`,
                            }}
                            className={styles.enabledSwitch}
                        />
                    </div>
                    <h3
                        className={classNames(styles.sectionHeading, {
                            [styles.sectionHeadingInactive]: isSectionInactive,
                        })}
                        id={`${idPrefix}-time-section-heading`}
                    >
                        When to nudge
                    </h3>
                    <Select
                        value={dateTimesToTimeMode(formState.startTime, formState.endTime)}
                        onChange={(ev) => {
                            const timeMode = ev.target.value as TimeMode;
                            const hasStartTime = timeMode === "after" || timeMode === "between";
                            const hasEndTime = timeMode === "before" || timeMode === "between";
                            setFormState((formState) => ({
                                ...formState,
                                startTime: hasStartTime
                                    ? formState.startTime ?? getDefaultStartTime(formState.endTime)
                                    : null,
                                endTime: hasEndTime
                                    ? formState.endTime ?? getDefaultEndTime(formState.startTime)
                                    : null,
                                errorInfo:
                                    (formState.errorInfo?.field === "startTime" && !hasStartTime) ||
                                    (formState.errorInfo?.field === "endTime" && !hasEndTime)
                                        ? null
                                        : formState.errorInfo,
                            }));
                        }}
                        inputProps={{ "aria-labelledby": `${idPrefix}-time-section-heading` }}
                        disabled={loading || !formState.isEnabled || isSaving}
                        IconComponent={ExpandIcon}
                        classes={{ select: styles.select }}
                    >
                        <TimeModeMenuItem value="any">
                            <ListItemText disableTypography>Anytime</ListItemText>
                        </TimeModeMenuItem>
                        <TimeModeMenuItem value="after">
                            <ListItemText disableTypography>
                                After a specific date and time
                            </ListItemText>
                        </TimeModeMenuItem>
                        <TimeModeMenuItem value="before">
                            <ListItemText disableTypography>
                                Before a specific date and time
                            </ListItemText>
                        </TimeModeMenuItem>
                        <TimeModeMenuItem value="between">
                            <ListItemText disableTypography>
                                Between two dates and times
                            </ListItemText>
                        </TimeModeMenuItem>
                    </Select>
                    {(formState.startTime !== null || formState.endTime !== null) && (
                        <div className={styles.timePickers}>
                            {formState.startTime !== null && (
                                <DateTimePicker
                                    value={formState.startTime}
                                    onChange={(value) =>
                                        setFormState((formState) => ({
                                            ...formState,
                                            startTime: value,
                                        }))
                                    }
                                    disabled={
                                        loading ||
                                        !formState.isEnabled ||
                                        formState.startTime === null ||
                                        isSaving
                                    }
                                    maxDateTime={formState.endTime ?? undefined}
                                    slotProps={{
                                        textField: {
                                            inputProps: {
                                                "aria-label": "Start time",
                                                className: styles.timePickerInput,
                                            },
                                            helperText:
                                                formState.errorInfo?.field === "startTime"
                                                    ? getDateTimeValidationErrorMessage(
                                                          formState.errorInfo.error,
                                                      )
                                                    : undefined,
                                        },
                                    }}
                                    onError={(error) =>
                                        setFormState((formState) => ({
                                            ...formState,
                                            errorInfo: error ? { field: "startTime", error } : null,
                                        }))
                                    }
                                    className={styles.startTimePicker}
                                    viewRenderers={{
                                        hours: null,
                                        minutes: null,
                                    }}
                                />
                            )}
                            {formState.startTime !== null && formState.endTime !== null && (
                                <div>To</div>
                            )}
                            {formState.endTime !== null && (
                                <DateTimePicker
                                    value={formState.endTime}
                                    onChange={(value) =>
                                        setFormState((formState) => ({
                                            ...formState,
                                            endTime: value,
                                        }))
                                    }
                                    disabled={
                                        loading ||
                                        !formState.isEnabled ||
                                        formState.endTime === null ||
                                        isSaving
                                    }
                                    minDateTime={formState.startTime ?? undefined}
                                    slotProps={{
                                        textField: {
                                            inputProps: {
                                                "aria-label": "End time",
                                                className: styles.timePickerInput,
                                            },
                                            helperText:
                                                formState.errorInfo?.field === "endTime"
                                                    ? getDateTimeValidationErrorMessage(
                                                          formState.errorInfo.error,
                                                      )
                                                    : undefined,
                                        },
                                    }}
                                    onError={(error) =>
                                        setFormState((formState) => ({
                                            ...formState,
                                            errorInfo: error ? { field: "endTime", error } : null,
                                        }))
                                    }
                                    className={styles.endTimePicker}
                                    viewRenderers={{
                                        hours: null,
                                        minutes: null,
                                    }}
                                />
                            )}
                        </div>
                    )}
                    <h3
                        className={classNames(styles.sectionHeading, {
                            [styles.sectionHeadingInactive]: isSectionInactive,
                        })}
                    >
                        Where to show nudge
                    </h3>
                    <StringPatternInput
                        pattern={formState.urlPattern}
                        onChange={(pattern) =>
                            setFormState((formState) => ({
                                ...formState,
                                urlPattern: pattern,
                            }))
                        }
                        disabled={loading || !formState.isEnabled || isSaving}
                        className={styles.urlPatternInput}
                    />
                    <h3
                        className={classNames(styles.sectionHeading, {
                            [styles.sectionHeadingInactive]: isSectionInactive,
                        })}
                    >
                        Audience
                    </h3>
                    <RadioGroup
                        aria-label="Audience"
                        value={formState.audienceType}
                        onChange={(ev) =>
                            setFormState((formState) => {
                                const audienceType = ev.target.value as TriggerAudienceType;
                                return {
                                    ...formState,
                                    audienceType,
                                    widgetUsers:
                                        audienceType === "WIDGET_USERS"
                                            ? []
                                            : formState.widgetUserIds,
                                };
                            })
                        }
                        className={styles.audienceRadioGroup}
                    >
                        <AudienceTypeFormControlLabel
                            value="WIDGET_USERS"
                            control={<Radio size="small" />}
                            label="All of my product&rsquo;s users (requires Driveway SDK)"
                            classes={{ disabled: styles.formControlLabelDisabled }}
                            disabled={loading || !formState.isEnabled || isSaving}
                            disableTypography
                        />
                        <AudienceTypeFormControlLabel
                            value="PERSONS"
                            control={<Radio size="small" />}
                            label="My teammates registered on Driveway"
                            classes={{ disabled: styles.formControlLabelDisabled }}
                            disabled={loading || !formState.isEnabled || isSaving}
                            disableTypography
                        />
                    </RadioGroup>
                    <Select
                        multiple
                        startAdornment={<AudienceIcon className={styles.audienceIcon} />}
                        value={formState.personIds}
                        onChange={(ev) =>
                            setFormState((formState) => ({
                                ...formState,
                                personIds:
                                    typeof ev.target.value === "string"
                                        ? [ev.target.value]
                                        : ev.target.value,
                            }))
                        }
                        renderValue={(personIds) =>
                            personIds.length > 0
                                ? data?.organizationMembers
                                      .filter((person) => personIds.includes(person.id))
                                      .map((person) => person.fullName)
                                      .join(", ")
                                : "All members of your team"
                        }
                        inputProps={{ "aria-label": "Audience" }}
                        displayEmpty
                        disabled={
                            loading ||
                            !formState.isEnabled ||
                            isSaving ||
                            formState.audienceType !== "PERSONS"
                        }
                        IconComponent={ExpandIcon}
                        classes={{ select: styles.select }}
                    >
                        {data?.organizationMembers &&
                            data.organizationMembers.map((person) => (
                                <MenuItem
                                    key={person.id}
                                    value={person.id}
                                    className={styles.menuItem}
                                >
                                    <Checkbox checked={formState.personIds.includes(person.id)} />
                                    <ListItemText primary={person.fullName} disableTypography />
                                </MenuItem>
                            ))}
                    </Select>
                    <h3
                        className={classNames(styles.sectionHeading, {
                            [styles.sectionHeadingInactive]: isSectionInactive,
                        })}
                    >
                        Frequency
                    </h3>
                    <Select
                        value={formState.frequency}
                        onChange={(ev) =>
                            setFormState((formState) => ({
                                ...formState,
                                frequency: ev.target.value as TriggerFrequency,
                            }))
                        }
                        inputProps={{ "aria-label": "Frequency" }}
                        disabled={loading || !formState.isEnabled || isSaving}
                        IconComponent={ExpandIcon}
                        classes={{ select: styles.select }}
                    >
                        <FrequencyMenuItem value="ONCE_PER_PERSON">
                            <ListItemText disableTypography>
                                Show once per audience member, the first time rules are true
                            </ListItemText>
                        </FrequencyMenuItem>
                        <FrequencyMenuItem value="ALWAYS">
                            <ListItemText disableTypography>
                                Show to members every time these rules are true
                            </ListItemText>
                        </FrequencyMenuItem>
                    </Select>
                </NetworkingWrapper>
            </DialogContent>
            <DialogActions className={styles.dialogActions}>
                <Button
                    variant="outlined"
                    onClick={onClose}
                    disabled={isSaving}
                    className={styles.dialogButton}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        setTrigger({
                            variables: {
                                input: {
                                    guideId: guide.id,
                                    isEnabled: formState.isEnabled,
                                    startTime: formState.startTime?.toISO(),
                                    endTime: formState.endTime?.toISO(),
                                    urlRegexes: [
                                        stringPatternToRegExp(formState.urlPattern).source,
                                    ],
                                    audienceType: formState.audienceType,
                                    personIds: formState.personIds,
                                    widgetUserIds: formState.widgetUserIds,
                                    frequency: formState.frequency,
                                },
                            },
                        }).catch(console.error);
                    }}
                    disabled={loading || !!error || isSaving || formState.errorInfo !== null}
                    className={styles.dialogButton}
                >
                    {isSaving ? "Saving…" : "Save"}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const FrequencyMenuItem = (props: Omit<MenuItemProps, "value"> & { value: TriggerFrequency }) => (
    <MenuItem {...props} />
);

const TimeModeMenuItem = (props: Omit<MenuItemProps, "value"> & { value: TimeMode }) => (
    <MenuItem {...props} />
);

const AudienceTypeFormControlLabel = (
    props: Omit<FormControlLabelProps, "value"> & { value: TriggerAudienceType },
) => <FormControlLabel {...props} />;

function dateTimesToTimeMode(startTime: DateTime | null, endTime: DateTime | null): TimeMode {
    if (startTime && endTime) {
        return "between";
    }
    if (startTime) {
        return "after";
    }
    if (endTime) {
        return "before";
    }
    return "any";
}

const getDefaultStartTime = (endTime: DateTime | null) =>
    endTime?.isValid ? endTime.minus({ days: 1 }) : DateTime.now();

const getDefaultEndTime = (startTime: DateTime | null) =>
    startTime?.isValid ? startTime.plus({ days: 1 }) : DateTime.now();

export default ConfigureTriggerDialog;
