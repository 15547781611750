import { Dialog } from "@mui/material";
import cx from "classnames";
import React, { ReactElement, useContext, useState } from "react";

import { UserContext } from "../../contexts/user";

import styles from "./InviteUserDialog.module.scss";
import InviteUserForm from "./InviteUserForm";
import InviteUserSuccessView from "./InviteUserSuccessView";
import UpgradeToInviteView from "./UpgradeToInviteView";

interface Props {
    isOpen: boolean;
    onClose: () => void;
}

function InviteUserDialog(props: Props): ReactElement {
    const { isOpen, onClose } = props;
    const [invitees, setInvitees] = useState("");
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [isSuccessState, setIsSuccessState] = useState(false);
    const {
        organization: { subscription },
    } = useContext(UserContext);
    const requiresUpgrade = !subscription?.is_within_seat_limit;

    const onDialogClose = () => {
        onClose();
        // Keep the invitees state in case of accidental close.
        setErrorMessage("");
        // Delay success state change to avoid flickering.
        setTimeout(() => setIsSuccessState(false), 200);
    };
    return (
        <Dialog
            open={isOpen}
            onClose={onDialogClose}
            classes={{
                paper: cx(styles.paper, {
                    [styles.success]: isSuccessState,
                    [styles.upgradeView]: requiresUpgrade,
                }),
            }}
        >
            {requiresUpgrade ? (
                <UpgradeToInviteView onClose={onDialogClose} />
            ) : isSuccessState ? (
                <InviteUserSuccessView onClose={onDialogClose} />
            ) : (
                <InviteUserForm
                    invitees={invitees}
                    setInvitees={setInvitees}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                    onClose={onClose}
                    onSuccess={() => setIsSuccessState(true)}
                />
            )}
        </Dialog>
    );
}

export default InviteUserDialog;
