import { TextField } from "@mui/material";
import cx from "classnames";
import { useSnackbar } from "notistack";
import React, { ReactElement, useContext, useId, useState } from "react";

import { UserContext } from "../../contexts/user";
import { isGuideCustomizationEnabled } from "../../hooks/featureAccess";
import { Card } from "../Card/Card";
import CustomBrandingUpgradePrompt from "../CustomBrandingUpgradePrompt/CustomBrandingUpgradePrompt";
import LogoUploader from "../LogoUploader/LogoUploader";
import PrimaryButton from "../PrimaryButton/PrimaryButton";

import {
    OrgGuideCustomizationsDocument,
    useOrgGuideCustomizationsQuery,
    useUpdateOrganizationMutation,
    useUpdateOrgGuideCustomizationsMutation,
} from "./operations.generated";
import styles from "./OrganizationSettings.module.scss";

interface Props {
    className?: string;
}

function Separator() {
    return <div className={styles.separator} />;
}

const DEFAULT_COLOR = "#5055f1";

function ChooseCustomColor() {
    const { data } = useOrgGuideCustomizationsQuery();
    const [updateCustomizations] = useUpdateOrgGuideCustomizationsMutation({
        update: (cache, { data }) => {
            cache.writeQuery({
                query: OrgGuideCustomizationsDocument,
                data: {
                    orgGuideCustomizations: data?.updateOrgGuideCustomizations,
                },
            });
        },
    });
    return (
        <>
            <h5 className={styles.subheader}>Color</h5>
            <p className={styles.txt}>
                Set a default color for screenshot annotations to match your brand.
            </p>
            <label className={styles.colorInputGroup}>
                <input
                    type="color"
                    className={styles.colorInput}
                    onChange={(evt) => {
                        updateCustomizations({
                            variables: {
                                input: {
                                    color: evt.target.value,
                                },
                            },
                        }).catch(console.error);
                    }}
                    // FIXME: power "default color" w the backend
                    value={data?.orgGuideCustomizations.color || DEFAULT_COLOR}
                />
                <span>Default color</span>
            </label>
        </>
    );
}

function OrganizationSettings(props: Props): ReactElement {
    const { className } = props;
    const { organization: organization } = useContext(UserContext);
    console.log("Wordmark URL:", organization.wordmark_url);
    const [formOrgName, setFormOrgName] = useState(organization.organization_name);
    const { enqueueSnackbar } = useSnackbar();

    const [updateOrganization, { loading }] = useUpdateOrganizationMutation({
        refetchQueries: ["Me"],
        onError: () => {
            enqueueSnackbar(
                "Something went wrong when trying to update your workspace. Please try again.",
                { variant: "error" },
            );
        },
        onCompleted: () => {
            enqueueSnackbar("Workspace name has been changed.", {
                variant: "success",
            });
        },
    });

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        void updateOrganization({ variables: { input: { organizationName: formOrgName } } });
    };

    const orgNameTextFieldId = useId();

    return (
        <>
            <Card variant="content" className={cx(styles.container, className)}>
                <h4 className={styles.header}>Workspace name</h4>
                <p className={styles.txt}>
                    This is usually the name of your company or organization.
                </p>
                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                    <label className={styles.label} htmlFor={orgNameTextFieldId}>
                        Name
                    </label>
                    <TextField
                        id={orgNameTextFieldId}
                        variant="outlined"
                        value={formOrgName}
                        onChange={(e) => {
                            setFormOrgName(e.target.value);
                        }}
                        className={styles.textField}
                        InputProps={{
                            classes: {
                                input: styles.textFieldRoot,
                            },
                        }}
                    />
                    <div className={styles.buttonRow}>
                        <PrimaryButton
                            type="submit"
                            className={styles.updateButton}
                            disabled={
                                loading ||
                                formOrgName.length === 0 ||
                                formOrgName === organization.organization_name
                            }
                        >
                            Update
                        </PrimaryButton>
                    </div>
                </form>
            </Card>

            <Card variant="content" className={cx(styles.container, className)}>
                {isGuideCustomizationEnabled(organization) ? (
                    <>
                        <h4 className={styles.header}>Custom Branding</h4>
                        <h5 className={styles.subheader}>Logo</h5>
                        <p className={styles.txt}>
                            Upload your own logo to appear on your guide exports, embeds, and
                            publicly shared guide pages. Recommended size is 100x100.
                        </p>
                        <div className={styles.logoUploader}>
                            <LogoUploader logoUrl={organization.logo_url || ""} uploadType="logo" />
                        </div>
                        <div>
                            <h5 className={styles.subheader}>Wordmark</h5>
                            <p className={styles.txt}>
                                Upload your own wordmark to appear in places where a wider format is
                                a better fit than a square logo. Recommended size is 100x200.
                            </p>
                            <div className={styles.logoUploader}>
                                <LogoUploader
                                    logoUrl={organization.wordmark_url || ""}
                                    uploadType="wordmark"
                                />
                            </div>
                        </div>
                        <Separator />
                        <ChooseCustomColor />
                    </>
                ) : (
                    <CustomBrandingUpgradePrompt />
                )}
            </Card>
        </>
    );
}

export default OrganizationSettings;
