import {
    ContentCopy as CopyIcon,
    Download as DownloadIcon,
    EmailOutlined as EmailOutlinedIcon,
} from "@mui/icons-material";
import { Button } from "@mui/material";
import cx from "classnames";
import React, { ReactElement, ReactNode, useId } from "react";

import styles from "./ExportAsFormatButton.module.scss";

interface Props {
    title: ReactNode;
    description: string | ReactNode;
    type: "copy" | "download" | "email";
    ariaLabel: string;
    ariaDescription: string;
    "data-dd-action-name"?: string;
    onClick: () => void;
    disabled?: boolean;
    className?: string;
}

function ExportTypeDescription(props: { type: "copy" | "download" | "email" }) {
    switch (props.type) {
        case "copy":
            return (
                <>
                    <CopyIcon className={styles.icon} /> Copy
                </>
            );
        case "download":
            return (
                <>
                    <DownloadIcon className={styles.icon} /> Download
                </>
            );

        case "email":
            return (
                <>
                    <EmailOutlinedIcon className={styles.icon} /> Email
                </>
            );

        default:
            console.error(`Invalid export type: ${props.type}`);
            return null;
    }
}

function ExportAsFormatButton(props: Props): ReactElement {
    const { title, description, type, ariaLabel, ariaDescription, onClick, disabled, className } =
        props;

    const accessibleDescriptionId = useId();

    return (
        <Button
            variant="outlined"
            className={cx(styles.button, className)}
            aria-label={ariaLabel}
            aria-describedby={accessibleDescriptionId}
            data-dd-action-name={props["data-dd-action-name"]}
            onClick={onClick}
            disabled={disabled}
        >
            <div className={styles.title}>{title}</div>
            <div className={styles.description}>{description}</div>
            {/* Use hidden div with aria-describedby since aria-description is not a valid ARIA attribute */}
            <div id={accessibleDescriptionId} hidden>
                {ariaDescription}
            </div>
            <div
                className={cx(styles.action, {
                    [styles.disabled]: disabled,
                })}
            >
                <ExportTypeDescription type={type} />
            </div>
        </Button>
    );
}

export default ExportAsFormatButton;
