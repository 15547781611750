import { Close as CloseIcon } from "@mui/icons-material";
import { Button, DialogTitle, IconButton } from "@mui/material";
import React, { ReactElement } from "react";
import { useIntercom } from "react-use-intercom";

import { useSubscriptionTier } from "../../hooks/subscriptionTier";
import SubscriptionPill from "../ProPill/SubscriptionPill";
import SubscriptionUpgradeButton from "../SubscriptionUpgradeButton/SubscriptionUpgradeButton";

import styles from "./UpgradeToInviteView.module.scss";

interface Props {
    onClose: () => void;
}

function UpgradeToInviteView(props: Props): ReactElement {
    const { onClose } = props;
    const subscriptionTier = useSubscriptionTier();
    const intercom = useIntercom();
    return (
        <>
            <IconButton
                aria-label="Close"
                onClick={onClose}
                className={styles.closeButton}
                size="large"
            >
                <CloseIcon />
            </IconButton>
            <SubscriptionPill
                tier={subscriptionTier === "FREE" ? "Pro" : "Enterprise"}
                className={styles.subscriptionPill}
            />
            <DialogTitle className={styles.title}>
                {subscriptionTier === "FREE"
                    ? "Upgrade to invite your team"
                    : "Upgrade to add more team members"}
            </DialogTitle>
            <p className={styles.text}>
                {subscriptionTier === "FREE"
                    ? "Get Driveway Pro to collaborate with your team in a shared workspace."
                    : "Upgrade to Enterprise to invite more than 10 team members and collaborate with advanced security and powerful features."}
            </p>
            {subscriptionTier === "FREE" ? (
                <SubscriptionUpgradeButton
                    variant="contained"
                    color="primary"
                    className={styles.button}
                >
                    Upgrade to Pro
                </SubscriptionUpgradeButton>
            ) : (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => intercom.show()}
                    className={styles.button}
                >
                    Contact Sales
                </Button>
            )}
        </>
    );
}

export default UpgradeToInviteView;
