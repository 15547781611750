import { Player, PlayerEvent } from "@lottiefiles/react-lottie-player";
import { LinearProgress } from "@mui/material";
import React, { ReactElement } from "react";
import { useNavigate } from "react-router-dom";

import GuideUploadErrorMessage from "../../../components/GuideUploadErrorMessage/GuideUploadErrorMessage";
import arrivedAtYourDestination from "../../../images/arrived-at-your-destination.json";
import driverIsOnTheWay from "../../../images/driver-is-on-the-way.json";
import { useCreateGuideFromChromeExtensionMutation } from "../../../mutations/createGuideFromChromeExtension";
import { useChromeExtensionCaptureDataQuery } from "../../../queries/chromeExtensionCaptureData";
import { useAddStepsFromChromeExtensionMutation } from "../../../mutations/addStepsFromChromeExtension";

import styles from "./UploadGuidePage.module.scss";

function UploadGuidePage(): ReactElement {
    const {
        data: createdGuide,
        mutate: createGuideFromChromeExtension,
        isLoading: isCreateGuideLoading,
        isError: isCreateGuideError,
        isSuccess: isCreateGuideSuccess,
    } = useCreateGuideFromChromeExtensionMutation();
    const {
        data: updatedGuide,
        mutate: addStepsFromChromeExtension,
        isLoading: isUpdateGuideLoading,
        isError: isUpdateGuideError,
        isSuccess: isUpdateGuideSuccess,
    } = useAddStepsFromChromeExtensionMutation();
    const {
        isLoading: isCaptureDataLoading,
        isError: isCaptureDataError,
        refetch: refetchCaptureData,
    } = useChromeExtensionCaptureDataQuery({
        onSuccess: (data) => {
            if (data.guide_id) {
                addStepsFromChromeExtension(data);
            } else {
                createGuideFromChromeExtension(data);
            }
        },
    });

    const isLoading = isCaptureDataLoading || isCreateGuideLoading || isUpdateGuideLoading;
    const isError = isCaptureDataError || isCreateGuideError || isUpdateGuideError;
    const isSuccess = isCreateGuideSuccess || isUpdateGuideSuccess;
    const guide = createdGuide || updatedGuide;
    const navigate = useNavigate();

    return (
        <div className={styles.container}>
            {isLoading && (
                <>
                    <div className={styles.animation}>
                        {/* This div is used to hide the blank bottom part of the lottie. */}
                        <Player src={driverIsOnTheWay} autoplay loop />
                    </div>
                    <LinearProgress className={styles.progressBar} />
                    <div className={styles.loadingMessage}>Your Driveway guide is on its way…</div>
                </>
            )}
            {isSuccess && (
                <>
                    <div className={styles.animation}>
                        {/* This div is used to hide the blank bottom part of the lottie. */}
                        <Player
                            src={arrivedAtYourDestination}
                            autoplay
                            keepLastFrame
                            onEvent={(event) => {
                                if (event === PlayerEvent.Complete && guide) {
                                    navigate(`/guides/${guide.id}`, { replace: true });
                                }
                            }}
                        />
                    </div>
                    <LinearProgress
                        variant="determinate"
                        value={100}
                        className={styles.progressBar}
                    />
                    <div className={styles.loadingMessage}>Your Driveway guide has arrived!</div>
                </>
            )}
            {isError && (
                <GuideUploadErrorMessage
                    retry={() => refetchCaptureData().catch(console.error)}
                    debugMessage={
                        isCaptureDataError
                            ? "Failed to read data from Chrome extension"
                            : "Failed to upload Chrome extension data"
                    }
                />
            )}
        </div>
    );
}

export default UploadGuidePage;
