import { Tooltip } from "@mui/material";
import React, { ReactElement, useCallback, useState } from "react";

import { useSubscriptionTier } from "../../hooks/subscriptionTier";
import useAmplitudeTracker from "../../hooks/useAmplitudeTracker";
import BoltIcon from "../../icons/bolt-outlined.svg";
import { Guide } from "../../network/responseTypes";
import { isPaidTier } from "../../util/SubscriptionUtil";
import ConfigureTriggerDialog from "../ConfigureTriggerDialog/ConfigureTriggerDialog";
import TopBarButton from "../TopBarButton/TopBarButton";
import UpgradeToTriggerDialog from "../UpgradeToTriggerDialog/UpgradeToTriggerDialog";

import styles from "./ConfigureTriggerButton.module.scss";

interface Props {
    guide: Guide;
    className?: string;
}

function ConfigureTriggerButton(props: Props): ReactElement {
    const { guide, className } = props;
    const subscriptionTier = useSubscriptionTier();
    const [isConfigureDialogOpen, setConfigureDialogOpen] = useState(false);
    const [isUpgradeDialogOpen, setUpgradeDialogOpen] = useState(false);
    const { track } = useAmplitudeTracker();
    const isTriggerFeatureEnabled = subscriptionTier === null || isPaidTier(subscriptionTier);

    const onClick = useCallback(() => {
        if (isTriggerFeatureEnabled) {
            setConfigureDialogOpen(true);
        } else {
            setUpgradeDialogOpen(true);
        }

        track("Clicked Configure Nudge Button", {
            guideId: guide.id,
        });
    }, [isTriggerFeatureEnabled, guide.id, track]);

    return (
        <>
            <Tooltip
                title="Set up a nudge to show this guide automatically to your team"
                classes={{ tooltip: styles.tooltip }}
                describeChild
            >
                <TopBarButton
                    startIcon={<img src={BoltIcon} alt="" />}
                    onClick={onClick}
                    className={className}
                >
                    Nudge
                </TopBarButton>
            </Tooltip>
            <ConfigureTriggerDialog
                guide={guide}
                isOpen={isConfigureDialogOpen}
                onClose={() => setConfigureDialogOpen(false)}
            />
            <UpgradeToTriggerDialog
                isOpen={isUpgradeDialogOpen}
                onClose={() => setUpgradeDialogOpen(false)}
            />
        </>
    );
}

export default ConfigureTriggerButton;
