import { UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";
import Axios from "axios";

import { Guide, Step } from "../network/responseTypes";
import { QueryKey } from "../queries/queryKeys";
import { UndoUpdateFunc, optimisticUpdate } from "../util/ReactQueryUtil";
import { convertTextToSlateNode } from "../util/SlateUtil";

interface EditStepInput {
    title?: string;
    description?: string;
}

interface MutationContext {
    undoGuideUpdate?: UndoUpdateFunc;
}

export function useEditStepMutation(
    stepId: string,
    guideId: string,
): UseMutationResult<Step, Error, EditStepInput, MutationContext> {
    const client = useQueryClient();
    return useMutation(
        async (variables) => {
            const description =
                variables.description !== undefined
                    ? convertTextToSlateNode(variables.description)
                    : undefined;
            const { data } = await Axios.patch<Step>(`/api/steps/${stepId}/`, {
                title: variables.title,
                description,
            });
            return data;
        },
        {
            onMutate: async (variables) => {
                const undoGuideUpdate = await optimisticUpdate<Guide>(
                    client,
                    [QueryKey.GuideDetail, guideId],
                    (guide) => ({
                        ...guide,
                        steps: guide.steps.map((step) =>
                            step.id === stepId
                                ? {
                                      ...step,
                                      title: variables.title ?? step.title,
                                      description:
                                          variables.description !== undefined
                                              ? convertTextToSlateNode(variables.description)
                                              : step.description,
                                  }
                                : step,
                        ),
                    }),
                );
                return { undoGuideUpdate };
            },
            onError: async (_err, _variables, context) => context?.undoGuideUpdate?.(),
            onSettled: () => client.invalidateQueries([QueryKey.GuideDetail, guideId]),
        },
    );
}
