import React, { useCallback, useState } from "react";
import { useSnackbar } from "notistack";
import { Icon, MenuItem } from "@mui/material";

import { TagType } from "../../../@types/graphql";
import ConfirmDeletionDialog from "../ConfirmDeletionDialog/ConfirmDeletionDialog";

import { useDeleteTagMutation } from "./operations.generated";
interface Props {
    tag: TagType;
    iconClassName?: string;
    onSuccess?: () => void;
}

function DeleteTagMenuItem(props: Props) {
    const { iconClassName, onSuccess, tag } = props;
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [deleteTag, { loading }] = useDeleteTagMutation({
        refetchQueries: ["Tags"],
        onCompleted: () => {
            enqueueSnackbar("Tag has been deleted", {
                variant: "success",
            });
            onSuccess && onSuccess();
        },
        onError: () => {
            enqueueSnackbar("Failed to delete tag. Please try again.", {
                variant: "error",
            });
        },
    });
    const { enqueueSnackbar } = useSnackbar();

    const handleDelete = useCallback(() => {
        void deleteTag({ variables: { id: tag.id } });
    }, [deleteTag, tag.id]);

    return (
        <>
            <MenuItem onClick={() => setIsDeleteDialogOpen(true)}>
                <Icon className={iconClassName}>delete_outline</Icon>
                Delete
            </MenuItem>
            <ConfirmDeletionDialog
                entity="tag"
                isOpen={isDeleteDialogOpen}
                isDisabled={loading}
                onConfirm={handleDelete}
                onClose={() => {
                    setIsDeleteDialogOpen(false);
                }}
            />
        </>
    );
}

export default DeleteTagMenuItem;
