import React from "react";

import FullScreenError from "../FullScreenError/FullScreenError";
import FullScreenLoader from "../FullScreenLoader/FullScreenLoader";

interface Props {
    children: React.ReactNode;
    error: unknown | null;
    loading: boolean;
    refetch?: () => void;
    errorComponent?: React.ReactNode;
    loadingComponent?: React.ReactNode;
}

function NetworkingWrapper({
    children,
    loading,
    error,
    refetch,
    errorComponent = <FullScreenError retry={refetch} />,
    loadingComponent = <FullScreenLoader />,
}: Props): JSX.Element {
    if (loading) {
        return <>{loadingComponent}</>;
    }

    if (error) {
        return <>{errorComponent}</>;
    }

    return <>{children}</>;
}

export default NetworkingWrapper;
