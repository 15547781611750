import { useCallback, useEffect, useRef, useState } from "react";

type ToggleOnFunc = () => void;

interface Options {
    timeoutMs: number;
    defaultValue: boolean;
}

/**
 * A boolean state hook which resets to `false` automatically once `timeoutMs`
 * milliseconds elapse after it's set to `true`.
 */
export function useAutoResetBoolean(options: Options): [boolean, ToggleOnFunc] {
    const { timeoutMs, defaultValue } = options;
    const [value, setValue] = useState(defaultValue);
    const timeoutIdRef = useRef<NodeJS.Timeout>();

    const startResetTimer = useCallback(() => {
        const timeoutId = timeoutIdRef.current;
        if (timeoutId) {
            clearTimeout(timeoutId);
            timeoutIdRef.current = undefined;
        }
        timeoutIdRef.current = setTimeout(() => {
            timeoutIdRef.current = undefined;
            setValue(false);
        }, timeoutMs);
    }, [timeoutMs]);

    const toggleOn = useCallback(() => {
        startResetTimer();
        setValue(true);
    }, [startResetTimer]);

    // Trigger startResetTimer on component mount in case defaultValue is true
    useEffect(startResetTimer, [startResetTimer]);

    useEffect(
        () =>
            // Clears any existing timeout when component unmounts
            () => {
                const timeoutId = timeoutIdRef.current;
                if (timeoutId) {
                    clearTimeout(timeoutId);
                    timeoutIdRef.current = undefined;
                }
            },
        [],
    );

    return [value, toggleOn];
}
