import { useRef, useState } from "react";

import useTimer from "./timer";

function useMediaRecorder(config: { deviceId?: string; onComplete?: (blob: Blob) => void }) {
    const { deviceId, onComplete } = config;
    const mediaRecorder = useRef<MediaRecorder | null>(null);
    const { time, start: timerStart, stop: timerStop, reset: timerReset } = useTimer(0, 1000);

    const [recording, setRecording] = useState(false);
    const [audioURL, setAudioURL] = useState<string | null>(null);

    const start = async () => {
        const stream = await navigator.mediaDevices.getUserMedia({
            audio: deviceId ? { deviceId } : true,
        });

        mediaRecorder.current = new MediaRecorder(stream);
        const chunks: BlobPart[] = [];

        mediaRecorder.current.ondataavailable = (event) => {
            if (event.data.size > 0) {
                chunks.push(event.data);
            }
        };

        mediaRecorder.current.onstop = () => {
            const audioBlob = new Blob(chunks, { type: "audio/webm" });
            const audioURL = URL.createObjectURL(audioBlob);
            onComplete && onComplete(audioBlob);
            setAudioURL(audioURL);
        };
        mediaRecorder.current.start();
        timerStart();
        setRecording(true);
    };

    const stop = () => {
        if (mediaRecorder.current) {
            mediaRecorder.current?.stream.getTracks().forEach((track) => track.stop());
            setRecording(false);
            timerStop();
        }
    };

    return {
        start,
        stop,
        reset: () => {
            setAudioURL(null);
            timerReset();
        },
        duration: time,
        recording,
        audioURL,
    };
}

export default useMediaRecorder;
