import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import cx from "classnames";
import React, { ReactElement, useState } from "react";
import { useIntercom } from "react-use-intercom";

import ProCrownIcon from "../../icons/crown-white-on-yellow.svg";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import SecondaryButton from "../SecondaryButton/SecondaryButton";

import styles from "./SubscriptionPricingTierCard.module.scss";

const SUBSCRIPTION_TIER_TO_TITLE_MAP = {
    FREE: "Free",
    PRO: "Pro",
    ENTERPRISE: "Enterprise",
};
const SUBSCRIPTION_TIER_TO_DESCRIPTION_MAP = {
    FREE: "Discover what Driveway can do and create how-to guides faster than ever.",
    PRO: "Unlock unlimited guides and advanced creator tools with essential features.",
    ENTERPRISE:
        "Work across your entire team or organization with support, security, and control to scale.",
};
const SUBSCRIPTION_TIER_TO_FEATURE_LIST_HEADING_MAP = {
    FREE: "What’s included",
    PRO: "All Free features, plus",
    ENTERPRISE: "All Pro features, plus",
};
const SUBSCRIPTION_TIER_TO_FEATURES_MAP = {
    FREE: [
        "3 public guides",
        "1 user",
        "AI-generated descriptions",
        "Embed in sites and help centers",
        "Record your own voiceovers",
        "Basic guide analytics",
        "Driveway watermarks",
    ],
    PRO: [
        "Unlimited public guides",
        "Up to 10 users",
        "Mark up screenshots",
        "AI-powered auto-blur",
        "AI-generated voiceovers",
        "Customize your logo and brand colors",
        "Advanced workspace analytics",
        "Remove Driveway watermarks",
    ],
    ENTERPRISE: [
        "Unlimited users",
        "Custom trained AI model for in-app interactive guidance",
        "Dedicated onboarding, implementation, and support",
        "Automatically scan all screenshots in workspace for sensitive content",
        "SSO, Okta, SAML 2.0, BAA, HIPAA",
    ],
};

type Props =
    | {
          tier: "FREE" | "ENTERPRISE";
          className?: string;
      }
    | {
          tier: "PRO";
          billingPeriod: "monthly" | "annual";
          className?: string;
      };

function SubscriptionPricingTierCard(props: Props): ReactElement {
    const { tier, className } = props;
    const intercom = useIntercom();
    const [isRedirecting, setIsRedirecting] = useState(false);

    const title = SUBSCRIPTION_TIER_TO_TITLE_MAP[tier];
    const description = SUBSCRIPTION_TIER_TO_DESCRIPTION_MAP[tier];
    const featureListHeading = SUBSCRIPTION_TIER_TO_FEATURE_LIST_HEADING_MAP[tier];
    const features = SUBSCRIPTION_TIER_TO_FEATURES_MAP[tier];

    return (
        <div
            className={cx(styles.container, className, {
                [styles.proTier]: tier === "PRO",
            })}
        >
            <div className={styles.card}>
                {tier === "PRO" && (
                    <div className={styles.mostPopular}>
                        <TrendingUpIcon className={styles.mostPopularIcon} />
                        Most popular
                    </div>
                )}
                <div className={styles.titleContainer}>
                    {tier === "PRO" && <img src={ProCrownIcon} alt="" />}
                    <h3 className={styles.title}>{title}</h3>
                </div>
                <div className={styles.description}>{description}</div>
                <div className={styles.priceContainer}>
                    {tier === "FREE" && (
                        <>
                            <div className={styles.dollarSign}>$</div>
                            <div className={styles.price}>0</div>
                        </>
                    )}
                    {tier === "PRO" && (
                        <>
                            <div className={styles.dollarSign}>$</div>
                            <div className={styles.price}>
                                {props.billingPeriod === "monthly" ? "10" : "8"}
                            </div>
                        </>
                    )}
                    {tier === "ENTERPRISE" && <ForumOutlinedIcon className={styles.priceIcon} />}
                </div>
                <div className={styles.priceDescription}>
                    {tier === "FREE" && "Free forever"}
                    {tier === "PRO" &&
                        (props.billingPeriod === "monthly"
                            ? "Per user/month billed monthly"
                            : "Per user/month billed annually at $96")}
                    {tier === "ENTERPRISE" && "Contact Sales for custom pricing"}
                </div>
                <>
                    {tier === "FREE" && (
                        <SecondaryButton fullWidth disabled className={styles.actionButton}>
                            Current plan
                        </SecondaryButton>
                    )}
                    {tier === "PRO" && (
                        <PrimaryButton
                            onClick={() => setIsRedirecting(true)}
                            href={`/subscriptions/create-checkout-session?billingPeriod=${props.billingPeriod}`}
                            className={cx(styles.actionButton, styles.upgradeButton)}
                            disabled={isRedirecting}
                            fullWidth
                        >
                            {isRedirecting ? "Redirecting…" : "Upgrade now"}
                        </PrimaryButton>
                    )}
                    {tier === "ENTERPRISE" && (
                        <SecondaryButton
                            onClick={() => intercom.show()}
                            className={cx(styles.actionButton, styles.contactButton)}
                            fullWidth
                        >
                            Contact us
                        </SecondaryButton>
                    )}
                </>
                <List disablePadding>
                    <div className={styles.featureListHeading}>{featureListHeading}</div>
                    {features.map((feature, index) => (
                        <ListItem key={index} disablePadding className={styles.featureListItem}>
                            <ListItemIcon
                                className={cx(styles.featureListItemIcon, {
                                    [styles.proTier]: tier === "PRO",
                                })}
                            >
                                <CheckCircleOutlineIcon className={styles.featureListItemIconSvg} />
                            </ListItemIcon>
                            <ListItemText
                                primary={feature}
                                className={styles.featureListItemText}
                                disableTypography
                            />
                        </ListItem>
                    ))}
                </List>
            </div>
        </div>
    );
}

export default SubscriptionPricingTierCard;
