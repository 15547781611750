import { useEffect } from "react";
import { useIntercom } from "react-use-intercom";

export function useHideIntercom(): void {
    const { update } = useIntercom();

    useEffect(() => {
        update({ hideDefaultLauncher: true });
        return () => update({ hideDefaultLauncher: false });
    }, [update]);
}
