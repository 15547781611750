import { useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";

import { useResetScreenshotMutation } from "../components/ResetScreenshotDialog/operations.generated";
import { QueryKey } from "../queries/queryKeys";

function useResetScreenshot(guideId: string, stepId: string, onCompleted: () => void) {
    const { enqueueSnackbar } = useSnackbar();
    const reactQueryClient = useQueryClient();
    return useResetScreenshotMutation({
        variables: {
            stepId,
        },
        onError: () => {
            enqueueSnackbar(
                "Something went wrong when trying to reset your screenshot. Please try again.",
                { variant: "error" },
            );
        },
        onCompleted: () => {
            onCompleted();
            // Remove when GuideDetail is migrated to graphql
            void reactQueryClient.invalidateQueries([QueryKey.GuideDetail, guideId]);
        },
        refetchQueries: ["Screenshot"],
    });
}

export default useResetScreenshot;
