import { KonvaEventObject } from "konva/lib/Node";
import { Vector2d } from "konva/lib/types";
import { uniqueId } from "lodash";
import React, { useContext, useState } from "react";
import { Image, Layer, Rect, Stage } from "react-konva";

import RedactRect from "./RedactRect";
import { RedactImageContext, RedactionRect } from "./RedactWrapper";

export function setStageCursor(cursor: string) {
    return (e: KonvaEventObject<MouseEvent>) => {
        const stage = e.target.getStage();
        if (stage) {
            stage.container().style.cursor = cursor;
        }
    };
}

export const redactionStrokeSize = 4;
export const redactionStrokeColor = "#5B57FF";

function getScaledPosition(pos: Vector2d | null, scale: number): Vector2d | void {
    if (pos) {
        return {
            x: pos.x / scale,
            y: pos.y / scale,
        };
    }
}

function RedactStage() {
    const context = useContext(RedactImageContext);
    const { setRedactions, selectedId, scaleFactor, redactions, setSelectedId, image, stageRef } =
        context;
    const [drawnRedaction, setDrawnRedaction] = useState<RedactionRect | null>(null);

    function handleMouseDown(e: KonvaEventObject<MouseEvent>) {
        const stage = e.target.getStage();
        const downOnStage = e.target === stage;
        if (downOnStage && !selectedId && !drawnRedaction && stage) {
            const scaledPosition = getScaledPosition(stage.getPointerPosition(), scaleFactor);
            if (!scaledPosition) return;

            setDrawnRedaction({
                x: scaledPosition.x,
                y: scaledPosition.y,
                width: 0,
                height: 0,
                id: uniqueId(),
            });
        }
    }

    function onUpdateRedaction(redaction: RedactionRect) {
        setRedactions((redactions) => [
            ...redactions.filter(({ id }) => id !== redaction.id),
            redaction,
        ]);
    }

    function handleMouseMove(e: KonvaEventObject<MouseEvent>) {
        const stage = e.target.getStage();
        if (drawnRedaction && stage) {
            const scaledPosition = getScaledPosition(stage.getPointerPosition(), scaleFactor);
            if (!scaledPosition) return;

            setDrawnRedaction({
                ...drawnRedaction,
                // we do not need to check for +/- sizing here as Konva
                // rectangles accept negative numbers
                width: scaledPosition.x - drawnRedaction.x,
                height: scaledPosition.y - drawnRedaction.y,
            });
        }
    }

    function handleFinishDrawing(e: KonvaEventObject<MouseEvent>) {
        const stage = e.target.getStage();
        if (drawnRedaction && stage) {
            const scaledPosition = getScaledPosition(stage.getPointerPosition(), scaleFactor);
            if (!scaledPosition) return;

            const width = scaledPosition.x - drawnRedaction.x;
            const height = scaledPosition.y - drawnRedaction.y;

            setDrawnRedaction(null);
            setRedactions((redactions) => [
                ...redactions,
                {
                    ...drawnRedaction,
                    // if the width or height is negative, we shift everything as
                    // Konva Image elements require positive numbers
                    x: width < 0 ? scaledPosition.x : drawnRedaction.x,
                    y: height < 0 ? scaledPosition.y : drawnRedaction.y,
                    width: Math.abs(width),
                    height: Math.abs(height),
                },
            ]);
        }
    }

    function handleMouseUp(e: KonvaEventObject<MouseEvent>) {
        if (selectedId && e.target === e.target.getStage()) {
            setSelectedId(null);
        }
        handleFinishDrawing(e);
    }

    return (
        <Stage
            height={image?.height * scaleFactor}
            width={image?.width * scaleFactor}
            scale={{
                x: scaleFactor,
                y: scaleFactor,
            }}
            ref={stageRef}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}
            onMouseEnter={setStageCursor("crosshair")}
            onMouseLeave={setStageCursor("default")}
        >
            {/* Context must be bridged because of Konva's custom renderer https://github.com/konvajs/react-konva/issues/349#issuecomment-478300618 */}
            <RedactImageContext.Provider value={context}>
                <Layer>
                    <Image
                        x={0}
                        y={0}
                        image={image.el}
                        width={image.width}
                        height={image.height}
                        listening={false} // pass click events through to Layer
                    />
                    {drawnRedaction && (
                        <Rect
                            key={drawnRedaction.id}
                            x={drawnRedaction.x}
                            y={drawnRedaction.y}
                            width={drawnRedaction.width}
                            height={drawnRedaction.height}
                            strokeEnabled
                            strokeWidth={redactionStrokeSize}
                            stroke={redactionStrokeColor}
                        />
                    )}
                    {redactions.map((redaction) => (
                        <RedactRect
                            key={redaction.id}
                            redaction={redaction}
                            isSelected={selectedId === redaction.id}
                            onSelect={setSelectedId}
                            image={image.el}
                            updateRedaction={onUpdateRedaction}
                        />
                    ))}
                </Layer>
            </RedactImageContext.Provider>
        </Stage>
    );
}

export default RedactStage;
