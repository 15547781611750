import {
    Avatar,
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import cx from "classnames";
import React, { ReactElement, useId } from "react";
import { Helmet } from "react-helmet-async";

import { InvitationType } from "../../../../@types/graphql";
import ErrorMessage from "../../../components/ErrorMessage/ErrorMessage";
import InviteUserButton from "../../../components/InviteUserButton/InviteUserButton";
import PersonAvatar from "../../../components/PersonAvatar/PersonAvatar";
import noAvatarIcon from "../../../icons/no-avatar.svg";

import InviteeActionMenu from "./InviteeActionMenu";
import { TeamMembersQuery, useTeamMembersQuery } from "./operations.generated";
import styles from "./TeamPage.module.scss";

type Member = TeamMembersQuery["organizationMembers"][0];

function TeamPage(): ReactElement | null {
    const { data, loading, error, refetch } = useTeamMembersQuery();

    const headingId = useId();

    return (
        <div className={styles.container}>
            <Helmet title="Team" />
            <div className={styles.titleRow}>
                <h1 className={styles.title} id={headingId}>
                    Team
                </h1>
                <InviteUserButton buttonType="primary" className={styles.inviteUserButton} />
            </div>
            {loading && <CircularProgress />}
            {error && <ErrorMessage retry={refetch} />}
            {data && (
                <TableContainer component={Paper} className={styles.tableContainer}>
                    <Table aria-labelledby={headingId}>
                        <TableHead>
                            <TableRow>
                                <TableCell className={styles.tableHeader}>Member</TableCell>
                                <TableCell className={styles.tableHeader}>Email</TableCell>
                                <TableCell colSpan={2} className={styles.tableHeader}>
                                    Status
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.organizationMembers.map((member) => (
                                <MemberTableRow key={member.id} member={member} />
                            ))}
                            {data.pendingInvitations.map((invitation) => (
                                <InviteeTableRow key={invitation.id} invitee={invitation} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </div>
    );
}

function MemberTableRow(props: { member: Member }): ReactElement {
    const { member } = props;

    const backwardsCompatiblePerson = {
        first_name: member.firstName,
        last_name: member.lastName,
        avatar_url: member.avatarUrl || "",
    };
    return (
        <TableRow>
            <TableCell className={cx(styles.tableCell, styles.memberNameAvatarCell)}>
                <PersonAvatar person={backwardsCompatiblePerson} className={styles.avatar} />
                {`${member.fullName}`}
            </TableCell>
            <TableCell className={styles.tableCell}>{member.email}</TableCell>
            <TableCell className={styles.tableCell} colSpan={2}>
                Registered
            </TableCell>
        </TableRow>
    );
}

function InviteeTableRow(props: { invitee: InvitationType }): ReactElement {
    const { invitee } = props;

    return (
        <TableRow>
            <TableCell colSpan={2} className={styles.tableCell}>
                <div className={styles.memberNameAvatarCell}>
                    <Avatar src={noAvatarIcon} className={styles.avatar} alt="" />
                    {invitee.email}
                </div>
            </TableCell>
            <TableCell className={styles.tableCell}>Invited (pending) </TableCell>
            <TableCell className={cx(styles.tableCell, styles.menuIconButtonCell)}>
                <InviteeActionMenu invitee={invitee} />
            </TableCell>
        </TableRow>
    );
}

export default TeamPage;
