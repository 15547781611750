/* eslint-disable */
// THIS IS A GENERATED FILE. DO NOT EDIT DIRECTLY
// To generate new type definitions run `npm run generate-schema-types`
import * as Types from '../../../@types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ExportGuideAsHtmlMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type ExportGuideAsHtmlMutation = { readonly __typename?: 'Mutation', readonly exportGuideAsHtml?: { readonly __typename?: 'ExportGuideAsHtmlMutation', readonly html?: string | null } | null };

export type ExportGuideAsMarkdownMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type ExportGuideAsMarkdownMutation = { readonly __typename?: 'Mutation', readonly exportGuideAsMarkdown?: { readonly __typename?: 'ExportGuideAsMarkdownMutation', readonly markdown: string } | null };

export type SetGuideAccessMutationVariables = Types.Exact<{
  input: Types.SetGuideAccessMutationInput;
}>;


export type SetGuideAccessMutation = { readonly __typename?: 'Mutation', readonly setGuideAccess?: { readonly __typename?: 'SetGuideAccessMutation', readonly ok?: boolean | null } | null };

export type ExportGuideAsVideoMutationVariables = Types.Exact<{
  input: Types.ExportGuideAsVideoMutationInput;
}>;


export type ExportGuideAsVideoMutation = { readonly __typename?: 'Mutation', readonly exportGuideAsVideo?: { readonly __typename?: 'ExportGuideAsVideoMutation', readonly status: Types.ExportGuideAsVideoStatus } | null };

export type VideoExportRequestsQueryVariables = Types.Exact<{
  guideId: Types.Scalars['ID']['input'];
}>;


export type VideoExportRequestsQuery = { readonly __typename?: 'Query', readonly videoExportRequests?: ReadonlyArray<{ readonly __typename?: 'VideoExportRequestType', readonly percentComplete: number, readonly guide: { readonly __typename?: 'GuideType', readonly id: string }, readonly videoExport?: { readonly __typename?: 'VideoExportType', readonly url?: string | null } | null }> | null };


export const ExportGuideAsHtmlDocument = gql`
    mutation ExportGuideAsHtml($id: ID!) {
  exportGuideAsHtml(id: $id) {
    html
  }
}
    `;
export type ExportGuideAsHtmlMutationFn = Apollo.MutationFunction<ExportGuideAsHtmlMutation, ExportGuideAsHtmlMutationVariables>;

/**
 * __useExportGuideAsHtmlMutation__
 *
 * To run a mutation, you first call `useExportGuideAsHtmlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportGuideAsHtmlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportGuideAsHtmlMutation, { data, loading, error }] = useExportGuideAsHtmlMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExportGuideAsHtmlMutation(baseOptions?: Apollo.MutationHookOptions<ExportGuideAsHtmlMutation, ExportGuideAsHtmlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportGuideAsHtmlMutation, ExportGuideAsHtmlMutationVariables>(ExportGuideAsHtmlDocument, options);
      }
export type ExportGuideAsHtmlMutationHookResult = ReturnType<typeof useExportGuideAsHtmlMutation>;
export type ExportGuideAsHtmlMutationResult = Apollo.MutationResult<ExportGuideAsHtmlMutation>;
export type ExportGuideAsHtmlMutationOptions = Apollo.BaseMutationOptions<ExportGuideAsHtmlMutation, ExportGuideAsHtmlMutationVariables>;
export const ExportGuideAsMarkdownDocument = gql`
    mutation ExportGuideAsMarkdown($id: ID!) {
  exportGuideAsMarkdown(id: $id) {
    markdown
  }
}
    `;
export type ExportGuideAsMarkdownMutationFn = Apollo.MutationFunction<ExportGuideAsMarkdownMutation, ExportGuideAsMarkdownMutationVariables>;

/**
 * __useExportGuideAsMarkdownMutation__
 *
 * To run a mutation, you first call `useExportGuideAsMarkdownMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportGuideAsMarkdownMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportGuideAsMarkdownMutation, { data, loading, error }] = useExportGuideAsMarkdownMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExportGuideAsMarkdownMutation(baseOptions?: Apollo.MutationHookOptions<ExportGuideAsMarkdownMutation, ExportGuideAsMarkdownMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportGuideAsMarkdownMutation, ExportGuideAsMarkdownMutationVariables>(ExportGuideAsMarkdownDocument, options);
      }
export type ExportGuideAsMarkdownMutationHookResult = ReturnType<typeof useExportGuideAsMarkdownMutation>;
export type ExportGuideAsMarkdownMutationResult = Apollo.MutationResult<ExportGuideAsMarkdownMutation>;
export type ExportGuideAsMarkdownMutationOptions = Apollo.BaseMutationOptions<ExportGuideAsMarkdownMutation, ExportGuideAsMarkdownMutationVariables>;
export const SetGuideAccessDocument = gql`
    mutation SetGuideAccess($input: SetGuideAccessMutationInput!) {
  setGuideAccess(input: $input) {
    ok
  }
}
    `;
export type SetGuideAccessMutationFn = Apollo.MutationFunction<SetGuideAccessMutation, SetGuideAccessMutationVariables>;

/**
 * __useSetGuideAccessMutation__
 *
 * To run a mutation, you first call `useSetGuideAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetGuideAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setGuideAccessMutation, { data, loading, error }] = useSetGuideAccessMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetGuideAccessMutation(baseOptions?: Apollo.MutationHookOptions<SetGuideAccessMutation, SetGuideAccessMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetGuideAccessMutation, SetGuideAccessMutationVariables>(SetGuideAccessDocument, options);
      }
export type SetGuideAccessMutationHookResult = ReturnType<typeof useSetGuideAccessMutation>;
export type SetGuideAccessMutationResult = Apollo.MutationResult<SetGuideAccessMutation>;
export type SetGuideAccessMutationOptions = Apollo.BaseMutationOptions<SetGuideAccessMutation, SetGuideAccessMutationVariables>;
export const ExportGuideAsVideoDocument = gql`
    mutation ExportGuideAsVideo($input: ExportGuideAsVideoMutationInput!) {
  exportGuideAsVideo(input: $input) {
    status
  }
}
    `;
export type ExportGuideAsVideoMutationFn = Apollo.MutationFunction<ExportGuideAsVideoMutation, ExportGuideAsVideoMutationVariables>;

/**
 * __useExportGuideAsVideoMutation__
 *
 * To run a mutation, you first call `useExportGuideAsVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportGuideAsVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportGuideAsVideoMutation, { data, loading, error }] = useExportGuideAsVideoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportGuideAsVideoMutation(baseOptions?: Apollo.MutationHookOptions<ExportGuideAsVideoMutation, ExportGuideAsVideoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportGuideAsVideoMutation, ExportGuideAsVideoMutationVariables>(ExportGuideAsVideoDocument, options);
      }
export type ExportGuideAsVideoMutationHookResult = ReturnType<typeof useExportGuideAsVideoMutation>;
export type ExportGuideAsVideoMutationResult = Apollo.MutationResult<ExportGuideAsVideoMutation>;
export type ExportGuideAsVideoMutationOptions = Apollo.BaseMutationOptions<ExportGuideAsVideoMutation, ExportGuideAsVideoMutationVariables>;
export const VideoExportRequestsDocument = gql`
    query VideoExportRequests($guideId: ID!) {
  videoExportRequests(guideId: $guideId) {
    guide {
      id
    }
    videoExport {
      url
    }
    percentComplete
  }
}
    `;

/**
 * __useVideoExportRequestsQuery__
 *
 * To run a query within a React component, call `useVideoExportRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVideoExportRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVideoExportRequestsQuery({
 *   variables: {
 *      guideId: // value for 'guideId'
 *   },
 * });
 */
export function useVideoExportRequestsQuery(baseOptions: Apollo.QueryHookOptions<VideoExportRequestsQuery, VideoExportRequestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VideoExportRequestsQuery, VideoExportRequestsQueryVariables>(VideoExportRequestsDocument, options);
      }
export function useVideoExportRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VideoExportRequestsQuery, VideoExportRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VideoExportRequestsQuery, VideoExportRequestsQueryVariables>(VideoExportRequestsDocument, options);
        }
export type VideoExportRequestsQueryHookResult = ReturnType<typeof useVideoExportRequestsQuery>;
export type VideoExportRequestsLazyQueryHookResult = ReturnType<typeof useVideoExportRequestsLazyQuery>;
export type VideoExportRequestsQueryResult = Apollo.QueryResult<VideoExportRequestsQuery, VideoExportRequestsQueryVariables>;