import { Icon, IconButton } from "@mui/material";
import cx from "classnames";
import React, { ReactElement } from "react";
import { useIntercom } from "react-use-intercom";

import { useCopyGuideLink } from "../../hooks/copyGuideLink";
import PrimaryButton from "../PrimaryButton/PrimaryButton";

import styles from "./CopyGuideLinkButton.module.scss";

interface Props {
    guideId: string;
    variant: "iconButton" | "button";
    className?: string;
    onClick?: () => void;
}

function CopyGuideLinkButton(props: Props): ReactElement {
    const { guideId, variant, className, onClick } = props;
    const copyGuideLink = useCopyGuideLink(guideId);
    const intercom = useIntercom();

    const handleClick = () => {
        copyGuideLink();
        intercom.trackEvent("clicked_copy_guide_link", { guideId });
        onClick?.();
    };

    return variant === "iconButton" ? (
        <IconButton size="small" onClick={handleClick} aria-label="Copy link" className={className}>
            <Icon className={styles.iconButton}>link</Icon>
        </IconButton>
    ) : (
        <PrimaryButton className={cx(styles.button, className)} onClick={handleClick}>
            <Icon className={styles.icon}>link</Icon>
            Copy link
        </PrimaryButton>
    );
}

export default CopyGuideLinkButton;
