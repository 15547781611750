import {
    CircularProgress,
    Icon,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { ReactElement, useState } from "react";

import { InvitationType } from "../../../../@types/graphql";
import ConfirmDeletionDialog from "../../../components/ConfirmDeletionDialog/ConfirmDeletionDialog";

import styles from "./InviteeActionMenu.module.scss";
import {
    DeleteInvitationMutation,
    ResendInvitationMutation,
    useDeleteInvitationMutation,
    useResendInvitationMutation,
} from "./operations.generated";

function InviteeActionMenu(props: { invitee: InvitationType }): ReactElement {
    const { invitee } = props;
    const [menuAnchorEl, setMenuAnchorEl] = useState<Element | null>(null);
    const [isDialogOpen, setDialogOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [resendInvite, { loading: isResendingInvite }] = useResendInvitationMutation({
        onCompleted: (data: ResendInvitationMutation) => {
            if (data.resendInvitation?.errors) {
                enqueueSnackbar(data.resendInvitation.errors[0].messages[0], {
                    variant: "error",
                });
            } else {
                enqueueSnackbar(`The invitation email was sent to ${invitee.email}.`, {
                    variant: "success",
                });
            }
        },
        onError: () => {
            enqueueSnackbar(
                `Something went wrong when trying to re-send invitation email to ${invitee.email}.`,
                {
                    variant: "error",
                },
            );
        },
    });
    const [deleteInvitee, { loading: isDeletingInvitee }] = useDeleteInvitationMutation({
        refetchQueries: ["TeamMembers"],
        onCompleted: (data: DeleteInvitationMutation) => {
            if (data.deleteInvitation?.errors) {
                enqueueSnackbar(data.deleteInvitation.errors[0].messages[0], {
                    variant: "error",
                });
            } else {
                enqueueSnackbar("Invitation deleted!", {
                    variant: "success",
                });
            }
        },
        onError: () => {
            enqueueSnackbar("Failed to delete invitation. Please try again.", {
                variant: "error",
            });
        },
    });

    const isLoading = isResendingInvite || isDeletingInvitee;
    const isMenuOpen = menuAnchorEl !== null;

    return (
        <>
            <IconButton
                id={`invitee-options-button-${invitee.id}`}
                aria-label={`Options for invited user ${invitee.email}`}
                aria-controls={isMenuOpen ? `invitee-options-menu-${invitee.id}` : undefined}
                aria-expanded={isMenuOpen ? "true" : undefined}
                aria-haspopup="true"
                onClick={(event) => setMenuAnchorEl(event.currentTarget)}
                disabled={isLoading}
                size="large"
            >
                {isLoading ? <CircularProgress size={24} /> : <Icon>more_horiz</Icon>}
            </IconButton>
            <Menu
                anchorEl={menuAnchorEl}
                keepMounted
                open={isMenuOpen}
                onClose={() => setMenuAnchorEl(null)}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: 16,
                    horizontal: -12,
                }}
                MenuListProps={{
                    id: `invitee-options-menu-${invitee.id}`,
                    "aria-labelledby": `invitee-options-button-${invitee.id}`,
                }}
                classes={{ paper: styles.paper }}
            >
                <MenuItem
                    disabled={isLoading}
                    onClick={() => {
                        setMenuAnchorEl(null);
                        void resendInvite({ variables: { id: invitee.id.toString() } });
                    }}
                    classes={{ root: styles.menuItem }}
                >
                    <ListItemIcon>
                        <Icon aria-hidden className={styles.icon}>
                            mail_outlined
                        </Icon>
                    </ListItemIcon>
                    <ListItemText disableTypography>Invite again</ListItemText>
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        setMenuAnchorEl(null);
                        setDialogOpen(true);
                    }}
                    disabled={isLoading}
                    classes={{ root: styles.menuItem }}
                >
                    <ListItemIcon>
                        <Icon aria-hidden className={styles.icon}>
                            delete_outline
                        </Icon>
                    </ListItemIcon>
                    <ListItemText disableTypography>Delete</ListItemText>
                </MenuItem>
            </Menu>
            <ConfirmDeletionDialog
                entity="invitation"
                isOpen={isDialogOpen}
                isDisabled={isDeletingInvitee}
                onConfirm={() => {
                    void deleteInvitee({ variables: { id: invitee.id.toString() } });
                }}
                onClose={() => setDialogOpen(false)}
            />
        </>
    );
}

export default InviteeActionMenu;
