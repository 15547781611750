import Konva from "konva";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Group, Image, Rect, Transformer } from "react-konva";

import { redactionStrokeColor, redactionStrokeSize, setStageCursor } from "./RedactStage";
import { RedactImageContext, RedactionRect } from "./RedactWrapper";

const BLUR_RADIUS = 20;
const PIXEL_SIZE = 24;
const NOISE_RATIO = 0.4;

interface RectProps {
    redaction: RedactionRect;
    image: HTMLImageElement;
    isSelected: boolean;
    onSelect: (id: string) => void;
    updateRedaction: (id: RedactionRect) => void;
}

function RedactRect({ redaction, isSelected, onSelect, updateRedaction }: RectProps) {
    const context = useContext(RedactImageContext);
    const [isFiltering, setFiltering] = useState(false);
    const imageRef = useRef<Konva.Image | null>(null);
    const groupRef = useRef<Konva.Group | null>(null);
    const transformRef = useRef<Konva.Transformer | null>(null);

    const imageNode = imageRef.current;
    const groupNode = groupRef.current;

    // isFiltering can not default to true as the element won't cache
    // based on the ref being set
    useEffect(() => setFiltering(true), []);

    useEffect(() => {
        if (isSelected && groupNode) {
            transformRef.current?.nodes([groupNode]);
            transformRef.current?.getLayer()?.batchDraw();
        }
    }, [isSelected, groupNode]);

    useEffect(() => {
        if (!imageNode) return;
        if (!isSelected && isFiltering) {
            imageNode.cache();
            imageNode.filters([Konva.Filters.Blur, Konva.Filters.Pixelate, Konva.Filters.Noise]);
            imageNode.blurRadius(BLUR_RADIUS);
            imageNode.pixelSize(PIXEL_SIZE);
            imageNode.noise(NOISE_RATIO);
            return;
        }

        imageNode.clearCache();
    }, [isFiltering, imageNode, isSelected]);

    const enableFilters = () => setFiltering(true);
    const disableFilters = () => setFiltering(false);

    const width = redaction.width + redactionStrokeSize * 2;
    const height = redaction.height + redactionStrokeSize * 2;

    return (
        <>
            <Group
                width={width}
                height={height}
                ref={groupRef}
                x={redaction.x}
                y={redaction.y}
                draggable
                onDragEnd={(e) => {
                    updateRedaction({
                        ...redaction,
                        x: e.target.getPosition().x,
                        y: e.target.getPosition().y,
                    });
                }}
                onClick={() => onSelect(redaction.id)}
                onTransformEnd={(e) => {
                    if (!groupNode) return;

                    updateRedaction({
                        ...redaction,
                        width: groupNode.width() * groupNode.scaleX(),
                        height: groupNode.height() * groupNode.scaleY(),
                        x: e.currentTarget.x() - redactionStrokeSize,
                        y: e.currentTarget.y() - redactionStrokeSize,
                    });

                    // transform changes scale not width/height
                    // set the scale back and rely on the redaction state to redraw
                    groupNode.setAttrs({
                        scaleX: 1,
                        scaleY: 1,
                    });
                }}
            >
                {/* The rectangle represents the redaction border and is the size of the group */}
                <Rect
                    width={width}
                    height={height}
                    strokeEnabled={!isSelected && !isFiltering}
                    stroke={redactionStrokeColor}
                    strokeWidth={redactionStrokeSize}
                />

                {/* The image represent the area to be filtered which is the size of the group minus the stroke width */}
                <Image
                    id={redaction.id}
                    image={!isSelected && isFiltering ? context.image.el : undefined}
                    ref={imageRef}
                    width={width}
                    height={height}
                    onMouseEnter={(e) => {
                        setStageCursor(isSelected ? "grab" : "pointer")(e);
                        disableFilters();
                    }}
                    onMouseLeave={(e) => {
                        setStageCursor("crosshair")(e);
                        enableFilters();
                    }}
                    crop={{
                        x: redaction.x,
                        y: redaction.y,
                        width: width,
                        height: height,
                    }}
                />
            </Group>
            {isSelected && (
                <Transformer
                    ref={transformRef}
                    borderStroke={redactionStrokeColor}
                    borderStrokeWidth={2}
                    anchorStroke={redactionStrokeColor}
                    anchorCornerRadius={100}
                    rotateEnabled={false}
                    keepRatio={false}
                />
            )}
        </>
    );
}

export default RedactRect;
