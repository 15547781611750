import { InfiniteData, QueryClient, QueryKey } from "@tanstack/react-query";

export type UndoUpdateFunc = () => void;

export function mapInfiniteData<T, R>(
    infiniteData: InfiniteData<T>,
    transform: (input: T, index?: number) => R,
): InfiniteData<R> {
    const mappedPages = infiniteData.pages.map(transform);
    return {
        ...infiniteData,
        pages: mappedPages,
    };
}

export async function optimisticUpdate<T>(
    queryClient: QueryClient,
    queryKey: QueryKey,
    updater: (prevData: T) => T,
): Promise<UndoUpdateFunc | undefined> {
    await queryClient.cancelQueries(queryKey);
    const prevData = queryClient.getQueryData<T>(queryKey);
    if (prevData) {
        const newData = updater(prevData);
        queryClient.setQueryData<T>(queryKey, newData);
        return () => queryClient.setQueryData<T>(queryKey, prevData);
    }
    return undefined;
}
