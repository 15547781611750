import { arrow, autoUpdate, flip, offset, shift, useFloating } from "@floating-ui/react";
import { Button } from "@mui/material";
import cx from "classnames";
import React, { ReactElement, useCallback, useEffect, useRef, useState } from "react";

import { ActionStep, StepInterestPoint } from "../../network/responseTypes";
import { useImageMeta } from "../../util/GuideUtil";
import { getInterestPointBoundingClientRect } from "../../util/InterestPointUtil";
import { slateNodesToString } from "../../util/SlateUtil";
import {
    TOOLTIP_ARROW_PADDING,
    TOOLTIP_FLIP_FALLBACK_AXIS_SIDE_DIR,
    TOOLTIP_SHIFT_CROSS_AXIS,
    getTooltipOffset,
    getTooltipPlacement,
} from "../../util/TooltipUtil";
import EditStepPopover from "../EditStepPopover/EditStepPopover";
import MessageBubble from "../MessageBubble/MessageBubble";
import MessageBubbleButton from "../MessageBubbleButton/MessageBubbleButton";
import MessageBubbleFloatingArrow from "../MessageBubbleFloatingArrow/MessageBubbleFloatingArrow";
import MessageBubbleFooter from "../MessageBubbleFooter/MessageBubbleFooter";

import styles from "./StepEditorMessageBubble.module.scss";

const SHIFT_PADDING = 24;

interface Props {
    guideId: string;
    step: ActionStep;
    screenshotUrl: string;
    onNextStep: () => void;
    onPreviousStep: () => void;
    onRestart: () => void;
    stepIndex: number;
    stepCount: number;
    interestPoint?: StepInterestPoint;
    getImageElBoundingClientRect: () => DOMRect;
    className?: string;
}

function StepEditorMessageBubble(props: Props): ReactElement {
    const {
        guideId,
        step,
        screenshotUrl,
        onNextStep,
        onPreviousStep,
        onRestart,
        stepIndex,
        stepCount,
        interestPoint,
        getImageElBoundingClientRect,
        className,
    } = props;
    const { height: imageHeight, width: imageWidth } = useImageMeta({
        imgUrl: screenshotUrl,
    });

    const getHighlightBoundingClientRect = useCallback(() => {
        const imageElRect = getImageElBoundingClientRect();
        return getInterestPointBoundingClientRect(
            interestPoint,
            imageElRect,
            imageWidth,
            imageHeight,
        );
    }, [interestPoint, getImageElBoundingClientRect, imageWidth, imageHeight]);

    const arrowRef = useRef(null);

    const tooltipConfigOffset = getTooltipOffset(step.tooltip_config);

    const { refs, floatingStyles, context } = useFloating({
        middleware: [
            offset(tooltipConfigOffset),
            flip({ fallbackAxisSideDirection: TOOLTIP_FLIP_FALLBACK_AXIS_SIDE_DIR }),
            shift({ padding: SHIFT_PADDING, crossAxis: TOOLTIP_SHIFT_CROSS_AXIS }),
            arrow({ element: arrowRef, padding: TOOLTIP_ARROW_PADDING }),
        ],
        placement: getTooltipPlacement(step.tooltip_config),
        whileElementsMounted: autoUpdate,
    });

    useEffect(
        () =>
            refs.setPositionReference({
                getBoundingClientRect: getHighlightBoundingClientRect,
            }),
        [refs, getHighlightBoundingClientRect],
    );

    const plainTextDescription = slateNodesToString(step.description ?? []);
    const isFirstStep = stepIndex === 0;
    const isLastStep = stepIndex === stepCount - 1;

    const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLElement | null>(null);
    const closePopover = useCallback(() => setPopoverAnchorEl(null), []);

    return (
        <>
            <MessageBubble
                title={step.title}
                description={plainTextDescription}
                ref={refs.setFloating}
                className={cx(
                    styles.messageBubble,
                    {
                        [styles.invisible]: !!popoverAnchorEl,
                    },
                    className,
                )}
                style={floatingStyles}
            >
                <MessageBubbleFooter>
                    <Button
                        onClick={(ev) => setPopoverAnchorEl(ev.currentTarget)}
                        size="small"
                        variant="text"
                        color="secondary"
                        className={styles.editButton}
                    >
                        Edit
                    </Button>
                    <Button
                        aria-label="Edit"
                        onClick={(ev) => setPopoverAnchorEl(ev.currentTarget)}
                        className={styles.clickableBackground}
                    />
                    {!isFirstStep && (
                        <MessageBubbleButton onClick={onPreviousStep}>Back</MessageBubbleButton>
                    )}
                    {!isLastStep && (
                        <MessageBubbleButton onClick={onNextStep}>Next</MessageBubbleButton>
                    )}
                    {isLastStep && (
                        <MessageBubbleButton onClick={onRestart}>Restart</MessageBubbleButton>
                    )}
                </MessageBubbleFooter>
                {interestPoint && <MessageBubbleFloatingArrow ref={arrowRef} context={context} />}
            </MessageBubble>
            <EditStepPopover
                key={step.id}
                guideId={guideId}
                step={step}
                open={!!popoverAnchorEl}
                anchorEl={popoverAnchorEl}
                onClose={closePopover}
                onCancel={closePopover}
                onSave={closePopover}
            />
        </>
    );
}

export default StepEditorMessageBubble;
