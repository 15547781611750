import React, { useState, useEffect } from "react";
import { Droppable, DroppableProps } from "react-beautiful-dnd";

// react-beautiful-dnd does not work with react 18's StrictMode.
// to compensate, we need to render our Droppable elements after an animation frame
// see: https://github.com/atlassian/react-beautiful-dnd/issues/2399#issuecomment-1175638194
const StrictModeDroppable = ({ children, ...props }: DroppableProps) => {
    const [enabled, setEnabled] = useState(false);
    useEffect(() => {
        const animation = requestAnimationFrame(() => setEnabled(true));
        return () => {
            cancelAnimationFrame(animation);
            setEnabled(false);
        };
    }, []);
    if (!enabled) {
        return null;
    }
    return <Droppable {...props}>{children}</Droppable>;
};

StrictModeDroppable.displayName = "StrictModeDroppable";

export default StrictModeDroppable;
