import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import Axios from "axios";

import { Guide } from "../network/responseTypes";

import { QueryKey } from "./queryKeys";

export function useGuideDetailPublicQuery(
    guideId: string,
    options?: UseQueryOptions<Guide, Error>,
): UseQueryResult<Guide, Error> {
    return useQuery<Guide, Error>(
        [QueryKey.GuideDetail, guideId],
        async () => {
            try {
                const { data } = await Axios.get<Guide>(`/api/guides/${guideId}/`);
                return data;
            } catch (err) {
                if (!Axios.isAxiosError(err)) {
                    throw err;
                }

                switch (err.response?.status) {
                    case 403:
                        throw new Error("FORBIDDEN");
                    case 404:
                        throw new Error("NOT_FOUND");
                    default:
                        throw new Error("UNKNOWN_ERROR");
                }
            }
        },
        {
            retry: 0,
            ...options,
        },
    );
}
