import { MutableRefObject, useEffect } from "react";

export const useFullViewObserver = (
    elementRef: MutableRefObject<HTMLElement | null>,
    callback: () => void,
) => {
    useEffect(() => {
        const element = elementRef.current;
        if (!element) return;

        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    callback();
                }
            },
            {
                threshold: 0.9,
            },
        );

        if (element) {
            observer.observe(element);
        }

        return () => {
            if (element) {
                observer.unobserve(element);
            }
        };
    }, [elementRef, callback]);
};
