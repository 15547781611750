import { Popover, PopoverProps, Tab, Tabs } from "@mui/material";
import React, { ReactElement, useContext, useId, useState } from "react";

import { UserContext } from "../../contexts/user";
import { isGuideCustomizationEnabled } from "../../hooks/featureAccess";
import { useSubscriptionTier } from "../../hooks/subscriptionTier";
import { Guide } from "../../network/responseTypes";
import InviteUserInput from "../InviteUserInput/InviteUserInput";
import PublicGuideAccessSwitch from "../PublicGuideAccessSwitch/PublicGuideAccessSwitch";
import ShareGuideEmbedTab from "../ShareGuideDialog/ShareGuideEmbedTab";
import ShareGuideExportTab from "../ShareGuideDialog/ShareGuideExportTab";
import ShareGuideLinkTab from "../ShareGuideDialog/ShareGuideLinkTab";
import UpgradeBannerButton from "../UpgradeBanner/UpgradeBannerButton";

import styles from "./ShareGuidePopover.module.scss";

interface Props extends Pick<PopoverProps, "anchorEl"> {
    guide: Guide;
    onClose: () => void;
}

function ShareGuidePopover(props: Props): ReactElement {
    const { guide, anchorEl, onClose } = props;
    const { organization } = useContext(UserContext);
    const subscriptionTier = useSubscriptionTier();
    const [activeTab, setActiveTab] = useState<"link" | "export" | "embed">("link");

    const tabIdPrefix = useId();
    const panelIdPrefix = useId();

    const { is_public: isGuidePublic } = guide;

    const isMemberOfGuideOrg = guide.organization.id === organization.id;
    const canInviteUsers =
        subscriptionTier !== "FREE" && organization.subscription?.is_within_seat_limit;

    return (
        <Popover
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            classes={{ paper: styles.paper }}
        >
            {isMemberOfGuideOrg && (
                <>
                    <InviteUserInput isDisabled={!canInviteUsers} guideId={guide.id} />
                    {!canInviteUsers && (
                        <>
                            <UpgradeBannerButton
                                tier={subscriptionTier === "FREE" ? "Pro" : "Enterprise"}
                                text={
                                    subscriptionTier === "FREE"
                                        ? "Upgrade to invite your team to Driveway"
                                        : "Upgrade to invite more team members"
                                }
                                className={styles.upgradeBanner}
                            />
                        </>
                    )}
                </>
            )}
            {isMemberOfGuideOrg && <PublicGuideAccessSwitch guide={guide} />}
            {isGuidePublic && (
                <>
                    <Tabs
                        value={activeTab}
                        onChange={(event, newValue) => setActiveTab(newValue)}
                        aria-label="Share as"
                        className={styles.tabsRoot}
                        TabIndicatorProps={{ style: { background: "#5055F1" } }}
                        classes={{
                            root: styles.tabsRoot,
                            flexContainer: styles.tabsFlexContainer,
                        }}
                        selectionFollowsFocus
                    >
                        <Tab
                            label="Link"
                            value="link"
                            aria-label="Link"
                            classes={{ root: styles.tabRoot }}
                            id={`${tabIdPrefix}-link`}
                            aria-controls={`${panelIdPrefix}-link`}
                        />
                        <Tab
                            label="Embed"
                            value="embed"
                            aria-label="Embed"
                            classes={{ root: styles.tabRoot }}
                            id={`${tabIdPrefix}-embed`}
                            aria-controls={`${panelIdPrefix}-embed`}
                        />
                        <Tab
                            label="Export"
                            value="export"
                            aria-label="Export"
                            classes={{ root: styles.tabRoot }}
                            id={`${tabIdPrefix}-export`}
                            aria-controls={`${panelIdPrefix}-export`}
                        />
                    </Tabs>
                    <div
                        id={`${panelIdPrefix}-link`}
                        role="tabpanel"
                        hidden={activeTab !== "link"}
                        aria-labelledby={`${tabIdPrefix}-link`}
                    >
                        {activeTab === "link" && <ShareGuideLinkTab guide={guide} />}
                    </div>
                    <div
                        id={`${panelIdPrefix}-export`}
                        role="tabpanel"
                        hidden={activeTab !== "export"}
                        aria-labelledby={`${tabIdPrefix}-export`}
                    >
                        {activeTab === "export" && <ShareGuideExportTab guide={guide} />}
                    </div>
                    <div
                        id={`${panelIdPrefix}-embed`}
                        role="tabpanel"
                        hidden={activeTab !== "embed"}
                        aria-labelledby={`${tabIdPrefix}-embed`}
                    >
                        {activeTab === "embed" && (
                            <ShareGuideEmbedTab
                                guide={guide}
                                navigateToPrivacySettings={() => setActiveTab("link")}
                            />
                        )}
                    </div>
                </>
            )}
            {isMemberOfGuideOrg && !isGuideCustomizationEnabled(organization) && (
                <UpgradeBannerButton
                    tier="Pro"
                    text="Upgrade to remove watermarks"
                    className={styles.upgradeBanner}
                />
            )}
        </Popover>
    );
}

export default ShareGuidePopover;
