/* eslint-disable */
// THIS IS A GENERATED FILE. DO NOT EDIT DIRECTLY
// To generate new type definitions run `npm run generate-schema-types`
import * as Types from '../../@types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MeQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MeQuery = { readonly __typename?: 'Query', readonly me?: { readonly __typename?: 'PersonType', readonly id: any, readonly firstName: string, readonly lastName: string, readonly email: string, readonly role: string, readonly avatarUrl?: string | null, readonly organizations: ReadonlyArray<{ readonly __typename?: 'OrganizationType', readonly id: any, readonly organizationName: string, readonly logoUrl?: string | null, readonly wordmarkUrl?: string | null, readonly createdAt: string, readonly subscription?: { readonly __typename?: 'SubscriptionType', readonly isTrial: boolean, readonly isExpired: boolean, readonly isWithinPlanLimit: boolean, readonly isWithinSeatLimit: boolean, readonly daysRemaining: number, readonly isManuallyManaged: boolean, readonly tier: Types.SubscriptionTier, readonly guideViewUsage: number, readonly maxGuideViewsPerPeriod?: number | null, readonly currentUsagePeriodEnd?: string | null, readonly sharedGuideLimitStats: { readonly __typename?: 'SharedGuideLimitStatsType', readonly isWithinLimit: boolean, readonly usage?: number | null, readonly limit?: number | null } } | null }> } | null };


export const MeDocument = gql`
    query Me {
  me {
    id
    firstName
    lastName
    email
    role
    avatarUrl
    organizations {
      id
      organizationName
      logoUrl
      wordmarkUrl
      createdAt
      subscription {
        isTrial
        isExpired
        isWithinPlanLimit
        isWithinSeatLimit
        sharedGuideLimitStats {
          isWithinLimit
          usage
          limit
        }
        daysRemaining
        isManuallyManaged
        tier
        guideViewUsage
        maxGuideViewsPerPeriod
        currentUsagePeriodEnd
      }
    }
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;