import { Button, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { ReactElement, useEffect, useId, useState } from "react";
import { useIntercom } from "react-use-intercom";

import { formatInviteErrorMessage } from "../../util/ErrorUtil";

import styles from "./InviteUserInput.module.scss";
import { useInviteUserMutation } from "./operations.generated";

const RESET_AFTER_INVITE_SUCCESS_MS = 2500;

interface FormState {
    email: string;
    errorMessage?: string;
    invitationSent: boolean;
}

interface Props {
    isDisabled?: boolean;
    className?: string;
    guideId: string;
}

const INITIAL_FORM_STATE: FormState = { email: "", invitationSent: false };

function InviteUserInput(props: Props): ReactElement {
    const { isDisabled = false, className, guideId } = props;
    const inputId = useId();
    const intercom = useIntercom();

    const { enqueueSnackbar } = useSnackbar();
    const [formState, setFormState] = useState<FormState>(INITIAL_FORM_STATE);
    const [inviteUser, { loading }] = useInviteUserMutation({
        refetchQueries: ["TeamMembers"],
        onCompleted: (data) => {
            if (data.inviteUsers?.errors) {
                const errorMessage = formatInviteErrorMessage(
                    data.inviteUsers.errors[0].messages[0],
                );

                setFormState((formState) => ({
                    ...formState,
                    errorMessage,
                }));
            } else if (data.inviteUsers?.ok === true) {
                setFormState({ email: "", invitationSent: true });
            }
        },
        onError: () => {
            enqueueSnackbar("An error occurred while sending the invitation. Please try again.", {
                variant: "error",
            });
        },
    });

    const { invitationSent } = formState;
    useEffect(() => {
        if (invitationSent) {
            const timeoutId = setTimeout(
                () => setFormState(INITIAL_FORM_STATE),
                RESET_AFTER_INVITE_SUCCESS_MS,
            );
            return () => clearTimeout(timeoutId);
        }
    }, [invitationSent]);

    const handleInvite = () => {
        intercom.trackEvent("clicked_invite_from_guide", {
            email: formState.email.trim(),
            guideId: guideId,
        });
        inviteUser({ variables: { email: formState.email.trim() } }).catch(console.error);
    };

    return (
        <div className={className}>
            <label htmlFor={inputId} className={styles.label}>
                Invite to workspace
            </label>
            <div className={styles.formContainer}>
                <TextField
                    id={inputId}
                    type="email"
                    placeholder="Type email address here"
                    size="small"
                    value={formState.email}
                    onChange={(ev) =>
                        setFormState(() => ({
                            email: ev.target.value,
                            errorMessage: undefined,
                            invitationSent: false,
                        }))
                    }
                    className={styles.emailTextField}
                    inputProps={{ className: styles.emailInput }}
                    error={!!formState.errorMessage}
                    helperText={
                        formState.invitationSent ? "Invitation sent!" : formState.errorMessage
                    }
                    disabled={loading || isDisabled}
                    FormHelperTextProps={{
                        classes: {
                            root: styles.helperText,
                            error: styles.helperTextError,
                        },
                    }}
                />
                <Button
                    variant="contained"
                    disabled={loading || isDisabled}
                    onClick={handleInvite}
                    className={styles.inviteButton}
                >
                    Invite
                </Button>
            </div>
        </div>
    );
}

export default InviteUserInput;
