import React, { ReactElement } from "react";
import { ButtonBase } from "@mui/material";

import { Guide } from "../../network/responseTypes";

import styles from "./GuideScrollOutline.module.scss";

interface Props {
    guide: Guide;
    scrollToStep: (stepId: string) => void;
}

export default function GuideScrollOutline(props: Props): ReactElement {
    const { guide } = props;
    return (
        <div className={styles.container}>
            <h3 className={styles.header}>Steps</h3>
            <div className={styles.list}>
                {guide.steps.map((step, idx) => (
                    <ButtonBase
                        key={step.id}
                        onClick={() => props.scrollToStep(step.id)}
                        className={styles.outlineItem}
                        aria-label={`Scroll to step ${idx + 1}`}
                    >
                        <div className={styles.outlineItemNumber}>{idx + 1}.</div>
                        <div>{step.title}</div>
                    </ButtonBase>
                ))}
            </div>
        </div>
    );
}
