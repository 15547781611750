import { useMutation, UseMutationResult } from "@tanstack/react-query";
import Axios from "axios";

export function useGetGuidePdfMutation(guideId: string): UseMutationResult<string> {
    return useMutation(async () => {
        const { data } = await Axios.get<string>(`/api/guides/${guideId}/pdf/`, {
            responseType: "blob",
        });
        return data;
    });
}
