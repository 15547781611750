import { MenuItem } from "@mui/material";
import React, { ReactElement, useState } from "react";
import { useSnackbar } from "notistack";
import { useQueryClient } from "@tanstack/react-query";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import { QueryKey } from "../../queries/queryKeys";
import ConfirmDeletionDialog from "../ConfirmDeletionDialog/ConfirmDeletionDialog";

import { useDeleteGuideMutation } from "./operations.generated";
interface Props {
    guideId: string;
    className?: string;
    iconClassName?: string;
    onSuccess?: () => void;
    onClose?: () => void;
}

function DeleteGuideMenuItem(props: Props): ReactElement {
    const { guideId, className, iconClassName, onSuccess, onClose } = props;
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const reactQueryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();
    const [deleteGuide, { loading }] = useDeleteGuideMutation({
        variables: { id: guideId },
        refetchQueries: ["Guides"],
        onCompleted: () => {
            onSuccess?.();
            enqueueSnackbar("Guide deleted", {
                variant: "success",
            });
        },
        onError: () => {
            // remove when GuideDetail is migrated to graphql
            void reactQueryClient.invalidateQueries([QueryKey.GuideDetail, guideId]);
            enqueueSnackbar("Failed to delete guide. Please try again.", {
                variant: "error",
            });
        },
    });

    return (
        <>
            {/*
                TODO(arnav): Refactor this so that the menu item doesn't remain open when the dialog is.
                Note weird effects with the containing menu.
            */}
            <MenuItem className={className} onClick={() => setIsDialogOpen(true)}>
                <DeleteOutlineIcon fontSize="small" className={iconClassName} />
                Delete
            </MenuItem>
            <ConfirmDeletionDialog
                entity="guide"
                isOpen={isDialogOpen}
                isDisabled={loading}
                onConfirm={deleteGuide}
                onClose={() => {
                    setIsDialogOpen(false);
                    onClose?.();
                }}
            />
        </>
    );
}

export default DeleteGuideMenuItem;
