import { useQuery, UseQueryResult } from "@tanstack/react-query";

import { sendDataToExtension } from "../util/ChromeExtensionUtil";

import { QueryKey } from "./queryKeys";

export function useChromeExtensionBetaEnabledQuery(): UseQueryResult<boolean> {
    return useQuery<boolean>(
        [QueryKey.ChromeExtensionBetaEnabled],
        // Attempt to send a message to the beta Chrome extension.
        // If any response is returned, the beta is enabled.
        () =>
            sendDataToExtension(
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                process.env.REACT_APP_BETA_CHROME_EXTENSION_ID!,
                "readVersion",
            )
                .then(() => true)
                .catch(() => false),
        {
            networkMode: "always",
            staleTime: Infinity,
            onSuccess: (isEnabled) =>
                console.log(`Beta chrome extension is ${isEnabled ? "enabled" : "not enabled"}`),
        },
    );
}
