import { Button, Tooltip } from "@mui/material";
import cx from "classnames";
import React, { ReactElement } from "react";

import drivewayLogo from "../../images/driveway-logo-blue-rounded.svg";

import styles from "./DrivewayWatermark.module.scss";

interface Props {
    className?: string;
}

function DrivewayWatermark(props: Props): ReactElement {
    const { className } = props;
    return (
        <Tooltip
            title="🏎 Made with Driveway"
            placement="left"
            classes={{ tooltip: styles.tooltip }}
            describeChild
        >
            <Button
                variant="contained"
                href="https://driveway.app/"
                target="_blank"
                className={cx(styles.drivewayButton, className)}
            >
                <img src={drivewayLogo} alt="Driveway" className={styles.drivewayLogo} />
            </Button>
        </Tooltip>
    );
}

export default DrivewayWatermark;
