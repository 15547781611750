/* eslint-disable */
// THIS IS A GENERATED FILE. DO NOT EDIT DIRECTLY
// To generate new type definitions run `npm run generate-schema-types`
import * as Types from '../../../@types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AutocompleteTagsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AutocompleteTagsQuery = { readonly __typename?: 'Query', readonly tags?: ReadonlyArray<{ readonly __typename?: 'TagType', readonly id: string, readonly name: string }> | null };

export type SetGuideTagsMutationVariables = Types.Exact<{
  input: Types.SetGuideTagsMutationInput;
}>;


export type SetGuideTagsMutation = { readonly __typename?: 'Mutation', readonly setGuideTags?: { readonly __typename?: 'SetGuideTagsMutation', readonly ok?: boolean | null } | null };


export const AutocompleteTagsDocument = gql`
    query AutocompleteTags {
  tags {
    id
    name
  }
}
    `;

/**
 * __useAutocompleteTagsQuery__
 *
 * To run a query within a React component, call `useAutocompleteTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAutocompleteTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutocompleteTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAutocompleteTagsQuery(baseOptions?: Apollo.QueryHookOptions<AutocompleteTagsQuery, AutocompleteTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AutocompleteTagsQuery, AutocompleteTagsQueryVariables>(AutocompleteTagsDocument, options);
      }
export function useAutocompleteTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AutocompleteTagsQuery, AutocompleteTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AutocompleteTagsQuery, AutocompleteTagsQueryVariables>(AutocompleteTagsDocument, options);
        }
export type AutocompleteTagsQueryHookResult = ReturnType<typeof useAutocompleteTagsQuery>;
export type AutocompleteTagsLazyQueryHookResult = ReturnType<typeof useAutocompleteTagsLazyQuery>;
export type AutocompleteTagsQueryResult = Apollo.QueryResult<AutocompleteTagsQuery, AutocompleteTagsQueryVariables>;
export const SetGuideTagsDocument = gql`
    mutation SetGuideTags($input: SetGuideTagsMutationInput!) {
  setGuideTags(input: $input) {
    ok
  }
}
    `;
export type SetGuideTagsMutationFn = Apollo.MutationFunction<SetGuideTagsMutation, SetGuideTagsMutationVariables>;

/**
 * __useSetGuideTagsMutation__
 *
 * To run a mutation, you first call `useSetGuideTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetGuideTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setGuideTagsMutation, { data, loading, error }] = useSetGuideTagsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetGuideTagsMutation(baseOptions?: Apollo.MutationHookOptions<SetGuideTagsMutation, SetGuideTagsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetGuideTagsMutation, SetGuideTagsMutationVariables>(SetGuideTagsDocument, options);
      }
export type SetGuideTagsMutationHookResult = ReturnType<typeof useSetGuideTagsMutation>;
export type SetGuideTagsMutationResult = Apollo.MutationResult<SetGuideTagsMutation>;
export type SetGuideTagsMutationOptions = Apollo.BaseMutationOptions<SetGuideTagsMutation, SetGuideTagsMutationVariables>;