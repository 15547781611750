/* eslint-disable */
// THIS IS A GENERATED FILE. DO NOT EDIT DIRECTLY
// To generate new type definitions run `npm run generate-schema-types`
import * as Types from '../../../@types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AllGuideViewsQueryVariables = Types.Exact<{
  guideId: Types.Scalars['ID']['input'];
}>;


export type AllGuideViewsQuery = { readonly __typename?: 'Query', readonly anonymousGuideViews?: ReadonlyArray<{ readonly __typename?: 'GuideViewType', readonly id: any, readonly source: Types.GuideViewEventSource }> | null, readonly guideViewsByPerson?: ReadonlyArray<{ readonly __typename?: 'GuideViewsByPersonType', readonly id?: string | null, readonly firstName?: string | null, readonly lastName?: string | null, readonly fullName?: string | null, readonly avatarUrl?: string | null, readonly viewsCount?: number | null }> | null };

export type GuideViewsSummaryQueryVariables = Types.Exact<{
  guideId: Types.Scalars['ID']['input'];
  personId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  viewEventId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type GuideViewsSummaryQuery = { readonly __typename?: 'Query', readonly guideViewsSummary?: { readonly __typename?: 'GuideViewsSummaryType', readonly viewsCount: number, readonly guideViewTimes: ReadonlyArray<string>, readonly averageCompletionRate?: number | null } | null };


export const AllGuideViewsDocument = gql`
    query AllGuideViews($guideId: ID!) {
  anonymousGuideViews(guideId: $guideId) {
    id
    source
  }
  guideViewsByPerson(guideId: $guideId) {
    id
    firstName
    lastName
    fullName
    avatarUrl
    viewsCount
  }
}
    `;

/**
 * __useAllGuideViewsQuery__
 *
 * To run a query within a React component, call `useAllGuideViewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllGuideViewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllGuideViewsQuery({
 *   variables: {
 *      guideId: // value for 'guideId'
 *   },
 * });
 */
export function useAllGuideViewsQuery(baseOptions: Apollo.QueryHookOptions<AllGuideViewsQuery, AllGuideViewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllGuideViewsQuery, AllGuideViewsQueryVariables>(AllGuideViewsDocument, options);
      }
export function useAllGuideViewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllGuideViewsQuery, AllGuideViewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllGuideViewsQuery, AllGuideViewsQueryVariables>(AllGuideViewsDocument, options);
        }
export type AllGuideViewsQueryHookResult = ReturnType<typeof useAllGuideViewsQuery>;
export type AllGuideViewsLazyQueryHookResult = ReturnType<typeof useAllGuideViewsLazyQuery>;
export type AllGuideViewsQueryResult = Apollo.QueryResult<AllGuideViewsQuery, AllGuideViewsQueryVariables>;
export const GuideViewsSummaryDocument = gql`
    query GuideViewsSummary($guideId: ID!, $personId: ID, $viewEventId: ID) {
  guideViewsSummary(
    guideId: $guideId
    personId: $personId
    viewEventId: $viewEventId
  ) {
    viewsCount
    guideViewTimes
    averageCompletionRate
  }
}
    `;

/**
 * __useGuideViewsSummaryQuery__
 *
 * To run a query within a React component, call `useGuideViewsSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuideViewsSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuideViewsSummaryQuery({
 *   variables: {
 *      guideId: // value for 'guideId'
 *      personId: // value for 'personId'
 *      viewEventId: // value for 'viewEventId'
 *   },
 * });
 */
export function useGuideViewsSummaryQuery(baseOptions: Apollo.QueryHookOptions<GuideViewsSummaryQuery, GuideViewsSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GuideViewsSummaryQuery, GuideViewsSummaryQueryVariables>(GuideViewsSummaryDocument, options);
      }
export function useGuideViewsSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GuideViewsSummaryQuery, GuideViewsSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GuideViewsSummaryQuery, GuideViewsSummaryQueryVariables>(GuideViewsSummaryDocument, options);
        }
export type GuideViewsSummaryQueryHookResult = ReturnType<typeof useGuideViewsSummaryQuery>;
export type GuideViewsSummaryLazyQueryHookResult = ReturnType<typeof useGuideViewsSummaryLazyQuery>;
export type GuideViewsSummaryQueryResult = Apollo.QueryResult<GuideViewsSummaryQuery, GuideViewsSummaryQueryVariables>;