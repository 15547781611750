import { useContext } from "react";

import { UserContext } from "../contexts/user";

export const DEVITOWAY_ORG_ID = "853633af-acfd-4957-b5d3-cae42e9c2986";
export const DRIVEWAY_ORG_ID = "21c955a0-fcfe-4801-9877-02bc5d41b53d";

const INTERNAL_ORGS = [DEVITOWAY_ORG_ID, DRIVEWAY_ORG_ID];

const EXTENDED_TRIAL_ORGS = [
    "82139812-dfe3-48e6-9eb3-b6c587eb2e52", // Knock
    "8d99f50b-21bc-4b94-b85f-f97f563cca43", // Opus
    "21c7cdb2-460f-4c7c-8cb8-750edbdb6d20", // Obe
    "7a56bd1b-03f9-4529-b722-237d6003914c", // Jones
    "d711359d-16ea-4193-801f-0eb46b5c6573", // Orchard David
    "5774e9e2-7202-43c6-a2e6-d09043d63b71", // Miami Ohio
    "c67611c8-0621-444c-a299-ac1b5151e22f", // Orchard OC Team
    "b10e1119-4201-43b2-85db-4cc0299442bc", // Orchard Leah
    "3c3dd549-44c8-43fa-b120-1c1c21db9e95", // Orchard Adam
    "39191c89-a194-4997-8dc3-982a71bb0001", // Orchard Analytics
    "da072ad4-e2b3-49bc-8e68-a283e56107da", // Apploi
    "6af10e87-b12b-4ebc-ad5b-888bf3df63b1", // Routable
    "6be7d4ed-54a9-4fc0-b6c5-c67983c8ac10", // Me360
    "5080ad04-f588-46d3-82c7-39c0ca29c07c", // Vendor PM
    "a05d8fa0-418c-4d8b-8775-2d7f532595a7", // Laura's EY Workspace
    "a699e239-5d9e-43c7-aa14-ab7cd546ea0e", // Ryan's Workspace
    "18b93c47-1774-4762-951f-d5bbad0a12e5", // Victor's Workspace
    "f71ac8db-9685-4deb-9d7a-718f7c2e5798", // Ian's Workspace
    "5e42aaab-6395-49d0-a0d5-8575b28e24a5", // Maegan's Workspace Double
    "85868e2e-7537-47a7-8713-50b03d504bbc", // EyeSchool Workspace
    "90d73a89-a5b6-42e5-a758-bfa125e10f5d", // Realo
];

export function useIsInternalTestOrg(): boolean {
    const {
        organization: { id: organizationId },
    } = useContext(UserContext);
    return organizationId === DEVITOWAY_ORG_ID;
}

export function useIsInternalOrg(): boolean {
    const {
        organization: { id: organizationId },
    } = useContext(UserContext);
    return INTERNAL_ORGS.includes(organizationId);
}

export function useShowSubscriptionSettings(): boolean {
    const {
        organization: { id: organizationId, subscription },
    } = useContext(UserContext);

    const isTrialOrg = EXTENDED_TRIAL_ORGS.includes(organizationId);
    // TODO: Remove trial and tier check when the beta org's trial period is over
    return (!isTrialOrg || subscription?.tier === "FREE") && !subscription?.is_manually_managed;
}
