/* eslint-disable */
// THIS IS A GENERATED FILE. DO NOT EDIT DIRECTLY
// To generate new type definitions run `npm run generate-schema-types`
import * as Types from '../../../@types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EditScreenshotMutationVariables = Types.Exact<{
  input: Types.EditScreenshotMutationInput;
}>;


export type EditScreenshotMutation = { readonly __typename?: 'Mutation', readonly editScreenshot?: { readonly __typename?: 'EditScreenshotMutation', readonly ok: boolean } | null };


export const EditScreenshotDocument = gql`
    mutation EditScreenshot($input: EditScreenshotMutationInput!) {
  editScreenshot(input: $input) {
    ok
  }
}
    `;
export type EditScreenshotMutationFn = Apollo.MutationFunction<EditScreenshotMutation, EditScreenshotMutationVariables>;

/**
 * __useEditScreenshotMutation__
 *
 * To run a mutation, you first call `useEditScreenshotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditScreenshotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editScreenshotMutation, { data, loading, error }] = useEditScreenshotMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditScreenshotMutation(baseOptions?: Apollo.MutationHookOptions<EditScreenshotMutation, EditScreenshotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditScreenshotMutation, EditScreenshotMutationVariables>(EditScreenshotDocument, options);
      }
export type EditScreenshotMutationHookResult = ReturnType<typeof useEditScreenshotMutation>;
export type EditScreenshotMutationResult = Apollo.MutationResult<EditScreenshotMutation>;
export type EditScreenshotMutationOptions = Apollo.BaseMutationOptions<EditScreenshotMutation, EditScreenshotMutationVariables>;