import { MoreHoriz as MoreIcon } from "@mui/icons-material";
import { CircularProgress, IconButton, Menu } from "@mui/material";
import cx from "classnames";
import React, { ReactElement, useContext, useEffect, useId, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { useIntercom } from "react-use-intercom";

import ConfigureTriggerButton from "../../../components/ConfigureTriggerButton/ConfigureTriggerButton";
import DeleteGuideMenuItem from "../../../components/DeleteGuideMenuItem/DeleteGuideMenuItem";
import DuplicateGuideMenuItem from "../../../components/DuplicateGuideMenuItem/DuplicateGuideMenuItem";
import EditableField from "../../../components/EditableField/EditableField";
import EventTrackingGuideEditor from "../../../components/EventTrackingGuideEditor/EventTrackingGuideEditor";
import EventTrackingGuidePreview from "../../../components/EventTrackingGuidePreview/EventTrackingGuidePreview";
import GuideBackButton from "../../../components/GuideBackButton/GuideBackButton";
import GuideViewStatsButton from "../../../components/GuideViewStatsButton/GuideViewStatsButton";
import InformationalTooltip from "../../../components/InformationalTooltip/InformationalTooltip";
import LiveViewButton from "../../../components/LiveViewButton/LiveViewButton";
import OrganizationLogo from "../../../components/OrganizationLogo/OrganizationLogo";
import PageNotFoundErrorMessage from "../../../components/PageNotFoundErrorMessage/PageNotFoundErrorMessage";
import PreviewToggleButton from "../../../components/PreviewToggleButton/PreviewToggleButton";
import ShareGuideButton from "../../../components/ShareGuideButton/ShareGuideButton";
import { UserContext } from "../../../contexts/user";
import { isGuideCustomizationEnabled } from "../../../hooks/featureAccess";
import { useSubscriptionTier } from "../../../hooks/subscriptionTier";
import { useKeyboardNavigation } from "../../../hooks/useKeyboardNavigation";
import GuideDetailBar from "../../../layouts/GuideDetailBar/GuideDetailBar";
import { useEditGuideMutation } from "../../../mutations/editGuide";
import { ViewEventSource } from "../../../mutations/recordGuideView";
import { useChromeExtensionInstalledQuery } from "../../../queries/chromeExtensionInstalled";
import { useGuideDetailQuery } from "../../../queries/guideDetail";
import { useStepManager } from "../../../util/GuideUtil";
import { useRequiredParams } from "../../../util/ReactRouterUtil";
import { slateNodesToString } from "../../../util/SlateUtil";
import { withMobileExperience } from "../../withMobileExperience";
import GuideDetailEmbedPage from "../guide-detail-embed/GuideDetailEmbedPage";

import styles from "./GuideDetailPage.module.scss";
import { useGuideCustomizationsQuery } from "./operations.generated";

function GuideDetailPage(): ReactElement {
    const { guideId } = useRequiredParams(["guideId"]);

    const { data: extensionInstalled, isLoading: isExtensionInstalledLoading } =
        useChromeExtensionInstalledQuery();

    const intercom = useIntercom();

    // not using error bc we don't want the page to depend on customizations
    const { data: guideCustomizationsData, loading: isGuideCustomizationsLoading } =
        useGuideCustomizationsQuery({
            variables: {
                guideId,
            },
        });

    const {
        data: guide,
        isLoading: isGuideDetailLoading,
        isError,
    } = useGuideDetailQuery(guideId, {
        retry: false,
    });

    const isLoading =
        isGuideDetailLoading || isExtensionInstalledLoading || isGuideCustomizationsLoading;
    const { mutate: editGuide, isLoading: isEditGuideSubmitting } = useEditGuideMutation(guideId);
    const { id: userId, organization } = useContext(UserContext);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const navigate = useNavigate();

    const isUserAuthor = guide?.author.id === userId;

    const isMemberOfGuideOrganization = organization?.id === guide?.organization.id;

    const subscriptionTier = useSubscriptionTier();

    const [isPreviewing, setPreviewing] = useState(true);

    const {
        stepNumber,
        goToNextStep,
        goToPreviousStep,
        setStepNumber,
        reset: resetStepManager,
    } = useStepManager(guide?.steps.length || 0);
    useKeyboardNavigation(goToPreviousStep, goToNextStep);

    const plaintextDescription = guide?.description ? slateNodesToString(guide.description) : "";

    const moreGuideOptionsButtonId = useId();

    const hidePlayButton = !!guideCustomizationsData?.guideCustomizations.hidePlayButton;

    useEffect(() => {
        if (guide?.id) {
            intercom.trackEvent("viewed-guide", {
                guide_id: guide.id,
                is_author: isUserAuthor,
            });
        }
    }, [intercom, isUserAuthor, guide?.id]);

    return (
        <div className={styles.pageContainer}>
            {guide && <Helmet title={guide.title} />}
            <GuideDetailBar>
                <div className={styles.guideTopbarContainer}>
                    <div className={styles.left}>
                        <GuideBackButton />
                        {guide && !isError && (
                            <>
                                {isGuideCustomizationEnabled(guide.organization) &&
                                    !!guide.organization.logo_url && (
                                        <OrganizationLogo
                                            src={
                                                guide.organization.wordmark_url ||
                                                guide.organization.logo_url
                                            }
                                            name={guide.organization.organization_name}
                                            className={cx(
                                                styles.orgLogo,
                                                guide.organization.wordmark_url
                                                    ? styles.wordmark
                                                    : styles.logo,
                                            )}
                                        />
                                    )}
                                {!isUserAuthor && (
                                    <h1
                                        className={cx(
                                            styles.pageTitleRoot,
                                            styles.pageTitle,
                                            styles.readOnly,
                                        )}
                                    >
                                        {guide.title}
                                    </h1>
                                )}
                                {isUserAuthor && (
                                    <EditableField
                                        value={guide?.title}
                                        mode="text"
                                        placeholder="Enter a title…"
                                        onSubmit={(title) => editGuide({ title })}
                                        isSubmitting={isEditGuideSubmitting}
                                        entityId={guide?.id}
                                        classes={{
                                            root: styles.pageTitleRoot,
                                            field: styles.pageTitle,
                                        }}
                                    />
                                )}
                                {!isUserAuthor && plaintextDescription && (
                                    <InformationalTooltip>
                                        {plaintextDescription}
                                    </InformationalTooltip>
                                )}
                            </>
                        )}
                    </div>
                    <div className={styles.right}>
                        {guide && !isError && (
                            <>
                                <GuideViewStatsButton
                                    guideId={guideId}
                                    numViews={guide.num_views}
                                    readOnly={!isMemberOfGuideOrganization}
                                />
                                {isUserAuthor && (
                                    <PreviewToggleButton
                                        isPreviewing={isPreviewing}
                                        onClick={() =>
                                            setPreviewing((isPreviewing) => !isPreviewing)
                                        }
                                    />
                                )}
                                {isUserAuthor && subscriptionTier === "ENTERPRISE" && (
                                    <ConfigureTriggerButton guide={guide} />
                                )}
                                {!hidePlayButton && subscriptionTier === "ENTERPRISE" && (
                                    <LiveViewButton guide={guide} />
                                )}
                                <ShareGuideButton guide={guide} />
                            </>
                        )}
                        {!isError && (
                            <div className={styles.actions}>
                                <IconButton
                                    onClick={(e) => setAnchorEl(e.currentTarget)}
                                    aria-label="More guide options"
                                    id={moreGuideOptionsButtonId}
                                >
                                    <MoreIcon className={styles.moreIcon} />
                                </IconButton>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={() => setAnchorEl(null)}
                                    classes={{ paper: styles.menu }}
                                    MenuListProps={{ "aria-labelledby": moreGuideOptionsButtonId }}
                                >
                                    <DuplicateGuideMenuItem
                                        guideId={guideId}
                                        iconClassName={styles.menuIcon}
                                        onSuccess={(duplicatedGuideId) => {
                                            navigate(`/guides/${duplicatedGuideId}`);
                                            setAnchorEl(null);
                                        }}
                                    />
                                    {isUserAuthor && (
                                        <DeleteGuideMenuItem
                                            guideId={guideId}
                                            iconClassName={styles.menuIcon}
                                            onSuccess={() => {
                                                navigate("/guides");
                                                setAnchorEl(null);
                                            }}
                                        />
                                    )}
                                </Menu>
                            </div>
                        )}
                    </div>
                </div>
            </GuideDetailBar>
            {isLoading && (
                <div className={cx(styles.contentContainer, styles.loading)}>
                    <CircularProgress />
                </div>
            )}
            {isError && (
                <div
                    className={cx(styles.contentContainer, styles.error)}
                    data-cy="guide-load-error"
                >
                    <PageNotFoundErrorMessage description="This guide was deleted or belongs to a workspace you don&rsquo;t have access to." />
                </div>
            )}
            {guide && !isLoading && !isError && (
                <div className={styles.contentContainer}>
                    {isUserAuthor && (
                        <EventTrackingGuideEditor
                            guide={guide}
                            isExtensionInstalled={extensionInstalled}
                            isPreviewing={isPreviewing}
                            eventSource={ViewEventSource.PRIVATE_GUIDE_DETAIL_PAGE}
                            className={styles.editor}
                        />
                    )}
                    {!isUserAuthor && (
                        <EventTrackingGuidePreview
                            guide={guide}
                            stepIndex={stepNumber}
                            onNextStep={goToNextStep}
                            onPreviousStep={goToPreviousStep}
                            onRestart={resetStepManager}
                            onSetStep={setStepNumber}
                            eventSource={ViewEventSource.PRIVATE_GUIDE_DETAIL_PAGE}
                            canComplete={false}
                        />
                    )}
                </div>
            )}
        </div>
    );
}

export default withMobileExperience(GuideDetailPage, GuideDetailEmbedPage);
