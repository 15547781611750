import React from "react";
import { MenuItem, Select } from "@mui/material";
import { MicNone } from "@mui/icons-material";

import useAudioCaptureDevices from "../../hooks/mediaDevices";

import styles from "./DeviceSelection.module.scss";

function DeviceSelection(props: {
    selectedDeviceId?: string;
    onSelect: (deviceId: string) => void;
    shouldPrompt: boolean;
}) {
    const devices = useAudioCaptureDevices({ prompt: props.shouldPrompt });

    if (devices.length === 0) {
        return null;
    }

    return (
        <div className={styles.container}>
            <MicNone className={styles.deviceSelectionIcon} />
            <Select
                value={props.selectedDeviceId || devices[0]?.deviceId || ""}
                onChange={(e) => props.onSelect(e.target.value)}
                variant="outlined"
                classes={{ outlined: styles.select }}
                MenuProps={{
                    classes: {
                        root: styles.selectMenuRoot,
                        paper: styles.selectMenuPaper,
                        list: styles.selectMenuList,
                    },
                }}
            >
                {devices.map((device) => (
                    <MenuItem
                        key={device.deviceId}
                        value={device.deviceId}
                        classes={{
                            root: styles.menuItem,
                            selected: styles.menuItemSelected,
                        }}
                    >
                        {device.label || "Unknown Device"}
                    </MenuItem>
                ))}
            </Select>
        </div>
    );
}

export default DeviceSelection;
