// NOTE: Prefer using the useChromeExtensionMessage hook to send a message to the Chrome extension
export function sendDataToExtension<TValue, TResult>(
    extensionId: string,
    name: string,
    data?: TValue,
): Promise<TResult> {
    return new Promise((resolve, reject) => {
        chrome.runtime.sendMessage(extensionId, { ...data, name }, (response) => {
            if (chrome.runtime.lastError) {
                reject(chrome.runtime.lastError);
            } else {
                resolve(response);
            }
        });
    });
}

enum BrowserType {
    Chrome = "Chrome",
    Firefox = "Firefox",
    Edge = "Edge",
    Opera = "Opera",
    Safari = "Safari",
    Unknown = "Unknown",
}

const BrowserNameToCopy = {
    [BrowserType.Chrome]: "Chrome",
    [BrowserType.Firefox]: "Firefox",
    [BrowserType.Edge]: "Edge",
    [BrowserType.Opera]: "Opera",
    [BrowserType.Safari]: "Safari",
    [BrowserType.Unknown]: "your browser",
};

function detectBrowserName(): BrowserType {
    // detecting the browser name is not as reliable as you'd think!
    // https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent#browser_name
    const ua = navigator.userAgent;
    if (ua.includes("Chrome")) {
        return BrowserType.Chrome;
    } else if (ua.includes("Firefox")) {
        return BrowserType.Firefox;
    } else if (ua.includes("Safari")) {
        return BrowserType.Safari;
    } else if (ua.includes("Edge")) {
        return BrowserType.Edge;
    } else if (ua.includes("Opera")) {
        return BrowserType.Opera;
    } else {
        return BrowserType.Unknown;
    }
}

export function getBrowserCopy() {
    return BrowserNameToCopy[detectBrowserName()];
}

export function isChrome() {
    return detectBrowserName() === BrowserType.Chrome;
}
