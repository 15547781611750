import React from "react";

import warningIcon from "../../icons/warning.svg";

import styles from "./RestrictedView.module.scss";

const EmbedRestrictedView = () => (
    <div className={styles.container}>
        <img className={styles.warningIcon} src={warningIcon} alt="" />
        <h1 className={styles.title}>Access to this guide is restricted</h1>
        <sub className={styles.sub}>Ask the guide owner to allow public access to this guide.</sub>
    </div>
);

export default EmbedRestrictedView;
