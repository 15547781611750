import React, { ReactElement } from "react";
import classnames from "classnames";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { AutoAwesome, ListAlt } from "@mui/icons-material";

import styles from "./GuideDetailViewToggle.module.scss";

export enum GuideDetailView {
    Scroll = "scroll",
    Demo = "demo",
}

interface Props {
    view: GuideDetailView;
    setGuideDetailView: (newView: GuideDetailView) => void;
    className?: string;
}

const ViewToAttrs = {
    [GuideDetailView.Scroll]: {
        label: "Scroll",
        icon: ListAlt,
        ariaLabel: "Scroll View",
    },
    [GuideDetailView.Demo]: {
        label: "Demo",
        icon: AutoAwesome,
        ariaLabel: "Demo View",
    },
};

const VIEWS = [GuideDetailView.Demo, GuideDetailView.Scroll];

export default function GuideDetailViewToggle(props: Props): ReactElement {
    const { view: currentView, setGuideDetailView } = props;

    const handleChange = (event: React.MouseEvent<HTMLElement>, newView: GuideDetailView) => {
        if (newView !== null) {
            setGuideDetailView(newView);
        }
    };

    return (
        <ToggleButtonGroup
            color="primary"
            value={currentView}
            exclusive
            onChange={handleChange}
            classes={{ root: classnames(styles.toggleButtonGroupRoot, props.className) }}
            aria-label="Guide Detail View"
        >
            {VIEWS.map((v) => {
                const { icon: Icon, ariaLabel } = ViewToAttrs[v];
                const isActive = currentView === v;
                return (
                    <ToggleButton
                        classes={{
                            root: styles.toggleButtonRoot,
                            selected: styles.toggleButtonSelected,
                            standard: styles.toggleButtonStandard,
                        }}
                        key={v}
                        value={v}
                        aria-label={ariaLabel}
                    >
                        <Icon
                            className={classnames(
                                styles.icon,
                                isActive ? styles.active : styles.inactive,
                            )}
                        />
                        <span className={isActive ? styles.active : styles.inactive}>
                            {ViewToAttrs[v].label}
                        </span>
                    </ToggleButton>
                );
            })}
        </ToggleButtonGroup>
    );
}
