import cx from "classnames";
import React, { ReactElement } from "react";
import { Avatar as MuiAvatar } from "@mui/material";

import styles from "./OrganizationLogo.module.scss";

interface Props {
    className?: string;
    src: string;
    name?: string;
}

function OrganizationLogo(props: Props): ReactElement {
    const { className, src, name } = props;

    return (
        <MuiAvatar
            src={src}
            alt={name ?? ""}
            className={cx(styles.logo, className)}
            variant="rounded"
        />
    );
}

export default OrganizationLogo;
