import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useImageMeta } from "../util/GuideUtil";

import { useOnWindowResize } from "./onWindowResize";

interface Result<T> {
    imageScale: number;
    containerRef: React.MutableRefObject<T | null>;
    imageWidth: number | undefined;
    imageHeight: number | undefined;
}

// Based on https://github.com/prc5/react-zoom-pan-pinch/blob/master/src/stories/examples/image-responsive/example.tsx
export function useResponsivePanZoom<T extends Element>(imageUrl: string): Result<T> {
    const containerRef = useRef<T | null>(null);
    const [containerWidth, setContainerWidth] = useState(0);
    const [containerHeight, setContainerHeight] = useState(0);

    const { height: imageHeight, width: imageWidth } = useImageMeta({
        imgUrl: imageUrl,
    });

    const imageScale = useMemo(() => {
        if (containerWidth === 0 || containerHeight === 0 || !imageWidth || !imageHeight) return 0;
        const scale = Math.min(containerWidth / imageWidth, containerHeight / imageHeight);
        return scale;
    }, [containerWidth, containerHeight, imageWidth, imageHeight]);

    const handleResize = useCallback(() => {
        // Resize the container when the window resizes
        const panZoomContainerEl = containerRef.current;
        if (panZoomContainerEl !== null) {
            const rect = panZoomContainerEl.getBoundingClientRect();
            setContainerWidth(rect.width);
            setContainerHeight(rect.height);
        } else {
            setContainerWidth(0);
            setContainerHeight(0);
        }
    }, []);

    useEffect(handleResize, [handleResize]);
    useOnWindowResize(handleResize);

    return { containerRef, imageScale, imageWidth, imageHeight };
}
