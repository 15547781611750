import { Button, Step, StepContent, StepLabel, Stepper, TextField } from "@mui/material";
import React, { ReactElement, useCallback, useState } from "react";

import { internallyEnabledPage } from "../../internallyEnabledPage";

import styles from "./WidgetTestPage.module.scss";

function loadWidgetScript(versionCode: string): void {
    const d = document;
    const s = "script";
    const o = "Driveway";
    const f = `https://driveway-scripts.s3.amazonaws.com/${versionCode}/widget.js`;
    const js = d.createElement(s);
    const fjs = d.getElementsByTagName(s)[0];
    js.id = o;
    js.src = f;
    js.async = true;
    fjs.parentNode?.insertBefore(js, fjs);
}

function WidgetTestPage(): ReactElement {
    const [versionCode, setVersionCode] = useState("widget-v0.1.0");
    const [setupStepIndex, setSetupStepIndex] = useState(0);
    const [guideId, setGuideId] = useState("");

    const isWidgetScriptLoaded = setupStepIndex > 0;

    const loadScript = useCallback(() => {
        loadWidgetScript(versionCode);
        setSetupStepIndex(1);
    }, [versionCode]);

    const renderLiveView = useCallback(() => {
        window.Driveway.renderLiveView();
    }, []);

    const startGuide = useCallback(() => {
        window.Driveway.startGuide(guideId);
    }, [guideId]);

    const closeSession = useCallback(() => {
        window.Driveway.closeSession();
    }, []);

    return (
        <div className={styles.container}>
            <h1 className={styles.heading}>Setup</h1>
            <Stepper activeStep={setupStepIndex} orientation="vertical" className={styles.stepper}>
                <Step completed={isWidgetScriptLoaded}>
                    <StepLabel>Load widget script</StepLabel>
                    <StepContent>
                        <div className={styles.setupRow}>
                            <TextField
                                label="Widget version code (widget-vX.Y.Z)"
                                value={versionCode}
                                disabled={isWidgetScriptLoaded}
                                onChange={(e) => setVersionCode(e.currentTarget.value)}
                                className={styles.setupTextField}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={loadScript}
                                disabled={isWidgetScriptLoaded || !versionCode.trim()}
                                className={styles.setupButton}
                            >
                                Load widget script
                            </Button>
                        </div>
                    </StepContent>
                </Step>
                <Step>
                    <StepLabel>Configure widget script</StepLabel>
                    <StepContent>
                        <div className={styles.setupRow}>
                            <TextField
                                label="Public guide ID"
                                value={guideId}
                                disabled={!isWidgetScriptLoaded}
                                onChange={(e) => setGuideId(e.currentTarget.value)}
                                className={styles.setupTextField}
                            />
                        </div>
                        <div className={styles.setupRow}>
                            <Button
                                variant="outlined"
                                onClick={renderLiveView}
                                className={styles.setupButton}
                            >
                                Render Live View
                            </Button>
                            <Button
                                variant="outlined"
                                onClick={startGuide}
                                className={styles.setupButton}
                            >
                                Start guide
                            </Button>
                            <Button
                                variant="outlined"
                                onClick={closeSession}
                                className={styles.setupButton}
                            >
                                Close session
                            </Button>
                        </div>
                    </StepContent>
                </Step>
            </Stepper>
        </div>
    );
}

export default internallyEnabledPage(
    WidgetTestPage,
    process.env.REACT_APP_WIDGET_TEST_PAGE_ENABLED === "true",
);
