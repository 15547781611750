import { Button, CircularProgress } from "@mui/material";
import Konva from "konva";
import { useSnackbar } from "notistack";
import React, { createContext, useCallback, useEffect, useId, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";

import infoIcon from "../../icons/info-circle.svg";
import { useReplaceGuideStepScreenshotMutation } from "../../mutations/replaceGuideStepScreenshot";
import { useImageMeta } from "../../util/GuideUtil";

import RedactStage from "./RedactStage";
import styles from "./RedactWrapper.module.scss";

export interface RedactionRect {
    id: string;
    x: number;
    y: number;
    width: number;
    height: number;
}

interface RedactDialogState {
    stageRef: React.RefObject<Konva.Stage> | null;
    image: {
        el: HTMLImageElement;
        width: number;
        height: number;
    };
    scaleFactor: number;
    selectedId: string | null;
    redactions: RedactionRect[];
    setSelectedId: (value: React.SetStateAction<string | null>) => void;
    setRedactions: (value: React.SetStateAction<RedactionRect[]>) => void;
}

const defaultState: RedactDialogState = {
    stageRef: null,
    image: { el: new window.Image(), width: 0, height: 0 },
    scaleFactor: 1,
    selectedId: null,
    redactions: [],
    setSelectedId: () => {},
    setRedactions: () => {},
};

export const RedactImageContext = createContext<RedactDialogState>(defaultState);

interface Props {
    imageUrl: string;
    guideId: string;
    stepId: string;
    onClose: () => void;
}

function RedactWrapper({ imageUrl, onClose, stepId, guideId }: Props) {
    const { enqueueSnackbar } = useSnackbar();
    const stageRef = useRef<Konva.Stage | null>(null);

    const {
        width: fullImageWidth,
        height: fullImageHeight,
        loaded,
        image,
    } = useImageMeta({ imgUrl: imageUrl, anonymousCrossOrigin: true, bustCache: true });
    const [scaleFactor, setScaleFactor] = useState<number>(1);
    const [pixelRatio, setPixelRatio] = useState<number>(3);
    const { mutate: replaceScreenshot, isLoading: isSaving } =
        useReplaceGuideStepScreenshotMutation();

    const [selectedId, setSelectedId] = useState<string | null>(null);
    const [redactions, setRedactions] = useState<RedactionRect[]>([]);

    useHotkeys(
        "backspace",
        () => {
            if (selectedId) {
                setRedactions((redactions) =>
                    redactions.filter((redaction) => redaction.id !== selectedId),
                );
                setSelectedId(null);
            }
        },
        [selectedId, setSelectedId, setRedactions],
    );

    const redactionScreenshotWrapperId = useId();

    const calculateRatioScale = useCallback(() => {
        const screenshotNodeWidth = window.document.getElementById(redactionScreenshotWrapperId)
            ?.offsetWidth;

        if (screenshotNodeWidth && fullImageWidth) {
            setPixelRatio(fullImageWidth / screenshotNodeWidth);
            setScaleFactor(screenshotNodeWidth / fullImageWidth);
        }
    }, [fullImageWidth, redactionScreenshotWrapperId]);

    useEffect(() => {
        calculateRatioScale();
    }, [image, calculateRatioScale]);

    useEffect(() => {
        window.addEventListener("resize", calculateRatioScale);
        return () => {
            window.removeEventListener("resize", calculateRatioScale);
        };
    }, [calculateRatioScale]);

    const handleExport = () => {
        if (!window.confirm("Are you sure you want to save? This action can not be undone.")) {
            return;
        }

        const dataUrl = stageRef?.current?.toDataURL({
            pixelRatio,
            quality: 1,
        });

        if (!dataUrl) {
            enqueueSnackbar(
                "There was an error, please try again. (screenshot could not be generated)",
                {
                    variant: "error",
                },
            );
            return;
        }

        replaceScreenshot(
            {
                guideId,
                stepId,
                dataUrl,
            },
            {
                onSuccess: () => {
                    enqueueSnackbar("Screenshot redacted!", {
                        variant: "success",
                    });
                    onClose();
                },
                onError: () => {
                    enqueueSnackbar("There was an error with the request, please try again.", {
                        variant: "error",
                    });
                },
            },
        );
    };

    const loading = !image || !loaded;

    const resetSelection = useCallback(() => setSelectedId(null), []);

    return (
        <RedactImageContext.Provider
            value={{
                stageRef,
                image: {
                    el: image,
                    width: fullImageWidth || 0,
                    height: fullImageHeight || 0,
                },
                scaleFactor: scaleFactor,
                selectedId,
                redactions,
                setSelectedId,
                setRedactions,
            }}
        >
            {loading && (
                <div className={styles.loading}>
                    <CircularProgress />
                </div>
            )}

            <div style={{ opacity: loading ? 0 : 1, transform: "opacity 300ms ease" }}>
                <div id={redactionScreenshotWrapperId} className={styles.screenshotWrapper}>
                    <RedactStage />
                </div>

                <div className={styles.actions}>
                    <sub className={styles.subTitle}>
                        <img className={styles.infoIcon} src={infoIcon} alt="" />
                        Manually redact sections by clicking and dragging over the image.
                    </sub>
                    <Button
                        disabled={isSaving || redactions.length === 0}
                        variant="contained"
                        color="primary"
                        onMouseDown={
                            // Unselect any actively selected redaction rect before onClick triggers,
                            // or else exported screenshot will still show the rect as selected
                            resetSelection
                        }
                        onClick={handleExport}
                    >
                        {isSaving ? "Saving…" : "Save"}
                    </Button>
                </div>
            </div>
        </RedactImageContext.Provider>
    );
}

export default RedactWrapper;
