import cx from "classnames";
import React, { CSSProperties, ReactNode } from "react";

import styles from "./MessageBubble.module.scss";

interface Props {
    title?: string;
    description?: string;
    children?: ReactNode;
    style?: CSSProperties;
    className?: string;
}

export default React.forwardRef<HTMLDivElement, Props>(function MessageBubble(props, ref) {
    const { title, description, children, style, className } = props;
    return (
        <div ref={ref} className={cx(styles.container, className)} style={style}>
            {title && <div className={styles.title}>{title}</div>}
            {description && <div className={styles.description}>{description}</div>}
            {children}
        </div>
    );
});
