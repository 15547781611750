import { ApolloClient, ApolloProvider, from, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { createUploadLink } from "apollo-upload-client";
import { SnackbarProvider } from "notistack";
import React, { ReactElement, useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { IntercomProvider } from "react-use-intercom";

import MainRouter from "./components/MainRouter/MainRouter";
import { UserContextProvider } from "./contexts/user";
import useAmplitudeTracker from "./hooks/useAmplitudeTracker";
import { appTheme } from "./MaterialTheme";
import { storageAvailable } from "./util/StorageUtil";

const queryClient = new QueryClient();

function getOrgId(): string | undefined {
    // localStorage is not available in incognito mode
    if (storageAvailable("localStorage")) {
        const orgId = localStorage.getItem("organizationId");
        if (orgId) {
            return orgId;
        }
    }
    return undefined;
}

const authLink = setContext((_, { headers }) => {
    return {
        headers: {
            ...headers,
            "X-ORG-ID": getOrgId() || "",
        },
    };
});
const uploadLink = createUploadLink({
    uri: "/graphql",
    credentials: "same-origin",
});

const apolloClient = new ApolloClient({
    cache: new InMemoryCache(),
    link: from([authLink, uploadLink]),
});

function App(): ReactElement {
    const location = useLocation();
    const { track } = useAmplitudeTracker();
    useEffect(() => {
        track("Page View", {
            page_location: window.location.href,
            page_path: window.location.pathname,
            page_title: document.title,
        });
    }, [location, track]);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={appTheme}>
                <ApolloProvider client={apolloClient}>
                    <QueryClientProvider client={queryClient}>
                        <SnackbarProvider
                            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                        >
                            <IntercomProvider
                                appId={process.env.REACT_APP_INTERCOM_APP_ID ?? ""}
                                autoBoot
                            >
                                <ReactQueryDevtools
                                    position="bottom-right"
                                    toggleButtonProps={{
                                        style: {
                                            // Offset to account for Intercom
                                            bottom: "84px",
                                            right: "16px",
                                        },
                                    }}
                                />
                                <HelmetProvider>
                                    <UserContextProvider>
                                        <LocalizationProvider dateAdapter={AdapterLuxon}>
                                            <Helmet
                                                defaultTitle="Driveway"
                                                titleTemplate="%s | Driveway"
                                            />
                                            <MainRouter />
                                        </LocalizationProvider>
                                    </UserContextProvider>
                                </HelmetProvider>
                            </IntercomProvider>
                        </SnackbarProvider>
                    </QueryClientProvider>
                </ApolloProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default App;
