import { useEffect, useState } from "react";

export function useWindowWidth(): number {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const listener = () => setWidth(window.innerWidth);
        window.addEventListener("resize", listener);
        return () => window.removeEventListener("resize", listener);
    }, []);

    return width;
}
