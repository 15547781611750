import { useMutation, UseMutationResult } from "@tanstack/react-query";
import Axios from "axios";

export function useGetGuidePngMutation(guideId: string): UseMutationResult<string> {
    return useMutation(async () => {
        const { data } = await Axios.get<string>(`/api/guides/${guideId}/png/`, {
            responseType: "blob",
        });
        return data;
    });
}
