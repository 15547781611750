import { Switch } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import cx from "classnames";
import { useSnackbar } from "notistack";
import React, { ReactElement, useId } from "react";
import { useIntercom } from "react-use-intercom";

import useAmplitudeTracker from "../../hooks/useAmplitudeTracker";
import { Guide } from "../../network/responseTypes";
import { QueryKey } from "../../queries/queryKeys";
import { useSetGuideAccessMutation } from "../ShareGuideDialog/operations.generated";

import styles from "./PublicGuideAccessSwitch.module.scss";

interface Props {
    guide: Guide;
    className?: string;
}

function PublicGuideAccessSwitch(props: Props): ReactElement {
    const { guide, className } = props;
    const { is_public: isPublic } = guide;

    const intercom = useIntercom();
    const { track } = useAmplitudeTracker();
    const { enqueueSnackbar } = useSnackbar();
    const reactQueryClient = useQueryClient();
    const [setGuideAccess] = useSetGuideAccessMutation({
        onCompleted: (data) => {
            if (data.setGuideAccess?.ok) {
                enqueueSnackbar("Privacy settings updated.", { variant: "success" });
            }
            reactQueryClient
                .invalidateQueries([QueryKey.GuideDetail, guide.id])
                .catch(console.error);
        },
        onError: () => {
            enqueueSnackbar("Unable to update privacy settings. Please try again.", {
                variant: "error",
            });
        },
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newIsPublic = event.target.checked;
        setGuideAccess({
            variables: {
                input: {
                    guideId: guide.id,
                    public: newIsPublic,
                },
            },
        }).catch(console.error);
        track(newIsPublic ? "Make Guide Public" : "Make Guide Private");
        intercom.trackEvent(
            newIsPublic ? "clicked-make-guide-public" : "clicked-make-guide-private",
            { guideId: guide.id },
        );
    };

    const switchId = useId();
    const descriptionId = useId();

    return (
        <div className={cx(styles.container, className)}>
            <label htmlFor={switchId} className={styles.label}>
                Public access
            </label>
            <div id={descriptionId} className={styles.description}>
                {isPublic
                    ? "Anyone with the link can view this guide"
                    : "Allow public access to share this guide with anyone"}
            </div>
            <Switch
                checked={isPublic}
                onChange={handleChange}
                id={switchId}
                inputProps={{
                    "aria-describedby": descriptionId,
                }}
                className={styles.switch}
            />
        </div>
    );
}

export default PublicGuideAccessSwitch;
