import React, { ReactElement } from "react";
import { Button as MuiButton, ButtonProps, Dialog, DialogContent } from "@mui/material";

import useResetScreenshot from "../../mutations/resetScreenshot";

import styles from "./ResetScreenshotDialog.module.scss";

interface Props {
    isOpen: boolean;
    guideId: string;
    stepId: string;
    onClose: () => void;
}

function ResetScreenshotDialog(props: Props): ReactElement {
    const { isOpen, onClose } = props;

    const [onConfirm, { loading }] = useResetScreenshot(props.guideId, props.stepId, onClose);

    return (
        <Dialog
            open={isOpen}
            onClose={!loading ? onClose : undefined}
            maxWidth="sm"
            classes={{ paper: styles.dialogPaper }}
        >
            <DialogContent className={styles.dialogContent}>
                <div className={styles.dialogTitle}>Reset to original screenshot</div>
                <div className={styles.dialogMessage}>
                    Are you sure you want to remove edits and annotations from this screenshot? This
                    action cannot be undone.
                </div>
                <Button
                    color="inherit"
                    variant="contained"
                    onClick={() => onConfirm()}
                    disabled={loading}
                    className={styles.deleteButton}
                    data-dd-action-name="Reset to original screenshot"
                    fullWidth
                >
                    Reset screenshot
                </Button>
                <Button
                    color="primary"
                    variant="outlined"
                    onClick={!loading ? onClose : undefined}
                    disabled={loading}
                    className={styles.cancelButton}
                    data-dd-action-name="Dismiss reset to original screenshot modal"
                    fullWidth
                >
                    Cancel
                </Button>
            </DialogContent>
        </Dialog>
    );
}

function Button(props: ButtonProps): ReactElement {
    const { classes, ...otherProps } = props;
    return (
        <MuiButton
            classes={{
                root: styles.buttonRoot,
                contained: styles.buttonContained,
                outlinedPrimary: styles.buttonOutlinedPrimary,
                ...classes,
            }}
            {...otherProps}
        />
    );
}

export default ResetScreenshotDialog;
