import cx from "classnames";
import React, { ReactElement } from "react";
import { Link, useLocation } from "react-router-dom";

import styles from "./NavigationRow.module.scss";

interface Props {
    to: string;
    label: string;
    iconSrc: string;
}

function NavigationRow(props: Props): ReactElement {
    const { to, label, iconSrc } = props;
    const location = useLocation();
    const isSelected = location.pathname.startsWith(to);
    return (
        <div className={cx(styles.container, { [styles.selected]: isSelected })}>
            <Link to={to} className={styles.link}>
                <img src={iconSrc} className={styles.icon} alt="" />
                {label}
            </Link>
        </div>
    );
}

export default NavigationRow;
