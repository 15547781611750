import { useEffect, useCallback } from "react";
import { useHotkeys } from "react-hotkeys-hook";

export function useKeyboardNavigation(
    goToPreviousStep: () => void,
    goToNextStep: () => void,
): void {
    const handler = useCallback(() => {
        if (document.body.classList.contains("no-cursor")) {
            document.body.classList.remove("no-cursor");
        }
    }, []);
    useEffect(() => {
        window.addEventListener("mousemove", handler);
        return () => {
            window.removeEventListener("mousemove", handler);
        };
    }, [handler]);
    useHotkeys(
        "left, up",
        () => {
            goToPreviousStep();
            document.body.classList.add("no-cursor");
        },
        [goToPreviousStep],
    );
    useHotkeys(
        "right, down",
        () => {
            goToNextStep();
            document.body.classList.add("no-cursor");
        },
        [goToNextStep],
    );
}
