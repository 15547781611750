import React, { ReactElement } from "react";

import { Step } from "../../network/responseTypes";

import DeleteStepButton from "./DeleteStepButton";
import EditScreenshotButton from "./EditScreenshotButton";
import Narrator from "./Narrator";
import RedactButton from "./RedactButton";
import ResetScreenshotButton from "./ResetScreenshotButton";
import styles from "./StepDetailToolbar.module.scss";

interface Props {
    guideId: string;
    onEditScreenshot: (file: File, imageState: string) => void;
    step: Step;
}

const StepDetailToolbar = (props: Props): ReactElement => {
    return (
        <div className={styles.container}>
            {props.step.type === "action" && (
                <>
                    <Narrator
                        key={props.step.id}
                        guideId={props.guideId}
                        stepId={props.step.id}
                        narration={props.step.narration}
                    />
                    <RedactButton guideId={props.guideId} step={props.step} />
                    <EditScreenshotButton
                        guideId={props.guideId}
                        step={props.step}
                        onEditScreenshot={props.onEditScreenshot}
                    />
                    {props.step.screenshot.is_edited ? (
                        <ResetScreenshotButton guideId={props.guideId} step={props.step} />
                    ) : null}
                </>
            )}
            <DeleteStepButton stepId={props.step.id} guideId={props.guideId} />
        </div>
    );
};

StepDetailToolbar.displayName = "StepDetailToolbar";

export default StepDetailToolbar;
