import Axios from "axios";
import { UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";

import { Guide } from "../network/responseTypes";
import { QueryKey } from "../queries/queryKeys";
import { convertTextToSlateNode } from "../util/SlateUtil";
import { convertMinutesStringToSeconds } from "../util/TimeUtil";
import { optimisticUpdate, UndoUpdateFunc } from "../util/ReactQueryUtil";

interface EditGuideInput {
    title?: string;
    description?: string;
    duration?: string | null;
}

interface MutationContext {
    undoGuideUpdate?: UndoUpdateFunc;
}

export function useEditGuideMutation(
    guideId: string,
): UseMutationResult<Guide, Error, EditGuideInput, MutationContext> {
    const client = useQueryClient();
    return useMutation(
        async (variables) => {
            const description =
                variables.description !== undefined
                    ? convertTextToSlateNode(variables.description)
                    : undefined;
            const duration =
                variables.duration !== undefined
                    ? convertMinutesStringToSeconds(variables.duration)
                    : undefined;
            const { data } = await Axios.patch<Guide>(`/api/guides/${guideId}/`, {
                title: variables.title,
                description,
                duration,
            });
            return data;
        },
        {
            onMutate: async (variables) => {
                const undoGuideUpdate = await optimisticUpdate<Guide>(
                    client,
                    [QueryKey.GuideDetail, guideId],
                    (guide) => ({
                        ...guide,
                        title: variables.title ?? guide.title,
                        description:
                            variables.description !== undefined
                                ? convertTextToSlateNode(variables.description)
                                : guide.description,
                        duration:
                            variables.duration !== undefined
                                ? convertMinutesStringToSeconds(variables.duration)
                                : guide.duration,
                    }),
                );
                return { undoGuideUpdate };
            },
            onError: async (_err, _variables, context) => {
                context?.undoGuideUpdate?.();
                await client.invalidateQueries([QueryKey.GuideDetail, guideId]);
            },
            onSuccess: (guide) => {
                client.setQueryData<Guide>([QueryKey.GuideDetail, guideId], guide);
            },
        },
    );
}
