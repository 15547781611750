import Axios from "axios";
import { UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";

import { QueryKey } from "../queries/queryKeys";
import { Step } from "../network/responseTypes";

interface UndoDeleteStepVars {
    guideId: string;
    stepId: string;
}

export function useUndoDeleteStepMutation(): UseMutationResult<Step, Error, UndoDeleteStepVars> {
    const client = useQueryClient();
    return useMutation(
        async ({ stepId }) => {
            const { data } = await Axios.put<Step>(`/api/steps/${stepId}/undo-delete/`);
            return data;
        },
        {
            onSettled: (_, __, variables) =>
                client.invalidateQueries([QueryKey.GuideDetail, variables.guideId]),
        },
    );
}
