import { useEffect } from "react";

type Callback = () => void;

const useOutsideClick = (ref: React.RefObject<HTMLElement>, callback: Callback): void => {
    useEffect(() => {
        const handleClick = (e: MouseEvent) => {
            if (ref.current && !ref.current.contains(e.target as Node)) {
                callback();
            }
        };
        document.addEventListener("mousedown", handleClick);

        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, [ref, callback]);
};

export default useOutsideClick;
