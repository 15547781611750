import React, { ReactElement } from "react";

import { Guide } from "../../network/responseTypes";

import GuideCopyAsHTMLButton from "./GuideCopyAsHTMLButton";
import GuideCopyAsMarkdownButton from "./GuideCopyAsMarkdownButton";
import GuideDownloadAsPdfButton from "./GuideDownloadAsPdfButton";
import GuideDownloadAsPngButton from "./GuideDownloadAsPngButton";
import GuideExportAsVideoButton from "./GuideExportAsVideoButton";
import styles from "./ShareGuideExportTab.module.scss";

interface Props {
    guide: Guide;
}

function ShareGuideExportTab({ guide }: Props): ReactElement {
    return (
        <div className={styles.container}>
            <GuideCopyAsHTMLButton guideId={guide.id} />
            <GuideCopyAsMarkdownButton guideId={guide.id} />
            <GuideExportAsVideoButton guide={guide} />
            <GuideDownloadAsPdfButton guide={guide} />
            <GuideDownloadAsPngButton guide={guide} />
        </div>
    );
}

export default ShareGuideExportTab;
