// TODO Add keyboard support for EditableField
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { InputAdornment, TextField } from "@mui/material";
import cx from "classnames";
import Linkify from "linkify-react";
import React, { ReactElement, useCallback, useEffect, useRef, useState } from "react";

import useOutsideClick from "../../hooks/clickOutside";
import { useOnValueChange } from "../../hooks/onValueChange";
import editIcon from "../../icons/pencil.svg";

import styles from "./EditableField.module.scss";

export interface EditableFieldProps {
    value: string;
    mode: "text" | "multiline";
    placeholder: string;
    onSubmit: (value: string) => void;
    isSubmitting: boolean;
    entityId?: string;
    postProcess?: (value: string) => string;
    validate?: (value: string) => boolean;
    readOnly?: boolean;
    prefixElement?: ReactElement;
    suffixElement?: ReactElement;
    classes?: { root?: string; field?: string };
    submitOnEnter?: boolean;
}

type InputState =
    | { isEditing: false }
    | { isEditing: true; inputValue: string; hasValidationError: boolean };

function EditableField(props: EditableFieldProps): ReactElement | null {
    const {
        value,
        mode,
        placeholder,
        onSubmit,
        isSubmitting,
        entityId,
        validate,
        postProcess,
        readOnly = false,
        prefixElement,
        suffixElement,
        classes,
        submitOnEnter = true,
    } = props;
    const [didJustSubmit, setDidJustSubmit] = useState<boolean>(false);
    const [fieldState, setFieldState] = useState<InputState>({ isEditing: false });

    const clickOutsideRef = useRef<HTMLDivElement>(null);
    useOutsideClick(clickOutsideRef, () => {
        if (fieldState.isEditing) {
            handleSubmit();
        }
    });

    const startEditing = useCallback(() => {
        setFieldState({ isEditing: true, inputValue: value, hasValidationError: false });
    }, [value]);
    useOnValueChange(entityId, () => setFieldState({ isEditing: false }));

    const multiline = mode === "multiline";
    const renderHtmlLinks = readOnly;

    const valueDisplay = (
        <>
            {prefixElement}
            <div
                className={cx(styles.valueDisplay, {
                    [styles.singlelineValue]: !multiline,
                    [styles.multilineValue]: multiline,
                    [styles.multilineEndMargin]: multiline && value.endsWith("\n"),
                })}
            >
                {renderHtmlLinks ? <Linkify>{value}</Linkify> : value}
            </div>
            {suffixElement}
        </>
    );

    useEffect(() => {
        if (!isSubmitting && didJustSubmit) {
            // if we flip isEditing to false before the network
            // returns, then the input value "blinks" (we flash
            // the old value before returning to the new one)
            setFieldState({ isEditing: false });
            setDidJustSubmit(false);
        }
    }, [isSubmitting, didJustSubmit]);

    const handleSubmit = useCallback(() => {
        if (fieldState.isEditing) {
            const processedValue = postProcess
                ? postProcess(fieldState.inputValue)
                : fieldState.inputValue;
            setFieldState((state) => ({
                ...state,
                inputValue: processedValue,
            }));
            const isValid = validate ? validate(processedValue) : true;
            if (isValid) {
                onSubmit(processedValue);
                setDidJustSubmit(true);
            } else {
                setFieldState((state) => ({
                    ...state,
                    hasValidationError: true,
                }));
            }
        }
    }, [fieldState, onSubmit, postProcess, validate]);

    if (readOnly) {
        return value ? (
            // TODO: The top div used for position in the grid display will automatically
            // stretch to the width of the grid cell. Simplify this when we move away from
            // the grid display.
            <div className={cx(styles.container, classes?.root)}>
                <div className={cx(styles.field, classes?.field)}>{valueDisplay}</div>
            </div>
        ) : null;
    }

    return fieldState.isEditing ? (
        <div ref={clickOutsideRef} className={cx(styles.container, classes?.root)}>
            <TextField
                variant="outlined"
                type="text"
                fullWidth
                multiline={multiline}
                value={fieldState.inputValue}
                onChange={(e) =>
                    setFieldState((state) => ({
                        ...state,
                        inputValue: e.target.value,
                    }))
                }
                InputProps={{
                    startAdornment: prefixElement ? (
                        <InputAdornment position="start">{prefixElement}</InputAdornment>
                    ) : null,
                    endAdornment: suffixElement ? (
                        <InputAdornment position="end">{suffixElement}</InputAdornment>
                    ) : null,
                    classes: {
                        root: cx(styles.field, classes?.field, {
                            [styles.inputEditing]: fieldState.isEditing,
                        }),
                        input: styles.inputInput,
                    },
                }}
                onFocus={() => setFieldState((state) => ({ ...state, hasValidationError: false }))}
                error={fieldState.hasValidationError}
                placeholder={placeholder}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                onKeyDown={(e) => {
                    if (submitOnEnter && e.key === "Enter" && !e.shiftKey) {
                        e.preventDefault();
                        handleSubmit();
                    }
                }}
            />
        </div>
    ) : (
        <div className={cx(styles.container, classes?.root, styles.editable)}>
            <div
                onClick={startEditing}
                onFocus={startEditing}
                tabIndex={0}
                className={cx(styles.field, classes?.field, {
                    [styles.placeholder]: !value,
                })}
                role="button"
            >
                {value ? valueDisplay : placeholder}
            </div>
            <img
                src={editIcon}
                className={cx(styles.icon, { [styles.multilineIcon]: multiline })}
                alt=""
                onClick={startEditing}
            />
        </div>
    );
}

export default EditableField;
