import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import Axios from "axios";

import { Guide } from "../network/responseTypes";

import { QueryKey } from "./queryKeys";

export function useGuideDetailQuery(
    guideId: string,
    options?: UseQueryOptions<Guide, Error>,
): UseQueryResult<Guide, Error> {
    return useQuery<Guide, Error>(
        [QueryKey.GuideDetail, guideId],
        async () => {
            const { data } = await Axios.get<Guide>(`/api/guides/${guideId}/`);
            return data;
        },
        options,
    );
}
