import { useCallback, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { SnackbarKey, useSnackbar } from "notistack";

import { QueryKey } from "../../queries/queryKeys";

import { useSynthesizeNarrationMutation } from "./operations.generated";

function useSynthesizeNarration(guideId: string, stepId: string) {
    const [synthesizingToastKey, setSynthesizingToastKey] = useState<SnackbarKey | null>(null);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const reactQueryClient = useQueryClient();
    const [mutate, { loading }] = useSynthesizeNarrationMutation({
        onError: () => {
            if (synthesizingToastKey) {
                closeSnackbar(synthesizingToastKey);
                setSynthesizingToastKey(null);
            }
            enqueueSnackbar(
                "Something went wrong when trying to synthesize your voiceover. Please try again.",
                { variant: "error" },
            );
        },
        onCompleted: () => {
            // Remove when GuideDetail is migrated to graphql
            void reactQueryClient.invalidateQueries([QueryKey.GuideDetail, guideId]);
            if (synthesizingToastKey) {
                closeSnackbar(synthesizingToastKey);
                setSynthesizingToastKey(null);
            }
            enqueueSnackbar("The voiceover has been successfully created.", {
                variant: "success",
            });
        },
    });
    const showWaitForVoiceoverCompletionToast = useCallback(() => {
        enqueueSnackbar("Please wait for the voiceover to finish", {
            variant: "info",
        });
    }, [enqueueSnackbar]);
    const synthesizeNarration = useCallback(() => {
        const snackbarKey = enqueueSnackbar("Syntheizing voiceover…", {
            persist: true,
            variant: "info",
        });
        setSynthesizingToastKey(snackbarKey);
        if (synthesizingToastKey) {
            showWaitForVoiceoverCompletionToast();
            return;
        }
        mutate({
            variables: {
                input: {
                    stepId,
                },
            },
        }).catch(console.error);
    }, [
        mutate,
        enqueueSnackbar,
        stepId,
        synthesizingToastKey,
        showWaitForVoiceoverCompletionToast,
    ]);
    return {
        loading,
        synthesizeNarration,
    };
}

export default useSynthesizeNarration;
