import React, { ReactElement } from "react";
import { DateTime } from "luxon";
import cx from "classnames";

import { Guide } from "../../network/responseTypes";
import { slateNodesToString } from "../../util/SlateUtil";
import PersonAvatar from "../PersonAvatar/PersonAvatar";

import styles from "./GuidePreviewInfo.module.scss";

interface Props {
    guide: Guide;
    className?: string;
}

export default function GuidePreviewInfo(props: Props): ReactElement {
    const { guide, className } = props;
    const { first_name, last_name } = guide.author;
    const fullName = `${first_name} ${last_name}`;
    const localizedDate = DateTime.fromISO(guide.created_at).toLocaleString();
    return (
        <div className={cx(styles.container, className)}>
            <div className={styles.authorContainer}>
                <div className={styles.author}>
                    <PersonAvatar person={guide.author} className={styles.authorAvatar} />
                    <span className={styles.authorLabel}>{fullName}</span>
                </div>
                <div>•</div>
                <div className={styles.createdAt}>Created {localizedDate}</div>
            </div>
            <p className={styles.description}>{slateNodesToString(guide.description)}</p>
        </div>
    );
}
