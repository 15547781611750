import { useEffect, useState } from "react";

import useBrowserPermissionStatus from "./browserPermissionStatus";

interface Config {
    prompt?: boolean;
}

// we could make this generic and use it for video devices
// but don't need to do that right now
function useAudioCaptureDevices({ prompt = false }: Config): MediaDeviceInfo[] {
    const permissionName = "microphone" as PermissionName;
    const permissionStatus = useBrowserPermissionStatus(permissionName);

    const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);

    const getDevices = async () => {
        const mediaDevices = await navigator.mediaDevices.enumerateDevices();
        const inputDevices = mediaDevices.filter((device) => device.kind === "audioinput");
        setDevices(inputDevices);
    };

    // there's no explicit way to prompt for this permission
    // so we have to just try getting a stream in order to prompt
    const promptMediaRecorderPermission = async () => {
        const stream = await navigator.mediaDevices.getUserMedia({
            audio: true,
        });

        stream.getTracks().forEach((track) => track.stop());
    };

    useEffect(() => {
        if (permissionStatus !== "granted") {
            setDevices([]);
            return;
        }

        getDevices().catch(console.error);
    }, [permissionStatus]);

    if (prompt && permissionStatus === "prompt") {
        promptMediaRecorderPermission().catch(console.error);
    }

    return devices;
}

export default useAudioCaptureDevices;
