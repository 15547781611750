import {
    Button,
    MenuItem,
    MenuItemProps,
    Popover,
    PopoverProps,
    Select,
    TextField,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import React, { ReactElement, useId } from "react";
import { Controller, useForm } from "react-hook-form";

import { TooltipPlacement } from "../../../@types/graphql";
import { useTooltipConfigOptimisticUpdate } from "../../hooks/tooltipConfigOptimisticUpdate";
import { useEditStepMutation } from "../../mutations/editStep";
import { Step } from "../../network/responseTypes";
import { QueryKey } from "../../queries/queryKeys";
import { slateNodesToString } from "../../util/SlateUtil";

import styles from "./EditStepPopover.module.scss";
import { useSetTooltipConfigMutation, useTooltipConfigLazyQuery } from "./operations.generated";

const MIN_OFFSET = 0;
const MAX_OFFSET = 64;

interface Props extends Pick<PopoverProps, "open" | "anchorEl" | "onClose"> {
    guideId: string;
    step: Step;
    onSave: () => void;
    onCancel: () => void;
}

interface FormState {
    title: string;
    description: string;
    placement: TooltipPlacement;
    offset: number;
}

const DEFAULT_TOOLTIP_PLACEMENT: TooltipPlacement = "TOP";
const DEFAULT_TOOLTIP_OFFSET = 24;

const STEP_TITLE_MAX_LENGTH = 300; // Must match the Step model's title field in the backend

function EditStepPopover(props: Props): ReactElement {
    const { guideId, step, onSave, onCancel, onClose, ...popoverProps } = props;

    const [getTooltipConfig, { loading: isQueryLoading }] = useTooltipConfigLazyQuery({
        variables: { stepId: step.id },
    });

    const { handleSubmit, control, reset } = useForm<FormState>({
        defaultValues: async () => {
            const { data } = await getTooltipConfig();
            return {
                title: step.title,
                description: slateNodesToString(step.description),
                placement: data?.tooltipConfig?.placement ?? DEFAULT_TOOLTIP_PLACEMENT,
                offset: data?.tooltipConfig?.offset ?? DEFAULT_TOOLTIP_OFFSET,
            };
        },
    });

    const { enqueueSnackbar } = useSnackbar();
    const reactQueryClient = useQueryClient();

    const { mutateAsync: editStep, isLoading: isEditStepMutationLoading } = useEditStepMutation(
        step.id,
        guideId,
    );

    const [setTooltipConfig, { loading: isSetTooltipConfigMutationLoading }] =
        useSetTooltipConfigMutation({
            refetchQueries: ["TooltipConfig"],
            onCompleted: () => {
                reactQueryClient
                    .invalidateQueries([QueryKey.GuideDetail, guideId])
                    .catch(console.error);

                onSave();
            },
            onError: () => {
                enqueueSnackbar("An error occurred while editing the step. Please try again.", {
                    variant: "error",
                });
            },
        });

    const optimisticallyUpdateTooltipConfig = useTooltipConfigOptimisticUpdate(guideId, step.id);

    const isLoading =
        isQueryLoading || isSetTooltipConfigMutationLoading || isEditStepMutationLoading;

    const handleSave = async (validFormState: FormState) => {
        await optimisticallyUpdateTooltipConfig({
            placement: validFormState.placement,
            offset: Number(validFormState.offset),
        });

        await Promise.all([
            editStep({
                title: validFormState.title,
                description: validFormState.description,
            }),
            setTooltipConfig({
                variables: {
                    input: {
                        stepId: step.id,
                        placement: validFormState.placement,
                        offset: Number(validFormState.offset),
                    },
                },
            }),
        ]);

        onSave();
    };

    const titleInputId = useId();
    const descriptionInputId = useId();

    return (
        <Popover
            anchorOrigin={{
                vertical: "center",
                horizontal: "center",
            }}
            transformOrigin={{
                vertical: "center",
                horizontal: "center",
            }}
            onClose={(...args) => {
                reset();
                onClose?.(...args);
            }}
            {...popoverProps}
            classes={{ paper: styles.paper }}
        >
            <h3 className={styles.heading}>Edit step</h3>
            <label className={styles.label} htmlFor={titleInputId}>
                Title
            </label>
            <Controller
                name="title"
                control={control}
                rules={{
                    required: {
                        value: true,
                        message: "Title is required",
                    },
                    maxLength: {
                        value: STEP_TITLE_MAX_LENGTH,
                        message: `Title cannot be longer than ${STEP_TITLE_MAX_LENGTH} characters`,
                    },
                }}
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        id={titleInputId}
                        className={styles.titleTextField}
                        inputProps={{ className: styles.titleTextFieldInput }}
                        error={!!error}
                        helperText={error?.message}
                        {...field}
                    />
                )}
            />
            <label className={styles.label} htmlFor={descriptionInputId}>
                Description
            </label>
            <Controller
                name="description"
                control={control}
                render={({ field }) => (
                    <TextField
                        id={descriptionInputId}
                        className={styles.descriptionTextField}
                        inputProps={{ className: styles.descriptionTextFieldInput }}
                        multiline
                        minRows={3}
                        {...field}
                    />
                )}
            />
            <h4 className={styles.sectionHeading}>Tooltip position</h4>
            <div className={styles.tooltipInstructions}>
                Set this tooltip&rsquo;s position relative to the highlight.
            </div>
            <div className={styles.tooltipOptions}>
                <Controller
                    name="placement"
                    control={control}
                    render={({ field }) => (
                        <Select
                            size="small"
                            disabled={isLoading}
                            inputProps={{ "aria-label": "Placement" }}
                            className={styles.tooltipPlacementSelect}
                            SelectDisplayProps={{
                                className: styles.tooltipPlacementSelectDisplay,
                            }}
                            {...field}
                        >
                            <TooltipPlacementMenuItem value="TOP">Top</TooltipPlacementMenuItem>
                            <TooltipPlacementMenuItem value="BOTTOM">
                                Bottom
                            </TooltipPlacementMenuItem>
                            <TooltipPlacementMenuItem value="LEFT">Left</TooltipPlacementMenuItem>
                            <TooltipPlacementMenuItem value="RIGHT">Right</TooltipPlacementMenuItem>
                        </Select>
                    )}
                />
                <Controller
                    name="offset"
                    control={control}
                    rules={{
                        required: {
                            value: true,
                            message: `Tooltip offset must be numeric`,
                        },
                        min: {
                            value: MIN_OFFSET,
                            message: `Tooltip offset must be greater or equal to ${MIN_OFFSET}`,
                        },
                        max: {
                            value: MAX_OFFSET,
                            message: `Tooltip offset must be less than or equal to ${MAX_OFFSET}`,
                        },
                    }}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            id={descriptionInputId}
                            size="small"
                            type="number"
                            className={styles.tooltipOffsetTextField}
                            inputProps={{
                                min: MIN_OFFSET,
                                max: MAX_OFFSET,
                                "aria-label": "Offset",
                                className: styles.tooltipOffsetInput,
                            }}
                            disabled={isLoading}
                            error={!!error}
                            helperText={error?.message}
                            {...field}
                        />
                    )}
                />
            </div>
            <div className={styles.footer}>
                <Button
                    variant="outlined"
                    size="small"
                    onClick={() => {
                        reset();
                        onCancel();
                    }}
                    className={styles.cancelButton}
                >
                    Cancel
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    onClick={handleSubmit(handleSave)}
                    disabled={isLoading}
                    className={styles.saveButton}
                >
                    Save
                </Button>
            </div>
        </Popover>
    );
}

const TooltipPlacementMenuItem = (
    props: Omit<MenuItemProps, "value"> & { value: TooltipPlacement },
) => <MenuItem {...props} />;

export default EditStepPopover;
