import { CircularProgress } from "@mui/material";
import { DateTime } from "luxon";
import React, { ReactElement } from "react";

import AnonymousAvatar from "../AnonymousAvatar/AnonymousAvatar";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import PersonAvatar from "../PersonAvatar/PersonAvatar";

import { AnonymousEngagementInfo, PersonEngagementInfo } from "./EngagementInsightPopover";
import {
    EngagementPanelSection,
    EngagementPanelSectionLabel,
    EngagementPanelSectionValue,
} from "./EngagementPanelSection";
import { useGuideViewsSummaryQuery } from "./operations.generated";
import styles from "./UserEngagementPanel.module.scss";

interface Props {
    guideId: string;
    info: PersonEngagementInfo | AnonymousEngagementInfo;
}

function UserEngagementPanel(props: Props): ReactElement {
    const { guideId, info } = props;
    const { data, loading, error, refetch } = useGuideViewsSummaryQuery({
        variables: {
            guideId,
            personId: info.type === "person" ? info.viewer.id : undefined,
            viewEventId: info.type === "anonymous" ? info.guideViewEvent.id : undefined,
        },
    });

    const viewerName = info.type === "anonymous" ? "Anonymous" : info.viewer.fullName;

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                {info.type === "person" ? (
                    <PersonAvatar
                        person={{
                            first_name: info.viewer.firstName || "",
                            last_name: info.viewer.lastName || "",
                            avatar_url: info.viewer.avatarUrl || "",
                        }}
                        className={styles.avatar}
                    />
                ) : (
                    <AnonymousAvatar className={styles.avatar} />
                )}
                {viewerName}
            </div>
            {loading && <CircularProgress className={styles.loader} />}
            {error && <ErrorMessage retry={refetch} className={styles.errorMessage} />}
            {data && (
                <>
                    <EngagementPanelSection>
                        <EngagementPanelSectionValue>
                            {data.guideViewsSummary?.viewsCount}
                        </EngagementPanelSectionValue>
                        <EngagementPanelSectionLabel>
                            Guide view{data.guideViewsSummary?.viewsCount !== 1 && "s"}
                        </EngagementPanelSectionLabel>
                    </EngagementPanelSection>
                    <EngagementPanelSection>
                        <EngagementPanelSectionValue>
                            {Math.round((data.guideViewsSummary?.averageCompletionRate || 0) * 100)}
                            %
                        </EngagementPanelSectionValue>
                        <EngagementPanelSectionLabel>
                            Average steps completed
                        </EngagementPanelSectionLabel>
                    </EngagementPanelSection>
                    {data.guideViewsSummary?.guideViewTimes && (
                        <EngagementPanelSection>
                            <div className={styles.viewTimesHeading}>View times</div>
                            {data.guideViewsSummary.guideViewTimes.map((viewTime) => (
                                <div key={viewTime} className={styles.viewTime}>
                                    {formatTimestamp(viewTime)}
                                </div>
                            ))}
                        </EngagementPanelSection>
                    )}
                </>
            )}
        </div>
    );
}

function formatTimestamp(timestamp: string): string {
    const dateTime = DateTime.fromISO(timestamp);
    const formattedDate = dateTime.toLocaleString(DateTime.DATE_MED);
    const formattedTime = dateTime.toLocaleString(DateTime.TIME_SIMPLE);
    return `${formattedDate} • ${formattedTime}`;
}

export default UserEngagementPanel;
