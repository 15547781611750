import { LinearProgress } from "@mui/material";
import cx from "classnames";
import React, { ReactElement, useContext } from "react";

import { UserContext } from "../../contexts/user";
import { formatInteger } from "../../util/StringUtil";

import styles from "./SharedGuideLimitProgressBar.module.scss";

function SharedGuideLimitProgressBar(): ReactElement | null {
    const {
        organization: { subscription },
    } = useContext(UserContext);
    if (
        !subscription ||
        subscription.tier !== "FREE" ||
        !subscription.shared_guide_limit_stats.usage ||
        !subscription.shared_guide_limit_stats.limit
    ) {
        return null;
    }

    const { usage, limit } = subscription.shared_guide_limit_stats;
    const percentUsage = Math.min((usage / limit) * 100, 100);

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div>Public guides</div>
                <div>
                    {/* Usage shown on the UI may exceed the limit. For example, "5/3". */}
                    {formatInteger(usage)}/{formatInteger(limit)}
                </div>
            </div>
            <LinearProgress
                variant="determinate"
                value={percentUsage}
                classes={{
                    root: styles.linearProgressRoot,
                    bar: cx(styles.linearProgressBar, {
                        [styles.full]: usage >= limit,
                    }),
                }}
            />
        </div>
    );
}

export default SharedGuideLimitProgressBar;
