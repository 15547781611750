import React, { ReactElement, ReactNode, RefObject, createContext, useCallback } from "react";

import { isFullscreenEnabled, useFullscreenStatus } from "../hooks/fullscreenStatus";

interface FullscreenContextState {
    toggleFullscreen: () => void;
    isFullscreen: boolean;
}

export const FullscreenContext = createContext<FullscreenContextState>({
    toggleFullscreen: () => {},
    isFullscreen: false,
});

interface ContextProviderProps {
    children: ReactNode;
    fullscreenableElRef: RefObject<Element>;
}

export function FullscreenContextProvider(props: ContextProviderProps): ReactElement {
    const { children, fullscreenableElRef } = props;

    const isFullscreen = useFullscreenStatus();

    const toggleFullscreen = useCallback(() => {
        if (!isFullscreenEnabled()) {
            fullscreenableElRef.current?.requestFullscreen().catch(console.error);
        } else if (document.exitFullscreen) {
            document.exitFullscreen().catch(console.error);
        }
    }, [fullscreenableElRef]);

    return (
        <FullscreenContext.Provider value={{ toggleFullscreen, isFullscreen }}>
            {children}
        </FullscreenContext.Provider>
    );
}
