import { BlurOn } from "@mui/icons-material";
import React, { ReactElement, useCallback, useState, useContext } from "react";

import { useSubscriptionTier } from "../../hooks/subscriptionTier";
import { UserContext } from "../../contexts/user";
import useAmplitudeTracker from "../../hooks/useAmplitudeTracker";
import CrownYellow from "../../icons/crown-yellow.svg";
import ProFeaturePopover from "../ProFeaturePopover/ProFeaturePopover";
import RedactImageDialog from "../RedactImageDialog/RedactImageDialog";

import ToolbarButton from "./ToolbarButton";
import styles from "./ToolbarButton.module.scss";

interface Props {
    guideId: string;
    step: {
        id: string;
        screenshot_url: string;
    };
}

function RedactButton(props: Props): ReactElement {
    const { guideId, step } = props;

    const { track } = useAmplitudeTracker();
    const subscriptionTier = useSubscriptionTier();
    const {
        organization: { subscription },
    } = useContext(UserContext);
    const is_trial = subscription ? subscription.is_trial : false;
    const [isRedactDialogOpen, setIsRedactDialogOpen] = useState(false);
    const [upgradeToRedactPopoverAnchorEl, setUpgradeToRedactPopoverAnchorEl] =
        useState<HTMLElement | null>(null);

    const isRedactionEnabled = subscriptionTier !== "FREE";
    const shouldShowCrownIcon = subscriptionTier === "FREE" || is_trial;

    const onClick = useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            if (isRedactionEnabled) {
                setIsRedactDialogOpen(true);
            } else {
                setUpgradeToRedactPopoverAnchorEl(event.currentTarget);
            }
            track("Redact Sensitive Info Button Clicked");
        },
        [track, isRedactionEnabled],
    );

    return (
        <>
            <ToolbarButton title="Blur sensitive content" onClick={onClick}>
                <BlurOn />
                {shouldShowCrownIcon && (
                    <img src={CrownYellow} alt="" className={styles.crownIcon} />
                )}
            </ToolbarButton>
            <RedactImageDialog
                isOpen={isRedactDialogOpen}
                guideId={guideId}
                stepId={step.id}
                onClose={() => setIsRedactDialogOpen(false)}
                imageUrl={step.screenshot_url}
            />
            <ProFeaturePopover
                open={Boolean(upgradeToRedactPopoverAnchorEl)}
                anchorEl={upgradeToRedactPopoverAnchorEl}
                onClose={() => setUpgradeToRedactPopoverAnchorEl(null)}
                title="Blur sensitive info"
                description="Automatically scan for sensitive information and blur it out of your screenshots for extra security."
            />
        </>
    );
}

export default RedactButton;
