import { Stop } from "@mui/icons-material";
import { ButtonBase, Tooltip } from "@mui/material";
import React, { ReactElement, useCallback, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import useMediaRecorder from "../../hooks/mediaRecorder";
import { humanizeDuration } from "../../util/TimeUtil";

import NarrationControls from "./NarrationControls";
import styles from "./Narrator.module.scss";
import RecordButton from "./RecordButton";
import Separator from "./Separator";
import useDeleteNarration from "./deleteNarration";
import useSynthesizeNarration from "./synthesizeNarration";
import useUploadNarration from "./uploadNarration";

function Narrator({
    guideId,
    stepId,
    narration,
}: {
    guideId: string;
    stepId: string;
    narration: {
        id: string;
        url: string;
    } | null;
}): ReactElement {
    const audioRef = useRef<HTMLAudioElement>(null);
    const { uploadNarration, loading: uploadNarrationLoading } = useUploadNarration(
        guideId,
        stepId,
    );
    const { synthesizeNarration, loading: synthesizeNarrationLoading } = useSynthesizeNarration(
        guideId,
        stepId,
    );

    const loading = uploadNarrationLoading || synthesizeNarrationLoading;

    const [selectedDeviceId, setSelectedDeviceId] = useState<string | undefined>();
    const {
        start,
        stop,
        reset,
        duration,
        recording,
        audioURL: tempAudioURL,
    } = useMediaRecorder({
        deviceId: selectedDeviceId,
        onComplete: (blob) => {
            const file = new File([blob], `${uuidv4()}-narration.webm`);
            uploadNarration(file);
        },
    });

    const [deleteNarration] = useDeleteNarration(guideId);

    const handleDelete = useCallback(() => {
        audioRef.current?.pause();
        reset();
        if (narration) {
            deleteNarration({ variables: { narrationId: narration.id } }).catch(console.error);
        }
    }, [audioRef, reset, deleteNarration, narration]);

    const audioURL = narration?.url || tempAudioURL;

    return (
        <>
            {audioURL ? (
                <NarrationControls
                    ref={audioRef}
                    src={audioURL}
                    disabled={loading}
                    onDelete={handleDelete}
                />
            ) : recording ? (
                <div className={styles.activeRecording}>
                    <Tooltip
                        title="Stop voiceover"
                        placement="top"
                        classes={{ tooltip: styles.tooltip }}
                    >
                        <ButtonBase onClick={stop} className={styles.stopButton}>
                            <Stop fontSize="small" className={styles.stopIcon} />
                            <div className={styles.activeRecordingTime}>
                                {humanizeDuration(duration)}
                            </div>
                        </ButtonBase>
                    </Tooltip>
                    <Separator />
                </div>
            ) : (
                <RecordButton
                    onRecord={start}
                    onSynthesize={synthesizeNarration}
                    onStop={stop}
                    onSelectDevice={setSelectedDeviceId}
                    selectedDeviceId={selectedDeviceId}
                    disabled={loading}
                />
            )}
        </>
    );
}

export default Narrator;
