import React, { ReactElement } from "react";

import AddScreenshotsButton from "./AddScreenshotsButton";
import styles from "./AddStepsButtons.module.scss";
import UploadVideoButton from "./UploadVideoButton";

interface Props {
    stepsInsertionOrder: number;
    guideId: string;
}

function AddStepsButtons({ guideId, stepsInsertionOrder }: Props): ReactElement {
    return (
        <div className={styles.addButtonsContainer}>
            <div className={styles.label}>Add more steps</div>
            <AddScreenshotsButton guideId={guideId} stepsInsertionOrder={stepsInsertionOrder} />
            <UploadVideoButton guideId={guideId} stepsInsertionOrder={stepsInsertionOrder} />
        </div>
    );
}

export default AddStepsButtons;
