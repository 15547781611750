import cx from "classnames";
import React, { ReactElement, useContext } from "react";
import { Link } from "react-router-dom";

import { UserContext } from "../../contexts/user";
import { isGuideCustomizationEnabled } from "../../hooks/featureAccess";
import drivewayLogo from "../../icons/driveway-logo.svg";
import guidesIcon from "../../icons/guides.svg";
import teamIcon from "../../icons/people.svg";
import settingsIcon from "../../icons/settings.svg";
import InviteUserButton from "../InviteUserButton/InviteUserButton";
import OrganizationLogo from "../OrganizationLogo/OrganizationLogo";
import SubscriptionBanner from "../SubscriptionBanner/SubscriptionBanner";

import NavigationRow from "./NavigationRow";
import styles from "./NavigationSidebar.module.scss";

interface Props {
    className?: string;
}

function NavigationSidebar(props: Props): ReactElement {
    const { className } = props;
    return (
        <nav className={cx(styles.sidebar, className)}>
            <OrgUserInfoRow />
            <NavigationRow to="/guides" label="Guides" iconSrc={guidesIcon} />
            <NavigationRow to="/team" label="Team" iconSrc={teamIcon} />
            <NavigationRow to="/settings" label="Settings" iconSrc={settingsIcon} />
            <div className={styles.divider} />
            <SubscriptionBanner className={styles.subscriptionBanner} />
            <InviteUserButton buttonType="secondary" className={styles.inviteButton} />
        </nav>
    );
}

function OrgUserInfoRow(): ReactElement {
    const { organization } = useContext(UserContext);
    const logoUrl =
        isGuideCustomizationEnabled(organization) && organization.logo_url
            ? organization.logo_url
            : drivewayLogo;
    return (
        <div className={styles.orgUserInfoRow}>
            <Link to="/" className={styles.orgInfo}>
                <OrganizationLogo
                    className={styles.orgLogo}
                    name={organization.organization_name}
                    src={logoUrl}
                />
                <div className={styles.orgName}>{organization.organization_name || "Driveway"}</div>
            </Link>
        </div>
    );
}

export default NavigationSidebar;
