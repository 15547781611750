import React, { ReactElement, ReactNode, useContext } from "react";
import { Navigate, Outlet, Route, Routes, useParams } from "react-router-dom";

import SidebarLayout from "../../components/SidebarLayout/SidebarLayout";
import { UserContext } from "../../contexts/user";
import NotFoundPage from "../../pages/global/NotFoundPage";
import LiveViewRedirectPage from "../../pages/live-view-redirect/LiveViewRedirectPage";
import GuideDetailEmbedPage from "../../pages/onboarding/guide-detail-embed/GuideDetailEmbedPage";
import GuideDetailPublicPage from "../../pages/onboarding/guide-detail-public/GuideDetailPublicPage";
import GuideDetailPage from "../../pages/onboarding/guide-detail/GuideDetailPage";
import GuideListPage from "../../pages/onboarding/guide-list/GuideListPage";
import PasswordChangePage from "../../pages/onboarding/settings/PasswordChangePage";
import SettingsPage from "../../pages/onboarding/settings/SettingsPage";
import TeamPage from "../../pages/onboarding/team/TeamPage";
import UploadGuidePage from "../../pages/onboarding/upload-guide/UploadGuidePage";
import WidgetTestPage from "../../pages/onboarding/widget-test/WidgetTestPage";
import VideoPage from "../../pages/video-page/VideoPage";
import FullScreenError from "../FullScreenError/FullScreenError";

function RootLayout(): ReactElement {
    return (
        <SidebarLayout>
            <Outlet />
        </SidebarLayout>
    );
}

interface AuthRouteProps {
    children?: ReactNode;
}

function AuthRoute(props: AuthRouteProps): ReactElement {
    const { children } = props;
    const { isAuthenticated, organization, id } = useContext(UserContext);

    if (!isAuthenticated) {
        // Current Login Route is a backend route
        // and so we need to hit the server again to render a different template
        const nextPath = window.location.pathname.includes("/login")
            ? "/guides"
            : window.location.pathname;
        window.location.replace(`/accounts/login/?next=${nextPath}`);
        return <></>;
    }

    if (!organization.id) {
        console.error(`No organization found for user ${id}`);
        return <FullScreenError />;
    }

    if (children) return <>{children}</>;

    return <Outlet />;
}

function withAuth(el: ReactElement) {
    return <AuthRoute>{el}</AuthRoute>;
}

// TODO: update GuideDetailPage to handle both authed and unauthed states
function AuthDependentGuidePage(): ReactElement {
    const { isAuthenticated } = useContext(UserContext);

    if (isAuthenticated) {
        return <GuideDetailPage />;
    } else {
        return <GuideDetailPublicPage />;
    }
}

function RedirectToGuidePage(): ReactElement {
    const { guideId } = useParams();
    return <Navigate replace to={`/guides/${guideId}`} />;
}

function MainRouter(): ReactElement {
    return (
        <Routes>
            <Route path="/" element={<RootLayout />}>
                <Route path="/" element={<Navigate to={"guides"} replace />} />
                <Route path="guides">
                    <Route index element={withAuth(<GuideListPage />)} />
                    <Route path=":guideId" element={<AuthDependentGuidePage />} />
                    <Route path=":guideId/embed" element={<GuideDetailEmbedPage />} />
                    <Route path=":guideId/public" element={<RedirectToGuidePage />} />
                    <Route path=":guideId/play" element={<LiveViewRedirectPage />} />
                    <Route path="upload" element={withAuth(<UploadGuidePage />)} />
                </Route>
                <Route path="videos/:videoId" element={<VideoPage />} />
                <Route path="team" element={withAuth(<TeamPage />)} />
                <Route path="widget-test" element={withAuth(<WidgetTestPage />)} />
                <Route path="settings">
                    <Route index element={withAuth(<SettingsPage />)} />
                    <Route path="password/change" element={withAuth(<PasswordChangePage />)} />
                </Route>
                <Route path="account" element={<Navigate to="/settings" replace />} />
                <Route
                    path="account/password/change"
                    element={<Navigate to="/settings/password/change" replace />}
                />
                <Route path="*" element={withAuth(<NotFoundPage />)} />
            </Route>
        </Routes>
    );
}

export default MainRouter;
