import semver from "semver";

export function isBigListCaptureDataEnabled(extensionVersion: string): boolean {
    return semver.gte(extensionVersion, "1.6.0-beta");
}

export function isAddStepsFeatureEnabled(extensionVersion: string): boolean {
    return semver.gte(extensionVersion, "1.4.0");
}

export function isCreateGuideDialogFeatureEnabled(extensionVersion: string): boolean {
    return semver.gte(extensionVersion, "1.5.0");
}

export function isInterestPointScalingFixEnabled(extensionVersion: string): boolean {
    // Prior to Chrome extension v1.19.7, interest points were scaled incorrectly if the user's page
    // was zoomed in or out when the guide was captured
    return semver.gte(extensionVersion, "1.19.7-beta");
}
