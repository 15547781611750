import { RefObject, useEffect, useRef, useState } from "react";

interface Result {
    videoElRef: RefObject<HTMLVideoElement>;
    supportsAutoplay: boolean;
}

export function useAutoplayingVideoRef(shouldAutoplay: boolean): Result {
    const videoElRef = useRef<HTMLVideoElement>(null);
    const [supportsAutoplay, setSupportsAutoplay] = useState(false);

    const videoSrc = videoElRef.current?.src;

    useEffect(() => {
        if (shouldAutoplay && !supportsAutoplay && videoElRef.current) {
            videoElRef.current
                .play()
                .then(() => setSupportsAutoplay(true))
                .catch((e) => {
                    setSupportsAutoplay(false);
                    if (e.name === "NotAllowedError") {
                        console.error(e);
                    }
                });
        }
    }, [shouldAutoplay, supportsAutoplay, videoSrc]);

    return { videoElRef, supportsAutoplay };
}
