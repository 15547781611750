import { Button, Checkbox, MenuItem, Select } from "@mui/material";
import React, { ReactElement, useId, useMemo, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import InfiniteScroll from "react-infinite-scroller";
import { useSearchParams } from "react-router-dom";

import { GuideType } from "../../../../@types/graphql";
import ChromeExtensionButton from "../../../components/ChromeExtensionButton/ChromeExtensionButton";
import CreateGuideButton from "../../../components/CreateGuideButton/CreateGuideButton";
import GuideListFilterButton from "../../../components/GuideListFilterButton/GuideListFilterButton";
import LoaderFooter from "../../../components/LoaderFooter/LoaderFooter";
import NavigationSidebar from "../../../components/NavigationSidebar/NavigationSidebar";
import NetworkingWrapper from "../../../components/NetworkingWrapper/NetworkingWrapper";
import starBlankIcon from "../../../icons/star-blank.svg";
import starFilledIcon from "../../../icons/star-filled.svg";
import { useChromeExtensionInstalledQuery } from "../../../queries/chromeExtensionInstalled";

import EmptyState from "./EmptyState";
import GuideListCard from "./GuideListCard";
import styles from "./GuideListPage.module.scss";
import { GuidesQuery, useGuidesLazyQuery } from "./operations.generated";
import { getEmptyStateContent } from "./utils";

const SORT_BY_MAP = {
    "-updated_at": "Most Recent",
    updated_at: "Oldest First",
    "-num_views": "Most Popular",
    title: "A-Z",
    "-title": "Z-A",
};

const SORT_BY_ORDER: ReadonlyArray<keyof typeof SORT_BY_MAP> = [
    "-updated_at",
    "updated_at",
    "-num_views",
    "title",
    "-title",
];

const Params = {
    AUTHORS: "authors",
    TAGS: "tags",
    STARRED: "starred",
};

const PAGE_SIZE = 48;

function GuideListPage(): ReactElement {
    const [searchParams, setSearchParams] = useSearchParams();

    const authorIdFilter = useMemo(
        () => searchParams.get(Params.AUTHORS)?.split(",") || [],
        [searchParams],
    );
    const tagIdFilter = useMemo(
        () => searchParams.get(Params.TAGS)?.split(",") || [],
        [searchParams],
    );
    const starredFilter = useMemo(
        () => searchParams.get(Params.STARRED) === "true",
        [searchParams],
    );

    const [sortBy, setSortBy] = useState<string>("-updated_at");
    const [allGuides, setAllGuides] = useState<GuideType[]>([]);
    const [loadGuides, { loading: isGuideListLoading, error, refetch, data, called }] =
        useGuidesLazyQuery({ fetchPolicy: "network-only" });

    React.useEffect(() => {
        void loadGuides({
            variables: {
                authors: authorIdFilter.length ? authorIdFilter : null,
                tags: tagIdFilter.length ? tagIdFilter : null,
                onlyStarred: starredFilter,
                orderBy: sortBy,
                first: PAGE_SIZE,
            },
            onCompleted: (data: GuidesQuery) => {
                setAllGuides(data.guides?.edges?.map((v) => v && v.node) as GuideType[]);
            },
        });
    }, [loadGuides, sortBy, authorIdFilter, starredFilter, tagIdFilter]);

    const loadMoreGuides = () => {
        void loadGuides({
            variables: {
                authors: authorIdFilter.length ? authorIdFilter : null,
                tags: tagIdFilter.length ? tagIdFilter : null,
                orderBy: sortBy,
                onlyStarred: starredFilter,
                first: PAGE_SIZE,
                after: data?.guides?.pageInfo.endCursor,
            },
            onCompleted: (data: GuidesQuery) => {
                setAllGuides((state) =>
                    state.concat(data.guides?.edges?.map((v) => v && v.node) as GuideType[]),
                );
            },
        });
    };
    const { data: extensionInstalled, isLoading: isExtensionInstalledLoading } =
        useChromeExtensionInstalledQuery();

    const scrollingContainerRef = useRef<HTMLDivElement>(null);
    const filterEnabled =
        starredFilter ||
        (authorIdFilter && authorIdFilter?.length > 0) ||
        (tagIdFilter && tagIdFilter.length > 0);

    const updateListParam = (key: string, value: Array<string> | null) => {
        value && value.length > 0
            ? searchParams.set(key, value.join(","))
            : searchParams.delete(key);
        setSearchParams(searchParams);
    };

    const updateBooleanParam = (key: string, value: boolean) => {
        value ? searchParams.set(key, value.toString()) : searchParams.delete(key);
        setSearchParams(searchParams);
    };
    const isLoading = (!called && isGuideListLoading) || isExtensionInstalledLoading;
    const isEmpty = !isGuideListLoading && allGuides.length === 0;

    const sortByLabelId = useId();

    return (
        <div className={styles.pageContainer} ref={scrollingContainerRef} data-cy="guide-list-page">
            <Helmet title="Guides" />
            <NavigationSidebar className={styles.sidebar} />
            <div className={styles.container}>
                <div className={styles.content}>
                    <div className={styles.centeredPanel}>
                        <div className={styles.header}>
                            <h1 className={styles.title}>Guides</h1>
                            {isExtensionInstalledLoading ? null : !extensionInstalled ? (
                                <ChromeExtensionButton />
                            ) : (
                                <CreateGuideButton />
                            )}
                        </div>
                        <div className={styles.topMenu}>
                            <div>
                                <GuideListFilterButton
                                    filteredAuthorIds={authorIdFilter}
                                    filteredTagIds={tagIdFilter}
                                    setFilteredAuthorIds={(ids: Array<string> | null) =>
                                        updateListParam(Params.AUTHORS, ids)
                                    }
                                    setFilteredTagIds={(ids: Array<string> | null) =>
                                        updateListParam(Params.TAGS, ids)
                                    }
                                />
                                <Checkbox
                                    checked={starredFilter}
                                    onChange={(e) =>
                                        updateBooleanParam(Params.STARRED, e.target.checked)
                                    }
                                    icon={
                                        <img
                                            src={starBlankIcon}
                                            className={styles.starIconBlank}
                                            alt=""
                                        />
                                    }
                                    checkedIcon={
                                        <img
                                            src={starFilledIcon}
                                            className={styles.starIconFilled}
                                            alt=""
                                        />
                                    }
                                    inputProps={{ "aria-label": "Filter by starred guides" }}
                                    className={styles.starCheckbox}
                                />
                            </div>
                            <div className={styles.sortByContainer}>
                                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                <label className={styles.sortByLabel} id={sortByLabelId}>
                                    Sort by:
                                </label>
                                <Select
                                    labelId={sortByLabelId}
                                    value={sortBy}
                                    variant="outlined"
                                    classes={{ outlined: styles.select }}
                                    onChange={(e) => {
                                        setSortBy(e.target.value as string);
                                    }}
                                    MenuProps={{
                                        classes: {
                                            paper: styles.selectMenuPaper,
                                            list: styles.selectMenuList,
                                        },
                                    }}
                                >
                                    {SORT_BY_ORDER.map((sortByKey) => (
                                        <MenuItem
                                            classes={{
                                                root: styles.menuItem,
                                                selected: styles.menuItemSelected,
                                            }}
                                            key={sortByKey}
                                            value={sortByKey}
                                        >
                                            {SORT_BY_MAP[sortByKey]}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>
                        </div>
                        <NetworkingWrapper error={error} refetch={refetch} loading={isLoading}>
                            {isEmpty ? (
                                <EmptyState
                                    {...getEmptyStateContent(!!extensionInstalled, filterEnabled)}
                                    button={
                                        !extensionInstalled ? (
                                            <ChromeExtensionButton />
                                        ) : (
                                            filterEnabled && (
                                                <Button
                                                    className={styles.clearFiltersButton}
                                                    color="primary"
                                                    variant="contained"
                                                    data-dd-action-name="Clear guide list filters"
                                                    onClick={() =>
                                                        setSearchParams(new URLSearchParams())
                                                    }
                                                >
                                                    Clear all filters
                                                </Button>
                                            )
                                        )
                                    }
                                />
                            ) : (
                                <InfiniteScroll
                                    loadMore={loadMoreGuides}
                                    initialLoad={false}
                                    hasMore={data?.guides?.pageInfo.hasNextPage}
                                    loader={<LoaderFooter key="loader" />}
                                    useWindow={false}
                                    getScrollParent={() => scrollingContainerRef.current}
                                >
                                    <div className={styles.gridContainer}>
                                        {allGuides.map((guide) => (
                                            <GuideListCard key={guide.id} guide={guide} />
                                        ))}
                                    </div>
                                </InfiniteScroll>
                            )}
                        </NetworkingWrapper>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GuideListPage;
