import { DeleteOutline, MoreVert } from "@mui/icons-material";
import { Menu, MenuItem } from "@mui/material";
import React, { useId } from "react";

import styles from "./NarrationControls.module.scss";
import Separator from "./Separator";
import ToolbarButton from "./ToolbarButton";

interface Props {
    src: string;
    disabled?: boolean;
    onDelete: () => void;
}

const NarrationControls = React.forwardRef((props: Props, ref: React.Ref<HTMLAudioElement>) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const audioMenuButtonId = useId();

    return (
        <div className={styles.container}>
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <audio
                className={props.disabled ? styles.disabledAudio : undefined}
                ref={ref}
                src={props.src}
                controls
                controlsList="noplaybackrate nodownload"
            />
            <ToolbarButton
                size="small"
                id={audioMenuButtonId}
                title="Audio menu"
                className={styles.menuButton}
                onClick={(e) => setAnchorEl(e.currentTarget)}
            >
                <MoreVert fontSize="small" />
            </ToolbarButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                classes={{ paper: styles.menu }}
                MenuListProps={{ "aria-labelledby": audioMenuButtonId }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <MenuItem className={styles.menuItem} onClick={props.onDelete}>
                    <DeleteOutline className={styles.menuIcon} fontSize="small" />
                    Delete
                </MenuItem>
            </Menu>
            <Separator />
        </div>
    );
});

NarrationControls.displayName = "NarrationControls";

export default NarrationControls;
