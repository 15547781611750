import { ActionStep } from "../network/responseTypes";

export const interestPointToPanZoomCenterXY = (
    interestPoint: ActionStep["interest_points"][0],
    scale: number,
) => {
    return {
        centerToX: -(interestPoint.position_x + interestPoint.width / 2) * scale,
        centerToY: -(interestPoint.position_y + interestPoint.height / 2) * scale,
    };
};

/*
 * Get the bounds for our image given a scale
 * Adapted from here: https://github.com/prc5/react-zoom-pan-pinch/blob/f6af3cdd63c9dbccbf1728e1ea2418fb4edf6894/src/core/bounds/bounds.utils.ts
 */

type ComponentsSizesType = {
    wrapperWidth: number;
    wrapperHeight: number;
    newContentWidth: number;
    newDiffWidth: number;
    newContentHeight: number;
    newDiffHeight: number;
};

type BoundsType = {
    minPositionX: number;
    maxPositionX: number;
    minPositionY: number;
    maxPositionY: number;
};

function getComponentsSizes(
    wrapperComponent: HTMLDivElement,
    contentComponent: HTMLDivElement,
    newScale: number,
): ComponentsSizesType {
    const wrapperWidth = wrapperComponent.offsetWidth;
    const wrapperHeight = wrapperComponent.offsetHeight;

    const contentWidth = contentComponent.offsetWidth;
    const contentHeight = contentComponent.offsetHeight;

    const newContentWidth = contentWidth * newScale;
    const newContentHeight = contentHeight * newScale;
    const newDiffWidth = wrapperWidth - newContentWidth;
    const newDiffHeight = wrapperHeight - newContentHeight;

    return {
        wrapperWidth,
        wrapperHeight,
        newContentWidth,
        newDiffWidth,
        newContentHeight,
        newDiffHeight,
    };
}

const getBounds = (
    wrapperWidth: number,
    newContentWidth: number,
    diffWidth: number,
    wrapperHeight: number,
    newContentHeight: number,
    diffHeight: number,
): BoundsType => {
    const scaleWidthFactor = wrapperWidth > newContentWidth ? diffWidth * 0.5 : 0;
    const scaleHeightFactor = wrapperHeight > newContentHeight ? diffHeight * 0.5 : 0;

    const minPositionX = wrapperWidth - newContentWidth - scaleWidthFactor;
    const maxPositionX = scaleWidthFactor;
    const minPositionY = wrapperHeight - newContentHeight - scaleHeightFactor;
    const maxPositionY = scaleHeightFactor;

    return { minPositionX, maxPositionX, minPositionY, maxPositionY };
};

export const calculateBounds = (
    wrapperComponent: HTMLDivElement,
    contentComponent: HTMLDivElement,
    newScale: number,
): BoundsType => {
    const {
        wrapperWidth,
        wrapperHeight,
        newContentWidth,
        newDiffWidth,
        newContentHeight,
        newDiffHeight,
    } = getComponentsSizes(wrapperComponent, contentComponent, newScale);

    const bounds = getBounds(
        wrapperWidth,
        newContentWidth,
        newDiffWidth,
        wrapperHeight,
        newContentHeight,
        newDiffHeight,
    );
    return bounds;
};
