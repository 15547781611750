/* eslint-disable */
// THIS IS A GENERATED FILE. DO NOT EDIT DIRECTLY
// To generate new type definitions run `npm run generate-schema-types`
import * as Types from '../../../@types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TooltipConfigQueryVariables = Types.Exact<{
  stepId: Types.Scalars['ID']['input'];
}>;


export type TooltipConfigQuery = { readonly __typename?: 'Query', readonly tooltipConfig?: { readonly __typename?: 'TooltipConfigType', readonly placement: Types.TooltipConfigPlacement, readonly offset: number } | null };

export type SetTooltipConfigMutationVariables = Types.Exact<{
  input: Types.SetTooltipConfigMutationInput;
}>;


export type SetTooltipConfigMutation = { readonly __typename?: 'Mutation', readonly setTooltipConfig?: { readonly __typename?: 'SetTooltipConfigMutation', readonly ok: boolean } | null };


export const TooltipConfigDocument = gql`
    query TooltipConfig($stepId: ID!) {
  tooltipConfig(stepId: $stepId) {
    placement
    offset
  }
}
    `;

/**
 * __useTooltipConfigQuery__
 *
 * To run a query within a React component, call `useTooltipConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useTooltipConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTooltipConfigQuery({
 *   variables: {
 *      stepId: // value for 'stepId'
 *   },
 * });
 */
export function useTooltipConfigQuery(baseOptions: Apollo.QueryHookOptions<TooltipConfigQuery, TooltipConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TooltipConfigQuery, TooltipConfigQueryVariables>(TooltipConfigDocument, options);
      }
export function useTooltipConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TooltipConfigQuery, TooltipConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TooltipConfigQuery, TooltipConfigQueryVariables>(TooltipConfigDocument, options);
        }
export type TooltipConfigQueryHookResult = ReturnType<typeof useTooltipConfigQuery>;
export type TooltipConfigLazyQueryHookResult = ReturnType<typeof useTooltipConfigLazyQuery>;
export type TooltipConfigQueryResult = Apollo.QueryResult<TooltipConfigQuery, TooltipConfigQueryVariables>;
export const SetTooltipConfigDocument = gql`
    mutation SetTooltipConfig($input: SetTooltipConfigMutationInput!) {
  setTooltipConfig(input: $input) {
    ok
  }
}
    `;
export type SetTooltipConfigMutationFn = Apollo.MutationFunction<SetTooltipConfigMutation, SetTooltipConfigMutationVariables>;

/**
 * __useSetTooltipConfigMutation__
 *
 * To run a mutation, you first call `useSetTooltipConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTooltipConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTooltipConfigMutation, { data, loading, error }] = useSetTooltipConfigMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetTooltipConfigMutation(baseOptions?: Apollo.MutationHookOptions<SetTooltipConfigMutation, SetTooltipConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetTooltipConfigMutation, SetTooltipConfigMutationVariables>(SetTooltipConfigDocument, options);
      }
export type SetTooltipConfigMutationHookResult = ReturnType<typeof useSetTooltipConfigMutation>;
export type SetTooltipConfigMutationResult = Apollo.MutationResult<SetTooltipConfigMutation>;
export type SetTooltipConfigMutationOptions = Apollo.BaseMutationOptions<SetTooltipConfigMutation, SetTooltipConfigMutationVariables>;