import React, { ReactElement } from "react";

import EventTrackingGuideSlideshow from "../../../components/EventTrackingGuideSlideshow/EventTrackingGuideSlideshow";
import FullScreenLoader from "../../../components/FullScreenLoader/FullScreenLoader";
import EmbedRestrictedView from "../../../components/GuideEmbed/RestrictedView";
import { SlideshowStatus } from "../../../components/GuideSlideshow/GuideSlideshow";
import { useDynamicIframelyLink } from "../../../hooks/useDynamicIframelyLink";
import { ViewEventSource } from "../../../mutations/recordGuideView";
import { useGuideDetailPublicQuery } from "../../../queries/guideDetailPublic";
import { useStepManager } from "../../../util/GuideUtil";
import { useRequiredParams } from "../../../util/ReactRouterUtil";

import styles from "./GuideDetailEmbedPage.module.scss";

function GuideDetailEmbedPage(): ReactElement {
    const { guideId } = useRequiredParams(["guideId"]);
    const { data: guide, isLoading, error: guideRequestError } = useGuideDetailPublicQuery(guideId);

    const isGuideRestricted =
        !!guideRequestError?.message &&
        ["NOT_FOUND", "FORBIDDEN"].includes(guideRequestError.message);

    const stepCount = guide?.steps.length || 0;
    useDynamicIframelyLink(guideId);
    const {
        stepNumber,
        goToNextStep,
        goToPreviousStep,
        reset: resetStepManager,
        setStepNumber,
    } = useStepManager(stepCount);

    if (isLoading) {
        return <FullScreenLoader />;
    }
    return (
        <div className={styles.pageContainer}>
            <div className={styles.content}>
                {isGuideRestricted && <EmbedRestrictedView />}
                {!isGuideRestricted && guide && (
                    <EventTrackingGuideSlideshow
                        eventSource={ViewEventSource.GUIDE_EMBED}
                        status={SlideshowStatus.Active}
                        guide={guide}
                        stepIndex={stepNumber}
                        onNextStep={goToNextStep}
                        onPreviousStep={goToPreviousStep}
                        onRestart={resetStepManager}
                        onSetStep={setStepNumber}
                        autoZoomScreenshots={false}
                        className={styles.slideshow}
                    />
                )}
            </div>
        </div>
    );
}

export default GuideDetailEmbedPage;
