import React from "react";
import { TextField, TextFieldProps } from "@mui/material";
import cx from "classnames";

import styles from "./TextInput.module.scss";

function TextInput(props: TextFieldProps): React.ReactElement {
    const { label, className, ...otherProps } = props;
    return (
        <div className={cx(styles.container, className)}>
            {label && <div className={styles.label}>{label}</div>}
            <TextField
                variant="outlined"
                fullWidth
                InputProps={{
                    classes: {
                        root: styles.root,
                        input: styles.input,
                    },
                }}
                {...otherProps}
            />
        </div>
    );
}

export default TextInput;
