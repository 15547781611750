import { CircularProgress } from "@mui/material";
import React, { ReactElement } from "react";

import styles from "./FullScreenLoader.module.scss";

function FullScreenLoader(): ReactElement {
    return (
        <div className={styles.container}>
            <CircularProgress />
        </div>
    );
}

export default FullScreenLoader;
