import { Player } from "@lottiefiles/react-lottie-player";
import { Button } from "@mui/material";
import React, { ReactElement } from "react";
import { Link } from "react-router-dom";

import { usePrefersReducedMotion } from "../../hooks/prefersReducedMotion";
import constructionBarricadeAnimation from "../../images/construction-barricade.json";

import styles from "./PageNotFoundErrorMessage.module.scss";

interface Props {
    description?: string;
}

function PageNotFoundErrorMessage(props: Props): ReactElement {
    const { description } = props;

    const isMotionReduced = usePrefersReducedMotion();

    return (
        <div className={styles.container}>
            <div aria-hidden>
                <Player
                    src={constructionBarricadeAnimation}
                    autoplay={!isMotionReduced}
                    loop
                    className={styles.animation}
                />
            </div>
            <h1 className={styles.heading}>Sorry, we can&rsquo;t find that page.</h1>
            {description && <p className={styles.description}>{description}</p>}
            <Button
                size="medium"
                variant="contained"
                color="primary"
                component={Link}
                to="/guides"
                className={styles.goToWorkspaceButton}
            >
                Go to your workspace
            </Button>
        </div>
    );
}

export default PageNotFoundErrorMessage;
