import { Button, ButtonProps } from "@mui/material";
import cx from "classnames";
import React from "react";

import styles from "./PrimaryButton.module.scss";

/**
 * As per the Design System, primary buttons should not have elevation, but they
 * should have a box-shadow of Shadow 4. It's difficult (if not impossible) to
 * disable elevation via Material UI themes but still apply a box-shadow to only
 * buttons of type "primary". The workaround is to use this custom component.
 */
export default React.forwardRef<HTMLButtonElement, ButtonProps>(function PrimaryButton(props, ref) {
    const { classes, className, ...otherProps } = props;
    return (
        <Button
            variant="contained"
            color="primary"
            className={cx(styles.button, className)}
            classes={{
                disableElevation: styles.disableElevation,
                ...classes,
            }}
            ref={ref}
            {...otherProps}
        />
    );
});
