import cx from "classnames";
import React from "react";

import { Guide } from "../../network/responseTypes";
import GuidePreviewInfo from "../GuidePreviewInfo/GuidePreviewInfo";
import GuideSlideshow, { SlideshowStatus } from "../GuideSlideshow/GuideSlideshow";

import styles from "./GuideDemoView.module.scss";

interface Props {
    guide: Guide;
    stepIndex: number;
    onNextStep: () => void;
    onPreviousStep: () => void;
    onRestart: () => void;
    onSetStep: React.Dispatch<React.SetStateAction<number>>;
    canComplete: boolean;
    className?: string;
}

export default function GuideDemoView({ className, ...props }: Props) {
    return (
        <div className={cx(styles.container, className)}>
            <GuideSlideshow
                {...props}
                status={SlideshowStatus.Active}
                className={styles.previewSlideshow}
            />
            <GuidePreviewInfo guide={props.guide} className={styles.guideInfo} />
        </div>
    );
}
