import { useQueryClient } from "@tanstack/react-query";
import cx from "classnames";
import { useSnackbar } from "notistack";
import React, {
    Dispatch,
    HTMLAttributes,
    ReactElement,
    SetStateAction,
    useContext,
    useEffect,
} from "react";
import { DragDropContext, Draggable, DropResult } from "react-beautiful-dnd";
import { Descendant } from "slate";

import AddStepsButtons from "../../components/AddStepsButtons/AddStepsButtons";
import DefaultVideoThumbnail from "../../components/DefaultVideoThumbnail/DefaultVideoThumbnail";
import EditableField from "../../components/EditableField/EditableField";
import PersonAvatar from "../../components/PersonAvatar/PersonAvatar";
import StrictModeDroppable from "../../components/StrictModeDroppable/StrictModeDroppable";
import TagsAutocomplete from "../../components/TagsAutocomplete/TagsAutocomplete";
import { UserContext } from "../../contexts/user";
import clockIcon from "../../icons/clock.svg";
import stepsIcon from "../../icons/steps.svg";
import { useEditGuideMutation } from "../../mutations/editGuide";
import { Guide, Person, Step, Tag } from "../../network/responseTypes";
import { useChromeExtensionVersionQuery } from "../../queries/chromeExtensionVersion";
import { QueryKey } from "../../queries/queryKeys";
import { optimisticUpdate } from "../../util/ReactQueryUtil";
import { slateNodesToString } from "../../util/SlateUtil";
import { isAddStepsFeatureEnabled } from "../../util/VersionUtil";

import styles from "./GuideSidebar.module.scss";
import { useMoveStepMutation } from "./operations.generated";

interface Props {
    guide: Guide;
    stepNumber: number;
    setStepNumber: Dispatch<SetStateAction<number>>;
    goToPreviousStep: () => void;
    goToNextStep: () => void;
    readOnly?: boolean;
    className?: string;
}
interface GuideInfoProps {
    author: Person;
    isUserAuthor: boolean;
    stepCount: number;
    description: ReadonlyArray<Descendant>;
    guideId: string;
    duration?: number | null;
    created_at: string;
    tags: ReadonlyArray<Tag>;
    readOnly?: boolean;
}

function GuideInfo({
    author,
    isUserAuthor,
    stepCount,
    description,
    guideId,
    duration,
    created_at,
    tags,
    readOnly,
}: GuideInfoProps): ReactElement {
    const { first_name, last_name } = author;
    const fullName = `${first_name} ${last_name}`;
    const { mutate: editGuide, isLoading: isEditGuideSubmitting } = useEditGuideMutation(guideId);
    const localizedDate = new Date(created_at).toLocaleDateString("en-US");
    return (
        <div className={styles.guideInfo}>
            <div className={styles.metadata}>
                <div className={styles.stepCount}>
                    <img src={stepsIcon} className={styles.stepCountIcon} alt="" />
                    <span>
                        {stepCount} step{stepCount !== 1 && "s"}
                    </span>
                </div>
                <div className={styles.createdAt}>{localizedDate}</div>
            </div>
            <div className={styles.author}>
                <PersonAvatar person={author} className={styles.authorAvatar} />
                <span>{fullName}</span>
            </div>
            {(isUserAuthor || duration) && (
                <div className={styles.duration}>
                    <img src={clockIcon} className={styles.durationIcon} alt="" />
                    <EditableField
                        value={
                            duration !== undefined && duration !== null
                                ? Math.ceil(duration / 60).toString()
                                : ""
                        }
                        mode="text"
                        validate={(value) => Number(value) >= 0}
                        placeholder="Add duration…"
                        isSubmitting={isEditGuideSubmitting}
                        onSubmit={(value) => editGuide({ duration: value })}
                        readOnly={!isUserAuthor}
                        suffixElement={<span>min</span>}
                        classes={{ field: styles.durationInput }}
                    />
                </div>
            )}
            <TagsAutocomplete
                guideId={guideId}
                tags={tags}
                className={styles.tags}
                readOnly={readOnly}
            />
            <EditableField
                value={slateNodesToString(description)}
                mode="multiline"
                placeholder="Enter a description…"
                isSubmitting={isEditGuideSubmitting}
                onSubmit={(value) => {
                    editGuide({ description: value });
                }}
                readOnly={!isUserAuthor}
                entityId={guideId}
                classes={{ field: styles.descriptionInput }}
            />
        </div>
    );
}

function reorder(list: readonly Step[], startIndex: number, endIndex: number): Step[] {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
}

interface GuideSidebarItemProps extends HTMLAttributes<HTMLDivElement> {
    stepIndex: number;
    step: {
        title: string;
        type: "action" | "video";
        screenshot_url?: string;
    };
}

const GuideSidebarItem = React.forwardRef(
    (
        { onClick, stepIndex, step, ...props }: GuideSidebarItemProps,
        ref: React.ForwardedRef<HTMLDivElement>,
    ) => {
        return (
            // TODO This should use a button, not a div. But using a button
            // overrides react-beautiful-dnd's default keyboard handling,
            // which prevents keyboard-only users from rearranging steps.
            // A better experience would be to provide a drag handle.
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
            <div onClick={onClick} ref={ref} {...props}>
                <div id={`step-${stepIndex}`} className={styles.stepInnerContainer}>
                    <div className={styles.stepNumber}>{stepIndex + 1}</div>
                    <div className={styles.stepInfo}>
                        <div className={styles.stepTitle}>{step.title}</div>
                        {step.type === "action" ? (
                            <img
                                className={cx(styles.stepImage, styles.actionStepImage)}
                                src={step.screenshot_url}
                                alt=""
                            />
                        ) : (
                            <DefaultVideoThumbnail className={styles.stepImage} />
                        )}
                    </div>
                </div>
            </div>
        );
    },
);

GuideSidebarItem.displayName = "GuideSidebarItem";

function GuideSidebar(props: Props): ReactElement {
    const { guide, stepNumber, setStepNumber, readOnly, className } = props;
    const { steps, author, description, id: guideId, created_at, duration, tags } = guide;
    const { id: userId } = useContext(UserContext);
    const userIsAuthor = userId === author.id;

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        const activeStepEl = document.getElementById(`step-${stepNumber}`);
        if (activeStepEl) {
            activeStepEl.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    }, [stepNumber]);

    const reactQueryClient = useQueryClient();
    const [moveStep] = useMoveStepMutation({
        onError: () => {
            enqueueSnackbar(
                "Something went wrong when trying to reorder your steps. Please try again.",
                { variant: "error" },
            );
        },
        onCompleted: (data) => {
            const guideId = data.moveStep?.guide?.id;
            // Remove when GuideDetail is migrated to graphql
            reactQueryClient
                .invalidateQueries([QueryKey.GuideDetail, guideId])
                .catch(console.error);
        },
    });

    const handleDragEnd = (result: DropResult) => {
        const sourceIndex = result.source.index;
        const destinationIndex = result.destination?.index;

        // Step was dropped outside list or dropped in its existing position
        if (destinationIndex === undefined || sourceIndex === destinationIndex) {
            return;
        }

        const movingStep = steps[sourceIndex];
        const anchorStep = steps[destinationIndex];

        optimisticUpdate<Guide>(reactQueryClient, [QueryKey.GuideDetail, guideId], (guide) => ({
            ...guide,
            steps: reorder(steps, sourceIndex, destinationIndex),
        }))
            .then(() =>
                moveStep({
                    variables: {
                        input: {
                            movingStepId: movingStep.id,
                            position: sourceIndex > destinationIndex ? "BEFORE" : "AFTER",
                            anchorStepId: anchorStep.id,
                        },
                    },
                }),
            )
            .catch(console.error);
    };

    const { data: extensionVersion } = useChromeExtensionVersionQuery();

    const addingStepsEnabled =
        !readOnly && userIsAuthor && extensionVersion && isAddStepsFeatureEnabled(extensionVersion);

    return (
        <div className={cx(styles.container, className)}>
            <GuideInfo
                guideId={guideId}
                isUserAuthor={userIsAuthor}
                author={author}
                stepCount={steps.length}
                description={description}
                duration={duration}
                tags={tags}
                created_at={created_at}
                readOnly={readOnly}
            />
            {addingStepsEnabled ? (
                <div>
                    <AddStepsButtons guideId={guide.id} stepsInsertionOrder={stepNumber} />
                </div>
            ) : null}
            <DragDropContext onDragEnd={handleDragEnd}>
                <StrictModeDroppable droppableId="droppable">
                    {(provided) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className={styles.stepsContainer}
                        >
                            {steps?.map((step, index) => (
                                <Draggable key={step.id} draggableId={step.id} index={index}>
                                    {(provided) => (
                                        <GuideSidebarItem
                                            key={step.id}
                                            onClick={() => setStepNumber(index)}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={{
                                                ...provided.draggableProps.style,
                                            }}
                                            className={cx(styles.stepContainer, {
                                                [styles.active]: index === stepNumber,
                                            })}
                                            step={step}
                                            stepIndex={index}
                                        />
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </StrictModeDroppable>
            </DragDropContext>
        </div>
    );
}

export default GuideSidebar;
