import { Button } from "@mui/material";
import cx from "classnames";
import React from "react";

import OrganizationLogo from "../../components/OrganizationLogo/OrganizationLogo";
import { isGuideCustomizationEnabled } from "../../hooks/featureAccess";
import clockIcon from "../../icons/clock.svg";
import eyeIcon from "../../icons/eye.svg";
import personIcon from "../../icons/person.svg";
import stepsIcon from "../../icons/steps.svg";
import { Guide } from "../../network/responseTypes";
import { slateNodesToString } from "../../util/SlateUtil";

import styles from "./InactiveView.module.scss";

interface Props {
    guide: Guide;
    onStartGuide?: () => void;
    className?: string;
}

const EmbedInactiveView = (props: Props) => {
    const { guide, onStartGuide, className } = props;
    const { organization, author, title, duration, num_views: numViews, description } = guide;

    const stepCount = guide.steps.length;
    const durationMinutes = duration ? Math.ceil(duration / 60) : null;
    const plaintextDescription = description ? slateNodesToString(description) : "";

    return (
        <div className={cx(styles.container, className)}>
            {isGuideCustomizationEnabled(organization) && !!organization.logo_url && (
                <OrganizationLogo
                    src={organization.logo_url}
                    name={organization.organization_name}
                />
            )}
            <h1 className={styles.title}>{title}</h1>
            <ul className={styles.metadata}>
                <li>
                    <img src={stepsIcon} alt="" className={styles.icon} />
                    {stepCount} step{stepCount !== 1 && "s"}
                </li>
                <li>
                    <img src={personIcon} alt="" className={styles.icon} />
                    {author.first_name} {author.last_name}
                </li>
                {durationMinutes && (
                    <li>
                        <img src={clockIcon} alt="" className={styles.icon} />
                        {durationMinutes} min{durationMinutes !== 1 && "s"}
                    </li>
                )}
                <li>
                    <img src={eyeIcon} alt="" className={styles.icon} />
                    {numViews} view{numViews !== 1 && "s"}
                </li>
            </ul>
            {plaintextDescription && (
                <div className={styles.description}>{plaintextDescription}</div>
            )}
            {stepCount > 0 && (
                <Button color="primary" variant="contained" onClick={onStartGuide}>
                    Start guide
                </Button>
            )}
        </div>
    );
};

export default EmbedInactiveView;
