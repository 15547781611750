/* eslint-disable */
// THIS IS A GENERATED FILE. DO NOT EDIT DIRECTLY
// To generate new type definitions run `npm run generate-schema-types`
import * as Types from '../../../@types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UploadOrganizationLogoMutationVariables = Types.Exact<{
  file: Types.Scalars['Upload']['input'];
}>;


export type UploadOrganizationLogoMutation = { readonly __typename?: 'Mutation', readonly uploadOrganizationLogo?: { readonly __typename?: 'UploadOrganizationLogoMutation', readonly ok?: boolean | null } | null };

export type UploadOrganizationWordmarkMutationVariables = Types.Exact<{
  file: Types.Scalars['Upload']['input'];
}>;


export type UploadOrganizationWordmarkMutation = { readonly __typename?: 'Mutation', readonly uploadOrganizationWordmark?: { readonly __typename?: 'UploadOrganizationWordmarkMutation', readonly ok?: boolean | null } | null };


export const UploadOrganizationLogoDocument = gql`
    mutation UploadOrganizationLogo($file: Upload!) {
  uploadOrganizationLogo(file: $file) {
    ok
  }
}
    `;
export type UploadOrganizationLogoMutationFn = Apollo.MutationFunction<UploadOrganizationLogoMutation, UploadOrganizationLogoMutationVariables>;

/**
 * __useUploadOrganizationLogoMutation__
 *
 * To run a mutation, you first call `useUploadOrganizationLogoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadOrganizationLogoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadOrganizationLogoMutation, { data, loading, error }] = useUploadOrganizationLogoMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadOrganizationLogoMutation(baseOptions?: Apollo.MutationHookOptions<UploadOrganizationLogoMutation, UploadOrganizationLogoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadOrganizationLogoMutation, UploadOrganizationLogoMutationVariables>(UploadOrganizationLogoDocument, options);
      }
export type UploadOrganizationLogoMutationHookResult = ReturnType<typeof useUploadOrganizationLogoMutation>;
export type UploadOrganizationLogoMutationResult = Apollo.MutationResult<UploadOrganizationLogoMutation>;
export type UploadOrganizationLogoMutationOptions = Apollo.BaseMutationOptions<UploadOrganizationLogoMutation, UploadOrganizationLogoMutationVariables>;
export const UploadOrganizationWordmarkDocument = gql`
    mutation UploadOrganizationWordmark($file: Upload!) {
  uploadOrganizationWordmark(file: $file) {
    ok
  }
}
    `;
export type UploadOrganizationWordmarkMutationFn = Apollo.MutationFunction<UploadOrganizationWordmarkMutation, UploadOrganizationWordmarkMutationVariables>;

/**
 * __useUploadOrganizationWordmarkMutation__
 *
 * To run a mutation, you first call `useUploadOrganizationWordmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadOrganizationWordmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadOrganizationWordmarkMutation, { data, loading, error }] = useUploadOrganizationWordmarkMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadOrganizationWordmarkMutation(baseOptions?: Apollo.MutationHookOptions<UploadOrganizationWordmarkMutation, UploadOrganizationWordmarkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadOrganizationWordmarkMutation, UploadOrganizationWordmarkMutationVariables>(UploadOrganizationWordmarkDocument, options);
      }
export type UploadOrganizationWordmarkMutationHookResult = ReturnType<typeof useUploadOrganizationWordmarkMutation>;
export type UploadOrganizationWordmarkMutationResult = Apollo.MutationResult<UploadOrganizationWordmarkMutation>;
export type UploadOrganizationWordmarkMutationOptions = Apollo.BaseMutationOptions<UploadOrganizationWordmarkMutation, UploadOrganizationWordmarkMutationVariables>;