import { escapeRegExp } from "lodash";

import { unescapeForwardSlash, unescapeRegExp } from "./StringUtil";

export type StringPattern =
    | { type: Extract<PatternType, "any"> }
    | { type: Exclude<PatternType, "any">; value: string };

export type PatternType =
    | "any"
    | "is"
    | "is not"
    | "starts with"
    | "ends with"
    | "contains"
    | "does not contain";

interface RegexAffixes {
    prefix: string;
    suffix: string;
}

// Use Map instead of object, since order of keys is important. Some prefixes and suffixes overlap.
const TYPE_TO_REGEX_AFFIX_MAP: ReadonlyMap<Exclude<PatternType, "any">, RegexAffixes> = new Map([
    ["does not contain", { prefix: "^(?:(?!", suffix: ").)*$" }], // ^(?:(?!text).)*$
    ["is not", { prefix: "^(?!", suffix: "$).*$" }], // ^(?!text$).*$
    ["contains", { prefix: "^.*", suffix: ".*$" }], // ^.*text.*$
    ["ends with", { prefix: "^.*", suffix: "$" }], // ^.*text$
    ["starts with", { prefix: "^", suffix: ".*$" }], // ^text.*$
    ["is", { prefix: "^", suffix: "$" }], // ^text$
]);

// Regular expression that matches any string not containing line breaks
const anyRegex = new RegExp("^.*$");

export function stringPatternToRegExp(stringPattern: StringPattern): RegExp {
    const { type } = stringPattern;

    if (type === "any") {
        return anyRegex;
    }

    const escapedValue = escapeRegExp(stringPattern.value);
    const { prefix = "", suffix = "" } = TYPE_TO_REGEX_AFFIX_MAP.get(type) ?? {};
    return new RegExp(prefix + escapedValue + suffix);
}

export function regExpToStringPattern(regex: RegExp): StringPattern {
    const { source } = regex;

    if (source === anyRegex.source) {
        return { type: "any" };
    }

    const [type] =
        [...TYPE_TO_REGEX_AFFIX_MAP.entries()].find(([, affixes]) => {
            const { prefix, suffix } = affixes;
            return source.startsWith(prefix) && source.endsWith(suffix);
        }) ?? [];

    if (!type) {
        // Default to any if this pattern is not recognized
        return { type: "any" };
    }

    const { prefix = "", suffix = "" } = TYPE_TO_REGEX_AFFIX_MAP.get(type) ?? {};

    const value = unescapeRegExp(
        // The source property of RegExp "is the text between the two forward slashes in the regular
        // expression literal". Because a / is a special character in a regular expression literal,
        // forward slash characters are escaped in the string returned by the source property.
        // See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/source#description
        unescapeForwardSlash(source.substring(prefix.length, source.length - suffix.length)),
    );
    return { type, value };
}
