/* eslint-disable */
// THIS IS A GENERATED FILE. DO NOT EDIT DIRECTLY
// To generate new type definitions run `npm run generate-schema-types`
import * as Types from '../../../@types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DuplicateGuideMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DuplicateGuideMutation = { readonly __typename?: 'Mutation', readonly duplicateGuide?: { readonly __typename?: 'DuplicateGuideMutation', readonly guide?: { readonly __typename?: 'GuideType', readonly id: string } | null } | null };


export const DuplicateGuideDocument = gql`
    mutation DuplicateGuide($id: ID!) {
  duplicateGuide(id: $id) {
    guide {
      id
    }
  }
}
    `;
export type DuplicateGuideMutationFn = Apollo.MutationFunction<DuplicateGuideMutation, DuplicateGuideMutationVariables>;

/**
 * __useDuplicateGuideMutation__
 *
 * To run a mutation, you first call `useDuplicateGuideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateGuideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateGuideMutation, { data, loading, error }] = useDuplicateGuideMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDuplicateGuideMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateGuideMutation, DuplicateGuideMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateGuideMutation, DuplicateGuideMutationVariables>(DuplicateGuideDocument, options);
      }
export type DuplicateGuideMutationHookResult = ReturnType<typeof useDuplicateGuideMutation>;
export type DuplicateGuideMutationResult = Apollo.MutationResult<DuplicateGuideMutation>;
export type DuplicateGuideMutationOptions = Apollo.BaseMutationOptions<DuplicateGuideMutation, DuplicateGuideMutationVariables>;