import cx from "classnames";
import React, { ReactElement, ReactNode } from "react";

import styles from "./PlaceholderMessage.module.scss";

interface Props {
    icon?: string | ReactElement<unknown>;
    title: string;
    className?: string;
    children?: ReactNode;
}

function PlaceholderMessage(props: Props): ReactElement {
    const { icon, title, className, children } = props;
    return (
        <div className={cx(styles.container, className)}>
            {icon !== undefined &&
                (React.isValidElement(icon) ? (
                    icon
                ) : (
                    <img src={icon} className={styles.icon} alt="" />
                ))}
            <div className={styles.title}>{title}</div>
            {children}
        </div>
    );
}

export default PlaceholderMessage;
