import { ChevronLeft as BackIcon } from "@mui/icons-material";
import { Button } from "@mui/material";
import { Action } from "history";
import React, { ReactElement } from "react";
import { Link, useNavigate, useNavigationType } from "react-router-dom";

import styles from "./GuideBackButton.module.scss";

function GuideBackButton(): ReactElement {
    const navigate = useNavigate();
    const navigationType = useNavigationType();

    const canNavigateBack = navigationType === Action.Push;

    return (
        <Button
            startIcon={<BackIcon />}
            classes={{ root: styles.backButton }}
            component={canNavigateBack ? "button" : Link}
            to={canNavigateBack ? undefined : "/guides"}
            onClick={canNavigateBack ? () => navigate(-1) : undefined}
            data-driveway="guide-back-button"
        >
            All guides
        </Button>
    );
}

export default GuideBackButton;
