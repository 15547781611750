import { useCallback } from "react";

import { AddVideoStepMutationInput } from "../../@types/graphql";
import { useAddVideoStepMutation } from "../components/AddStepsButtons/operations.generated";
import { useUploadVideo } from "../mutations/uploadVideo";

type AddVideoStepVariables = Omit<AddVideoStepMutationInput, "videoS3Key" | "originalFilename"> & {
    file: File;
};

interface AddVideoStepOptions {
    onError?: (error: Error) => void;
    onSuccess?: () => void;
}

export function useAddVideoVideoStep(options: AddVideoStepOptions = {}) {
    const { onError, onSuccess } = options;
    const { mutate: uploadVideo, isLoading: isUploadingVideo } = useUploadVideo();
    const [addVideoStep, { loading: isAddVideoStepLoading }] = useAddVideoStepMutation({
        onError,
        onCompleted: onSuccess,
    });

    const mutate = useCallback(
        (variable: AddVideoStepVariables) => {
            const { guideId, insertionIndex, file } = variable;
            uploadVideo(
                { file },
                {
                    onError,
                    onSuccess: (videoS3Key) => {
                        addVideoStep({
                            variables: {
                                input: {
                                    guideId,
                                    insertionIndex,
                                    videoS3Key,
                                    originalFilename: file.name,
                                },
                            },
                        }).catch(console.error);
                    },
                },
            );
        },
        [addVideoStep, onError, uploadVideo],
    );

    return {
        mutate,
        loading: isAddVideoStepLoading || isUploadingVideo,
    };
}
