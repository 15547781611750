import { Avatar as MuiAvatar } from "@mui/material";
import cx from "classnames";
import React, { ReactElement } from "react";

import { Person } from "../../network/responseTypes";

import styles from "./PersonAvatar.module.scss";

interface Props {
    person: Pick<Person, "avatar_url" | "first_name" | "last_name">;
    className?: string;
}

function PersonAvatar(props: Props): ReactElement {
    const { person, className } = props;
    const { first_name: firstName, last_name: lastName, avatar_url: avatarUrl } = person;

    const fullName = `${firstName} ${lastName}`;
    const initials = `${firstName.substring(0, 1)}${lastName.substring(0, 1)}`;

    return (
        <MuiAvatar
            src={avatarUrl ?? undefined}
            alt={fullName}
            className={cx(styles.avatar, className)}
        >
            {initials}
        </MuiAvatar>
    );
}

export default PersonAvatar;
