import { useEffect } from "react";

export function useDynamicIframelyLink(guideId: string | undefined): void {
    // dynamically add the iframely link to the head element of the document
    useEffect(() => {
        const link = document.createElement("link");
        link.rel = "iframely player";
        link.type = "text/html";
        link.href = guideId
            ? `${window.location.origin}/guides/${guideId}/embed`
            : // fallback to guides page
              `${window.location.origin}/guides`;
        document.head.appendChild(link);
        return () => {
            document.head.removeChild(link);
        };
    }, [guideId]);
}
