import { ButtonProps } from "@mui/material";
import cx from "classnames";
import React, { ReactElement } from "react";

import PrimaryButton from "../PrimaryButton/PrimaryButton";
import SecondaryButton from "../SecondaryButton/SecondaryButton";

import styles from "./TopBarButton.module.scss";

interface Props extends ButtonProps {
    target?: string;
    color?: "primary" | "secondary";
}

function TopBarButton(props: Props): ReactElement {
    const { color, className, classes, ...restProps } = props;
    const ButtonComponent = props.color === "primary" ? PrimaryButton : SecondaryButton;
    return (
        <ButtonComponent
            {...restProps}
            className={cx(
                styles.button,
                color === "primary" ? styles.primary : styles.secondary,
                className,
            )}
            classes={{
                ...classes,
                startIcon: cx(classes?.startIcon, styles.startIcon),
            }}
        />
    );
}

export default TopBarButton;
