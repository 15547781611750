import cx from "classnames";
import React, { ReactElement, ReactNode } from "react";

import styles from "./Card.module.scss";

interface CardProps {
    variant: "content" | "contextual";
    children: ReactNode;
    className?: string;
}

export function Card(props: CardProps): ReactElement {
    const { variant, children, className } = props;
    return (
        <div
            className={cx(
                styles.container,
                {
                    [styles.contentContainer]: variant === "content",
                    [styles.contextualContainer]: variant === "contextual",
                },
                className,
            )}
        >
            {children}
        </div>
    );
}

interface CardTitleProps {
    children: ReactNode;
    className?: string;
}

export function CardTitle(props: CardTitleProps): ReactElement {
    const { children, className } = props;
    return <div className={cx(styles.title, className)}>{children}</div>;
}

interface CardDescriptionProps {
    children: ReactNode;
    className?: string;
}

export function CardDescription(props: CardDescriptionProps): ReactElement {
    const { children, className } = props;
    return <div className={cx(styles.description, className)}>{children}</div>;
}
