import { UseMutationResult, useMutation } from "@tanstack/react-query";
import Axios from "axios";

interface UploadVideoVariables {
    file: File;
}

interface AWSPresignedPost {
    url: string;
    fields: Record<string, string> & {
        key: string;
    };
}

export function useUploadVideo(): UseMutationResult<string, Error, UploadVideoVariables> {
    return useMutation(async ({ file }) => {
        const { data: presignedPost } = await Axios.post<AWSPresignedPost>(
            `/api/video/generate-presigned-post`,
            { file_name: file.name },
        );
        const formData = new FormData();
        Object.entries(presignedPost.fields).forEach(([key, value]) => {
            formData.append(key, value);
        });
        formData.append("file", file);
        await Axios.post(presignedPost.url, formData);
        const videoS3Key = presignedPost.fields.key;
        return videoS3Key;
    });
}
