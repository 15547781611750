import { useEffect, useState } from "react";

export function isFullscreenEnabled(): boolean {
    return document.fullscreenElement !== null;
}

export function useFullscreenStatus(): boolean {
    const [enabled, setEnabled] = useState(isFullscreenEnabled());

    useEffect(() => {
        const listener = () => setEnabled(isFullscreenEnabled());
        window.addEventListener("fullscreenchange", listener);
        return () => window.removeEventListener("fullscreenchange", listener);
    }, []);

    return enabled;
}
