import { Edit as EditIcon, VisibilityOutlined as ShowPreviewIcon } from "@mui/icons-material";
import { IconButtonProps } from "@mui/material";
import React, { ReactElement } from "react";

import TopBarButton from "../TopBarButton/TopBarButton";

interface Props extends Pick<IconButtonProps, "onClick" | "className"> {
    isPreviewing: boolean;
}

function PreviewToggleButton(props: Props): ReactElement {
    const { isPreviewing, onClick, className } = props;
    return (
        <TopBarButton
            onClick={onClick}
            className={className}
            startIcon={isPreviewing ? <EditIcon /> : <ShowPreviewIcon />}
        >
            {isPreviewing ? "Edit" : "Preview"}
        </TopBarButton>
    );
}

export default PreviewToggleButton;
