import { Button, CircularProgress, Icon } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { ReactElement, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import cx from "classnames";
import { useIntercom } from "react-use-intercom";

import OrganizationLogo from "../OrganizationLogo/OrganizationLogo";

import styles from "./LogoUploader.module.scss";
import {
    useUploadOrganizationLogoMutation,
    useUploadOrganizationWordmarkMutation,
} from "./operations.generated";

type Props = {
    logoUrl: string;
    uploadType: "logo" | "wordmark";
};

function LogoUploader(props: Props): ReactElement {
    const { enqueueSnackbar } = useSnackbar();
    const intercom = useIntercom();
    const [uploadLogo, { loading: logoLoading }] = useUploadOrganizationLogoMutation({
        refetchQueries: ["Me"],
        onError: () => {
            enqueueSnackbar(
                "Something went wrong when trying to upload your workspace logo. Please try again.",
                { variant: "error" },
            );
        },
        onCompleted: () => {
            enqueueSnackbar("Your workspace logo has been successfully uploaded.", {
                variant: "success",
            });
        },
    });

    const [uploadWordmark, { loading: wordmarkLoading }] = useUploadOrganizationWordmarkMutation({
        refetchQueries: ["Me"],
        onError: () => {
            enqueueSnackbar(
                "Something went wrong when trying to upload your workspace wordmark. Please try again.",
                { variant: "error" },
            );
        },
        onCompleted: () => {
            enqueueSnackbar("Your workspace wordmark has been successfully uploaded.", {
                variant: "success",
            });
        },
    });

    const uploadFunction = props.uploadType === "logo" ? uploadLogo : uploadWordmark;
    const loading = props.uploadType === "logo" ? logoLoading : wordmarkLoading;
    const logoClass = props.uploadType === "logo" ? styles.logo : styles.wordmark;

    const dropzoneClass = cx(
        styles.dropzone,
        props.uploadType === "logo" ? styles.logo : styles.wordmark,
    );

    const onDrop = useCallback(
        (acceptedFiles: ReadonlyArray<File>) => {
            const file = acceptedFiles[0];
            intercom.trackEvent("uploaded_logo", { uploadType: props.uploadType });
            void uploadFunction({ variables: { file } });
        },
        [uploadFunction, intercom, props.uploadType],
    );
    const { getRootProps, getInputProps, open } = useDropzone({
        onDrop,
        maxFiles: 1,
        accept: {
            "image/png": [".png"],
            "image/jpeg": [".jpg", ".jpeg"],
        },
    });

    return (
        <>
            <div {...getRootProps({ className: dropzoneClass })}>
                <input {...getInputProps()} />
                {props.logoUrl ? (
                    <OrganizationLogo src={props.logoUrl} className={logoClass} />
                ) : (
                    <div className={styles.blank}>
                        <Icon classes={{ root: styles.blankIcon }}>image</Icon>
                    </div>
                )}
                {loading && (
                    <div className={styles.loadingContainer}>
                        <CircularProgress />
                    </div>
                )}
            </div>
            <Button
                color="primary"
                startIcon={<Icon>create</Icon>}
                size="small"
                onClick={open}
                disabled={loading}
            >
                Edit {props.uploadType === "logo" ? "Logo" : "Wordmark"}
            </Button>
        </>
    );
}

export default LogoUploader;
