import { platform } from "@floating-ui/react";
import { Button } from "@mui/material";
import cx from "classnames";
import React, { ReactElement, useCallback, useEffect, useRef, useState } from "react";
import { useTransformEffect } from "react-zoom-pan-pinch";

import { StepInterestPoint } from "../../network/responseTypes";
import { isValidRGBHexColor } from "../../util/ColorUtil";
import { getInterestPointBoundingClientRect } from "../../util/InterestPointUtil";

import styles from "./ClickableInterestPointHighlighter.module.scss";

// Margin applied to the interest point when highlighted
const HIGHLIGHT_MARGIN = 4; // pixels

const CSS_PROPERTY_SHADOW_COLOR = "--highlight-shadow-color";

const ALPHA_90_AS_HEX = "e6"; // e6 is 90% alpha when in RGBA hex format

interface Props {
    color?: string;
    interestPoint: StepInterestPoint;
    imageHeight?: number;
    imageWidth?: number;
    getImageElBoundingClientRect: () => DOMRect;
    onClick: () => void;
    isWithinTransformWrapper?: boolean;
    className?: string;
}

function ClickableInterestPointHighlighter(props: Props): ReactElement {
    const {
        color,
        interestPoint,
        imageHeight,
        imageWidth,
        getImageElBoundingClientRect,
        onClick,
        isWithinTransformWrapper = false,
        className,
    } = props;

    const buttonRef = useRef<HTMLButtonElement>(null);

    const [imageElRect, setImageElRect] = useState<DOMRect>(getImageElBoundingClientRect);

    const refreshImageElRect = useCallback(
        () => setImageElRect(getImageElBoundingClientRect()),
        [getImageElBoundingClientRect],
    );

    useEffect(refreshImageElRect, [refreshImageElRect, imageWidth, imageHeight]);
    useEffect(() => {
        window.addEventListener("resize", refreshImageElRect);
        return () => window.removeEventListener("resize", refreshImageElRect);
    }, [refreshImageElRect]);

    const interestPointRect = getInterestPointBoundingClientRect(
        interestPoint,
        imageElRect,
        imageWidth,
        imageHeight,
    );

    const positionedAncestorEl = buttonRef.current
        ? platform.getOffsetParent(buttonRef.current)
        : null;
    const positionedAncestorRect = (
        positionedAncestorEl instanceof Element ? positionedAncestorEl : document.documentElement
    ).getBoundingClientRect();

    const highlightRect = DOMRect.fromRect({
        x: interestPointRect.x - positionedAncestorRect.x - HIGHLIGHT_MARGIN,
        y: interestPointRect.y - positionedAncestorRect.y - HIGHLIGHT_MARGIN,
        width: interestPointRect.width + 2 * HIGHLIGHT_MARGIN,
        height: interestPointRect.height + 2 * HIGHLIGHT_MARGIN,
    });

    useEffect(
        () =>
            buttonRef.current?.style.setProperty(
                CSS_PROPERTY_SHADOW_COLOR,
                color && isValidRGBHexColor(color) ? color + ALPHA_90_AS_HEX : "",
            ),
        [color],
    );

    return (
        <>
            <Button
                onClick={onClick}
                aria-label="Next step"
                ref={buttonRef}
                className={cx(styles.container, className)}
                style={{
                    top: highlightRect.top,
                    left: highlightRect.left,
                    width: highlightRect.width,
                    height: highlightRect.height,
                }}
            />
            {
                // Triggers a refresh when screenshot is zoomed, panned, etc. This works around
                // useTransformEffect throwing an error when used outside a TransformerWrapper.
                isWithinTransformWrapper && <RefreshOnTransform onTransform={refreshImageElRect} />
            }
        </>
    );
}

function RefreshOnTransform(props: { onTransform: () => void }): null {
    const { onTransform } = props;
    useTransformEffect(
        // Update position of highlight whenever user zooms, pinches, or pans
        onTransform,
    );
    return null;
}

export default ClickableInterestPointHighlighter;
