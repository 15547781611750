import { IconButton, Tooltip } from "@mui/material";
import React, { ComponentProps } from "react";

import styles from "./ToolbarButton.module.scss";

interface Props extends ComponentProps<typeof IconButton> {
    title: string;
}

function ToolbarButton({ title, children, ...props }: Props) {
    return (
        <Tooltip title={title} placement="top" classes={{ tooltip: styles.tooltip }}>
            <IconButton
                className={styles.iconButton}
                size="medium"
                {...props}
                aria-label={title}
                data-dd-action-name={title}
            >
                {children}
            </IconButton>
        </Tooltip>
    );
}

export default ToolbarButton;
