import React from "react";

import { useIsInternalOrg } from "../hooks/featureFlags";

import NotFoundPage from "./global/NotFoundPage";

export function internallyEnabledPage(
    Component: React.ComponentType,
    override = false,
): React.FunctionComponent {
    const Page = () => {
        const isInternalOrg = useIsInternalOrg();
        const isPageEnabled = override || isInternalOrg;
        return isPageEnabled ? <Component /> : <NotFoundPage />;
    };

    return Page;
}
