/* eslint-disable */
// THIS IS A GENERATED FILE. DO NOT EDIT DIRECTLY
// To generate new type definitions run `npm run generate-schema-types`
import * as Types from '../../../@types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TagsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type TagsQuery = { readonly __typename?: 'Query', readonly tags?: ReadonlyArray<{ readonly __typename?: 'TagType', readonly id: string, readonly name: string, readonly guideCount?: number | null }> | null };

export type TagMutationVariables = Types.Exact<{
  input: Types.TagMutationInput;
}>;


export type TagMutation = { readonly __typename?: 'Mutation', readonly tag?: { readonly __typename?: 'TagMutationPayload', readonly id?: string | null, readonly errors?: ReadonlyArray<{ readonly __typename?: 'ErrorType', readonly field: string, readonly messages: ReadonlyArray<string> } | null> | null } | null };

export type DeleteTagMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteTagMutation = { readonly __typename?: 'Mutation', readonly deleteTag?: { readonly __typename?: 'DeleteTagMutation', readonly ok?: boolean | null } | null };


export const TagsDocument = gql`
    query Tags {
  tags {
    id
    name
    guideCount
  }
}
    `;

/**
 * __useTagsQuery__
 *
 * To run a query within a React component, call `useTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTagsQuery(baseOptions?: Apollo.QueryHookOptions<TagsQuery, TagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TagsQuery, TagsQueryVariables>(TagsDocument, options);
      }
export function useTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TagsQuery, TagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TagsQuery, TagsQueryVariables>(TagsDocument, options);
        }
export type TagsQueryHookResult = ReturnType<typeof useTagsQuery>;
export type TagsLazyQueryHookResult = ReturnType<typeof useTagsLazyQuery>;
export type TagsQueryResult = Apollo.QueryResult<TagsQuery, TagsQueryVariables>;
export const TagDocument = gql`
    mutation Tag($input: TagMutationInput!) {
  tag(input: $input) {
    errors {
      field
      messages
    }
    id
  }
}
    `;
export type TagMutationFn = Apollo.MutationFunction<TagMutation, TagMutationVariables>;

/**
 * __useTagMutation__
 *
 * To run a mutation, you first call `useTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tagMutation, { data, loading, error }] = useTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTagMutation(baseOptions?: Apollo.MutationHookOptions<TagMutation, TagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TagMutation, TagMutationVariables>(TagDocument, options);
      }
export type TagMutationHookResult = ReturnType<typeof useTagMutation>;
export type TagMutationResult = Apollo.MutationResult<TagMutation>;
export type TagMutationOptions = Apollo.BaseMutationOptions<TagMutation, TagMutationVariables>;
export const DeleteTagDocument = gql`
    mutation DeleteTag($id: ID!) {
  deleteTag(id: $id) {
    ok
  }
}
    `;
export type DeleteTagMutationFn = Apollo.MutationFunction<DeleteTagMutation, DeleteTagMutationVariables>;

/**
 * __useDeleteTagMutation__
 *
 * To run a mutation, you first call `useDeleteTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTagMutation, { data, loading, error }] = useDeleteTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTagMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTagMutation, DeleteTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTagMutation, DeleteTagMutationVariables>(DeleteTagDocument, options);
      }
export type DeleteTagMutationHookResult = ReturnType<typeof useDeleteTagMutation>;
export type DeleteTagMutationResult = Apollo.MutationResult<DeleteTagMutation>;
export type DeleteTagMutationOptions = Apollo.BaseMutationOptions<DeleteTagMutation, DeleteTagMutationVariables>;