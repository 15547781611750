import React, { ReactElement, useCallback, useState } from "react";
import { History } from "@mui/icons-material";

import ResetScreenshotDialog from "../ResetScreenshotDialog/ResetScreenshotDialog";

import ToolbarButton from "./ToolbarButton";

interface Props {
    guideId: string;
    step: {
        id: string;
    };
}

function ResetScreenshotButton(props: Props): ReactElement {
    const { guideId, step } = props;

    const [isResetScreenshotDialogOpen, setIsResetScreenshotDialogOpen] = useState(false);

    const onClick = useCallback(() => {
        setIsResetScreenshotDialogOpen(true);
    }, []);

    return (
        <>
            <ToolbarButton title="Reset screenshot edits" onClick={onClick}>
                <History />
            </ToolbarButton>
            <ResetScreenshotDialog
                isOpen={isResetScreenshotDialogOpen}
                guideId={guideId}
                stepId={step.id}
                onClose={() => setIsResetScreenshotDialogOpen(false)}
            />
        </>
    );
}

export default ResetScreenshotButton;
