import { Button, Checkbox, Divider, FormControlLabel } from "@mui/material";
import React, { ReactElement, useEffect, useState } from "react";

import styles from "./CheckboxGroupPanel.module.scss";

export interface Option {
    id: string;
    label: string;
    checked: boolean;
}

interface Props {
    title?: string;
    options: Array<Option>;
    helpText?: string;
    helpIcon?: string;
    applyUpdate: (selectedIds: Array<string>) => void;
}

function CheckboxGroupPanel(props: Props): ReactElement {
    const { title, options: initialOptions, helpText, helpIcon, applyUpdate } = props;
    const [options, setOptions] = useState<Array<Option>>(initialOptions);

    useEffect(() => {
        setOptions(initialOptions);
    }, [initialOptions]);

    const updateChecked = (id: string, checked: boolean) => {
        setOptions((options) =>
            options.map((option) => {
                if (option.id === id) {
                    return { ...option, checked };
                }
                return option;
            }),
        );
    };

    const affectAll = (selected: boolean) => {
        setOptions((options) =>
            options.map((option) => {
                return { ...option, checked: selected };
            }),
        );
    };

    const showHelper = helpText && options.length === 0;

    return (
        <div className={styles.container}>
            <div className={styles.topSection}>
                <div className={styles.title}>{title}</div>
                <Button
                    className={styles.selectAll}
                    color="primary"
                    onClick={() => affectAll(true)}
                >
                    Select all
                </Button>
            </div>
            <div className={styles.checkboxesContainer}>
                {showHelper && (
                    <div className={styles.helperContainer}>
                        {helpIcon && (
                            <div className={styles.helperIcon}>
                                <img src={helpIcon} alt="" />
                            </div>
                        )}
                        <div className={styles.helperMessage}>{helpText}</div>
                    </div>
                )}

                {options.map((option) => (
                    <FormControlLabel
                        key={option.id}
                        label={option.label}
                        classes={{ label: styles.checkboxLabel }}
                        control={
                            <Checkbox
                                size="small"
                                checked={option.checked}
                                onChange={(e) => {
                                    updateChecked(option.id, e.target.checked);
                                }}
                            />
                        }
                    />
                ))}
            </div>
            <div className={styles.fade}></div>
            <Divider className={styles.divider} />
            <div className={styles.bottomSection}>
                <Button
                    className={styles.clearAll}
                    color="primary"
                    onClick={() => affectAll(false)}
                >
                    Clear all
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                        applyUpdate(
                            options.filter((option) => option.checked).map((options) => options.id),
                        )
                    }
                >
                    Apply
                </Button>
            </div>
        </div>
    );
}
export default CheckboxGroupPanel;
