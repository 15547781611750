import { Node as SlateNode, Descendant } from "slate";

export function convertTextToSlateNode(text: string): ReadonlyArray<Descendant> {
    return [
        {
            type: "paragraph",
            children: [{ text }],
        },
    ];
}

export function slateNodesToString(nodes: readonly Descendant[]): string {
    return nodes.map((n: SlateNode) => SlateNode.string(n)).join("\n");
}
