import { Popover, PopoverProps } from "@mui/material";
import cx from "classnames";
import React from "react";

import SubscriptionPill from "../ProPill/SubscriptionPill";
import SubscriptionUpgradeButton from "../SubscriptionUpgradeButton/SubscriptionUpgradeButton";

import styles from "./ProFeaturePopover.module.scss";

interface Props
    extends Pick<
        PopoverProps,
        "open" | "anchorEl" | "onClose" | "anchorOrigin" | "transformOrigin"
    > {
    title: string;
    description: string;
    arrowPosition?: "center" | "right";
    className?: string;
}

function ProFeaturePopover(props: Props) {
    const { title, description, arrowPosition = "center", className, ...popoverProps } = props;
    return (
        <Popover
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
            classes={{
                paper: cx(styles.paper, className),
            }}
            marginThreshold={0}
            {...popoverProps}
        >
            {/* Use the transparent paper and another container div to display the arrow */}
            <div
                className={cx(styles.container, {
                    [styles.arrowCenter]: arrowPosition === "center",
                    [styles.arrowRight]: arrowPosition === "right",
                })}
            >
                <SubscriptionPill tier="Pro" />
                <h3 className={styles.title}>{title}</h3>
                <p className={styles.description}>{description}</p>
                <SubscriptionUpgradeButton
                    variant="contained"
                    color="primary"
                    className={styles.button}
                    disableElevation
                    fullWidth
                >
                    Upgrade to Pro
                </SubscriptionUpgradeButton>
            </div>
        </Popover>
    );
}

export default ProFeaturePopover;
