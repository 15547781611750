import { SubscriptionTier } from "../../@types/graphql";

export function isProTier(tier: SubscriptionTier): boolean {
    // Enums are uppercase in GQL and lowercase in REST
    switch (tier.toUpperCase()) {
        case "PRO1":
        case "PRO2":
        case "PRO3":
            return true;
        case "FREE":
        case "ENTERPRISE":
        default:
            return false;
    }
}

export function isPaidTier(tier: SubscriptionTier): boolean {
    // Enums are uppercase in GQL and lowercase in REST
    switch (tier.toUpperCase()) {
        case "PRO":
        case "PRO1":
        case "PRO2":
        case "PRO3":
        case "ENTERPRISE":
            return true;
        case "FREE":
        default:
            return false;
    }
}

const DEFAULT_INVITE_USER_DESCRIPTION =
    "Your Driveway plan has unlimited users. Type as many comma-separated email addresses as you&rsquo;d like or paste a column from a .csv file.";
const ENTERPRISE_INVITE_USER_DESCRIPTION =
    "Invite more people to your workspace by typing their email addresses below or paste from a CSV.";
const PRO_TRIAL_INVITE_USER_DESCRIPTION =
    "Your free trial of Driveway Pro includes up to 25 users. Invite more people to your workspace by typing their email addresses below.";
const PRO_INVITE_USER_DESCRIPTION =
    "Your Pro plan includes up to 25 users. Invite more people to your workspace by typing their email addresses below.";

export function getInviteUserDescription(tier: SubscriptionTier, isTrial: boolean): string {
    if (tier === "ENTERPRISE") {
        return ENTERPRISE_INVITE_USER_DESCRIPTION;
    }
    if (tier === "PRO") {
        return isTrial ? PRO_TRIAL_INVITE_USER_DESCRIPTION : PRO_INVITE_USER_DESCRIPTION;
    }
    // Return default description for invalid tiers
    return DEFAULT_INVITE_USER_DESCRIPTION;
}
