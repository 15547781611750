import cx from "classnames";
import React, { MouseEventHandler, useCallback, useState } from "react";

import { useAutoResetBoolean } from "../../hooks/autoResetBoolean";
import { isGuideCustomizationEnabled } from "../../hooks/featureAccess";
import { useIsWithinGuideEmbed } from "../../hooks/isWithinGuideEmbed";
import { Organization, Person, Step } from "../../network/responseTypes";
import DrivewayWatermark from "../DrivewayWatermark/DrivewayWatermark";
import SlideshowNavigation from "../SlideshowNavigation/SlideshowNavigation";
import VideoPlayer from "../VideoPlayer/VideoPlayer";

import PanZoomScreenshot from "./PanZoomScreenshot";
import styles from "./StepView.module.scss";

const SLIDESHOW_NAV_VISIBILITY_TIMEOUT_MS = 1000;

// Slideshow nav only displays when user hovers within this
// amount of pixels from the bottom of the slideshow
const SLIDESHOW_NAV_BOTTOM_HOVER_OFFSET = 84; // pixels

// Slideshow nav only displays when user hovers to the left of this amount of pixels from the right
// of the slideshow (only when the Driveway watermark is present)
const SLIDESHOW_NAV_RIGHT_HOVER_OFFSET = 120; // pixels

interface Props {
    guideId: string;
    author: Pick<Person, "avatar_url" | "first_name" | "last_name">;
    step: Step;
    organization: Organization;
    activeStepIndex: number;
    onNextStep: () => void;
    onPreviousStep: () => void;
    onRestart: () => void;
    onSetStep: (stepIndex: number) => void;
    getStepScreenshotUrl: (stepIndex: number) => string | undefined;
    getStepColor: (stepIndex: number) => string | undefined;
    isFirstStep: boolean;
    isLastStep: boolean;
    stepCount: number;
    autoZoomScreenshots: boolean;
    showClickableInterestPointHighlighter: boolean;
    className?: string;
}

const EmbedStepView = (props: Props) => {
    const {
        guideId,
        step,
        organization,
        onNextStep,
        onPreviousStep,
        onRestart,
        onSetStep,
        getStepScreenshotUrl,
        getStepColor,
        activeStepIndex,
        stepCount,
        autoZoomScreenshots,
        showClickableInterestPointHighlighter,
        className,
    } = props;
    const [enableWheelZoom, setEnableWheelZoom] = useState(false);

    const [isSlideshowNavigationVisible, toggleSlideshowNavigationVisible] = useAutoResetBoolean({
        timeoutMs: SLIDESHOW_NAV_VISIBILITY_TIMEOUT_MS,
        defaultValue: true,
    });

    const hasWatermark = !isGuideCustomizationEnabled(organization);
    const isWithinGuideEmbed = useIsWithinGuideEmbed();
    const [autoPlayVideos, setAutoPlayVideos] = useState(
        // Don't autoplay the first step when it's a video step within a guide embed
        !isWithinGuideEmbed || activeStepIndex !== 0 || step.type !== "video",
    );

    const onMouseMove = useCallback<MouseEventHandler>(
        (ev) => {
            const rect = ev.currentTarget.getBoundingClientRect();
            const offsetFromRight = rect.right - ev.clientX;
            const offsetFromBottom = rect.bottom - ev.clientY;
            if (
                offsetFromBottom < SLIDESHOW_NAV_BOTTOM_HOVER_OFFSET &&
                (!hasWatermark || offsetFromRight > SLIDESHOW_NAV_RIGHT_HOVER_OFFSET)
            ) {
                toggleSlideshowNavigationVisible();
            }
        },
        [hasWatermark, toggleSlideshowNavigationVisible],
    );

    return (
        <div className={cx(styles.container, className)}>
            {step.type === "action" ? (
                <PanZoomScreenshot
                    step={step}
                    autoZoom={!step.is_autozoom_disabled && autoZoomScreenshots}
                    className={styles.mediaContent}
                    onNextStep={onNextStep}
                    onPreviousStep={onPreviousStep}
                    onRestart={onRestart}
                    stepIndex={activeStepIndex}
                    stepCount={stepCount}
                    enableWheelZoom={enableWheelZoom}
                    stepColor={step.color ?? undefined}
                    showClickableInterestPointHighlighter={showClickableInterestPointHighlighter}
                    messageBubbleShiftPadding={{
                        left: 24,
                        right: 24,
                        top: 24,
                        bottom: isSlideshowNavigationVisible
                            ? // Enough bottom padding so bubble doesn't overlap slideshow nav
                              92
                            : 24,
                    }}
                    onMouseMove={onMouseMove}
                >
                    {/* SlideshowNavigation must be child of PanZoomScreenshot for zoom buttons to work */}
                    <SlideshowNavigation
                        guideId={guideId}
                        stepIndex={activeStepIndex}
                        stepCount={stepCount}
                        onNextStep={onNextStep}
                        onPreviousStep={onPreviousStep}
                        onSetStep={onSetStep}
                        onInteraction={toggleSlideshowNavigationVisible}
                        getStepScreenshotUrl={getStepScreenshotUrl}
                        getStepColor={getStepColor}
                        onToggleEnableWheelZoom={() => setEnableWheelZoom((prev) => !prev)}
                        enableWheelZoom={enableWheelZoom}
                        className={styles.slideshowNavigation}
                        isVisible={isSlideshowNavigationVisible}
                        showZoomButtons
                    />
                </PanZoomScreenshot>
            ) : (
                <VideoPlayer
                    step={step}
                    onNextStep={() => {
                        setAutoPlayVideos(true);
                        onNextStep();
                    }}
                    onMouseMove={onMouseMove}
                    className={styles.mediaContent}
                    autoPlay={autoPlayVideos}
                />
            )}
            {step.type === "video" && (
                <SlideshowNavigation
                    guideId={guideId}
                    stepIndex={activeStepIndex}
                    stepCount={stepCount}
                    onNextStep={() => {
                        setAutoPlayVideos(true);
                        onNextStep();
                    }}
                    onPreviousStep={() => {
                        setAutoPlayVideos(true);
                        onPreviousStep();
                    }}
                    onSetStep={(stepIndex) => {
                        setAutoPlayVideos(true);
                        onSetStep(stepIndex);
                    }}
                    onToggleEnableWheelZoom={() => setEnableWheelZoom((prev) => !prev)}
                    enableWheelZoom={enableWheelZoom}
                    onInteraction={toggleSlideshowNavigationVisible}
                    getStepScreenshotUrl={getStepScreenshotUrl}
                    getStepColor={getStepColor}
                    className={styles.slideshowNavigation}
                    isVisible={isSlideshowNavigationVisible}
                    showZoomButtons={false}
                />
            )}
            {hasWatermark && (
                <DrivewayWatermark
                    className={cx(styles.drivewayWatermark, {
                        [styles.offsetForSlideshowNav]: isSlideshowNavigationVisible,
                    })}
                />
            )}
        </div>
    );
};

export default EmbedStepView;
