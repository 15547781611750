import React, { ReactElement } from "react";
import cx from "classnames";
import { Tooltip } from "@mui/material";

import styles from "./UpgradePill.module.scss";

interface Props {
    className?: string;
}

function UpgradePill(props: Props): ReactElement {
    const { className } = props;

    return (
        <Tooltip title="This feature is only available with a Premium plan." placement="bottom">
            <div className={cx(styles.pill, className)}>Upgrade</div>
        </Tooltip>
    );
}

export default UpgradePill;
