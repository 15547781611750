import { Add as AddIcon } from "@mui/icons-material";
import { Button } from "@mui/material";
import React, { ReactElement } from "react";

import chromeIcon from "../../icons/chrome-colored.svg";
import drivewayIcon from "../../icons/driveway-logo.svg";
import { getBrowserCopy, isChrome } from "../../util/ChromeExtensionUtil";

const TYPEFORM_LINK = "https://sgfeci4r4aq.typeform.com/to/EFMZmKiA";

import styles from "./DownloadExtensionMessage.module.scss";

const CHROME_SUFFIX = "from the Chrome Web Store.";
const NON_CHROME_SUFFIX = "and view this guide on Chrome.";

type Props = {
    showGuideDetailPageLink?: boolean;
    guideId?: string;
};

function DownloadExtensionMessage(props: Props): ReactElement {
    const { showGuideDetailPageLink, guideId } = props;
    return (
        <>
            <div className={styles.iconsRow}>
                <div className={styles.iconContainer}>
                    <img src={drivewayIcon} className={styles.drivewayIcon} alt="" />
                </div>
                <AddIcon className={styles.addIcon} />
                <div className={styles.iconContainer}>
                    <img src={chromeIcon} className={styles.chromeIcon} alt="" />
                </div>
            </div>
            <div className={styles.title}>Add the Driveway Chrome extension to continue</div>
            <div className={styles.description}>
                <p className={styles.descriptionPara}>
                    Viewing this guide as an interactive walkthrough requires the Driveway Chrome
                    extension. Click the button below to download it{" "}
                    {isChrome() ? CHROME_SUFFIX : NON_CHROME_SUFFIX}
                </p>
                {!isChrome() && (
                    <p className={styles.descriptionPara}>
                        <Button variant="text" href={TYPEFORM_LINK}>
                            Update me when the Driveway extension is available for{" "}
                            {getBrowserCopy()} <span aria-hidden>→</span>
                        </Button>
                    </p>
                )}
            </div>
            <Button
                variant="contained"
                color="primary"
                className={styles.downloadButton}
                component="a"
                href={`https://chrome.google.com/webstore/detail/driveway/${process.env.REACT_APP_CHROME_EXTENSION_ID}/`}
                target="_blank"
                rel="noreferrer"
            >
                Get the Chrome extension
            </Button>
            {showGuideDetailPageLink && guideId && (
                <a className={styles.guideDetailPageLink} href={`/guides/${guideId}/`}>
                    View a non-interactive version of this guide instead
                </a>
            )}
        </>
    );
}
export default DownloadExtensionMessage;
