import React, { ReactElement } from "react";
import { SnackbarKey, useSnackbar } from "notistack";
import { Button } from "@mui/material";

import { useUndoDeleteStepMutation } from "../../mutations/undoDeleteStep";

import styles from "./UndoDeleteStepButton.module.scss";

interface Props {
    deletedStepId: string;
    guideId: string;
    snackbarId: SnackbarKey;
}

const UndoDeleteStepButton = (props: Props): ReactElement => {
    const { guideId, deletedStepId, snackbarId } = props;
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { mutate: undoDeleteStep, isLoading } = useUndoDeleteStepMutation();
    return (
        <Button
            disabled={isLoading}
            className={styles.button}
            variant="contained"
            color="inherit"
            onClick={() => {
                undoDeleteStep(
                    {
                        guideId,
                        stepId: deletedStepId,
                    },
                    {
                        onSuccess: () => {
                            closeSnackbar(snackbarId);
                            // delay for 500ms to allow toast to close
                            setTimeout(() => {
                                enqueueSnackbar("You've undone deleting a step.", {
                                    variant: "info",
                                });
                            }, 500);
                        },
                        onError: () => {
                            enqueueSnackbar("Failed to undo deleting your last step.", {
                                variant: "error",
                            });
                        },
                    },
                );
            }}
        >
            <span className={styles.text}>{isLoading ? "Undoing..." : "Undo"}</span>
        </Button>
    );
};

export default UndoDeleteStepButton;
