import { Padding } from "@floating-ui/react";
import cx from "classnames";
import React, { DOMAttributes, ReactNode, useCallback, useEffect, useRef } from "react";
import { ReactZoomPanPinchRef, TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

import { usePrefersReducedMotion } from "../../hooks/prefersReducedMotion";
import { useResponsivePanZoom } from "../../hooks/responsivePanZoom";
import { ActionStep } from "../../network/responseTypes";
import { calculateBounds, interestPointToPanZoomCenterXY } from "../../util/PanZoomUtil";
import ActionStepMessageBubble from "../ActionStepMessageBubble/ActionStepMessageBubble";
import ClickableInterestPointHighlighter from "../ClickableInterestPointHighlighter/ClickableInterestPointHighlighter";

import styles from "./PanZoomScreenshot.module.scss";

interface Props extends Pick<DOMAttributes<HTMLDivElement>, "onMouseMove"> {
    step: ActionStep;
    autoZoom: boolean;
    onNextStep: () => void;
    onPreviousStep: () => void;
    onRestart: () => void;
    stepIndex: number;
    stepCount: number;
    stepColor?: string;
    showClickableInterestPointHighlighter: boolean;
    messageBubbleShiftPadding: Padding;
    enableWheelZoom?: boolean;
    children?: ReactNode;
    className?: string;
}

// it feels extreme to zoom in more than this
const MAX_PERCENT_ZOOM_IN = 0.4;
const IP_PADDING = 64;

const PanZoomScreenshot = (props: Props) => {
    const {
        step,
        autoZoom,
        onNextStep,
        onPreviousStep,
        onRestart,
        stepIndex,
        stepCount,
        stepColor,
        showClickableInterestPointHighlighter,
        children,
        messageBubbleShiftPadding,
        enableWheelZoom = true,
        onMouseMove,
        className,
    } = props;
    const { containerRef, imageScale, imageWidth, imageHeight } =
        useResponsivePanZoom<HTMLDivElement>(step.screenshot_url);

    const screenshotImgRef = useRef<HTMLImageElement>(null);

    const prefersReducedMotion = usePrefersReducedMotion();
    const animationTime = prefersReducedMotion ? 1000 : 400;

    const transformWrapperRef = useRef<ReactZoomPanPinchRef | null>(null);
    const interestPoint = step.interest_points[0];

    const autoZoomIn = useCallback(() => {
        if (
            !transformWrapperRef.current ||
            !containerRef.current ||
            !transformWrapperRef.current.instance.wrapperComponent ||
            !transformWrapperRef.current.instance.contentComponent
        ) {
            return;
        }

        const containerWidth = containerRef.current.clientWidth;
        const containerHeight = containerRef.current.clientHeight;
        if (autoZoom && interestPoint) {
            // in rare cases, x or y values are negative, which causes us to zoom
            // in outside the bounds of our screenshot. in these cases, we should
            // just not zoom in at all.
            if (interestPoint.position_x < 0 || interestPoint.position_y < 0) {
                return;
            }

            const zoomedScaleX = 1 / ((interestPoint.width + IP_PADDING) / containerWidth);
            const zoomedScaleY = 1 / ((interestPoint.height + IP_PADDING) / containerHeight);
            const zoomedImageScale = Math.min(
                zoomedScaleX,
                zoomedScaleY,
                imageScale * (1 / MAX_PERCENT_ZOOM_IN),
            );

            const { centerToX, centerToY } = interestPointToPanZoomCenterXY(
                interestPoint,
                zoomedImageScale,
            );
            const newPositionX = containerWidth / 2 + centerToX;
            const newPositionY = containerHeight / 2 + centerToY;

            const { minPositionX, maxPositionX, minPositionY, maxPositionY } = calculateBounds(
                transformWrapperRef.current.instance.wrapperComponent,
                transformWrapperRef.current.instance.contentComponent,
                zoomedImageScale,
            );

            transformWrapperRef.current.setTransform(
                Math.min(Math.max(minPositionX, newPositionX), maxPositionX),
                Math.min(Math.max(minPositionY, newPositionY), maxPositionY),
                zoomedImageScale,
                animationTime,
            );
        } else {
            const containerWidth = containerRef.current.clientWidth;
            const containerHeight = containerRef.current.clientHeight;
            const newX = imageWidth ? (containerWidth - imageWidth * imageScale) / 2 : 0;
            const newY = imageHeight ? (containerHeight - imageHeight * imageScale) / 2 : 0;
            transformWrapperRef.current.setTransform(newX, newY, imageScale, animationTime);
        }
    }, [containerRef, imageScale, imageWidth, imageHeight, autoZoom, interestPoint, animationTime]);

    useEffect(() => {
        const zoomInTimeoutId = setTimeout(autoZoomIn, 300);
        return () => {
            clearTimeout(zoomInTimeoutId);
        };
    }, [interestPoint, animationTime, autoZoomIn]);

    const getScreenshotBoundingClientRect = useCallback(
        () =>
            screenshotImgRef.current?.getBoundingClientRect() ??
            // This should never happen
            new DOMRect(),
        [],
    );

    return (
        <div className={cx(styles.panZoomWrapper, className)} ref={containerRef}>
            {imageScale > 0 && (
                <TransformWrapper
                    doubleClick={{ disabled: true }}
                    initialScale={imageScale}
                    minScale={imageScale}
                    centerOnInit
                    wheel={{
                        disabled: !enableWheelZoom,
                    }}
                    ref={transformWrapperRef}
                >
                    {() => (
                        <>
                            <TransformComponent
                                wrapperClass={styles.imageWrap}
                                wrapperProps={{ onMouseMove }}
                            >
                                <img
                                    src={step.screenshot_url}
                                    alt={step.title + " screenshot"}
                                    ref={screenshotImgRef}
                                />
                            </TransformComponent>
                            <ActionStepMessageBubble
                                step={step}
                                interestPoint={interestPoint}
                                getImageElBoundingClientRect={getScreenshotBoundingClientRect}
                                onNextStep={onNextStep}
                                onPreviousStep={onPreviousStep}
                                onRestart={onRestart}
                                stepIndex={stepIndex}
                                stepCount={stepCount}
                                shiftPadding={messageBubbleShiftPadding}
                                className={styles.messageBubble}
                            />
                            {showClickableInterestPointHighlighter && interestPoint && (
                                <ClickableInterestPointHighlighter
                                    color={stepColor}
                                    interestPoint={interestPoint}
                                    imageWidth={imageWidth}
                                    imageHeight={imageHeight}
                                    getImageElBoundingClientRect={getScreenshotBoundingClientRect}
                                    onClick={onNextStep}
                                    className={styles.clickableInterestPointHighlighter}
                                    isWithinTransformWrapper
                                />
                            )}
                            {children}
                        </>
                    )}
                </TransformWrapper>
            )}
        </div>
    );
};

export default PanZoomScreenshot;
