import { MovieOutlined as MovieIcon } from "@mui/icons-material";
import cx from "classnames";
import React from "react";

import backgroundImage from "../../images/default-video-thumbnail.svg";

import styles from "./DefaultVideoThumbnail.module.scss";

interface Props {
    className?: string;
}

function DefaultVideoThumbnail(props: Props) {
    const { className } = props;
    return (
        <div className={cx(styles.container, className)}>
            <img src={backgroundImage} alt="" className={styles.background} />
            <div className={styles.filter} />
            <MovieIcon className={styles.icon} />
        </div>
    );
}

export default DefaultVideoThumbnail;
