import { Avatar, AvatarProps } from "@mui/material";
import cx from "classnames";
import React, { ReactElement } from "react";

import personIcon from "../../icons/person.svg";

import styles from "./AnonymousAvatar.module.scss";

function AnonymousAvatar(props: AvatarProps): ReactElement {
    const { className, ...otherProps } = props;
    return (
        <Avatar className={cx(styles.avatar, className)} {...otherProps}>
            <img src={personIcon} alt="Anonymous user" className={styles.icon} />
        </Avatar>
    );
}

export default AnonymousAvatar;
