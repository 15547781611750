import React, { ReactNode, ReactElement } from "react";

import styles from "./GuideDetailBar.module.scss";

interface Props {
    children: ReactNode;
}
function GuideDetailBar(props: Props): ReactElement {
    const { children } = props;
    return <div className={styles.detailbar}>{children}</div>;
}

export default GuideDetailBar;
