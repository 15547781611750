import { autoUpdate, offset, shift, useFloating } from "@floating-ui/react";
import cx from "classnames";
import { range } from "lodash";
import React, { KeyboardEventHandler, ReactElement, useCallback, useState } from "react";

import { useWindowWidth } from "../../hooks/windowWidth";

import styles from "./SlideshowStepSlider.module.scss";

const SCREENSHOT_THUMBNAIL_OFFSET = 10;
const SCREENSHOT_THUMBNAIL_SHIFT_PADDING = 20;
const MAX_GAP = 24; // pixels

interface Props {
    stepIndex: number;
    stepCount: number;
    onPreviousStep: () => void;
    onNextStep: () => void;
    onSetStep: (stepIndex: number) => void;
    getStepScreenshotUrl: (stepIndex: number) => string | undefined;
    getStepColor: (stepIndex: number) => string | undefined;
    className?: string;
}

function SlideshowStepSlider(props: Props): ReactElement {
    const {
        stepIndex,
        stepCount,
        onPreviousStep,
        onNextStep,
        onSetStep,
        getStepScreenshotUrl,
        getStepColor,
        className,
    } = props;

    const onKeyDown = useCallback<KeyboardEventHandler>(
        (ev) => {
            switch (ev.key) {
                case "ArrowLeft":
                case "ArrowDown":
                    onPreviousStep();
                    ev.preventDefault();
                    ev.stopPropagation();
                    break;
                case "ArrowRight":
                case "ArrowUp":
                    onNextStep();
                    ev.preventDefault();
                    ev.stopPropagation();
                    break;
                case "Home":
                    onSetStep(0);
                    ev.preventDefault();
                    ev.stopPropagation();
                    break;
                case "End":
                    onSetStep(stepCount - 1);
                    ev.preventDefault();
                    ev.stopPropagation();
                    break;
            }
        },
        [onPreviousStep, onNextStep, onSetStep, stepCount],
    );

    const windowWidth = useWindowWidth();
    const gap = Math.min(windowWidth / (8 * stepCount), MAX_GAP);

    return (
        <div
            className={cx(styles.slider, className)}
            style={{ gap }}
            role="slider"
            aria-valuenow={stepIndex}
            aria-valuemin={0}
            aria-valuemax={stepCount - 1}
            aria-valuetext={`Step ${stepIndex + 1} of ${stepCount}`}
            tabIndex={0}
            aria-label="Current step"
            onKeyDown={onKeyDown}
        >
            {range(0, stepCount).map((index) => (
                <SlideshowStepMark
                    key={`step-${index}`}
                    onClick={() => onSetStep(index)}
                    isFilled={stepIndex >= index}
                    screenshotUrl={getStepScreenshotUrl(index)}
                    showScreenshotThumbnail={stepCount > 1}
                    color={getStepColor(index)}
                />
            ))}
        </div>
    );
}

function SlideshowStepMark(props: {
    onClick: () => void;
    isFilled: boolean;
    screenshotUrl?: string;
    showScreenshotThumbnail: boolean;
    color?: string;
}): ReactElement {
    const { onClick, isFilled, screenshotUrl, showScreenshotThumbnail, color } = props;

    const [isHovered, setHovered] = useState(false);

    const { refs, floatingStyles } = useFloating({
        middleware: [
            offset(SCREENSHOT_THUMBNAIL_OFFSET),
            shift({ padding: SCREENSHOT_THUMBNAIL_SHIFT_PADDING }),
        ],
        placement: "top",
        whileElementsMounted: autoUpdate,
    });

    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events, jsx-a11y/mouse-events-have-key-events
        <div
            onClick={onClick}
            onMouseOver={() => setHovered(true)}
            onMouseOut={() => setHovered(false)}
            className={styles.stepMark}
        >
            <div
                className={cx(styles.bar, {
                    [styles.filled]: isFilled,
                })}
                style={isFilled && color ? { backgroundColor: color } : undefined}
                ref={refs.setReference}
            />
            {screenshotUrl !== undefined && isHovered && showScreenshotThumbnail && (
                <div
                    className={styles.screenshotThumbnailContainer}
                    ref={refs.setFloating}
                    style={floatingStyles}
                >
                    <img src={screenshotUrl} alt="" className={styles.screenshotThumbnailImage} />
                </div>
            )}
        </div>
    );
}

export default SlideshowStepSlider;
