import { useContext } from "react";

import { UserContext } from "../contexts/user";
import { Person } from "../network/responseTypes";

/**
 * @returns the {@link Person} corresponding to the logged in user.
 */
export function useUserPerson(): Person {
    const { id: userId, firstName, lastName, avatarUrl, role, email } = useContext(UserContext);
    return {
        id: userId,
        first_name: firstName,
        last_name: lastName,
        avatar_url: avatarUrl ?? null,
        role,
        email,
    };
}
