/* eslint-disable */
// THIS IS A GENERATED FILE. DO NOT EDIT DIRECTLY
// To generate new type definitions run `npm run generate-schema-types`
import * as Types from '../../../@types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InviteUsersMutationVariables = Types.Exact<{
  input: Types.InviteUsersInput;
}>;


export type InviteUsersMutation = { readonly __typename?: 'Mutation', readonly inviteUsers?: { readonly __typename?: 'InviteUsersMutation', readonly ok?: boolean | null, readonly errors?: ReadonlyArray<{ readonly __typename?: 'ErrorType', readonly field: string, readonly messages: ReadonlyArray<string> }> | null } | null };


export const InviteUsersDocument = gql`
    mutation InviteUsers($input: InviteUsersInput!) {
  inviteUsers(input: $input) {
    ok
    errors {
      field
      messages
    }
  }
}
    `;
export type InviteUsersMutationFn = Apollo.MutationFunction<InviteUsersMutation, InviteUsersMutationVariables>;

/**
 * __useInviteUsersMutation__
 *
 * To run a mutation, you first call `useInviteUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUsersMutation, { data, loading, error }] = useInviteUsersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteUsersMutation(baseOptions?: Apollo.MutationHookOptions<InviteUsersMutation, InviteUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteUsersMutation, InviteUsersMutationVariables>(InviteUsersDocument, options);
      }
export type InviteUsersMutationHookResult = ReturnType<typeof useInviteUsersMutation>;
export type InviteUsersMutationResult = Apollo.MutationResult<InviteUsersMutation>;
export type InviteUsersMutationOptions = Apollo.BaseMutationOptions<InviteUsersMutation, InviteUsersMutationVariables>;