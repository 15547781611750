import React, { ReactElement } from "react";
import { Icon, DialogTitle, Button } from "@mui/material";

import styles from "./InviteUserSuccessView.module.scss";

interface Props {
    onClose: () => void;
}

function InviteUserSuccessView(props: Props): ReactElement {
    const { onClose } = props;
    return (
        <>
            <div className={styles.iconContainer}>
                <Icon aria-hidden className={styles.icon}>
                    mail_outlined
                </Icon>
            </div>
            <DialogTitle className={styles.title}>Email sent!</DialogTitle>
            <p className={styles.text}>
                An invite email will be sent to the invitee. Once they sign up, they will be able to
                access your team&rsquo;s guides on Driveway.
            </p>
            <Button variant="contained" color="primary" onClick={onClose} className={styles.button}>
                Close
            </Button>
        </>
    );
}

export default InviteUserSuccessView;
