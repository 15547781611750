import { useLocation, matchPath } from "react-router";

interface Route {
    path: string;
    matchChildRoutes?: boolean;
}

/**
 * @returns a boolean indicating if the current location matches the routes
 */
export function useIsMatchingRoute(routes: Route[]): boolean {
    const { pathname } = useLocation();
    const locationPath = pathname;

    return routes.some((routeObj: Route) => {
        const { matchChildRoutes = false } = routeObj;
        if (matchChildRoutes) {
            const trimmedLocation = locationPath.slice(0, routeObj.path.length);
            return matchPath(routeObj.path, trimmedLocation) !== null;
        } else {
            const currentPath = locationPath.endsWith("/")
                ? locationPath.slice(0, -1)
                : locationPath;
            return matchPath(routeObj.path, currentPath) !== null;
        }
    });
}
