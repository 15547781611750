import { Tooltip } from "@mui/material";
import cx from "classnames";
import React, { ReactElement, ReactNode } from "react";

import infoIcon from "../../icons/info.svg";

import styles from "./InformationalTooltip.module.scss";

interface Props {
    children: ReactNode;
    className?: string;
}

function InformationalTooltip(props: Props): ReactElement {
    const { children, className } = props;
    return (
        <Tooltip title={children} placement="bottom" classes={{ tooltip: styles.tooltip }}>
            <img className={cx(styles.infoIcon, className)} src={infoIcon} alt="More information" />
        </Tooltip>
    );
}

export default InformationalTooltip;
