import { IconButton } from "@mui/material";
import cx from "classnames";
import { useSnackbar } from "notistack";
import React, { ReactElement, useCallback, useEffect, useState } from "react";

import starBlankIcon from "../../../icons/star-blank.svg";
import starFilledIcon from "../../../icons/star-filled.svg";

import { useStarGuideMutation } from "./operations.generated";
import styles from "./StarGuide.module.scss";

interface Props {
    guideId: string;
    isStarred: boolean;
}

function StarGuide(props: Props): ReactElement {
    const { enqueueSnackbar } = useSnackbar();
    const { guideId } = props;
    const [isStarred, setIsStarred] = useState(props.isStarred);
    const [starGuide] = useStarGuideMutation();

    const handleClick = useCallback(async () => {
        setIsStarred((state) => !state);
        void starGuide({
            variables: { input: { guideId, isStarred: !isStarred } },
            refetchQueries: ["Guides"],
            onError: () => {
                enqueueSnackbar("Something went wrong when trying to star this guide", {
                    variant: "error",
                });
                setIsStarred((state) => !state);
            },
        });
    }, [isStarred, starGuide, guideId, enqueueSnackbar]);

    useEffect(() => {
        setIsStarred(props.isStarred);
    }, [props.isStarred]);

    return (
        <IconButton
            onClick={handleClick}
            classes={{ root: styles.button }}
            aria-label={isStarred ? "Unstar guide" : "Star guide"}
        >
            <img
                className={cx(styles.star, {
                    [styles.blank]: !isStarred,
                })}
                src={isStarred ? starFilledIcon : starBlankIcon}
                alt=""
            />
        </IconButton>
    );
}

export default StarGuide;
