import { TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { ReactElement, useId, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

import PrimaryButton from "../../../components/PrimaryButton/PrimaryButton";

import { useUpdatePasswordMutation } from "./operations.generated";
import styles from "./PasswordChangePage.module.scss";

const ERROR_WRONG_PASSWORD = "Wrong password";
const ERROR_INVALID_PASSWORD = "Invalid password";

function PasswordChangePage(): ReactElement {
    const [currentPassword, setCurrentPassword] = useState("");
    const [currentPasswordError, setCurrentPasswordError] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordError, setNewPasswordError] = useState("");
    const [repeatedNewPassword, setRepeatedNewPassword] = useState("");
    const [repeatedNewPasswordError, setRepeatedNewPasswordError] = useState("");

    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const [updatePassword, { loading }] = useUpdatePasswordMutation({
        onCompleted: () => {
            enqueueSnackbar("Your password has been successfully changed.", {
                variant: "success",
            });
            navigate("/settings");
        },
        onError: (error) => {
            const errorMessage = error.toString();
            if (errorMessage.includes(ERROR_WRONG_PASSWORD)) {
                setCurrentPasswordError("Please enter the correct password.");
            }
            if (errorMessage.includes(ERROR_INVALID_PASSWORD)) {
                setNewPasswordError("Please enter a valid password.");
            }
            enqueueSnackbar(
                "Something went wrong when trying to change your password. Please try again.",
                { variant: "error" },
            );
        },
    });

    const validateInputs = () => {
        if (!currentPassword) {
            setCurrentPasswordError("Current password is required.");
            return false;
        }
        if (!newPassword) {
            setNewPasswordError("New password is required.");
            return false;
        }
        if (newPassword !== repeatedNewPassword) {
            setRepeatedNewPasswordError("New password entries needs to match.");
            return false;
        }
        return true;
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (validateInputs()) {
            void updatePassword({ variables: { newPassword, currentPassword } });
        }
    };

    const textFieldIdPrefix = useId();

    return (
        <div className={styles.container}>
            <Helmet title="Change password" />
            <h1 className={styles.title}>Change password</h1>
            <form noValidate autoComplete="off" className={styles.form} onSubmit={handleSubmit}>
                <label htmlFor={`${textFieldIdPrefix}-current-password`} className={styles.label}>
                    Current password
                </label>
                <TextField
                    id={`${textFieldIdPrefix}-current-password`}
                    type="password"
                    variant="outlined"
                    required
                    value={currentPassword}
                    onChange={(e) => {
                        setCurrentPassword(e.target.value);
                        setCurrentPasswordError("");
                    }}
                    error={!!currentPasswordError}
                    helperText={currentPasswordError || " "}
                    className={styles.textField}
                    InputProps={{
                        classes: {
                            input: styles.textFieldRoot,
                        },
                    }}
                    FormHelperTextProps={{
                        classes: {
                            error: styles.errorHelperText,
                        },
                    }}
                />
                <label htmlFor={`${textFieldIdPrefix}-new-password`} className={styles.label}>
                    New password
                </label>
                <TextField
                    id={`${textFieldIdPrefix}-new-password`}
                    type="password"
                    variant="outlined"
                    required
                    value={newPassword}
                    onChange={(e) => {
                        setNewPassword(e.target.value);
                        setNewPasswordError("");
                    }}
                    error={!!newPasswordError}
                    helperText={newPasswordError || " "}
                    className={styles.textField}
                    InputProps={{
                        classes: {
                            input: styles.textFieldRoot,
                        },
                    }}
                    FormHelperTextProps={{
                        classes: {
                            error: styles.errorHelperText,
                        },
                    }}
                />
                <label
                    htmlFor={`${textFieldIdPrefix}-confirm-new-password`}
                    className={styles.label}
                >
                    Confirm new password
                </label>
                <TextField
                    id={`${textFieldIdPrefix}-confirm-new-password`}
                    type="password"
                    variant="outlined"
                    required
                    value={repeatedNewPassword}
                    onChange={(e) => {
                        setRepeatedNewPassword(e.target.value);
                        setRepeatedNewPasswordError("");
                    }}
                    error={!!repeatedNewPasswordError}
                    helperText={repeatedNewPasswordError || " "}
                    className={styles.textField}
                    InputProps={{
                        classes: {
                            input: styles.textFieldRoot,
                        },
                    }}
                    FormHelperTextProps={{
                        classes: {
                            error: styles.errorHelperText,
                        },
                    }}
                />
                <PrimaryButton type="submit" className={styles.button} disabled={loading}>
                    Submit
                </PrimaryButton>
            </form>
        </div>
    );
}

export default PasswordChangePage;
