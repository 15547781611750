const TEXT_MIME_TYPE = "text/plain";
const HTML_MIME_TYPE = "text/html";

export function copyHtmlToClipboard(html: string): Promise<void> {
    return navigator.clipboard.write([
        new ClipboardItem({
            [TEXT_MIME_TYPE]: new Blob([html], { type: TEXT_MIME_TYPE }),
            [HTML_MIME_TYPE]: new Blob([html], { type: HTML_MIME_TYPE }),
        }),
    ]);
}
