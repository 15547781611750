import * as amplitude from "@amplitude/analytics-browser";
import { datadogRum } from "@datadog/browser-rum";
import React, { createContext, ReactElement, ReactNode } from "react";
import { useIntercom } from "react-use-intercom";

import NetworkingWrapper from "../components/NetworkingWrapper/NetworkingWrapper";
import { setUpAxios } from "../network/axiosConfig";
import { Organization } from "../network/responseTypes";

import { MeQuery, useMeQuery } from "./operations.generated";

interface UserContextState {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    role: string;
    avatarUrl?: string | null;
    isAuthenticated: boolean;
    organization: Organization;
}

const defaultValue: UserContextState = {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    role: "",
    avatarUrl: null,
    isAuthenticated: false,
    organization: {
        id: "",
        organization_name: "",
        logo_url: null,
        subscription: null,
        created_at: "",
        wordmark_url: null,
    },
};

export const UserContext = createContext<UserContextState>(defaultValue);

export function UserContextProvider(props: { children: ReactNode }): ReactElement {
    const intercom = useIntercom();

    const { loading, error, data } = useMeQuery({
        onCompleted: (data) => {
            setUpAxios(data.me?.organizations[0]?.id);
            const orgId = data.me?.organizations[0]?.id;
            if (orgId) {
                localStorage.setItem("organizationId", orgId);
            }

            if (data.me) {
                const userInfo = {
                    id: data.me.id,
                    name: `${data.me.firstName} ${data.me.lastName}`,
                    email: data.me.email,
                    organization_id: data.me.organizations[0]?.id,
                    organization_name: data.me.organizations[0]?.organizationName,
                };
                datadogRum.setUser(userInfo);
                amplitude.setUserId(data.me.email);
                if (userInfo.organization_id) {
                    amplitude.setGroup("organizationId", userInfo.organization_id);
                }

                const firstOrg = data.me.organizations[0];
                const subscriptionTier = firstOrg?.subscription?.tier;
                const isWithinSharedGuideLimit =
                    firstOrg?.subscription?.sharedGuideLimitStats.isWithinLimit ?? false;

                const identify = new amplitude.Identify();
                identify.set("subscriptionTier", subscriptionTier ?? "NONE");
                amplitude.identify(identify);

                intercom.update({
                    userId: data.me.id,
                    email: data.me.email,
                    name: `${data.me.firstName} ${data.me.lastName}`,
                    customAttributes: {
                        subscription_tier: subscriptionTier,
                        is_within_shared_guide_limit: isWithinSharedGuideLimit,
                    },
                });
            } else {
                intercom.shutdown();
                intercom.boot();
                datadogRum.clearUser();
                amplitude.reset();
                localStorage.removeItem("organizationId");
            }
        },
    });

    return (
        <NetworkingWrapper error={error} loading={loading}>
            {data && (
                <UserContext.Provider value={formatContextValue(data.me)}>
                    {props.children}
                </UserContext.Provider>
            )}
        </NetworkingWrapper>
    );
}

function formatContextValue(me: MeQuery["me"]): UserContextState {
    if (!me) return defaultValue;

    let organization = defaultValue.organization;

    if (me.organizations.length) {
        const firstOrg = me.organizations[0];

        organization = {
            id: firstOrg.id,
            organization_name: firstOrg.organizationName,
            logo_url: firstOrg.logoUrl || null,
            subscription: null,
            created_at: firstOrg.createdAt,
            wordmark_url: firstOrg.wordmarkUrl || null,
        };

        if (firstOrg.subscription) {
            organization.subscription = {
                is_trial: firstOrg.subscription.isTrial,
                is_expired: firstOrg.subscription.isExpired,
                is_within_plan_limit: firstOrg.subscription.isWithinPlanLimit,
                is_within_seat_limit: firstOrg.subscription.isWithinSeatLimit,
                shared_guide_limit_stats: firstOrg.subscription.sharedGuideLimitStats,
                days_remaining: firstOrg.subscription.daysRemaining,
                is_manually_managed: firstOrg.subscription.isManuallyManaged,
                tier: firstOrg.subscription.tier,
            };
        }
    }

    return {
        id: me.id,
        firstName: me.firstName,
        lastName: me.lastName,
        role: me.role,
        email: me.email,
        isAuthenticated: true,
        avatarUrl: me.avatarUrl,
        organization,
    };
}
