import { Autocomplete, Icon, TextField } from "@mui/material";
import { createFilterOptions } from "@mui/material/useAutocomplete";
import { useQueryClient } from "@tanstack/react-query";
import cx from "classnames";
import { useSnackbar } from "notistack";
import React, { ReactElement, useCallback } from "react";

import tagsIcon from "../../icons/tags.svg";
import { Tag } from "../../network/responseTypes";
import { QueryKey } from "../../queries/queryKeys";
import TagChip from "../TagsChip/TagChip";

import { useAutocompleteTagsQuery, useSetGuideTagsMutation } from "./operations.generated";
import styles from "./TagsAutocomplete.module.scss";

const filter = createFilterOptions<Tag>();

interface Props {
    guideId: string;
    tags: ReadonlyArray<Tag>;
    className?: string;
    readOnly?: boolean;
}

function TagsAutocomplete(props: Props): ReactElement | null {
    const reactQueryClient = useQueryClient();
    const { guideId, tags: guideTags, className, readOnly = false } = props;
    const { data } = useAutocompleteTagsQuery({ skip: readOnly });
    const [setGuideTags] = useSetGuideTagsMutation({
        refetchQueries: ["AutocompleteTags"],
        onCompleted: () => {
            void reactQueryClient.invalidateQueries([QueryKey.GuideDetail, guideId]);
        },
        onError: () => {
            enqueueSnackbar("Something went wrong when trying to modify tags for this guide.", {
                variant: "error",
            });
        },
    });
    const { enqueueSnackbar } = useSnackbar();

    const allTags = (
        data?.tags ? data.tags.map((t) => ({ id: t.id, name: t.name })) : []
    ) as ReadonlyArray<Tag>;

    const onChange = useCallback(
        (value: Array<Tag | string>) => {
            const tags = value.reduce((acc, val) => {
                let tagName = "";
                if (typeof val === "string" && val) tagName = val;
                if (typeof val === "object" && val.name) tagName = val.name;

                if (tagName.trim()) acc.push(tagName.trim());

                return acc;
            }, [] as string[]);
            void setGuideTags({ variables: { input: { guideId, tags } } });
        },
        [setGuideTags, guideId],
    );

    if (readOnly && guideTags.length === 0) {
        return null;
    }

    return (
        <div className={cx(styles.container, className)}>
            <img src={tagsIcon} className={styles.icon} alt="" />
            {readOnly && (
                <div className={styles.readOnlyTags}>
                    {guideTags.map((tag) => (
                        <TagChip key={tag.id} tag={tag} />
                    ))}
                </div>
            )}
            {!readOnly && (
                <Autocomplete
                    multiple
                    freeSolo
                    options={allTags as Array<Tag>}
                    getOptionLabel={(tag) => (tag as Tag).name}
                    defaultValue={[...guideTags]}
                    onChange={(_event, value) => onChange(value)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Add tags…"
                            InputProps={{
                                ...params.InputProps,
                                classes: { notchedOutline: styles.inputOutline },
                            }}
                            aria-label="Tags"
                        />
                    )}
                    renderTags={(value, getTagProps) =>
                        value.map((tag, index) => (
                            <TagChip {...getTagProps({ index })} key={tag.id} tag={tag} multiline />
                        ))
                    }
                    disableClearable
                    filterSelectedOptions
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    filterOptions={(options, state) => {
                        const filtered = filter(options, state);
                        if (state.inputValue !== "") {
                            filtered.push({
                                id: "",
                                name: state.inputValue,
                            });
                        }
                        return filtered;
                    }}
                    groupBy={(option) => (option.id === "" ? "create-tag" : "")}
                    renderGroup={(option) => (
                        <div key={option.key}>
                            <hr className={styles.separator} />
                            {option.children}
                        </div>
                    )}
                    renderOption={({ className, ...otherProps }, option) => {
                        return option.id ? (
                            <li {...otherProps} className={className} key={option.id}>
                                {option.name}
                            </li>
                        ) : (
                            <li {...otherProps} className={cx(styles.createTagOption, className)}>
                                <Icon fontSize="small" className={styles.createIcon} aria-label="">
                                    add
                                </Icon>
                                Create {option.name}
                            </li>
                        );
                    }}
                    classes={{
                        root: styles.autocomplete,
                        inputRoot: styles.inputRoot,
                        input: styles.input,
                        listbox: styles.listbox,
                        option: styles.option,
                    }}
                />
            )}
        </div>
    );
}

export default TagsAutocomplete;
