import { useMediaQuery } from "./mediaQuery";

const MEDIA_QUERY = "(prefers-reduced-motion)";

/**
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/CSS/@media/prefers-reduced-motion}
 */
export function usePrefersReducedMotion(): boolean {
    return useMediaQuery(MEDIA_QUERY);
}
