import {
    MutationOptions,
    useMutation,
    UseMutationResult,
    useQueryClient,
} from "@tanstack/react-query";
import Axios from "axios";

import { GuideViewEvent } from "../network/responseTypes";
import { QueryKey } from "../queries/queryKeys";

export enum ViewEventSource {
    PRIVATE_GUIDE_DETAIL_PAGE = "private_guide_detail_page",
    PUBLIC_GUIDE_DETAIL_PAGE = "public_guide_detail_page",
    GUIDE_EMBED = "guide_embed",
}

export interface RecordGuideViewVariables {
    guideId: string;
    source: ViewEventSource;
}

export function useRecordGuideViewMutation(
    options?: MutationOptions<GuideViewEvent, Error, RecordGuideViewVariables>,
): UseMutationResult<GuideViewEvent, Error, RecordGuideViewVariables> {
    const client = useQueryClient();
    return useMutation(
        async ({ guideId, source }) => {
            const { data } = await Axios.post<GuideViewEvent>(
                `/api/guides/${guideId}/record-view/`,
                {
                    source,
                },
            );
            return data;
        },
        {
            ...options,
            onSuccess: (data, variables, context) => {
                options?.onSuccess?.(data, variables, context);
                // Invalidate the view count number to include this view event.
                return client.invalidateQueries([QueryKey.GuideDetail, variables.guideId]);
            },
        },
    );
}
