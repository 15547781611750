import { useMutation, useQueryClient } from "@tanstack/react-query";
import Axios from "axios";

import { QueryKey } from "../queries/queryKeys";

interface ReplaceScreenshotInput {
    guideId: string;
    stepId: string;
    dataUrl: string;
}

export function useReplaceGuideStepScreenshotMutation() {
    const client = useQueryClient();

    return useMutation<void, Error, ReplaceScreenshotInput>(
        async ({ stepId, dataUrl }) => {
            const data = {
                step_id: stepId,
                screenshot_data_url: dataUrl,
            };

            await Axios.post(`/api/screenshot/replace`, data);
        },
        {
            onSuccess: async (_, { guideId }) => {
                await client.invalidateQueries([QueryKey.GuideDetail, guideId]);
            },
        },
    );
}
