import { Button, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { ReactElement, useContext, useId, useState } from "react";
import { Link } from "react-router-dom";
import { useIntercom } from "react-use-intercom";

import { UserContext } from "../../contexts/user";
import AvatarUploader from "../AvatarUploader/AvatarUploader";
import { Card } from "../Card/Card";
import PrimaryButton from "../PrimaryButton/PrimaryButton";

import { useUpdateProfileMutation } from "./operations.generated";
import styles from "./ProfileSettings.module.scss";

const isFormDirty = (formState: FormState, user: FormState): boolean => {
    return (
        formState.firstName !== user.firstName ||
        formState.lastName !== user.lastName ||
        formState.role !== user.role
    );
};

interface FormState {
    firstName: string;
    lastName: string;
    role: string;
}

function ProfileSettings(): ReactElement {
    const user = useContext(UserContext);
    const [formState, setFormState] = useState<FormState>({
        firstName: user.firstName,
        lastName: user.lastName,
        role: user.role,
    });

    const [updateProfile, { loading }] = useUpdateProfileMutation({
        refetchQueries: ["Me"],
        onError: () => {
            enqueueSnackbar(
                "Something went wrong when trying to update your profile. Please try again.",
                { variant: "error" },
            );
        },
        onCompleted: () => {
            enqueueSnackbar("Your profile has been successfully changed.", {
                variant: "success",
            });
        },
    });
    const { enqueueSnackbar } = useSnackbar();
    const { shutdown: shutdownIntercom } = useIntercom();

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        void updateProfile({ variables: { input: formState } });
    };

    const formError = {
        firstName: !formState.firstName,
        lastName: !formState.lastName,
    };

    const textFieldIdPrefix = useId();

    return (
        <Card variant="content" className={styles.container}>
            <div className={styles.avatarContainer}>
                <AvatarUploader />
            </div>
            <form
                noValidate
                autoComplete="off"
                className={styles.profileFormColumn}
                onSubmit={handleSubmit}
            >
                <label className={styles.label} htmlFor={`${textFieldIdPrefix}-first-name`}>
                    First name
                </label>
                <TextField
                    id={`${textFieldIdPrefix}-first-name`}
                    variant="outlined"
                    value={formState.firstName}
                    onChange={(e) => {
                        setFormState((state) => ({ ...state, firstName: e.target.value }));
                    }}
                    error={formError.firstName}
                    helperText={formError.firstName ? "First name cannot be empty." : undefined}
                    className={styles.textField}
                    InputProps={{
                        classes: {
                            input: styles.textFieldRoot,
                        },
                    }}
                    FormHelperTextProps={{
                        classes: {
                            error: styles.errorHelperText,
                        },
                    }}
                />
                <label className={styles.label} htmlFor={`${textFieldIdPrefix}-last-name`}>
                    Last name
                </label>
                <TextField
                    id={`${textFieldIdPrefix}-last-name`}
                    variant="outlined"
                    value={formState.lastName}
                    onChange={(e) => {
                        setFormState((state) => ({ ...state, lastName: e.target.value }));
                    }}
                    error={formError.lastName}
                    helperText={formError.lastName ? "Last name cannot be empty." : undefined}
                    className={styles.textField}
                    InputProps={{
                        classes: {
                            input: styles.textFieldRoot,
                        },
                    }}
                    FormHelperTextProps={{
                        classes: {
                            error: styles.errorHelperText,
                        },
                    }}
                />
                <label className={styles.label} htmlFor={`${textFieldIdPrefix}-role`}>
                    Role
                </label>
                <TextField
                    id={`${textFieldIdPrefix}-role`}
                    variant="outlined"
                    value={formState.role}
                    onChange={(e) => {
                        setFormState((state) => ({ ...state, role: e.target.value }));
                    }}
                    className={styles.textField}
                    InputProps={{
                        classes: {
                            input: styles.textFieldRoot,
                        },
                    }}
                />
                <div className={styles.buttonRow}>
                    <PrimaryButton
                        type="submit"
                        className={styles.updateButton}
                        disabled={
                            loading ||
                            formError.firstName ||
                            formError.lastName ||
                            !isFormDirty(formState, user)
                        }
                    >
                        Update
                    </PrimaryButton>
                    <Button
                        variant="outlined"
                        className={styles.outlinedButton}
                        component={Link}
                        to="/settings/password/change"
                    >
                        Change password
                    </Button>
                    <Button
                        variant="outlined"
                        className={styles.outlinedButton}
                        component="a"
                        // Do not use React Router's Link, as the logout route
                        // is handled by Django, not the React frontend
                        href="/accounts/logout/"
                        onClick={() => {
                            shutdownIntercom();
                        }}
                    >
                        Log out
                    </Button>
                </div>
            </form>
        </Card>
    );
}

export default ProfileSettings;
