/* eslint-disable */
// THIS IS A GENERATED FILE. DO NOT EDIT DIRECTLY
// To generate new type definitions run `npm run generate-schema-types`
import * as Types from '../../../@types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddVideoStepMutationVariables = Types.Exact<{
  input: Types.AddVideoStepMutationInput;
}>;


export type AddVideoStepMutation = { readonly __typename?: 'Mutation', readonly addVideoStep?: { readonly __typename?: 'AddVideoStepMutation', readonly ok: boolean } | null };


export const AddVideoStepDocument = gql`
    mutation AddVideoStep($input: AddVideoStepMutationInput!) {
  addVideoStep(input: $input) {
    ok
  }
}
    `;
export type AddVideoStepMutationFn = Apollo.MutationFunction<AddVideoStepMutation, AddVideoStepMutationVariables>;

/**
 * __useAddVideoStepMutation__
 *
 * To run a mutation, you first call `useAddVideoStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddVideoStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addVideoStepMutation, { data, loading, error }] = useAddVideoStepMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddVideoStepMutation(baseOptions?: Apollo.MutationHookOptions<AddVideoStepMutation, AddVideoStepMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddVideoStepMutation, AddVideoStepMutationVariables>(AddVideoStepDocument, options);
      }
export type AddVideoStepMutationHookResult = ReturnType<typeof useAddVideoStepMutation>;
export type AddVideoStepMutationResult = Apollo.MutationResult<AddVideoStepMutation>;
export type AddVideoStepMutationOptions = Apollo.BaseMutationOptions<AddVideoStepMutation, AddVideoStepMutationVariables>;