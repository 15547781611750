/* eslint-disable */
// THIS IS A GENERATED FILE. DO NOT EDIT DIRECTLY
// To generate new type definitions run `npm run generate-schema-types`
import * as Types from '../../../@types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ScreenshotQueryVariables = Types.Exact<{
  stepId: Types.Scalars['ID']['input'];
}>;


export type ScreenshotQuery = { readonly __typename?: 'Query', readonly screenshot: { readonly __typename?: 'ScreenshotType', readonly originalUrl: string, readonly editedUrl: string, readonly hasDeprecatedEdits: boolean, readonly imageStateHistory?: any | null } };

export type GuideColorCustomizationsQueryVariables = Types.Exact<{
  guideId: Types.Scalars['ID']['input'];
}>;


export type GuideColorCustomizationsQuery = { readonly __typename?: 'Query', readonly guideCustomizations: { readonly __typename?: 'OrgGuideCustomizationsType', readonly color?: string | null } };


export const ScreenshotDocument = gql`
    query Screenshot($stepId: ID!) {
  screenshot(stepId: $stepId) {
    originalUrl
    editedUrl
    hasDeprecatedEdits
    imageStateHistory
  }
}
    `;

/**
 * __useScreenshotQuery__
 *
 * To run a query within a React component, call `useScreenshotQuery` and pass it any options that fit your needs.
 * When your component renders, `useScreenshotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScreenshotQuery({
 *   variables: {
 *      stepId: // value for 'stepId'
 *   },
 * });
 */
export function useScreenshotQuery(baseOptions: Apollo.QueryHookOptions<ScreenshotQuery, ScreenshotQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ScreenshotQuery, ScreenshotQueryVariables>(ScreenshotDocument, options);
      }
export function useScreenshotLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ScreenshotQuery, ScreenshotQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ScreenshotQuery, ScreenshotQueryVariables>(ScreenshotDocument, options);
        }
export type ScreenshotQueryHookResult = ReturnType<typeof useScreenshotQuery>;
export type ScreenshotLazyQueryHookResult = ReturnType<typeof useScreenshotLazyQuery>;
export type ScreenshotQueryResult = Apollo.QueryResult<ScreenshotQuery, ScreenshotQueryVariables>;
export const GuideColorCustomizationsDocument = gql`
    query GuideColorCustomizations($guideId: ID!) {
  guideCustomizations(guideId: $guideId) {
    color
  }
}
    `;

/**
 * __useGuideColorCustomizationsQuery__
 *
 * To run a query within a React component, call `useGuideColorCustomizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuideColorCustomizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuideColorCustomizationsQuery({
 *   variables: {
 *      guideId: // value for 'guideId'
 *   },
 * });
 */
export function useGuideColorCustomizationsQuery(baseOptions: Apollo.QueryHookOptions<GuideColorCustomizationsQuery, GuideColorCustomizationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GuideColorCustomizationsQuery, GuideColorCustomizationsQueryVariables>(GuideColorCustomizationsDocument, options);
      }
export function useGuideColorCustomizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GuideColorCustomizationsQuery, GuideColorCustomizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GuideColorCustomizationsQuery, GuideColorCustomizationsQueryVariables>(GuideColorCustomizationsDocument, options);
        }
export type GuideColorCustomizationsQueryHookResult = ReturnType<typeof useGuideColorCustomizationsQuery>;
export type GuideColorCustomizationsLazyQueryHookResult = ReturnType<typeof useGuideColorCustomizationsLazyQuery>;
export type GuideColorCustomizationsQueryResult = Apollo.QueryResult<GuideColorCustomizationsQuery, GuideColorCustomizationsQueryVariables>;