import React, { ReactElement } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import cx from "classnames";

import EventTrackingGuidePreview from "../../../components/EventTrackingGuidePreview/EventTrackingGuidePreview";
import FullScreenLoader from "../../../components/FullScreenLoader/FullScreenLoader";
import GuideViewStatsButton from "../../../components/GuideViewStatsButton/GuideViewStatsButton";
import InformationalTooltip from "../../../components/InformationalTooltip/InformationalTooltip";
import LiveViewButton from "../../../components/LiveViewButton/LiveViewButton";
import MadeWithDrivewayBadge from "../../../components/MadeWithDrivewayBadge/MadeWithDrivewayBadge";
import OrganizationLogo from "../../../components/OrganizationLogo/OrganizationLogo";
import PlaceholderMessage from "../../../components/PlaceholderMessage/PlaceholderMessage";
import { isGuideCustomizationEnabled } from "../../../hooks/featureAccess";
import GuideDetailBar from "../../../layouts/GuideDetailBar/GuideDetailBar";
import { ViewEventSource } from "../../../mutations/recordGuideView";
import { useGuideDetailPublicQuery } from "../../../queries/guideDetailPublic";
import { useStepManager } from "../../../util/GuideUtil";
import { useRequiredParams } from "../../../util/ReactRouterUtil";
import { slateNodesToString } from "../../../util/SlateUtil";
import { withMobileExperience } from "../../withMobileExperience";
import GuideDetailEmbedPage from "../guide-detail-embed/GuideDetailEmbedPage";
import { useGuideCustomizationsQuery } from "../guide-detail/operations.generated";

import styles from "./GuideDetailPublicPage.module.scss";

function GuideDetailPublicPage(): ReactElement {
    const { guideId } = useRequiredParams(["guideId"]);
    const location = useLocation();

    const { data: guide, isLoading: isGuideDetailLoading } = useGuideDetailPublicQuery(guideId, {
        onError: () => {
            window.location.replace(`/accounts/login/?next=${location.pathname}`);
        },
    });

    const displayOrgLogo = !!guide?.organization && isGuideCustomizationEnabled(guide.organization);

    const {
        stepNumber,
        goToNextStep,
        goToPreviousStep,
        reset: resetStepManager,
        setStepNumber,
    } = useStepManager(guide?.steps.length || 0);

    // not using error bc we don't want the page to depend on customizations
    const { data: guideCustomizationsData, loading: isGuideCustomizationsLoading } =
        useGuideCustomizationsQuery({
            variables: {
                guideId,
            },
        });

    const isLoading = isGuideDetailLoading || isGuideCustomizationsLoading;

    const hidePlayButton = !!guideCustomizationsData?.guideCustomizations.hidePlayButton;

    const plaintextDescription = guide?.description ? slateNodesToString(guide.description) : "";

    return (
        <div className={styles.pageContainer}>
            {isLoading && <FullScreenLoader />}
            {guide && (
                <div
                    // while this looks redundant, it's important for ss rendering
                    className={styles.pageContainer}
                >
                    <Helmet title={guide.title} />
                    <GuideDetailBar>
                        {displayOrgLogo && !!guide.organization?.logo_url && (
                            <OrganizationLogo
                                src={guide.organization.wordmark_url || guide.organization.logo_url}
                                name={guide.organization.organization_name}
                                className={cx(
                                    styles.orgLogo,
                                    guide.organization.wordmark_url ? styles.wordmark : styles.logo,
                                )}
                            />
                        )}
                        <h1 className={styles.pageTitle}>{guide?.title}</h1>
                        {plaintextDescription && (
                            <InformationalTooltip>{plaintextDescription}</InformationalTooltip>
                        )}
                        <div className={styles.guideActions}>
                            <GuideViewStatsButton
                                guideId={guideId}
                                numViews={guide.num_views}
                                readOnly
                            />
                            {!isGuideCustomizationEnabled(guide.organization) && (
                                <MadeWithDrivewayBadge className={styles.madeWithDrivewayBadge} />
                            )}
                            {!hidePlayButton &&
                                // Enums are uppercase in GQL and lowercase in REST
                                guide.organization.subscription?.tier?.toUpperCase() ===
                                    "ENTERPRISE" && <LiveViewButton guide={guide} />}
                        </div>
                    </GuideDetailBar>
                    <div className={styles.contentContainer}>
                        {guide.steps.length === 0 ? (
                            <PlaceholderMessage
                                className={styles.placeholder}
                                title="There are no steps in this guide."
                            />
                        ) : (
                            <EventTrackingGuidePreview
                                guide={guide}
                                stepIndex={stepNumber}
                                onNextStep={goToNextStep}
                                onPreviousStep={goToPreviousStep}
                                onRestart={resetStepManager}
                                onSetStep={setStepNumber}
                                eventSource={ViewEventSource.PUBLIC_GUIDE_DETAIL_PAGE}
                                canComplete={false}
                            />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

export default withMobileExperience(GuideDetailPublicPage, GuideDetailEmbedPage);
