import { noop } from "lodash";
import { useCallback, useMemo, useRef, useState } from "react";

import { useRecordGuideViewMutation, ViewEventSource } from "../mutations/recordGuideView";
import { RecordStepViewVariables, useRecordStepViewMutation } from "../mutations/recordStepView";
import { GuideViewEvent } from "../network/responseTypes";

interface Options {
    source: ViewEventSource;
}

type RecordViewEventFunc = (attrs: GuideAttributes | StepAttributes) => void;

interface GuideAttributes {
    type: "guide";
    guideId: string;
    force?: boolean;
}

interface StepAttributes
    extends Pick<RecordStepViewVariables, "stepId" | "stepIndex" | "totalSteps"> {
    type: "step";
}

export function useViewEventRecorder(options: Options): RecordViewEventFunc {
    const [guideViewEvent, recordGuideView] = useGuideViewRecorder(options);
    const makeStepViewRecordFunc = useStepViewRecorder(options);

    const recordStepView = useMemo(
        () => (guideViewEvent ? makeStepViewRecordFunc(guideViewEvent.id) : noop),
        [guideViewEvent, makeStepViewRecordFunc],
    );

    return useCallback(
        (attrs) => (attrs.type === "guide" ? recordGuideView(attrs) : recordStepView(attrs)),
        [recordGuideView, recordStepView],
    );
}

function useGuideViewRecorder(
    options: Options,
): [GuideViewEvent | undefined, (attrs: GuideAttributes) => void] {
    const { source } = options;

    const [viewEvent, setViewEvent] = useState<GuideViewEvent>();

    const guideIdRef = useRef<string>();
    const { mutate } = useRecordGuideViewMutation({
        // Use onSuccess callback of useMutation hook, NOT onSuccess option passed to mutate function.
        // If component unmounts before the mutation succeeds, mutate function's onSuccess will not trigger.
        onSuccess: (data) => setViewEvent(data),
    });

    return [
        viewEvent,
        useCallback(
            (attrs) => {
                const { guideId, force = false } = attrs;
                if (force || guideIdRef.current !== guideId) {
                    mutate({ guideId, source });
                    guideIdRef.current = guideId;
                }
            },
            [mutate, source],
        ),
    ];
}

function useStepViewRecorder(
    options: Options,
): (guideViewEventId: string) => (attrs: StepAttributes) => void {
    const { source } = options;
    const { mutate } = useRecordStepViewMutation();

    return useCallback(
        (guideViewEventId) => {
            const viewedSteps = new Set();
            return (attrs) => {
                const { stepId, stepIndex, totalSteps } = attrs;
                if (guideViewEventId && !viewedSteps.has(stepId)) {
                    mutate({ guideViewEventId, source, stepId, stepIndex, totalSteps });
                    viewedSteps.add(stepId);
                }
            };
        },
        [source, mutate],
    );
}
