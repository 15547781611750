import cx from "classnames";
import React, { ReactElement, useCallback, useRef } from "react";

import { ActionStep } from "../../network/responseTypes";
import ClickableInterestPointHighlighter from "../ClickableInterestPointHighlighter/ClickableInterestPointHighlighter";
import StepEditorMessageBubble from "../StepEditorMessageBubble/StepEditorMessageBubble";

import styles from "./EditableStepScreenshot.module.scss";

interface Props {
    guideId: string;
    step: ActionStep;
    onNextStep: () => void;
    onPreviousStep: () => void;
    onRestart: () => void;
    stepIndex: number;
    stepCount: number;
    className?: string;
}

function EditableStepScreenshot(props: Props): ReactElement {
    const {
        guideId,
        step,
        onNextStep,
        onPreviousStep,
        onRestart,
        stepIndex,
        stepCount,
        className,
    } = props;

    const screenshotImgRef = useRef<HTMLImageElement>(null);
    const { height: imageHeight, width: imageWidth, url: screenshotUrl } = step.screenshot;

    const getScreenshotBoundingClientRect = useCallback(
        () =>
            screenshotImgRef.current?.getBoundingClientRect() ??
            // This should never happen
            new DOMRect(),
        [],
    );

    const interestPoint = step.interest_points[0];

    return (
        <div className={cx(styles.container, className)}>
            <img
                src={screenshotUrl}
                alt={step.title + " screenshot"}
                className={styles.screenshotImage}
                ref={screenshotImgRef}
            />
            {screenshotUrl !== undefined && (
                <div className={styles.messageBubbleWrapper}>
                    <StepEditorMessageBubble
                        guideId={guideId}
                        step={step}
                        screenshotUrl={screenshotUrl}
                        onNextStep={onNextStep}
                        onPreviousStep={onPreviousStep}
                        onRestart={onRestart}
                        stepIndex={stepIndex}
                        stepCount={stepCount}
                        interestPoint={interestPoint}
                        getImageElBoundingClientRect={getScreenshotBoundingClientRect}
                    />
                    {interestPoint !== undefined && (
                        <ClickableInterestPointHighlighter
                            color={step.color ?? undefined}
                            interestPoint={interestPoint}
                            imageWidth={imageWidth}
                            imageHeight={imageHeight}
                            getImageElBoundingClientRect={getScreenshotBoundingClientRect}
                            onClick={onNextStep}
                        />
                    )}
                </div>
            )}
        </div>
    );
}

export default EditableStepScreenshot;
