import React from "react";
import { Dialog, Icon, IconButton } from "@mui/material";

import RedactWrapper from "./RedactWrapper";
import styles from "./RedactImageDialog.module.scss";

interface Props {
    imageUrl: string;
    isOpen: boolean;
    stepId: string;
    guideId: string;
    onClose: () => void;
}

function RedactImageDialog({ isOpen, onClose, imageUrl, stepId, guideId }: Props) {
    return (
        <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth scroll="body">
            <div className={styles.content}>
                <div className={styles.titleWrapper}>
                    <h2 className={styles.title}>Blur sensitive content</h2>
                    <IconButton
                        aria-label="Close"
                        onClick={onClose}
                        className={styles.closeButton}
                        size="large"
                    >
                        <Icon>close</Icon>
                    </IconButton>
                </div>
                <RedactWrapper
                    guideId={guideId}
                    stepId={stepId}
                    onClose={onClose}
                    imageUrl={imageUrl}
                />
            </div>
        </Dialog>
    );
}

export default RedactImageDialog;
