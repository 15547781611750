import cx from "classnames";
import React, { ReactElement } from "react";

import playIcon from "../../icons/play.svg";

import styles from "./PlayButton.module.scss";

interface Props {
    onClick: () => void;
    className?: string;
}

function PlayButton(props: Props): ReactElement {
    const { onClick, className } = props;
    return (
        <button onClick={onClick} aria-label="Play" className={cx(styles.button, className)}>
            <div className={styles.inner}>
                <img src={playIcon} alt="" className={styles.playIcon} />
            </div>
        </button>
    );
}

export default PlayButton;
