import React, { ReactElement } from "react";
import { Chip, Icon, ChipProps } from "@mui/material";
import cx from "classnames";

import { Tag } from "../../network/responseTypes";

import styles from "./TagChip.module.scss";

interface Props extends ChipProps {
    tag: Tag;
    multiline?: boolean;
    className?: string;
}

function TagChip(props: Props): ReactElement {
    const { tag, multiline, className, ...restProps } = props;
    return (
        <Chip
            {...restProps}
            label={tag.name}
            deleteIcon={<Icon aria-label="Delete tag">close</Icon>}
            classes={{
                root: styles.root,
                label: cx(styles.label, {
                    [styles.multiline]: multiline,
                }),
            }}
            className={className}
        />
    );
}

export default TagChip;
