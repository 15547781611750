import { Button, ButtonProps } from "@mui/material";
import React, { ReactElement } from "react";

import styles from "./SecondaryButton.module.scss";

interface ButtonWithAnchor extends ButtonProps {
    target?: string;
}

function SecondaryButton(props: ButtonWithAnchor): ReactElement {
    return (
        <Button
            variant="outlined"
            classes={{
                root: styles.button,
                disabled: styles.disabled,
            }}
            {...props}
        />
    );
}

export default SecondaryButton;
