import { Button, Icon } from "@mui/material";
import cx from "classnames";
import React, { ReactElement } from "react";
import { useIntercom } from "react-use-intercom";

import { useCopyToClipboard } from "../../hooks/copyToClipboard";
import copyIcon from "../../icons/copy.svg";

import styles from "./GuideCopyButtons.module.scss";
import { EmbedFormat } from "./ShareGuideEmbedTab";

interface Props {
    format: EmbedFormat;
    copyValue: string;
    disabled?: boolean;
    className?: string;
    guideId: string;
}

function GuideCopyEmbedCodeButton(props: Props): ReactElement {
    const { format, disabled, copyValue, className, guideId } = props;

    const copyToClipboard = useCopyToClipboard();
    const isSnippet = format === EmbedFormat.Snippet;
    const intercom = useIntercom();

    const handleClick = () => {
        copyToClipboard(copyValue, isSnippet ? "embed snippet" : "embed link");
        intercom.trackEvent("clicked_copy_embed_code", {
            format: isSnippet ? "snippet" : "link",
            guideId,
        });
    };

    return (
        <Button
            classes={{ root: cx(styles.copyButton, className) }}
            color="primary"
            variant="contained"
            disabled={disabled}
            data-dd-action-name={
                isSnippet ? "Copy Guide Embed Snippet Button" : "Copy Guide Embed Link Button"
            }
            onClick={handleClick}
            fullWidth
            startIcon={
                <Icon classes={{ root: styles.buttonIcon }}>
                    <img src={copyIcon} alt="" />
                </Icon>
            }
        >
            Copy embed {isSnippet ? "snippet" : "link"}
        </Button>
    );
}

export default GuideCopyEmbedCodeButton;
