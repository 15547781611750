import { Link, Tabs } from "@mui/material";
import React, { ReactElement, useId, useRef, useState } from "react";

import { Guide } from "../../network/responseTypes";
import Pill from "../Pill/Pill";

import GuideCopyEmbedCodeButton from "./GuideCopyEmbedCodeButton";
import styles from "./ShareGuideEmbedTab.module.scss";

export enum EmbedFormat {
    Snippet = "snippet",
    Link = "link",
}

interface Props {
    guide: Guide;
    navigateToPrivacySettings: () => void;
}

function ShareGuideEmbedTab({ guide, navigateToPrivacySettings }: Props): ReactElement {
    const [embedFormat, setEmbedFormat] = useState<EmbedFormat>(EmbedFormat.Snippet);

    const textareaEl = useRef<HTMLTextAreaElement | null>(null);
    const inputEl = useRef<HTMLInputElement | null>(null);

    const isEmbedDisabled = !guide.is_public;
    const embedLink = `${window.location.origin}/guides/${guide.id}/embed`;
    const embedSnippet = `<iframe style="border: 1px solid rgba(0, 0, 0, 0.1);" width="600" height="500" src="${embedLink}" allow="clipboard-write" allowfullscreen></iframe>`;

    const footer = (
        <>
            <GuideCopyEmbedCodeButton
                format={embedFormat}
                disabled={isEmbedDisabled}
                copyValue={embedFormat === EmbedFormat.Snippet ? embedSnippet : embedLink}
                className={styles.copyButton}
                guideId={guide.id}
            />
            <div className={styles.privacySettings}>
                {!isEmbedDisabled ? (
                    `✅ Public sharing is enabled for this guide. Embed this guide by copying and pasting the ${
                        embedFormat === EmbedFormat.Snippet ? "snippet" : "link"
                    } above.`
                ) : (
                    <>
                        👀 Embedded guides must have public sharing turned on. Update this
                        guide&rsquo;s{" "}
                        <Link
                            onClick={navigateToPrivacySettings}
                            component="button"
                            variant="body2"
                        >
                            Privacy settings
                        </Link>{" "}
                        to <strong>Anyone with the link can view</strong>.
                    </>
                )}
            </div>
        </>
    );

    const tabIdPrefix = useId();
    const panelIdPrefix = useId();

    return (
        <div className={styles.container}>
            <Tabs
                value={embedFormat}
                onChange={(_event, newValue) => setEmbedFormat(newValue)}
                aria-label="Embed format"
                TabIndicatorProps={{ style: { display: "none" } }}
                selectionFollowsFocus
            >
                <Pill
                    label="Snippet"
                    value={EmbedFormat.Snippet}
                    id={`${tabIdPrefix}-snippet`}
                    aria-controls={`${panelIdPrefix}-snippet`}
                />
                <Pill
                    label="Link"
                    value={EmbedFormat.Link}
                    id={`${tabIdPrefix}-link`}
                    aria-controls={`${panelIdPrefix}-link`}
                />
            </Tabs>
            <div
                id={`${panelIdPrefix}-snippet`}
                role="tabpanel"
                hidden={embedFormat !== EmbedFormat.Snippet}
                aria-labelledby={`${tabIdPrefix}-snippet`}
                className={styles.tabPanel}
            >
                {embedFormat === EmbedFormat.Snippet && (
                    <>
                        <textarea
                            aria-label="Snippet"
                            ref={textareaEl}
                            onClick={() => textareaEl.current?.select()}
                            className={styles.snippetTextarea}
                            readOnly
                            disabled={isEmbedDisabled}
                            value={embedSnippet}
                        />
                        {footer}
                    </>
                )}
            </div>
            <div
                id={`${panelIdPrefix}-link`}
                role="tabpanel"
                hidden={embedFormat !== EmbedFormat.Link}
                aria-labelledby={`${tabIdPrefix}-link`}
                className={styles.tabPanel}
            >
                {embedFormat === EmbedFormat.Link && (
                    <>
                        <input
                            type="text"
                            aria-label="Link"
                            ref={inputEl}
                            onClick={() => inputEl.current?.select()}
                            className={styles.linkInput}
                            readOnly
                            disabled={isEmbedDisabled}
                            value={embedLink}
                        />
                        {footer}
                    </>
                )}
            </div>
        </div>
    );
}

export default ShareGuideEmbedTab;
