import { PlayCircle as PlayIcon } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { ReactElement, useState } from "react";

import { useSendGuideToExtensionMutation } from "../../mutations/sendGuideToExtension";
import { Guide } from "../../network/responseTypes";
import { useChromeExtensionInstalledQuery } from "../../queries/chromeExtensionInstalled";
import DownloadExtensionDialog from "../DownloadExtensionDialog/DownloadExtensionDialog";
import TopBarButton from "../TopBarButton/TopBarButton";

import styles from "./LiveViewButton.module.scss";

const GENERAL_ERROR_MESSAGE = "Something went wrong while starting the interactive guide.";

interface Props {
    guide: Guide;
}

function LiveViewButton(props: Props): ReactElement {
    const { guide } = props;
    const { mutate: sendGuideToExtension } = useSendGuideToExtensionMutation();
    const { data: extensionInstalled } = useChromeExtensionInstalledQuery();
    const { enqueueSnackbar } = useSnackbar();
    const [showDownloadDialog, setShowDownloadDialog] = useState<boolean>(false);

    return (
        <>
            <Tooltip
                title="Learn this guide by launching it as an interactive walkthrough in a new tab."
                classes={{ tooltip: styles.tooltip }}
            >
                <TopBarButton
                    startIcon={<PlayIcon />}
                    disabled={!guide.supports_live_view}
                    data-dd-action-name="Launch Live View Button"
                    onClick={
                        !extensionInstalled
                            ? () => setShowDownloadDialog(true)
                            : () =>
                                  sendGuideToExtension(
                                      { guide },
                                      {
                                          onSuccess: (data) =>
                                              console.log("Guide sent successfully", data),
                                          onError: (error) => {
                                              console.error(
                                                  "Error sending guide to extension",
                                                  error,
                                              );
                                              enqueueSnackbar(GENERAL_ERROR_MESSAGE, {
                                                  variant: "error",
                                              });
                                          },
                                      },
                                  )
                    }
                >
                    Play guide
                </TopBarButton>
            </Tooltip>
            <DownloadExtensionDialog
                open={showDownloadDialog}
                onClose={() => setShowDownloadDialog(false)}
            />
        </>
    );
}

export default LiveViewButton;
