import { useQuery, UseQueryResult } from "@tanstack/react-query";

import { useChromeExtensionId } from "../hooks/chromeExtensionId";
import { sendDataToExtension } from "../util/ChromeExtensionUtil";

import { QueryKey } from "./queryKeys";

interface ExtensionVersion {
    extensionVersion: string;
}

export function useChromeExtensionVersionQuery(): UseQueryResult<string | null> {
    const extensionId = useChromeExtensionId();
    return useQuery<string | null>(
        [QueryKey.ChromeExtensionVersion, extensionId],
        // Attempt to send a message to the Chrome extension.
        // If no response is returned, the extension is not installed.
        () =>
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            sendDataToExtension<void, ExtensionVersion>(extensionId!, "readVersion")
                .then((data) => data.extensionVersion)
                .catch(() => null),
        { enabled: extensionId !== undefined, networkMode: "always" },
    );
}
