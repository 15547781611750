import { FloatingArrow, FloatingArrowProps } from "@floating-ui/react";
import React from "react";

type Props = Omit<FloatingArrowProps, "tipRadius" | "fill">;

const ARROW_TIP_RADIUS = 1;
const ARROW_FILL_COLOR = "#1d1f20"; // grey900, matches MessageBubble background

export default React.forwardRef<SVGSVGElement, Props>(
    function MessageBubbleFloatingArrow(props, ref) {
        return (
            <FloatingArrow
                ref={ref}
                tipRadius={ARROW_TIP_RADIUS}
                fill={ARROW_FILL_COLOR}
                {...props}
            />
        );
    },
);
