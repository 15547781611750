import { Button, DialogContent, DialogTitle, TextField } from "@mui/material";
import cx from "classnames";
import { useSnackbar } from "notistack";
import React, { ReactElement, useCallback, useContext, useId } from "react";
import { useIntercom } from "react-use-intercom";

import { UserContext } from "../../contexts/user";
import { formatInviteErrorMessage } from "../../util/ErrorUtil";
import { getInviteUserDescription } from "../../util/SubscriptionUtil";

import styles from "./InviteUserForm.module.scss";
import { InviteUsersMutation, useInviteUsersMutation } from "./operations.generated";

interface Props {
    invitees: string;
    setInvitees: (invitees: string) => void;
    errorMessage: string;
    setErrorMessage: (errorMessage: string) => void;
    onClose: () => void;
    onSuccess: () => void;
}

function InviteUserForm(props: Props): ReactElement {
    const { invitees, setInvitees, errorMessage, setErrorMessage, onClose, onSuccess } = props;
    const {
        organization: { subscription },
    } = useContext(UserContext);
    const description = getInviteUserDescription(
        subscription?.tier ?? "FREE",
        !!subscription?.is_trial,
    );

    const { enqueueSnackbar } = useSnackbar();
    const intercom = useIntercom();
    const [inviteUsers, { loading }] = useInviteUsersMutation({
        refetchQueries: ["TeamMembers"],
        onCompleted: (data: InviteUsersMutation) => {
            if (data.inviteUsers?.errors) {
                setErrorMessage(formatInviteErrorMessage(data.inviteUsers.errors[0].messages[0]));
            } else {
                setInvitees("");
                onSuccess();
            }
        },
        onError: () => {
            enqueueSnackbar("Something went wrong when sending invitations", {
                variant: "error",
            });
        },
    });

    const inviteeEmails = invitees
        .split(/,|\n|\t/)
        .map((email) => email.trim())
        .filter(Boolean);
    const hasError = !!errorMessage;

    const handleInvite = useCallback(() => {
        setErrorMessage("");
        intercom.trackEvent("clicked_invite_from_form", {
            inviteeEmails: inviteeEmails.join(", "),
        });
        void inviteUsers({ variables: { input: { emails: inviteeEmails } } });
    }, [inviteeEmails, inviteUsers, setErrorMessage, intercom]);

    const descriptionId = useId();

    return (
        <>
            <DialogTitle className={styles.title}>Invite people</DialogTitle>
            <DialogContent className={styles.content}>
                <p className={styles.description} id={descriptionId}>
                    {description}
                </p>
                <TextField
                    value={invitees}
                    onChange={(e) => setInvitees(e.target.value)}
                    multiline
                    placeholder="example@email.com, example@email.com…"
                    variant="outlined"
                    inputProps={{
                        "aria-label": "Email addresses to invite",
                        "aria-describedby": descriptionId,
                        className: styles.textField,
                    }}
                    FormHelperTextProps={{ classes: { error: styles.errorMessage } }}
                    error={hasError}
                    helperText={hasError && errorMessage}
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus
                />
                <div className={styles.buttonRow}>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                            onClose();
                            setInvitees("");
                            setErrorMessage("");
                        }}
                        className={cx(styles.button, styles.cancelButton)}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleInvite}
                        disabled={!invitees || loading}
                        className={cx(styles.button, styles.inviteButton)}
                        data-dd-action-name="Invite Users"
                    >
                        Invite
                    </Button>
                </div>
            </DialogContent>
        </>
    );
}

export default InviteUserForm;
