import { useEffect, useMemo, useState } from "react";

export function useMediaQuery(queryString: string): boolean {
    const mediaQuery = useMemo(() => window.matchMedia(queryString), [queryString]);
    const [matches, setMatches] = useState(mediaQuery.matches);

    useEffect(() => {
        const listener = () => setMatches(mediaQuery.matches);
        mediaQuery.addEventListener("change", listener);
        return () => mediaQuery.removeEventListener("change", listener);
    }, [mediaQuery]);

    return matches;
}
