import React, { ReactElement, useCallback } from "react";

import useAmplitudeTracker from "../../hooks/useAmplitudeTracker";
import analyticsIcon from "../../icons/analytics.svg";
import TopBarButton from "../TopBarButton/TopBarButton";

import EngagementInsightPopover from "./EngagementInsightPopover";
import styles from "./GuideViewStatsButton.module.scss";

interface Props {
    guideId: string;
    numViews: number;
    readOnly?: boolean;
}

function GuideViewStatsButton(props: Props): ReactElement {
    const { guideId, numViews, readOnly = false } = props;
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const { track } = useAmplitudeTracker();

    const openEngagementPopover = useCallback(
        (evt: React.MouseEvent<HTMLButtonElement>) => {
            track("Guide View Stats Button Clicked");
            setAnchorEl(evt.currentTarget);
        },
        [setAnchorEl, track],
    );

    if (readOnly) {
        return (
            <div className={styles.readOnlyTotalCount}>
                {numViews} view{numViews !== 1 && "s"}
            </div>
        );
    }

    return (
        <>
            <TopBarButton
                onClick={openEngagementPopover}
                startIcon={<img src={analyticsIcon} alt="" className={styles.icon} />}
            >
                {numViews} view{numViews !== 1 && "s"}
            </TopBarButton>
            <EngagementInsightPopover
                guideId={guideId}
                numViews={numViews}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
            />
        </>
    );
}

export default GuideViewStatsButton;
