import { Button, Dialog, DialogContent, DialogTitle, Icon, MenuItem } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { ReactElement, useCallback, useState } from "react";

import { TagType } from "../../../@types/graphql";
import TextInput from "../../components/TextInput/TextInput";

import styles from "./EditTagMenuItem.module.scss";
import { TagMutation, useTagMutation } from "./operations.generated";

interface Props {
    tag: TagType;
    iconClassName?: string;
}

function EditTagMenuItem(props: Props): ReactElement {
    const [formTagName, setFormTagName] = useState<string>(props.tag.name);
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const { enqueueSnackbar } = useSnackbar();

    const [updateTag, { loading }] = useTagMutation({
        refetchQueries: ["Tags"],
        onCompleted: (data: TagMutation) => {
            if (data.tag?.errors) {
                let errorMessage = "Something went wrong when editing the tag name.";
                if (
                    data.tag.errors.some(
                        (error) =>
                            error?.field === "name" &&
                            error?.messages.includes("Unique name validation failed."),
                    )
                ) {
                    errorMessage = "Unable to edit tag. Tag with that name already exists.";
                }
                enqueueSnackbar(errorMessage, {
                    variant: "error",
                });
            } else {
                setFormTagName("");
                setIsDialogOpen(false);
                enqueueSnackbar(`Tag name has been changed to "${formTagName}".`, {
                    variant: "success",
                });
            }
        },
        onError: () => {
            enqueueSnackbar("Something went wrong when editing the tag name.", {
                variant: "error",
            });
        },
    });
    const handleClick = useCallback(
        (formTagName: string) => {
            void updateTag({ variables: { input: { name: formTagName, id: props.tag.id } } });
        },
        [updateTag, props.tag.id],
    );
    const onDialogClose = useCallback(() => setIsDialogOpen(false), []);

    return (
        <>
            <MenuItem onClick={() => setIsDialogOpen(true)}>
                <Icon className={props.iconClassName}>edit_outline</Icon>Edit
            </MenuItem>
            <Dialog open={isDialogOpen} onClose={onDialogClose} classes={{ paper: styles.paper }}>
                <DialogTitle className={styles.title}>Edit a new tag</DialogTitle>
                <DialogContent>
                    <TextInput
                        value={formTagName}
                        placeholder={formTagName}
                        onChange={(e) => setFormTagName(e.target.value)}
                        label="Tag name"
                        disabled={loading}
                        // eslint-disable-next-line jsx-a11y/no-autofocus
                        autoFocus
                    />
                    <div className={styles.buttonRow}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleClick(formTagName)}
                            disabled={
                                loading || !formTagName.trim() || formTagName === props.tag.name
                            }
                        >
                            Edit tag
                        </Button>
                        <Button
                            variant="outlined"
                            className={styles.cancelButton}
                            onClick={onDialogClose}
                            disabled={loading}
                        >
                            Cancel
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default EditTagMenuItem;
