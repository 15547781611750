import { Icon } from "@mui/material";
import React, { MouseEvent, ReactElement, useCallback, useContext, useState } from "react";

import { UserContext } from "../../contexts/user";
import useAmplitudeTracker from "../../hooks/useAmplitudeTracker";
import { Guide } from "../../network/responseTypes";
import ProFeaturePopover from "../ProFeaturePopover/ProFeaturePopover";
import ShareGuidePopover from "../ShareGuidePopover/ShareGuidePopover";
import TopBarButton from "../TopBarButton/TopBarButton";

import styles from "./ShareGuideButton.module.scss";

interface Props {
    guide: Guide;
}

function ShareGuideButton(prop: Props): ReactElement {
    const { guide } = prop;
    const { organization } = useContext(UserContext);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const { track } = useAmplitudeTracker();

    const openPopover = useCallback(
        (ev: MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(ev.currentTarget);
            track("Guide Share Button Clicked");
        },
        [track],
    );

    const isMemberOfGuideOrg = guide.organization.id === organization.id;
    const canShare =
        guide.is_public ||
        !isMemberOfGuideOrg ||
        (organization.subscription?.shared_guide_limit_stats.isWithinLimit ?? false);

    return (
        <>
            <TopBarButton
                color="primary"
                startIcon={<Icon>{guide.is_public ? "public" : "people"}</Icon>}
                onClick={openPopover}
                data-dd-action-name="Share Guide Button"
            >
                Share
            </TopBarButton>
            {canShare && (
                <ShareGuidePopover
                    guide={guide}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                />
            )}
            {!canShare && (
                <ProFeaturePopover
                    open={!!anchorEl}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    title="Share more guides and invite your team"
                    description="You&rsquo;re over the 3 public guide limit on your Free plan. Upgrade to Pro to share unlimited guides and invite your team to Driveway."
                    arrowPosition="right"
                    className={styles.proFeaturePopover}
                />
            )}
        </>
    );
}

export default ShareGuideButton;
