import { useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";

import { QueryKey } from "../../queries/queryKeys";

import { useDeleteNarrationMutation } from "./operations.generated";

function useDeleteNarration(guideId: string) {
    const { enqueueSnackbar } = useSnackbar();
    const reactQueryClient = useQueryClient();
    return useDeleteNarrationMutation({
        onError: () => {
            enqueueSnackbar(
                "Something went wrong when trying to delete your voiceover. Please try again.",
                { variant: "error" },
            );
        },
        onCompleted: () => {
            // Remove when GuideDetail is migrated to graphql
            void reactQueryClient.invalidateQueries([QueryKey.GuideDetail, guideId]);
            enqueueSnackbar("The voiceover has been deleted.", {
                variant: "success",
            });
        },
    });
}

export default useDeleteNarration;
