import useAnimationFrame from "@phntms/use-animation-frame";
import { useCallback, useState } from "react";

const FRAMES_PER_SECOND = 30;

interface Options {
    from: number;
    to: number;
}

type ResetFunc = () => void;

/**
 * A hook which counts down a numeric value using requestAnimationFrame.
 */
export function useAnimationFrameCountdown(options: Options): [number, ResetFunc] {
    const { from, to } = options;
    const [value, setValue] = useState(from);

    useAnimationFrame(
        () => setValue((value) => (value > to ? value - 1 : value)),
        FRAMES_PER_SECOND,
    );

    const reset = useCallback(() => setValue(from), [from]);

    return [value, reset];
}
