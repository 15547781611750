import { CircularProgress, Icon, IconButton, Menu } from "@mui/material";
import React, { ReactElement } from "react";
import { Link } from "react-router-dom";

import { TagType } from "../../../@types/graphql";
import NetworkingWrapper from "../../components/NetworkingWrapper/NetworkingWrapper";
import TagChip from "../../components/TagsChip/TagChip";
import ellipsesIcon from "../../icons/ellipses.svg";
import infoIcon from "../../icons/info.svg";
import tagsIcon from "../../icons/tags.svg";
import { Card } from "../Card/Card";

import CreateTagButton from "./CreateTagButton";
import DeleteTagMenuItem from "./DeleteTagMenuItem";
import EditTagMenuItem from "./EditTagMenuItem";
import styles from "./TagsSettings.module.scss";
import { useTagsQuery } from "./operations.generated";

const TagItem = ({ tag }: { tag: TagType }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    return (
        <div className={styles.tagItem}>
            <div className={styles.tagTitle}>
                <Icon>
                    <img className={styles.tagsIcon} src={tagsIcon} alt="" />
                </Icon>
                <TagChip tag={tag} className={styles.tagChip} />
            </div>
            {tag.guideCount ? (
                <Link to={`/guides?tags=${tag.id}`} className={styles.numConnectedGuidesLink}>
                    {tag.guideCount}
                </Link>
            ) : (
                <span className={styles.numConnectedGuidesUnlink}>{tag.guideCount}</span>
            )}
            <div className={styles.actions}>
                <IconButton
                    size="small"
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                    aria-label="More"
                >
                    <Icon>
                        <img src={ellipsesIcon} className={styles.ellipsesIcon} alt="" />
                    </Icon>
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                >
                    <EditTagMenuItem tag={tag} iconClassName={styles.menuItemIcon} />
                    <DeleteTagMenuItem
                        tag={tag}
                        onSuccess={() => setAnchorEl(null)}
                        iconClassName={styles.menuItemIcon}
                    />
                </Menu>
            </div>
        </div>
    );
};

const TagList = ({ tags }: { tags: readonly TagType[] }) => {
    return (
        <>
            <div className={styles.tagListHeader}>
                <div className={styles.currentTags}>Current Tags</div>
                <div>Connected Guides</div>
            </div>
            <div className={styles.tagList}>
                {tags.map((tag) => (
                    <TagItem key={tag.id} tag={tag} />
                ))}
            </div>
            <div className={styles.tagListFooter}>
                <Icon>
                    <img className={styles.infoIcon} src={infoIcon} alt="" />
                </Icon>
                <div className={styles.tagListFooterDescription}>
                    Add tags to your guide pages to keep your workspace organized.
                </div>
            </div>
        </>
    );
};

const TagsEmpty = () => {
    return (
        <div className={styles.emptyContainer}>
            <div className={styles.emptyIconContainer}>
                <Icon>
                    <img className={styles.emptyTagsIcon} src={tagsIcon} alt="" />
                </Icon>
            </div>
            <div className={styles.emptyDescription}>
                Add your first tag to keep your workspace organized
            </div>
        </div>
    );
};

const TagsLoading = () => (
    <>
        <h2 className={styles.sectionTitle}>Workspace Tags</h2>
        <Card variant="content" className={styles.container}>
            <CircularProgress className={styles.loader} />
        </Card>
    </>
);

function TagsSettings(): ReactElement | null {
    const { data, error, loading } = useTagsQuery();

    return (
        <NetworkingWrapper
            loading={loading}
            error={error}
            errorComponent={<></>}
            loadingComponent={<TagsLoading />}
        >
            <>
                <h2 className={styles.sectionTitle}>
                    Workspace Tags{data?.tags ? ` (${data.tags.length})` : ""}
                </h2>
                <Card variant="content" className={styles.container}>
                    {!!data?.tags && (
                        <>
                            <p className={styles.txt}>
                                These are your workspace tags. You can edit a tag name, delete or
                                create a new one.
                            </p>
                            {data.tags.length ? <TagList tags={data.tags} /> : <TagsEmpty />}
                            <div>
                                <CreateTagButton />
                            </div>
                        </>
                    )}
                </Card>
            </>
        </NetworkingWrapper>
    );
}

export default TagsSettings;
