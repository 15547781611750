import cx from "classnames";
import React, { DOMAttributes, ReactElement, useCallback, useState } from "react";

import { useAutoplayingVideoRef } from "../../hooks/autoplayVideo";
import { VideoStep } from "../../network/responseTypes";
import PlayButton from "../PlayButton/PlayButton";

import styles from "./VideoPlayer.module.scss";

interface Props extends Pick<DOMAttributes<HTMLDivElement>, "onMouseMove"> {
    step: VideoStep;
    onNextStep: () => void;
    autoPlay?: boolean;
    className?: string;
}

function VideoPlayer(props: Props): ReactElement {
    const { step, onNextStep, autoPlay = false, onMouseMove, className } = props;

    const [didPlaybackStart, setPlaybackStarted] = useState(false);

    const { videoElRef, supportsAutoplay } = useAutoplayingVideoRef(autoPlay);

    const togglePlayback = useCallback(() => {
        const videoEl = videoElRef.current;
        if (videoEl) {
            if (!videoEl.paused) {
                videoEl.pause();
            } else {
                videoEl.play().catch(console.error);
            }
        }
    }, [videoElRef]);

    const showPlayButtonOverlay = (!autoPlay || !supportsAutoplay) && !didPlaybackStart;

    return (
        <div onMouseMove={onMouseMove} className={cx(styles.container, className)}>
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <video
                src={step.video.url}
                onPlay={() => setPlaybackStarted(true)}
                onEnded={onNextStep}
                onClick={togglePlayback}
                ref={videoElRef}
                autoPlay={autoPlay && supportsAutoplay}
                className={styles.video}
            />
            {showPlayButtonOverlay && (
                <>
                    <div className={styles.overlay} />
                    <PlayButton onClick={togglePlayback} className={styles.playButton} />
                </>
            )}
        </div>
    );
}

export default VideoPlayer;
