import { Icon } from "@mui/material";
import cx from "classnames";
import React, { ReactElement } from "react";

import SubscriptionUpgradeButton from "../SubscriptionUpgradeButton/SubscriptionUpgradeButton";
import UpgradePill from "../UpgradePill/UpgradePill";

import styles from "./CustomBrandingUpgradePrompt.module.scss";

interface Props {
    className?: string;
}

function CustomBrandingUpgradePrompt(props: Props): ReactElement {
    return (
        <div className={cx(styles.container, props.className)}>
            <div className={styles.inner}>
                <UpgradePill />
                <h4 className={styles.header}>Add Custom Branding</h4>
                <p className={styles.text}>
                    Upload your own logo and display it on your guide exports, embeds, and publicly
                    shared guide pages.
                </p>
                <div className={styles.recommended}>
                    <Icon className={styles.icon}>favorite_border</Icon>
                    For Customer Success Teams
                </div>
            </div>
            <SubscriptionUpgradeButton color="primary" variant="contained" fullWidth>
                Upgrade Now
            </SubscriptionUpgradeButton>
        </div>
    );
}

export default CustomBrandingUpgradePrompt;
