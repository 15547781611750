import { Button, Icon, IconButton, Popover, PopoverProps } from "@mui/material";
import React, { ReactElement, useState } from "react";

import { GuideViewType, GuideViewsByPersonType } from "../../../@types/graphql";

import styles from "./EngagementInsightPopover.module.scss";
import EngagementOverviewPanel from "./EngagementOverviewPanel";
import UserEngagementPanel from "./UserEngagementPanel";

interface Props extends Pick<PopoverProps, "anchorEl"> {
    guideId: string;
    numViews: number;
    onClose: () => void;
}

export interface PersonEngagementInfo {
    type: "person";
    viewer: GuideViewsByPersonType;
}

export interface AnonymousEngagementInfo {
    type: "anonymous";
    guideViewEvent: GuideViewType;
}

type Mode =
    | { type: "overview" }
    | { type: "userDetail"; info: PersonEngagementInfo | AnonymousEngagementInfo };

function EngagementInsightPopover(props: Props): ReactElement {
    const { guideId, anchorEl, onClose } = props;
    const [mode, setMode] = useState<Mode>({ type: "overview" });

    return (
        <Popover
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={() => onClose()}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            classes={{ paper: styles.paper }}
        >
            <div className={styles.buttons}>
                {mode.type !== "overview" && (
                    <Button
                        startIcon={<Icon>chevron_left</Icon>}
                        onClick={() => setMode({ type: "overview" })}
                    >
                        Back
                    </Button>
                )}
                <IconButton
                    onClick={onClose}
                    className={styles.closeButton}
                    size="small"
                    aria-label="Close"
                    edge="end"
                >
                    <Icon>close</Icon>
                </IconButton>
            </div>
            {mode.type === "overview" && (
                <EngagementOverviewPanel
                    {...props}
                    onSelectViewer={(info) => setMode({ type: "userDetail", info })}
                />
            )}
            {mode.type === "userDetail" && (
                <UserEngagementPanel guideId={guideId} info={mode.info} />
            )}
        </Popover>
    );
}

export default EngagementInsightPopover;
