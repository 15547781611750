import { useParams } from "react-router-dom";

type RequiredParams<Key extends string> = {
    readonly [key in Key]: string;
};

export function useRequiredParams<K extends string>(
    paramNames: ReadonlyArray<K>,
): RequiredParams<K> {
    const params = useParams();

    for (const paramName of paramNames) {
        if (!params[paramName]) {
            throw Error(`Param ${paramName} is required`);
        }
    }

    return params as RequiredParams<K>;
}
