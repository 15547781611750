/* eslint-disable */
// THIS IS A GENERATED FILE. DO NOT EDIT DIRECTLY
// To generate new type definitions run `npm run generate-schema-types`
import * as Types from '../../../../@types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StarGuideMutationVariables = Types.Exact<{
  input: Types.StarGuideMutationInput;
}>;


export type StarGuideMutation = { readonly __typename?: 'Mutation', readonly starGuide?: { readonly __typename?: 'StarGuideMutation', readonly ok?: boolean | null } | null };

export type GuidesQueryVariables = Types.Exact<{
  orderBy?: Types.InputMaybe<Types.Scalars['String']['input']>;
  tags?: Types.InputMaybe<ReadonlyArray<Types.InputMaybe<Types.Scalars['ID']['input']>> | Types.InputMaybe<Types.Scalars['ID']['input']>>;
  authors?: Types.InputMaybe<ReadonlyArray<Types.InputMaybe<Types.Scalars['UUID']['input']>> | Types.InputMaybe<Types.Scalars['UUID']['input']>>;
  onlyStarred?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type GuidesQuery = { readonly __typename?: 'Query', readonly guides?: { readonly __typename?: 'GuideTypeConnection', readonly pageInfo: { readonly __typename?: 'PageInfo', readonly hasNextPage: boolean, readonly endCursor?: string | null }, readonly edges: ReadonlyArray<{ readonly __typename?: 'GuideTypeEdge', readonly node?: { readonly __typename?: 'GuideType', readonly id: string, readonly title: string, readonly updatedAt: string, readonly numViews: number, readonly isStarred: boolean, readonly tags: ReadonlyArray<{ readonly __typename?: 'TagType', readonly id: string, readonly name: string }>, readonly author: { readonly __typename?: 'PersonType', readonly id: any, readonly fullName: string, readonly firstName: string, readonly lastName: string, readonly avatarUrl?: string | null } } | null } | null> } | null };


export const StarGuideDocument = gql`
    mutation StarGuide($input: StarGuideMutationInput!) {
  starGuide(input: $input) {
    ok
  }
}
    `;
export type StarGuideMutationFn = Apollo.MutationFunction<StarGuideMutation, StarGuideMutationVariables>;

/**
 * __useStarGuideMutation__
 *
 * To run a mutation, you first call `useStarGuideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStarGuideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [starGuideMutation, { data, loading, error }] = useStarGuideMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStarGuideMutation(baseOptions?: Apollo.MutationHookOptions<StarGuideMutation, StarGuideMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StarGuideMutation, StarGuideMutationVariables>(StarGuideDocument, options);
      }
export type StarGuideMutationHookResult = ReturnType<typeof useStarGuideMutation>;
export type StarGuideMutationResult = Apollo.MutationResult<StarGuideMutation>;
export type StarGuideMutationOptions = Apollo.BaseMutationOptions<StarGuideMutation, StarGuideMutationVariables>;
export const GuidesDocument = gql`
    query Guides($orderBy: String, $tags: [ID], $authors: [UUID], $onlyStarred: Boolean, $first: Int, $after: String) {
  guides(
    orderBy: $orderBy
    tags: $tags
    authors: $authors
    onlyStarred: $onlyStarred
    first: $first
    after: $after
  ) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        title
        tags {
          id
          name
        }
        author {
          id
          fullName
          firstName
          lastName
          avatarUrl
        }
        updatedAt
        numViews
        isStarred
      }
    }
  }
}
    `;

/**
 * __useGuidesQuery__
 *
 * To run a query within a React component, call `useGuidesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuidesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuidesQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      tags: // value for 'tags'
 *      authors: // value for 'authors'
 *      onlyStarred: // value for 'onlyStarred'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGuidesQuery(baseOptions?: Apollo.QueryHookOptions<GuidesQuery, GuidesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GuidesQuery, GuidesQueryVariables>(GuidesDocument, options);
      }
export function useGuidesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GuidesQuery, GuidesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GuidesQuery, GuidesQueryVariables>(GuidesDocument, options);
        }
export type GuidesQueryHookResult = ReturnType<typeof useGuidesQuery>;
export type GuidesLazyQueryHookResult = ReturnType<typeof useGuidesLazyQuery>;
export type GuidesQueryResult = Apollo.QueryResult<GuidesQuery, GuidesQueryVariables>;