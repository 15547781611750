/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { useChromeExtensionBetaEnabledQuery } from "../queries/chromeExtensionBetaEnabled";

export function useChromeExtensionId(): string | undefined {
    const { data: isBetaEnabled } = useChromeExtensionBetaEnabledQuery();

    if (isBetaEnabled === undefined) {
        return undefined;
    }

    return isBetaEnabled
        ? process.env.REACT_APP_BETA_CHROME_EXTENSION_ID!
        : process.env.REACT_APP_CHROME_EXTENSION_ID!;
}
