import React, { ReactElement } from "react";

import PageNotFoundErrorMessage from "../../components/PageNotFoundErrorMessage/PageNotFoundErrorMessage";

import styles from "./NotFoundPage.module.scss";

function NotFoundPage(): ReactElement {
    return (
        <div className={styles.container}>
            <PageNotFoundErrorMessage />
        </div>
    );
}

export default NotFoundPage;
