import {
    ChevronLeft as ChevronLeftIcon,
    ChevronRight as ChevronRightIcon,
    Link as CopyLinkIcon,
    FullscreenExit as FullscreenExitIcon,
    Fullscreen as FullscreenIcon,
    ZoomOutMap as ZoomEnabledIcon,
} from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import cx from "classnames";
import React, { ReactElement, useContext } from "react";

import { FullscreenContext } from "../../contexts/fullscreen";
import { useCopyGuideLink } from "../../hooks/copyGuideLink";
import SlideshowStepSlider from "../SlideshowStepSlider/SlideshowStepSlider";

import styles from "./SlideshowNavigation.module.scss";

interface Props {
    guideId: string;
    stepIndex: number;
    stepCount: number;
    onNextStep: () => void;
    onPreviousStep: () => void;
    onSetStep: (stepIndex: number) => void;
    onInteraction: () => void;
    enableWheelZoom: boolean;
    onToggleEnableWheelZoom: () => void;
    getStepScreenshotUrl: (stepIndex: number) => string | undefined;
    getStepColor: (stepIndex: number) => string | undefined;
    isVisible: boolean;
    showZoomButtons: boolean;
    className?: string;
}

function SlideshowNavigation(props: Props): ReactElement {
    const {
        guideId,
        stepIndex,
        stepCount,
        onNextStep,
        onPreviousStep,
        onSetStep,
        onInteraction,
        getStepScreenshotUrl,
        onToggleEnableWheelZoom,
        enableWheelZoom,
        getStepColor,
        isVisible,
        showZoomButtons,
        className,
    } = props;

    const { toggleFullscreen, isFullscreen } = useContext(FullscreenContext);
    const copyGuideLink = useCopyGuideLink(guideId);

    const isFirstStep = stepIndex === 0;
    const isLastStep = stepIndex === stepCount - 1;

    const handleNextStep = () => {
        onInteraction();
        onNextStep();
    };

    const handlePrevStep = () => {
        onInteraction();
        onPreviousStep();
    };

    return (
        <div
            className={cx(styles.container, { [styles.visible]: isVisible }, className)}
            onFocus={onInteraction}
        >
            <SlideshowStepSlider
                stepIndex={stepIndex}
                stepCount={stepCount}
                onPreviousStep={handlePrevStep}
                onNextStep={handleNextStep}
                onSetStep={(stepIndex) => {
                    onInteraction();
                    onSetStep(stepIndex);
                }}
                getStepScreenshotUrl={getStepScreenshotUrl}
                getStepColor={getStepColor}
                className={styles.stepSlider}
            />
            <div className={styles.navControls}>
                <IconButton
                    onClick={handlePrevStep}
                    aria-label="Previous step"
                    disabled={isFirstStep}
                    className={styles.prevStepButton}
                >
                    <ChevronLeftIcon />
                </IconButton>
                <div className={styles.stepCount}>
                    {stepIndex + 1} / {stepCount}
                </div>
                <IconButton
                    onClick={handleNextStep}
                    aria-label="Next step"
                    disabled={isLastStep}
                    className={styles.nextStepButton}
                >
                    <ChevronRightIcon />
                </IconButton>
                {showZoomButtons && (
                    <ToggleZoomButton
                        enableWheelZoom={enableWheelZoom}
                        onInteraction={onInteraction}
                        onClick={onToggleEnableWheelZoom}
                    />
                )}
                <Tooltip title="Copy link" placement="top" disableHoverListener={!isVisible}>
                    <IconButton
                        onClick={() => {
                            onInteraction();
                            copyGuideLink();
                        }}
                        className={styles.copyLinkButton}
                    >
                        <CopyLinkIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip
                    title={isFullscreen ? "Exit fullscreen" : "Enter fullscreen"}
                    placement="top"
                    disableHoverListener={!isVisible}
                >
                    <IconButton
                        onClick={() => {
                            onInteraction();
                            toggleFullscreen();
                        }}
                        disabled={!document.fullscreenEnabled}
                        className={styles.fullscreenButton}
                    >
                        {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                    </IconButton>
                </Tooltip>
            </div>
        </div>
    );
}

function ToggleZoomButton(
    props: Pick<Props, "onInteraction" | "enableWheelZoom"> & { onClick: () => void },
): ReactElement {
    const { onInteraction, enableWheelZoom, onClick } = props;
    return (
        <>
            <Tooltip title={enableWheelZoom ? "Disable zoom" : "Enable zoom"} placement="top">
                <IconButton
                    onClick={() => {
                        onInteraction();
                        onClick();
                    }}
                    className={cx(
                        styles.enableZoomButton,
                        enableWheelZoom ? styles.enableZoomButtonActive : undefined,
                    )}
                >
                    <ZoomEnabledIcon />
                </IconButton>
            </Tooltip>
        </>
    );
}

export default SlideshowNavigation;
