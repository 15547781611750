/* eslint-disable */
// THIS IS A GENERATED FILE. DO NOT EDIT DIRECTLY
// To generate new type definitions run `npm run generate-schema-types`
import * as Types from '../../../@types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VideoDetailQueryVariables = Types.Exact<{
  videoId: Types.Scalars['ID']['input'];
}>;


export type VideoDetailQuery = { readonly __typename?: 'Query', readonly videoDetail: { readonly __typename?: 'VideoType', readonly id: any, readonly url?: string | null } };


export const VideoDetailDocument = gql`
    query VideoDetail($videoId: ID!) {
  videoDetail(id: $videoId) {
    id
    url
  }
}
    `;

/**
 * __useVideoDetailQuery__
 *
 * To run a query within a React component, call `useVideoDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useVideoDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVideoDetailQuery({
 *   variables: {
 *      videoId: // value for 'videoId'
 *   },
 * });
 */
export function useVideoDetailQuery(baseOptions: Apollo.QueryHookOptions<VideoDetailQuery, VideoDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VideoDetailQuery, VideoDetailQueryVariables>(VideoDetailDocument, options);
      }
export function useVideoDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VideoDetailQuery, VideoDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VideoDetailQuery, VideoDetailQueryVariables>(VideoDetailDocument, options);
        }
export type VideoDetailQueryHookResult = ReturnType<typeof useVideoDetailQuery>;
export type VideoDetailLazyQueryHookResult = ReturnType<typeof useVideoDetailLazyQuery>;
export type VideoDetailQueryResult = Apollo.QueryResult<VideoDetailQuery, VideoDetailQueryVariables>;