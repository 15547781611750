import { Button, ButtonProps } from "@mui/material";
import cx from "classnames";
import React, { ReactElement } from "react";

import styles from "./MessageBubbleButton.module.scss";

type Props = ButtonProps;

function MessageBubbleButton(props: Props): ReactElement {
    const { className, ...otherProps } = props;
    return <Button variant="outlined" className={cx(styles.button, className)} {...otherProps} />;
}

export default MessageBubbleButton;
