import { Dialog, Icon, IconButton, ToggleButton, ToggleButtonGroup } from "@mui/material";
import cx from "classnames";
import React, { ReactElement } from "react";

import SubscriptionPricingTierCard from "./SubscriptionPricingTierCard";
import styles from "./SubscriptionUpgradeDialog.module.scss";

interface Props {
    isOpen: boolean;
    onClose: () => void;
}

function SubscriptionUpgradeDialog(props: Props): ReactElement | null {
    const { isOpen, onClose } = props;
    const [billingPeriod, setBillingPeriod] = React.useState<"monthly" | "annual">("monthly");
    return (
        <Dialog
            classes={{ paper: styles.paper }}
            maxWidth="lg"
            fullWidth
            open={isOpen}
            onClose={onClose}
        >
            <div className={styles.blurredBackground} />
            <IconButton aria-label="Close" onClick={onClose} className={styles.closeButton}>
                <Icon>close</Icon>
            </IconButton>
            <div className={styles.billingPeriodContainer}>
                {/* Hidden "Save 20%" in order to center the toggle buttons */}
                <div className={cx(styles.saving, styles.hidden)} aria-hidden>
                    Save 20%
                </div>
                <ToggleButtonGroup
                    value={billingPeriod}
                    exclusive
                    onChange={(event, value) => {
                        if (value) {
                            setBillingPeriod(value);
                        }
                    }}
                    aria-label="Billing period"
                    className={styles.billingPeriodButtonGroup}
                >
                    <ToggleButton
                        value="monthly"
                        aria-label="Monthly"
                        classes={{
                            root: styles.billingPeriodButton,
                            selected: styles.selected,
                        }}
                    >
                        Monthly
                    </ToggleButton>
                    <ToggleButton
                        value="annual"
                        aria-label="Annual"
                        classes={{
                            root: styles.billingPeriodButton,
                            selected: styles.selected,
                        }}
                    >
                        Annual
                    </ToggleButton>
                </ToggleButtonGroup>
                <div className={styles.saving}> Save 20%</div>
            </div>
            <div className={styles.pricingTiers}>
                <SubscriptionPricingTierCard tier="FREE" className={styles.pricingTierCard} />
                <SubscriptionPricingTierCard
                    tier="PRO"
                    billingPeriod={billingPeriod}
                    className={styles.pricingTierCard}
                />
                <SubscriptionPricingTierCard tier="ENTERPRISE" className={styles.pricingTierCard} />
            </div>
        </Dialog>
    );
}

export default SubscriptionUpgradeDialog;
