import React, { ReactElement, useContext, useEffect } from "react";

import DownloadExtensionMessage from "../../components/DownloadExtensionDialog/DownloadExtensionMessage";
import NetworkingWrapper from "../../components/NetworkingWrapper/NetworkingWrapper";
import { UserContext } from "../../contexts/user";
import { useSendGuideToExtensionMutation } from "../../mutations/sendGuideToExtension";
import { useChromeExtensionInstalledQuery } from "../../queries/chromeExtensionInstalled";
import { useGuideDetailQuery } from "../../queries/guideDetail";
import { useRequiredParams } from "../../util/ReactRouterUtil";

import styles from "./LiveViewRedirectPage.module.scss";

function LiveViewRedirectPage(): ReactElement {
    const { guideId } = useRequiredParams(["guideId"]);
    const { isAuthenticated } = useContext(UserContext);
    const {
        data: extensionInstalled,
        isLoading: isExtensionInstalledLoading,
        isError: isExtensionInstalledError,
        refetch: refetchExtensionInstalled,
    } = useChromeExtensionInstalledQuery();
    const {
        data: guide,
        isLoading: isGuideDetailLoading,
        isError: isGuideDetailError,
        refetch: refetchGuideDetail,
    } = useGuideDetailQuery(guideId, {
        retry: false,
        onError: () => {
            if (!isAuthenticated) {
                window.location.replace(`/accounts/login/?next=${location.pathname}`);
            }
        },
    });
    const { mutate: sendGuideToExtension, isError: isSendGuideToExtensionError } =
        useSendGuideToExtensionMutation();
    useEffect(() => {
        if (guide && extensionInstalled) {
            sendGuideToExtension({ guide, openInCurrentTab: true });
        }
    }, [guide, extensionInstalled, sendGuideToExtension]);

    return (
        <NetworkingWrapper
            loading={isExtensionInstalledLoading || isGuideDetailLoading}
            error={isExtensionInstalledError || isGuideDetailError || isSendGuideToExtensionError}
            refetch={() => {
                if (isSendGuideToExtensionError) {
                    // Reload the page to refresh the query results，and avoid triggering the mutation
                    // effect multiple times.
                    location.reload();
                    return;
                }
                isExtensionInstalledError && refetchExtensionInstalled().catch(console.error);
                isGuideDetailError && refetchGuideDetail().catch(console.error);
            }}
            errorComponent={isAuthenticated ? undefined : "redirecting to login page..."}
        >
            <div className={styles.container}>
                {extensionInstalled ? (
                    "Starting the interactive walkthrough of this guide..."
                ) : (
                    <div className={styles.downloadExtensionMessage}>
                        <DownloadExtensionMessage showGuideDetailPageLink guideId={guideId} />
                    </div>
                )}
            </div>
        </NetworkingWrapper>
    );
}

export default LiveViewRedirectPage;
