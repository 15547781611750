import { Button } from "@mui/material";
import cx from "classnames";
import React, { ReactElement } from "react";

import chromeIcon from "../../icons/chrome.svg";
import { useChromeExtensionInstalledQuery } from "../../queries/chromeExtensionInstalled";

import styles from "./ChromeExtensionButton.module.scss";

interface Props {
    className?: string;
}

function ChromeExtensionButton(props: Props): ReactElement | null {
    const { className } = props;

    const { data: extensionInstalled } = useChromeExtensionInstalledQuery();

    return extensionInstalled ? null : (
        <Button
            color="primary"
            variant="contained"
            className={cx(styles.installButton, className)}
            component="a"
            href={`https://chrome.google.com/webstore/detail/driveway/${process.env.REACT_APP_CHROME_EXTENSION_ID}/`}
            target="_blank"
            rel="noreferrer"
        >
            <img src={chromeIcon} className={styles.chromeIcon} alt="" />
            Install extension
        </Button>
    );
}

export default ChromeExtensionButton;
