import { Tab, TabProps } from "@mui/material";
import React, { ReactElement } from "react";

import styles from "./Pill.module.scss";

interface Props extends TabProps {
    label: string;
    value: string;
}

/*
- drop this into a component and pass in the display name of the pill, the activePill from the component you are dropping it into, and a setter function to set the active pill
*/
function Pill(props: Props): ReactElement {
    return (
        <Tab
            {...props}
            aria-label={props.label}
            classes={{
                root: styles.pillRoot,
                selected: styles.selectedPill,
            }}
        />
    );
}

export default Pill;
