import Axios from "axios";

export function setUpAxios(organizationId: string | undefined): void {
    // Django defaults; see https://docs.djangoproject.com/en/3.1/ref/csrf/
    Axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
    Axios.defaults.xsrfCookieName = "drivewaycsrf";
    if (organizationId) {
        Axios.defaults.headers.common["X-ORG-ID"] = organizationId;
    } else {
        delete Axios.defaults.headers.common["X-ORG-ID"];
    }
}
