import { Button } from "@mui/material";
import React, { ReactElement } from "react";

import styles from "./FullScreenError.module.scss";

interface Props {
    retry?: () => void;
}

function FullScreenError(props: Props): ReactElement {
    const { retry } = props;

    return (
        <div className={styles.container}>
            <h2>Uh oh! Something went wrong.</h2>
            {retry && (
                <Button size="large" variant="contained" color="secondary" onClick={retry}>
                    Retry
                </Button>
            )}
        </div>
    );
}

export default FullScreenError;
