import React, { ReactElement, useCallback, useEffect } from "react";

import { useViewEventRecorder } from "../../hooks/viewEventRecorder";
import { ViewEventSource } from "../../mutations/recordGuideView";
import { Guide, Step } from "../../network/responseTypes";
import GuidePreview, { Props as GuidePreviewProps } from "../GuidePreview/GuidePreview";

interface Props extends GuidePreviewProps {
    guide: Guide;
    eventSource: ViewEventSource;
}

function EventTrackingGuidePreview({ stepIndex, onSetStep, ...props }: Props): ReactElement {
    const { guide, eventSource } = props;

    const recordViewEvent = useViewEventRecorder({
        source: eventSource,
    });

    const { id: guideId } = guide;
    useEffect(() => recordViewEvent({ type: "guide", guideId }), [recordViewEvent, guideId]);

    const onStepChange = useCallback(
        (step: Step, stepIndex: number, totalSteps: number) => {
            recordViewEvent({
                type: "step",
                stepId: step.id,
                stepIndex,
                totalSteps,
            });
        },
        [recordViewEvent],
    );

    const goToPreviousStep = () => {
        const isFirstStep = guide?.steps && stepIndex === 0;
        if (!isFirstStep) {
            onSetStep(stepIndex - 1);
        }
    };

    const goToNextStep = () => {
        const isLastStep = guide?.steps && stepIndex === guide?.steps.length - 1;
        if (!isLastStep) {
            onSetStep(stepIndex + 1);
        }
    };

    return (
        <GuidePreview
            guide={guide}
            stepIndex={stepIndex}
            onNextStep={goToNextStep}
            onPreviousStep={goToPreviousStep}
            onRestart={() => onSetStep(0)}
            onSetStep={onSetStep}
            canComplete={false}
            onStepChange={onStepChange}
        />
    );
}

export default EventTrackingGuidePreview;
