/* eslint-disable */
// THIS IS A GENERATED FILE. DO NOT EDIT DIRECTLY
// To generate new type definitions run `npm run generate-schema-types`
import * as Types from '../../../@types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UploadProfileAvatarMutationVariables = Types.Exact<{
  file: Types.Scalars['Upload']['input'];
}>;


export type UploadProfileAvatarMutation = { readonly __typename?: 'Mutation', readonly uploadProfileAvatar?: { readonly __typename?: 'UploadProfileAvatarMutation', readonly ok?: boolean | null } | null };


export const UploadProfileAvatarDocument = gql`
    mutation UploadProfileAvatar($file: Upload!) {
  uploadProfileAvatar(file: $file) {
    ok
  }
}
    `;
export type UploadProfileAvatarMutationFn = Apollo.MutationFunction<UploadProfileAvatarMutation, UploadProfileAvatarMutationVariables>;

/**
 * __useUploadProfileAvatarMutation__
 *
 * To run a mutation, you first call `useUploadProfileAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadProfileAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadProfileAvatarMutation, { data, loading, error }] = useUploadProfileAvatarMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadProfileAvatarMutation(baseOptions?: Apollo.MutationHookOptions<UploadProfileAvatarMutation, UploadProfileAvatarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadProfileAvatarMutation, UploadProfileAvatarMutationVariables>(UploadProfileAvatarDocument, options);
      }
export type UploadProfileAvatarMutationHookResult = ReturnType<typeof useUploadProfileAvatarMutation>;
export type UploadProfileAvatarMutationResult = Apollo.MutationResult<UploadProfileAvatarMutation>;
export type UploadProfileAvatarMutationOptions = Apollo.BaseMutationOptions<UploadProfileAvatarMutation, UploadProfileAvatarMutationVariables>;