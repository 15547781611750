import { useCallback } from "react";

import { useCopyToClipboard } from "./copyToClipboard";

export function useCopyGuideLink(guideId: string): () => void {
    const copyToClipboard = useCopyToClipboard();
    return useCallback(() => {
        const url = `${window.location.origin}/guides/${guideId}`;
        copyToClipboard(url, "guide link");
    }, [copyToClipboard, guideId]);
}
