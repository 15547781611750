import { Organization } from "../network/responseTypes";
import { isPaidTier } from "../util/SubscriptionUtil";

export function isGuideCustomizationEnabled(organization: Organization): boolean {
    const { subscription } = organization;

    return (
        !!subscription &&
        (subscription.tier
            ? isPaidTier(subscription.tier)
            : !subscription.is_trial && !subscription.is_expired)
    );
}
