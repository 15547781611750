import { useCallback } from "react";
import { useSnackbar } from "notistack";

export function useCopyToClipboard(): (value: string, name?: string) => void {
    const { enqueueSnackbar } = useSnackbar();
    return useCallback(
        (value: string, name = "text") => {
            navigator.clipboard
                .writeText(value)
                .then(() =>
                    enqueueSnackbar(`The ${name} has been copied to your clipboard`, {
                        variant: "success",
                    }),
                )
                .catch((err) => {
                    enqueueSnackbar(`Failed to copy the ${name} to clipboard. Please try again.`, {
                        variant: "error",
                    });
                    console.error(err);
                });
        },
        [enqueueSnackbar],
    );
}
